// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint react/prop-types: 0 */
// mostly copied from https://code.amazon.com/packages/MeridianComponents/blobs/5.x/--/src/components/progress-tracker/progress-tracker-step.js
// some changes made to add the checkmark for acknowledgements

import Loader from '@amzn/meridian/loader';
import Context from '@amzn/meridian/progress-tracker/progress-tracker-context';
import checkCircleTokens from '@amzn/meridian-tokens/base/icon/check-circle';
import { spacingXsmall } from '@amzn/meridian-tokens/base/spacing';
import { css } from 'emotion';
import uniqueId from 'lodash/uniqueId';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useMemo } from 'react';
import { useAppDispatch } from '../../context/store';
import { labels } from '../../lib/labels';
import { setCaseDetails } from '../../slices/CaseDetailsSlice';
import { addToast } from '../../slices/ToastSlice';
import { useLazyAcknowledgeUpdateQuery } from '../../slices/WotManApiSlice';
import CustomMilestone from './CustomMilestone';
import CustomTrack, { getTrackDimensions } from './CustomTrack';
import { memoizeTokenStyles } from './MeridianUtils';
import TitledButton from './TitledButton';

const styles = memoizeTokenStyles(
    (t, { direction }) =>
        css({
            // Reset the default margin on figure tags
            margin: '0',
            display: 'flex',
            flexDirection: direction === 'row' ? 'column' : 'row',
            '& [role=img]': {
                display: 'flex',
                flexDirection: direction
            }
        }),
    ['direction']
);

const contentStyles = memoizeTokenStyles(
    (t, { direction, rowLabelAlignment, trackLength, size }) => {
        const { lengthInPx, widthInPx } = getTrackDimensions(t, {
            length: trackLength,
            size
        });
        const milestoneDiameter = t('milestoneDiameter', size);
        const column = direction === 'column';
        const row = !column;
        const rowCenter = row && rowLabelAlignment === 'center';
        return css({
            display: 'flex',
            flexDirection: 'column',
            flex: 'auto',
            alignItems: rowCenter ? 'center' : row ? 'flex-start' : 'stretch',
            // Constrain the width of the label and children in a horizontal progress
            // tracker to the width of a milestone. This won't actually squish the
            // content into the width of a milestone because the label and children
            // have their own widths set to the length of the track, but it will
            // ensure that the label and children don't affect the overall width of
            // the progress tracker in the page layout (making it easier to center the
            // progress tracker).
            width: rowCenter ? milestoneDiameter : undefined,
            // HACK: IE11 does not support padding-inline-* so we have
            // to use directional padding-left/right. If/when we drop IE11 we can simplify
            // these styles to be:
            // {
            //    [row ? "paddingTop" : "paddingInlineStart"]: widthInPx,
            // }
            [row ? 'paddingTop' : 'paddingLeft']: widthInPx,
            [`[dir="rtl"] &`]: {
                [row ? 'paddingTop' : 'paddingRight']: widthInPx
            },
            // The label is vertically aligned to the right of the milestone for
            // vertical trackers. It is horizontally aligned below the milestone for
            // horizontal trackers.
            '& [data-mdn-progress-tracker-step-label]': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: rowCenter ? 'center' : undefined,
                textAlign: rowCenter ? 'center' : undefined,
                width: row ? lengthInPx : undefined,
                height: row ? undefined : milestoneDiameter
            },
            // Children are placed below the label. They are left aligned and
            // constrained to the height of the track for vertical trackers. They are
            // center aligned and constrained to the width of the track for horizontal
            // trackers.
            '& [data-mdn-progress-tracker-step-children]': {
                boxSizing: 'border-box',
                display: 'flex',
                flex: 'auto',
                justifyContent: rowCenter ? 'center' : undefined,
                textAlign: rowCenter ? 'center' : undefined,
                width: row ? lengthInPx : undefined,
                // Don't constrain the height of the children for the last step (there
                // are no more steps below that we have to worry about overlapping)
                paddingTop: column && size === 'small' ? spacingXsmall : undefined,
                overflow: 'hidden'
            }
        });
    },
    ['direction', 'rowLabelAlignment', 'trackLength', 'size', 'lastStep']
);

const hiddenStyles = css({
    display: 'none'
});

/**
 * @since 4.x
 */
function AcknowledgeableProgressTrackerStep(props) {
    const context = useContext(Context);
    const dispatch = useAppDispatch();
    const id = useMemo(() => uniqueId('mdn-pt-step-'), []);
    const lastStep = !context.nextStepType;
    const showLabel = props.showLabel === undefined ? context.size === 'large' : props.showLabel;
    const [useAcknowledgeUpdate, acknowledgeUpdateEndpoint] = useLazyAcknowledgeUpdateQuery();

    useEffect(() => {
        if (!acknowledgeUpdateEndpoint.isFetching && acknowledgeUpdateEndpoint.isSuccess) {
            dispatch(setCaseDetails(acknowledgeUpdateEndpoint.data));
            dispatch(
                addToast({
                    type: 'success',
                    action: 'Acknowledged update to ',
                    message: props.caseName,
                    SIM: false,
                    timeout: 3000
                })
            );
        } else if (!acknowledgeUpdateEndpoint.isFetching && acknowledgeUpdateEndpoint.isError) {
            dispatch(
                addToast({
                    type: 'error',
                    action: 'There was an error acknowledging update',
                    message: '',
                    SIM: true,
                    timeout: undefined
                })
            );
        }
    }, [acknowledgeUpdateEndpoint.isFetching]);

    const onClick = () => {
        useAcknowledgeUpdate({
            incidentId: props.caseId,
            updateId: props.updateId
        });
    };

    return (
        <li className={styles(context.t, { direction: context.direction })}>
            <div style={{ width: '34px' }}>
                {props.needsAcknowledgement && !acknowledgeUpdateEndpoint.isFetching && (
                    <TitledButton
                        title={labels.ackUpdate}
                        buttonProps={{
                            className: 'AcknowledgeButton IconPadding',
                            type: 'icon',
                            size: 'small',
                            onClick: onClick
                        }}
                        iconProps={{
                            tokens: checkCircleTokens,
                            className: 'BlueCheck'
                        }}
                    />
                )}
                {acknowledgeUpdateEndpoint.isFetching && <Loader size='medium' />}
            </div>
            <div
                role='img'
                aria-labelledby={`${id}-label ${id}-label-type`}
                aria-describedby={props.children ? `${id}-children` : undefined}
            >
                <CustomMilestone
                    type={props.type}
                    size={context.size}
                    alert={props.alert}
                    showIcon={props.showIcon}
                    neutral={context.neutral}
                />
                <CustomTrack
                    direction={context.direction}
                    size={context.size}
                    length={props.trackLength}
                    neutral={context.neutral}
                    type={props.type}
                />
            </div>
            <div
                style={{ width: 'calc(100%  - 32px)' }}
                aria-hidden='true'
                className={
                    showLabel
                        ? contentStyles(context.t, {
                              direction: context.direction,
                              rowLabelAlignment: context.rowLabelAlignment,
                              trackLength: props.trackLength,
                              size: context.size,
                              lastStep
                          })
                        : hiddenStyles
                }
            >
                <div id={`${id}-label`} data-mdn-progress-tracker-step-label=''>
                    {props.label}
                </div>
                {props.children ? (
                    <div
                        style={{ marginBottom: '32px', marginTop: '16px' }}
                        id={`${id}-children`}
                        data-mdn-progress-tracker-step-children=''
                    >
                        {props.children}
                    </div>
                ) : null}
                <div id={`${id}-label-type`} className={hiddenStyles}>
                    {
                        {
                            past: context.labelPast,
                            present: context.labelPresent,
                            future: context.labelFuture
                        }[props.type]
                    }
                </div>
            </div>
        </li>
    );
}

AcknowledgeableProgressTrackerStep.propTypes = {
    /**
     * A label for the step.
     *
     * This should describe what the step represents (e.g.
     * "Shipped" or "Delivered"). This is required to ensure the progress tracker
     * is accessible. This should be relatively short, use the `children` prop for
     * long content.
     *
     * This will not be visible in the UI for compact progress
     * trackers. If you do not wish to show this in the UI for non-compact
     * progress trackers you can set the "showLabel" prop to false.
     */
    label: PropTypes.node.isRequired,
    /**
     * The temporal state of the step.
     */
    type: PropTypes.oneOf(['past', 'present', 'future']).isRequired,
    /**
     * Set this to show the step in an alert state.
     */
    alert: PropTypes.oneOf(['error', 'warning', 'none']),
    /**
     * Additional content to render below the step's label. This will only be
     * visible if the label is visible.
     */
    children: PropTypes.node,
    /**
     * An id for the step that is unique among the other steps in the same
     * progress tracker.
     *
     * This is only necessary if you plan to add, remove, or
     * re-order steps after they are first rendered. Note that this id is not
     * rendered to the DOM, this is simply used to track the step internally.
     */
    id: PropTypes.string,
    /**
     * Show an icon in the step's milestone circle.
     *
     * This only applies to steps that are not in an alert state and are rendered
     * in non-compact progress trackers.
     */
    showIcon: PropTypes.bool,
    /**
     * Show the step's label (and children) in the UI.
     *
     * This defaults to true for regular progress trackers and false for compact
     * progress trackers.
     */
    showLabel: PropTypes.bool,
    /**
     * Use this to adjust the step's track length.
     *
     * Note that this is not a pixel value, this is the number of "dots" that make up the track. This must be an
     * even integer (other numbers will be rounded to the nearest even integer).
     * This prop will not affect the display of the last step in the progress
     * tracker (the last step does not have a track associated with it).
     */
    caseId: PropTypes.number,
    caseName: PropTypes.string,
    trackLength: PropTypes.number,
    needsAcknowledgement: PropTypes.bool,
    onClick: PropTypes.func,
    updateId: PropTypes.number
};

AcknowledgeableProgressTrackerStep.defaultProps = {
    alert: 'none',
    trackLength: 6,
    showIcon: true,
    needsAcknowledgement: false
};

export default AcknowledgeableProgressTrackerStep;
