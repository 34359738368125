import Badge from '@amzn/meridian/badge';
import Icon from '@amzn/meridian/icon';
import clockIconTokens from '@amzn/meridian-tokens/base/icon/clock';
import metricsIconTokens from '@amzn/meridian-tokens/base/icon/metrics';
import viewIconTokens from '@amzn/meridian-tokens/base/icon/view';
import React, { FunctionComponent } from 'react';
import AllCases from '../components/CasePages/AllCases';
import CreateCase from '../components/CasePages/CreateCase';
import Dashboard from '../components/CasePages/Dashboard';
import MyCases from '../components/CasePages/MyCases';
import NewCases from '../components/CasePages/NewCases';
import UpdatedCases from '../components/CasePages/UpdatedCases';
import AdvancedSearch from '../components/Cases/AdvancedSearch/AdvancedSearch';
import CaseDetails from '../components/Cases/CaseDetails';
import Consultations from '../components/Consultations/Consultations';
import Metrics from '../components/Metrics/Metrics';
import Settings from '../components/Settings/Settings';
import {
    alertCircleIconTokens,
    allInclusiveIconTokens,
    homeIconTokens,
    phoneIconTokens
} from './customIcons';

interface Routes {
    readonly path: string;
    readonly label: string;
    readonly component: FunctionComponent;
}

interface NavRouteConstants extends Routes {
    readonly iconTokens: React.ComponentProps<typeof Icon>['tokens'];
    readonly notification?: JSX.Element;
    readonly numberOfNotifications?: number;
}

export const dashboardRoute: NavRouteConstants = {
    path: '/',
    label: 'Dashboard',
    iconTokens: homeIconTokens,
    notification: (
        <span className='CollapsedBadge'>
            <Badge type='error' />
        </span>
    ),
    component: Dashboard
};

export const newCasesRoute: NavRouteConstants = {
    path: '/newCases',
    label: 'New Cases',
    iconTokens: alertCircleIconTokens,
    notification: (
        <span className='CollapsedBadge'>
            <Badge type='theme' />
        </span>
    ),
    component: NewCases
};

export const updatedCasesRoute: NavRouteConstants = {
    path: '/updatedCases',
    label: 'Updated Cases',
    iconTokens: clockIconTokens,
    notification: (
        <span className='CollapsedBadge'>
            <Badge />
        </span>
    ),
    component: UpdatedCases
};

const myCasesRoute: NavRouteConstants = {
    path: '/myCases',
    label: 'My Cases',
    iconTokens: viewIconTokens,
    component: MyCases
};

const allCasesRoute: NavRouteConstants = {
    path: '/allCases',
    label: 'All Cases',
    iconTokens: allInclusiveIconTokens,
    component: AllCases
};

const consultationsRoute: NavRouteConstants = {
    path: '/engagements',
    label: 'Engagements',
    iconTokens: phoneIconTokens,
    component: Consultations
};

const metricsRoute: NavRouteConstants = {
    path: '/metrics',
    label: 'Metrics',
    iconTokens: metricsIconTokens,
    component: Metrics
};

const settingsRoute: Routes = {
    path: '/settings',
    label: 'Settings',
    component: Settings
};

const createCaseRoute: Routes = {
    path: '/createCase',
    label: 'Create Case',
    component: CreateCase
};

const caseDetailsRoute: Routes = {
    path: '/cases/:caseId',
    label: 'Case Details',
    component: CaseDetails
};

const searchRoute: Routes = {
    path: '/search',
    label: 'Search',
    component: AdvancedSearch
};

const navRoutes = [
    dashboardRoute,
    newCasesRoute,
    updatedCasesRoute,
    myCasesRoute,
    allCasesRoute,
    consultationsRoute,
    metricsRoute
];

export const allRoutes = [...navRoutes, settingsRoute, createCaseRoute, caseDetailsRoute, searchRoute];

export default navRoutes;
