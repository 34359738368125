import Alert from '@amzn/meridian/alert';
import Link from '@amzn/meridian/link';
import Toaster from '@amzn/meridian/toaster';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../context/store';
import { SIM_LINK } from '../../lib/constants';
import { closeToast } from '../../slices/ToastSlice';

const ToastAlert: React.FunctionComponent = () => {
    const { toasts } = useSelector((state: RootState) => state.toast);
    const dispatch = useAppDispatch();
    const onCloseToast = useCallback((id) => dispatch(closeToast(id)), [toasts]);

    return (
        <React.Fragment>
            <Toaster toasts={toasts} onCloseToast={onCloseToast}>
                {(toast) =>
                    toast.type == 'success' ? (
                        <Alert toast={true} type={toast.type}>
                            <strong>{toast.action}</strong>
                            {toast.message}
                        </Alert>
                    ) : !toast.SIM ? (
                        <Alert toast={true} type={toast.type}>
                            <strong>{toast.action}</strong>
                            {toast.message}
                        </Alert>
                    ) : (
                        <Alert toast={true} type={toast.type} onClose={toast.onClose}>
                            <strong>{toast.action}</strong>
                            {toast.message}. Try again or{' '}
                            <Link href={SIM_LINK} target='_blank' rel='noopener noreferrer'>
                                {' '}
                                open a SIM ticket
                            </Link>
                        </Alert>
                    )
                }
            </Toaster>
        </React.Fragment>
    );
};

export default ToastAlert;
