import Card from '@amzn/meridian/card';
import Column from '@amzn/meridian/column';
import Link from '@amzn/meridian/link';
import Row from '@amzn/meridian/row';
import Tag from '@amzn/meridian/tag';
import Text from '@amzn/meridian/text';
import closeTokens from '@amzn/meridian-tokens/base/icon/close';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useAppDispatch } from '../../context/store';
import { Consultation } from '../../lib/CaseDefinitions';
import { WIDTH_OF_CARD, WIDTHS_OF_CONSULTATION_HEADER_COLUMNS } from '../../lib/constants';
import { renderDateDiff } from '../../lib/helpers';
import { labels } from '../../lib/labels';
import { setConsultationUnlinking, setDisplayingUnlinkModal } from '../../slices/CaseDetailsSlice';
import { setConsultationId } from '../../slices/ConsultationsSlice';
import ConsultationSiteLocations from '../CustomComponents/ConsultationSiteLocations';
import TitledButton from '../CustomComponents/TitledButton';

type ConsultationHeaderProps = {
    consultationHeader: Consultation;
    isUnlinkable?: boolean;
};

const ConsultationHeader: React.FunctionComponent<ConsultationHeaderProps> = ({
    consultationHeader,
    isUnlinkable = false
}: ConsultationHeaderProps) => {
    const history = useHistory();
    const dispatch = useAppDispatch();

    return (
        <Card
            maxWidth={WIDTH_OF_CARD}
            width={'100%'}
            spacingInset='400'
            onClick={() => dispatch(setConsultationId(consultationHeader.consultationId))}
            tag={'a'}
        >
            <Row widths={WIDTHS_OF_CONSULTATION_HEADER_COLUMNS} alignmentVertical='stretch'>
                <Column spacing='none'>
                    <Text type='h200'> {consultationHeader.name} </Text>
                </Column>
                <Column spacing='none'>
                    <Text type='h200'> Method </Text>
                    <Text type='b300'>{consultationHeader.method} </Text>
                </Column>
                <Column spacing='none'>
                    <ConsultationSiteLocations consultationHeader={consultationHeader} />
                </Column>
                <Column spacing='none'>
                    <Text type='h200'> Added by </Text>
                    <Text type='b300'>
                        {consultationHeader.added.firstName} {consultationHeader.added.lastName}
                    </Text>
                    <Text> {renderDateDiff(consultationHeader.added.timeAdded)}</Text>
                </Column>
                <Column spacing='none' heights={['100%']}>
                    {consultationHeader.linkedCases.length > 0 ? (
                        isUnlinkable ? (
                            <Row alignmentHorizontal='right' alignmentVertical='center'>
                                <TitledButton
                                    title={labels.unlinkConsultation}
                                    buttonProps={{
                                        onClick: () => {
                                            dispatch(setConsultationUnlinking(consultationHeader));
                                            dispatch(setDisplayingUnlinkModal('consultation'));
                                        },
                                        type: 'secondary',
                                        size: 'small'
                                    }}
                                    iconProps={{ tokens: closeTokens }}
                                />
                            </Row>
                        ) : (
                            <Column spacing='none'>
                                <Text> Linked to </Text>
                                {consultationHeader.linkedCases.map((linked) => (
                                    <Link
                                        key={linked.incidentId}
                                        onClick={history.push}
                                        href={`/cases/${linked.incidentId}`}
                                    >
                                        {linked.incidentName}
                                    </Link>
                                ))}
                            </Column>
                        )
                    ) : (
                        <Column spacing='none'>
                            <Tag type='neutral'> Unlinked </Tag>
                        </Column>
                    )}
                </Column>
            </Row>
        </Card>
    );
};

export default ConsultationHeader;
