import Alert from '@amzn/meridian/alert';
import Column from '@amzn/meridian/column';
import Input from '@amzn/meridian/input';
import Loader from '@amzn/meridian/loader';
import Row from '@amzn/meridian/row';
import Select, { SelectOption } from '@amzn/meridian/select';
import Text from '@amzn/meridian/text';
import closeSmallTokens from '@amzn/meridian-tokens/base/icon/close-small';
import { saveAs } from 'file-saver';
import { Parser } from 'json2csv';
import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '../../context/store';
import { FILTER_PAGE_WIDTH, SELECT_WIDTH, WIDTHS_OF_GENERATE_REPORT_COLUMNS } from '../../lib/constants';
import { initialAdvancedFilter, initialConsultationFilter } from '../../lib/FilterDefinitons';
import { ConsultationSearchOrReportOnSubmit, searchOrReportOnSubmit } from '../../lib/helpers';
import { addToast } from '../../slices/ToastSlice';
import { useLazySearchCasesQuery, useLazySearchConsultationsQuery } from '../../slices/WotManApiSlice';
import AdvancedCaseFilterHelper from '../Cases/AdvancedSearch/AdvancedCaseFilterHelper';
import AdvancedConsultationFilterHelper from '../Cases/AdvancedSearch/AdvancedConsultationFilterHelper';
import DateRow from '../Cases/AdvancedSearch/DateRow';
import TitledButton from '../CustomComponents/TitledButton';

const GenerateReport: React.FunctionComponent = () => {
    const dispatch = useAppDispatch();
    const [localCaseFilterOptions, setLocalCaseFilterOptions] = useState(initialAdvancedFilter);
    const [localConsultationFilterOptions, setLocalConsultationFilterOptions] = useState(
        initialConsultationFilter
    );
    const [searchCases, searchCasesEndpoint] = useLazySearchCasesQuery();
    const [searchConsultations, searchConsultationsEndpoint] = useLazySearchConsultationsQuery();
    const [reportTitle, setReportTitle] = useState('');
    const [reportType, setReportType] = useState<string | undefined>(undefined);
    const [missingDate, setIsMissingDate] = useState<boolean>(false);
    const [missingTitle, setIsMissingTitle] = useState<boolean>(false);
    const [onSubmit, setOnSubmit] = useState<boolean>(false);
    const [showLoadingBar, setShowLoadingBar] = useState<boolean>(false);
    const [isDownloading, setIsDownloading] = useState<boolean>(true);

    const parser = new Parser();

    const createCsv = (data: { [k: string]: string }[]) => {
        const csvData = data.length > 0 ? data : [{}];

        const csv = parser.parse(csvData);

        const blob = new Blob([csv], {
            type: `text/csv;charset=utf-8`
        });

        saveAs(blob, `${reportTitle}.csv`);
    };

    useEffect(() => {
        if (!searchCasesEndpoint.isFetching && searchCasesEndpoint.isSuccess && isDownloading) {
            createCsv(searchCasesEndpoint.data);
            setShowLoadingBar(false);
        } else if (!searchCasesEndpoint.isFetching && searchCasesEndpoint.isError) {
            dispatch(
                addToast({
                    type: 'error',
                    action: 'There was an error searching cases, please try again',
                    message: '',
                    SIM: true,
                    timeout: undefined
                })
            );
        } else if (searchCasesEndpoint.isFetching && isDownloading) {
            setShowLoadingBar(true);
        }
    }, [searchCasesEndpoint.isFetching, isDownloading]);

    useEffect(() => {
        if (reportTitle.length > 0) {
            setIsMissingTitle(false);
        }
    }, [reportTitle]);

    useEffect(() => {
        if (onSubmit) {
            dispatch(
                addToast({
                    type: 'error',
                    action: 'One or more invalid form fields. ',
                    message: 'Correct any errors and submit again',
                    SIM: false,
                    timeout: 3000
                })
            );
            setOnSubmit(false);
        }
    }, [onSubmit]);

    useEffect(() => {
        if (
            !searchConsultationsEndpoint.isFetching &&
            searchConsultationsEndpoint.isSuccess &&
            isDownloading
        ) {
            createCsv(searchConsultationsEndpoint.data);
            setShowLoadingBar(false);
        } else if (!searchConsultationsEndpoint.isFetching && searchConsultationsEndpoint.isError) {
            dispatch(
                addToast({
                    type: 'error',
                    action: 'There was an error searching engagements, please try again',
                    message: '',
                    SIM: true,
                    timeout: undefined
                })
            );
        } else if (searchConsultationsEndpoint.isFetching && isDownloading) {
            setShowLoadingBar(true);
        }
    }, [searchConsultationsEndpoint.isFetching, isDownloading]);

    return (
        <Column width={FILTER_PAGE_WIDTH} spacing={'large'}>
            <Column>
                <Text type={'h200'}> Generate report </Text>
                <Row alignmentVertical={'top'}>
                    <Column>
                        <Input
                            value={reportTitle}
                            onChange={setReportTitle}
                            label={'Report title *'}
                            suffix={'.csv'}
                            width={SELECT_WIDTH}
                            error={missingTitle}
                        />
                        {missingTitle && (
                            <Alert type='error' size='small'>
                                Title is required
                            </Alert>
                        )}
                    </Column>
                    <Select
                        value={reportType}
                        onChange={setReportType}
                        label={'Report type *'}
                        width={SELECT_WIDTH}
                    >
                        <SelectOption label={'Cases'} value={'case'} />
                        <SelectOption label={'Engagements'} value={'consultation'} />
                    </Select>
                </Row>
                <DateRow
                    filter={localCaseFilterOptions}
                    missingDate={missingDate}
                    setDates={(dates) => {
                        setIsMissingDate(false);
                        setLocalConsultationFilterOptions({
                            ...localConsultationFilterOptions,
                            dateTo: dates[1],
                            dateFrom: dates[0]
                        });
                        setLocalCaseFilterOptions({
                            ...localCaseFilterOptions,
                            dateTo: dates[1],
                            dateFrom: dates[0]
                        });
                    }}
                />
            </Column>
            <Column>
                {reportType !== undefined && <Text type={'h200'}> Report filters </Text>}
                {reportType === 'consultation' && (
                    <AdvancedConsultationFilterHelper
                        buttonName={'Generate report'}
                        localFilterOptions={localConsultationFilterOptions}
                        setLocalFilterOptions={setLocalConsultationFilterOptions}
                        widthsOfColumns={WIDTHS_OF_GENERATE_REPORT_COLUMNS}
                        onClick={() => {
                            if (
                                reportTitle === '' &&
                                (!localConsultationFilterOptions.dateFrom ||
                                    !localConsultationFilterOptions.dateTo)
                            ) {
                                setIsMissingTitle(true);
                                setIsMissingDate(true);
                                setOnSubmit(true);
                            } else if (reportTitle === '') {
                                setIsMissingTitle(true);
                                setOnSubmit(true);
                            } else if (!localCaseFilterOptions.dateFrom || !localCaseFilterOptions.dateTo) {
                                setIsMissingDate(true);
                                setOnSubmit(true);
                            } else {
                                setIsDownloading(true);
                                ConsultationSearchOrReportOnSubmit(
                                    localConsultationFilterOptions,
                                    setIsMissingDate,
                                    searchConsultations,
                                    true,
                                    dispatch
                                );
                            }
                        }}
                    />
                )}
                {reportType === 'case' && (
                    <AdvancedCaseFilterHelper
                        buttonName={'Generate report'}
                        localFilterOptions={localCaseFilterOptions}
                        setLocalFilterOptions={setLocalCaseFilterOptions}
                        widthsOfColumns={WIDTHS_OF_GENERATE_REPORT_COLUMNS}
                        onClick={() => {
                            if (
                                reportTitle === '' &&
                                (!localCaseFilterOptions.dateFrom || !localCaseFilterOptions.dateTo)
                            ) {
                                setIsMissingTitle(true);
                                setIsMissingDate(true);
                                setOnSubmit(true);
                            } else if (reportTitle === '') {
                                setIsMissingTitle(true);
                                setOnSubmit(true);
                            } else if (!localCaseFilterOptions.dateFrom || !localCaseFilterOptions.dateTo) {
                                setIsMissingDate(true);
                                setOnSubmit(true);
                            } else {
                                setIsDownloading(true);
                                searchOrReportOnSubmit(
                                    localCaseFilterOptions,
                                    setIsMissingDate,
                                    searchCases,
                                    true,
                                    dispatch
                                );
                            }
                        }}
                    />
                )}
                {showLoadingBar && (
                    <div className='LoadingMessage'>
                        <div className='TextBar'>
                            <Text type='b200'>Generating report... </Text>
                        </div>
                        <div className='LoadingBar'>
                            <Loader type='linear' size='large' />
                        </div>
                        <div className='CancelIcon'>
                            <TitledButton
                                title={'Cancel'}
                                buttonProps={{
                                    onClick: () => {
                                        setShowLoadingBar(false);
                                        setIsDownloading(false);
                                    },
                                    type: 'icon',
                                    size: 'small',
                                    className: 'IconPadding'
                                }}
                                iconProps={{ tokens: closeSmallTokens }}
                            />
                        </div>
                    </div>
                )}
            </Column>
        </Column>
    );
};

export default GenerateReport;
