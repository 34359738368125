import Column from '@amzn/meridian/column';
import Icon from '@amzn/meridian/icon';
import Pagination from '@amzn/meridian/pagination';
import Responsive from '@amzn/meridian/responsive';
import Row from '@amzn/meridian/row';
import Tab, { TabGroup } from '@amzn/meridian/tab';
import Text from '@amzn/meridian/text';
import lockTokens from '@amzn/meridian-tokens/base/icon/lock';
import viewTokens from '@amzn/meridian-tokens/base/icon/view';
import React, { useEffect, useState, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../context/store';
import { NUM_HEADERS_PER_PAGE, ResponsiveVariables, responsiveVariables } from '../../lib/constants';
import { bookmarkIconTokens } from '../../lib/customIcons';
import { CreateCaseButton, isUserAdmin } from '../../lib/helpers';
import { FilterOptions } from '../../slices/DashboardSlice';
import SortingDropDown from '../SortingDropdown';
import CaseFilter from './CaseFilter';
import CaseHeader from './CaseHeader';
import ResponsiveHooks from './DetailsTabs/ResponsiveHooks';

type CaseColumnProps = {
    heading: string;
    initialFilterState?: FilterOptions;
    checkBoxesDisabled?: boolean;
    tabs?: {
        myCaseTab: string;
        setMyCaseTab: (newTab: string) => void;
    };
};

const CaseColumn: React.FunctionComponent<CaseColumnProps> = ({
    heading,
    initialFilterState,
    checkBoxesDisabled,
    tabs
}: CaseColumnProps) => {
    const { displayedCases } = useSelector((state: RootState) => state.dashboard);
    const [currentPage, setCurrentPage] = useState(1);
    const numPages = Math.ceil(displayedCases.length / NUM_HEADERS_PER_PAGE);
    const startHeader = (currentPage - 1) * NUM_HEADERS_PER_PAGE;

    const { displayingSheet } = useSelector((state: RootState) => state.consultations);
    const { isNavCollapsed } = useSelector((state: RootState) => state.dashboard);
    const { userRoles } = useSelector((state: RootState) => state.selectOptions);

    useEffect(() => {
        setCurrentPage(1);
    }, [displayedCases]);

    return (
        <Responsive query='min-width' props={responsiveVariables}>
            {({
                withNoneOpen,
                withNavBarOpen,
                withSheetOpen,
                withBothOpen
            }: {
                withNoneOpen: boolean;
                withNavBarOpen: boolean;
                withSheetOpen: boolean;
                withBothOpen: ResponsiveVariables;
            }) => {
                const isStacked = isNavCollapsed
                    ? displayingSheet
                        ? withSheetOpen
                        : withNoneOpen
                    : displayingSheet
                    ? withBothOpen === ResponsiveVariables.STACK ||
                      withBothOpen === ResponsiveVariables.CLOSE_ON_OPEN
                    : withNavBarOpen;
                return (
                    <Fragment>
                        <ResponsiveHooks withBothOpen={withBothOpen} />
                        <Column spacing='500' spacingInset='400 none'>
                            <Row alignmentHorizontal='justify'>
                                <Text type='d100'> {heading} </Text>
                                <CreateCaseButton />
                            </Row>
                            <Row alignmentHorizontal='justify'>
                                {tabs && (
                                    <span>
                                        <TabGroup value={tabs.myCaseTab} onChange={tabs.setMyCaseTab}>
                                            <Tab value='assignedToMe'>
                                                <Row alignmentVertical='bottom' spacing='200'>
                                                    <Icon tokens={viewTokens} /> Assigned to me
                                                </Row>
                                            </Tab>
                                            <Tab value='bookmarked'>
                                                <Row alignmentVertical='bottom' spacing='200'>
                                                    <Icon tokens={bookmarkIconTokens} /> Bookmarked
                                                </Row>
                                            </Tab>
                                            {isUserAdmin(userRoles) && (
                                                <Tab value='restricted'>
                                                    <Row alignmentVertical='bottom' spacing='200'>
                                                        <Icon tokens={lockTokens} /> Restricted
                                                    </Row>
                                                </Tab>
                                            )}
                                        </TabGroup>
                                    </span>
                                )}
                                <Row alignmentHorizontal={'right'} width={'100%'}>
                                    <Text type='h200'> Sort By</Text>
                                    <SortingDropDown />
                                </Row>
                            </Row>
                            {isStacked && (
                                <CaseFilter
                                    horizontalOptions
                                    initialFilterState={initialFilterState}
                                    checkBoxesDisabled={checkBoxesDisabled}
                                />
                            )}
                            <Column>
                                {displayedCases.length > 0 ? (
                                    displayedCases
                                        .slice(startHeader, startHeader + NUM_HEADERS_PER_PAGE)
                                        .map((caseHeader) => (
                                            <CaseHeader caseHeader={caseHeader} key={caseHeader.caseId} />
                                        ))
                                ) : (
                                    <Text alignment='center' color='secondary'>
                                        No{' '}
                                        {tabs
                                            ? tabs.myCaseTab === 'assignedToMe'
                                                ? 'cases assigned to you'
                                                : tabs.myCaseTab === 'bookmarked'
                                                ? 'bookmarked cases'
                                                : 'restricted cases'
                                            : 'cases'}{' '}
                                        to display
                                    </Text>
                                )}
                            </Column>
                            {displayedCases.length > 0 && (
                                <Row alignmentHorizontal='center'>
                                    <Pagination
                                        numberOfPages={numPages}
                                        onChange={setCurrentPage}
                                        currentPage={currentPage}
                                        showSkipArrows
                                    />
                                </Row>
                            )}
                        </Column>
                        {!isStacked && (
                            <CaseFilter
                                initialFilterState={initialFilterState}
                                checkBoxesDisabled={checkBoxesDisabled}
                            />
                        )}
                    </Fragment>
                );
            }}
        </Responsive>
    );
};

export default CaseColumn;
