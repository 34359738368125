import Loader from '@amzn/meridian/loader/loader';
import Row from '@amzn/meridian/row';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { useAppDispatch, RootState } from '../../context/store';
import { WIDTH_OF_CARD, TAB_NAMES } from '../../lib/constants';
import { filterCasesOnOptions } from '../../lib/helpers';
import { initialFilterOptions, setDisplayedCases, setFilterOptions } from '../../slices/DashboardSlice';
import CaseColumn from '../Cases/CaseColumn';

const UpdatedCases: React.FunctionComponent = () => {
    const { openCases, filterOptions, hasFinishedLoading } = useSelector(
        (state: RootState) => state.dashboard
    );
    const dispatch = useAppDispatch();
    const updatedCasesFilter = { ...initialFilterOptions, onlyUpdated: true };
    const updatedCases = filterCasesOnOptions(openCases, filterOptions);

    useEffect(() => {
        dispatch(setFilterOptions(updatedCasesFilter));
    }, []);

    useEffect(() => {
        dispatch(setDisplayedCases(updatedCases));
    }, [openCases, filterOptions]);

    return hasFinishedLoading ? (
        <React.Fragment>
            <Helmet defer={false}>
                <title> {TAB_NAMES.UPDATED_CASES} | MARS </title>
            </Helmet>
            <Row
                width='100%'
                alignmentVertical='top'
                alignmentHorizontal='center'
                widths={[WIDTH_OF_CARD, '272px']}
            >
                <CaseColumn
                    heading='Updated Cases'
                    initialFilterState={updatedCasesFilter}
                    checkBoxesDisabled={true}
                />
            </Row>
        </React.Fragment>
    ) : (
        <Loader />
    );
};

export default UpdatedCases;
