import addProximityReducer from '../slices/AddProximitySlice';
import advancedSearchReducer from '../slices/AdvancedSearchSlice';
import caseDetailsReducer from '../slices/CaseDetailsSlice';
import consultationsReducer from '../slices/ConsultationsSlice';
import dashboardReducer from '../slices/DashboardSlice';
import selectOptionsReducer from '../slices/SelectOptionsSlice';
import toastReducer from '../slices/ToastSlice';

const rootReducer = {
    dashboard: dashboardReducer,
    caseDetails: caseDetailsReducer,
    consultations: consultationsReducer,
    advancedSearch: advancedSearchReducer,
    addProximity: addProximityReducer,
    toast: toastReducer,
    selectOptions: selectOptionsReducer
};

export default rootReducer;
