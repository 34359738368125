import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Case, Consultation } from '../lib/CaseDefinitions';
import {
    CaseFilter,
    ConsultationFilter,
    initialAdvancedFilter,
    initialConsultationFilter
} from '../lib/FilterDefinitons';

interface AdvancedSearchState {
    filterOptions: CaseFilter;
    consultationFilterOptions: ConsultationFilter;
    hasAdvancedSearchCase: boolean;
    hasAdvancedSearchConsultation: boolean;
    displayedCases: Case[];
    displayedConsultations: Consultation[];
    casePageNumber: number;
    consultationPageNumber: number;
}

const initialState: AdvancedSearchState = {
    filterOptions: initialAdvancedFilter,
    consultationFilterOptions: initialConsultationFilter,
    hasAdvancedSearchCase: false,
    hasAdvancedSearchConsultation: false,
    displayedCases: [],
    displayedConsultations: [],
    casePageNumber: 1,
    consultationPageNumber: 1
};

//Todo: createbackend for search and link to search
const advancedSearch = createSlice({
    name: 'advancedSearch',
    initialState,
    reducers: {
        caseSearch: (state: AdvancedSearchState, { payload }: PayloadAction<{ caseFilter: CaseFilter }>) => {
            state.filterOptions = payload.caseFilter;
            state.casePageNumber = 1;
            state.hasAdvancedSearchCase = true;
        },
        consultationSearch: (
            state: AdvancedSearchState,
            { payload }: PayloadAction<{ consultationFilter: ConsultationFilter }>
        ) => {
            state.hasAdvancedSearchConsultation = true;
            state.consultationPageNumber = 1;
            state.consultationFilterOptions = payload.consultationFilter;
        },
        setConsultationsDisplaying: (
            state: AdvancedSearchState,
            { payload }: PayloadAction<{ consultations: Consultation[] }>
        ) => {
            state.displayedConsultations = payload.consultations;
        },
        setCasesDisplaying: (state: AdvancedSearchState, { payload }: PayloadAction<Case[]>) => {
            state.displayedCases = payload;
        },
        setCasePageNumber: (state: AdvancedSearchState, { payload }: PayloadAction<{ page: number }>) => {
            state.casePageNumber = payload.page;
        },
        setConsultationPageNumber: (
            state: AdvancedSearchState,
            { payload }: PayloadAction<{ page: number }>
        ) => {
            state.consultationPageNumber = payload.page;
        },
        resetState: (state: AdvancedSearchState, { payload }: PayloadAction<{ set: boolean }>) => {
            if (payload.set) {
                state.filterOptions = initialState.filterOptions;
                state.consultationFilterOptions = initialState.consultationFilterOptions;
                state.hasAdvancedSearchCase = initialState.hasAdvancedSearchCase;
                state.hasAdvancedSearchConsultation = initialState.hasAdvancedSearchConsultation;
                state.displayedCases = initialState.displayedCases;
                state.displayedConsultations = initialState.displayedConsultations;
                state.casePageNumber = initialState.casePageNumber;
                state.consultationPageNumber = initialState.consultationPageNumber;
            }
        }
    }
});

export const {
    caseSearch,
    consultationSearch,
    setConsultationsDisplaying,
    setCasesDisplaying,
    setCasePageNumber,
    setConsultationPageNumber,
    resetState
} = advancedSearch.actions;

export default advancedSearch.reducer;
