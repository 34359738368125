export enum EventType {
    AbusiveBehavior = 'Abusive Behavior',
    ActiveShooter = 'Active Shooter / Armed Aggressor',
    Arson = 'Arson',
    Assault = 'Assault',
    BombThreat = 'Bomb Threat',
    Bullying = 'Bullying',
    ConcerningBehavior = 'Concerning Behavior',
    CyberSecurity = 'Cyber Security Breach',
    Hostage = 'Hostage',
    ImpedingEgress = 'Impeding Egress',
    Intimidation = 'Intimidation',
    Kidnapping = 'Kidnapping',
    ProvocativeGraffiti = 'Provocative Graffiti',
    Robbery = 'Robbery',
    Sabotage = 'Sabotage',
    Shooting = 'Shooting',
    Stalking = 'Stalking',
    Suicidality = 'Suicide',
    Theft = 'Theft',
    Threats = 'Threats',
    Trespass = 'Trespass',
    Vandalism = 'Vandalism',
    WeaponsViolation = 'Weapons Policy Violation'
}

export enum EventItems {
    ByAmazonAffiliate = 'By AMZN',
    TowardsAmznAffiliate = 'Towards AMZN',
    Mutual = 'By & Towards AMZN',
    Injury = 'Injury',
    NoInjury = 'No Injury',
    UnknownInjury = 'Injury Unknown',
    NotAMZN = 'Not AMZN-Related',
    First = 'First Incident',
    Ongoing = 'Ongoing',
    SuicidalThreats = 'Suicidal Threats / Behavior',
    SuicidalIdeation = 'Suicidal Thoughts and Ideation',
    Verbal = 'Verbal',
    Written = 'Written',
    Vehicle = 'Of Vehicle',
    FromVehicle = 'From Vehicle',
    NotVehicleRelated = 'Not Vehicle Related',
    Reported = 'Reported',
    SelfReported = 'Self Reported',
    IdentifiedInScreening = 'Identified in Screening',
    Fatality = 'Fatality'
}

export const getEventItems = (eventType: EventType): EventItems[] => {
    let res = [];
    switch (eventType) {
        case EventType.Arson:
        case EventType.ActiveShooter:
        case EventType.Assault:
        case EventType.Sabotage:
        case EventType.Robbery:
        case EventType.Kidnapping:
            res = [EventItems.Injury, EventItems.NoInjury, EventItems.UnknownInjury, EventItems.Fatality];
            break;
        case EventType.AbusiveBehavior:
        case EventType.ConcerningBehavior:
            res = [EventItems.ByAmazonAffiliate, EventItems.TowardsAmznAffiliate, EventItems.Mutual];
            break;
        case EventType.BombThreat:
        case EventType.Threats:
            res = [EventItems.Written, EventItems.Verbal];
            break;
        case EventType.Trespass:
            res = [EventItems.First, EventItems.Ongoing];
            break;
        case EventType.CyberSecurity:
        case EventType.Intimidation:
        case EventType.Stalking:
        case EventType.Hostage:
        case EventType.Vandalism:
        case EventType.ProvocativeGraffiti:
        case EventType.Bullying:
            return [];
        case EventType.ImpedingEgress:
            res = [EventItems.ByAmazonAffiliate, EventItems.TowardsAmznAffiliate];
            break;
        case EventType.Shooting:
            res = [
                EventItems.Injury,
                EventItems.NoInjury,
                EventItems.UnknownInjury,
                EventItems.NotAMZN,
                EventItems.Fatality
            ];
            break;
        case EventType.Suicidality:
            res = [EventItems.SuicidalIdeation, EventItems.SuicidalThreats, EventItems.Fatality];
            break;
        case EventType.Theft:
            res = [EventItems.Vehicle, EventItems.FromVehicle, EventItems.NotVehicleRelated];
            break;
        case EventType.WeaponsViolation:
            res = [EventItems.SelfReported, EventItems.Reported, EventItems.IdentifiedInScreening];
            break;
    }
    return res.sort();
};

export const getAllSelectItems = (): string[] => {
    return Object.values(EventItems).sort();
};

export const sortedEventTypes = () => {
    return Object.values(EventType).sort();
};

export const getItemsFromTypes = (types: EventType[]): EventItems[] => {
    const set = new Set<EventItems>();
    types.forEach((type) => {
        getEventItems(type).forEach((item) => set.add(item));
    });
    return Array.from(set).sort();
};
