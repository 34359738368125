import Column from '@amzn/meridian/column';
import Icon from '@amzn/meridian/icon';
import Row from '@amzn/meridian/row';
import Text from '@amzn/meridian/text';
import Tooltip from '@amzn/meridian/tooltip';
import geopinIconTokens from '@amzn/meridian-tokens/base/icon/geopin';
import React from 'react';
import { Consultation, Proximity } from '../../lib/CaseDefinitions';

type ConsultationSiteLocationsProps = {
    consultationHeader: Consultation;
};

const ConsultationSiteLocations: React.FunctionComponent<ConsultationSiteLocationsProps> = ({
    consultationHeader
}: ConsultationSiteLocationsProps) => {
    let locationColumn;
    let primaryCountry: string | undefined;
    const siteNamesSet = new Set<string>();
    const countriesSet = new Set<string>();

    const hasNonEmptyPrimaryLocation = () => {
        return consultationHeader.primaryLocation && consultationHeader.primaryLocation.length > 0;
    };

    const hasNoPrimaryandSecondaryLocation = () => {
        return !consultationHeader.primaryLocation && !consultationHeader.secondaryLocation;
    };

    if (hasNonEmptyPrimaryLocation()) {
        primaryCountry = consultationHeader.primaryLocation[0].country;
    }
    consultationHeader.secondaryLocation?.forEach((facility) => {
        siteNamesSet.add(facility.siteName);
        if (!primaryCountry || !countriesSet.has(primaryCountry)) {
            countriesSet.add(facility.country);
        }
    });
    const siteNames = Array.from(siteNamesSet.values());
    const countries = Array.from(countriesSet.values());

    switch (consultationHeader.proximity) {
        case Proximity.OFFSITE:
            locationColumn = (
                <Column tag='span' spacing='none' width={'125px'}>
                    <Text type={'h200'}> {consultationHeader.proximity} </Text>
                    <Column spacing='none'>
                        <Text tag={'span'}>
                            {' '}
                            {hasNonEmptyPrimaryLocation()
                                ? consultationHeader.primaryLocation[0].siteName
                                : ''}
                        </Text>
                        {hasNonEmptyPrimaryLocation() && siteNames.length > 0 ? (
                            <Tooltip position='right' title={siteNames.join(', ')}>
                                <Text color='secondary' tag={'span'}>
                                    {' '}
                                    and {siteNamesSet.size} more
                                </Text>
                            </Tooltip>
                        ) : siteNames.length > 1 ? (
                            <Column spacing='none'>
                                <Text tag={'span'}> {siteNames[0]}</Text>
                                <Tooltip
                                    position='right'
                                    title={siteNames.slice(1, siteNames.length).join(', ')}
                                >
                                    <Text color='secondary' tag={'span'}>
                                        {' '}
                                        and {siteNamesSet.size - 1} more
                                    </Text>
                                </Tooltip>
                            </Column>
                        ) : (
                            <Column spacing='none'>
                                <Text tag={'span'}> {siteNames}</Text>
                            </Column>
                        )}
                        <Column spacing='none'>
                            <Text> {primaryCountry ?? ''} </Text>
                            {!primaryCountry && countriesSet.size === 1 && (
                                <Text color='secondary' tag={'span'}>
                                    {' '}
                                    {countries}
                                </Text>
                            )}
                            {!primaryCountry && countriesSet.size > 1 ? (
                                <Column spacing='none'>
                                    <Text tag={'span'}> {countries[0]}</Text>
                                    <Tooltip
                                        position='right'
                                        title={countries.slice(1, countries.length).join(', ')}
                                    >
                                        <Text color='secondary' tag={'span'}>
                                            {' '}
                                            and {countriesSet.size - 1} more
                                        </Text>
                                    </Tooltip>
                                </Column>
                            ) : (
                                primaryCountry &&
                                countriesSet.size > 1 && (
                                    <Tooltip position='right' title={countries.join(', ')}>
                                        <Text color='secondary' tag={'span'}>
                                            {' '}
                                            and {countriesSet.size} more
                                        </Text>
                                    </Tooltip>
                                )
                            )}
                        </Column>
                        {hasNoPrimaryandSecondaryLocation() &&
                            consultationHeader.location.siteName &&
                            consultationHeader.location.siteName != '' && (
                                <Text breakWord={true}> {consultationHeader.location.siteName} </Text>
                            )}
                        {hasNoPrimaryandSecondaryLocation() &&
                            consultationHeader.location.country &&
                            consultationHeader.location.country != '' && (
                                <Text> {consultationHeader.location.country} </Text>
                            )}
                        {(!consultationHeader.location || consultationHeader.location.siteName == '') &&
                            (!consultationHeader.location ||
                                !consultationHeader.location.country ||
                                consultationHeader.location.country == '') &&
                            !consultationHeader.primaryLocation &&
                            siteNames.length === 0 && <Text color={'secondary'}> {'No address'} </Text>}
                    </Column>
                </Column>
            );
            break;
        case Proximity.ONSITE:
            locationColumn = (
                <Column tag='span' spacing='none' width={'125px'}>
                    <Text type={'h200'}> Onsite </Text>
                    <Column spacing='none'>
                        <Text tag={'span'}>
                            {' '}
                            {hasNonEmptyPrimaryLocation()
                                ? consultationHeader.primaryLocation[0].siteName
                                : ''}
                        </Text>
                        {siteNames.length > 0 && (
                            <Tooltip position='right' title={siteNames.join(', ')}>
                                <Text color='secondary' tag={'span'}>
                                    {' '}
                                    and {siteNamesSet.size} more
                                </Text>
                            </Tooltip>
                        )}
                    </Column>
                    <Column spacing='none'>
                        <Text> {primaryCountry ?? ''} </Text>
                        {countriesSet.size > 2 && (
                            <Tooltip position='right' title={countries.join(', ')}>
                                <Text color='secondary' tag={'span'}>
                                    {' '}
                                    and {countriesSet.size} more
                                </Text>
                            </Tooltip>
                        )}
                    </Column>
                </Column>
            );
            break;
    }
    return (
        <Row alignmentVertical='top' spacing='small'>
            <Icon tokens={geopinIconTokens} />
            {locationColumn}
        </Row>
    );
};

export default ConsultationSiteLocations;
