import React from 'react';
import reactStringReplace from 'react-string-replace';
import { labels } from './labels';

export const replaceFlaggedWords = (text: string, flaggedWords: string[]): React.ReactNodeArray => {
    const words = flaggedWords.join('|');
    return reactStringReplace(text, new RegExp(`\\b(${words})\\b`, 'gi'), (match, i) => (
        <span key={i} title={labels.flaggedWord} className='Orange'>
            {match}
        </span>
    ));
};
