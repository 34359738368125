import Alert from '@amzn/meridian/alert';
import Button from '@amzn/meridian/button';
import ButtonGroup, { ButtonOption } from '@amzn/meridian/button-group';
import Column from '@amzn/meridian/column';
import Modal from '@amzn/meridian/modal';
import ModalFooter from '@amzn/meridian/modal/modal-footer';
import Row from '@amzn/meridian/row';
import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../context/store';
import { LevelOfConcern } from '../../lib/CaseDefinitions';
import { CHARACTER_LIMIT, MODAL_WIDTH } from '../../lib/constants';
import { locMap } from '../../lib/helpers';
import { closeLocModal, setCaseDetails } from '../../slices/CaseDetailsSlice';
import { addToast } from '../../slices/ToastSlice';
import { useLazyUpdateCaseQuery } from '../../slices/WotManApiSlice';
import AlertModal from '../Alert/AlertModal';
import { FormTextarea } from './FormFields';

const EditLocModal: React.FunctionComponent = () => {
    const { caseDetails, isDisplayingEditLOCModal } = useSelector((state: RootState) => state.caseDetails);
    const dispatch = useAppDispatch();
    const [tempLoc, setTempLoc] = useState<LevelOfConcern | undefined>();
    const [missingLoc, setMissingLoc] = useState<boolean>(false);
    const [note, setNote] = useState<string | undefined>('');
    const [useUpdateLoc, updateLocEndpoint] = useLazyUpdateCaseQuery();
    const [showAlertPrompt, setShowAlertPrompt] = useState(false);

    useEffect(() => {
        if (!updateLocEndpoint.isFetching && updateLocEndpoint.isSuccess && tempLoc) {
            dispatch(setCaseDetails(updateLocEndpoint.data));
            dispatch(
                addToast({
                    type: 'success',
                    action: 'Level of concern saved ',
                    message: `for ${caseDetails.caseName}`,
                    SIM: false,
                    timeout: 3000
                })
            );
        } else if (!updateLocEndpoint.isFetching && updateLocEndpoint.isError) {
            dispatch(
                addToast({
                    type: 'error',
                    action: 'There was an error saving level of concern',
                    message: '',
                    SIM: true,
                    timeout: undefined
                })
            );
        }
    }, [updateLocEndpoint.isFetching]);

    useEffect(() => {
        setTempLoc(caseDetails.loc);
        setNote(caseDetails.locNote);
    }, [isDisplayingEditLOCModal]);

    const handleCancel = () => {
        setShowAlertPrompt(false);
        dispatch(closeLocModal());
        setTempLoc(caseDetails.loc);
        setNote(caseDetails.locNote);
    };

    const isFormFilled = () => {
        if (tempLoc !== caseDetails.loc) setShowAlertPrompt(true);
        else {
            handleCancel();
        }
    };

    const handleShowModal = () => {
        setShowAlertPrompt(false);
    };

    return (
        <Fragment>
            <Modal
                title='Set level of concern'
                width={MODAL_WIDTH}
                open={isDisplayingEditLOCModal}
                scrollContainer='modal'
                onClose={() => isFormFilled()}
            >
                <Column height={tempLoc !== undefined ? '435px' : 'fill'}>
                    <Row alignmentHorizontal='center'>
                        <ButtonGroup value={tempLoc} onChange={setTempLoc} type='outline'>
                            <ButtonOption value={LevelOfConcern.LOW}>Low</ButtonOption>
                            <ButtonOption value={LevelOfConcern.MODERATE}>Moderate</ButtonOption>
                            <ButtonOption value={LevelOfConcern.HIGH}>High</ButtonOption>
                            <ButtonOption value={LevelOfConcern.CRITICAL}>Critical</ButtonOption>
                        </ButtonGroup>
                    </Row>
                    {tempLoc !== undefined && locMap(tempLoc)}
                    {tempLoc !== undefined && (
                        <Column spacing='small'>
                            <FormTextarea
                                label='Note'
                                value={note ? note : ''}
                                onChange={setNote}
                                missing={false}
                                max={CHARACTER_LIMIT.NOTE_TEXT_LIMIT}
                                rows={2}
                            />
                            {missingLoc ? (
                                <Alert type='error' size='small'>
                                    Level of concern is required.
                                </Alert>
                            ) : null}
                        </Column>
                    )}
                </Column>
                <ModalFooter>
                    <Row alignmentHorizontal='right'>
                        <Button type='secondary' onClick={() => isFormFilled()}>
                            Cancel
                        </Button>
                        <Button
                            type='primary'
                            disabled={
                                tempLoc == undefined ||
                                updateLocEndpoint.isFetching ||
                                tempLoc === caseDetails.loc
                            }
                            onClick={() => {
                                if (tempLoc && (!note || note.length <= CHARACTER_LIMIT.NOTE_TEXT_LIMIT)) {
                                    useUpdateLoc({
                                        updating: {
                                            type: 'loc',
                                            newContent: LevelOfConcern[tempLoc],
                                            note: note || '',
                                            incidentId: caseDetails.caseId
                                        }
                                    });
                                }
                                if (tempLoc == undefined) setMissingLoc(true);
                            }}
                        >
                            Save
                        </Button>
                    </Row>
                </ModalFooter>
            </Modal>
            {showAlertPrompt && (
                <AlertModal
                    showPrompt={showAlertPrompt}
                    handleConfirm={handleShowModal}
                    onCancel={handleCancel}
                    onClose={handleShowModal}
                ></AlertModal>
            )}
        </Fragment>
    );
};

export default EditLocModal;
