import Button from '@amzn/meridian/button';
import Checkbox from '@amzn/meridian/checkbox';
import Column from '@amzn/meridian/column';
import InputGroup from '@amzn/meridian/input-group';
import Row from '@amzn/meridian/row';
import Select, { SelectOption } from '@amzn/meridian/select';
import Tag from '@amzn/meridian/tag';
import Text from '@amzn/meridian/text';
import TimePicker from '@amzn/meridian/time-picker';
import closeTokens from '@amzn/meridian-tokens/base/icon/close';
import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../context/store';
import { PersonsInvolvedAndOtherRoles, StatusType } from '../../../lib/CaseDefinitions';
import { ADDITIONAL_TAGS, locValues, SELECT_WIDTH } from '../../../lib/constants';
import {
    EventType,
    getAllSelectItems,
    getItemsFromTypes,
    sortedEventTypes
} from '../../../lib/EmtCTIDefinitions';
import { CaseFilter, initialAdvancedFilter } from '../../../lib/FilterDefinitons';
import { labels } from '../../../lib/labels';
import SelectTags from '../../CustomComponents/SelectTags';
import TitledButton from '../../CustomComponents/TitledButton';
import FlaggedWordsInput from '../../Inputs/FlaggedWordsInput';
import LoginNameSearch from '../../Inputs/LoginNameSearch';
import TimeZoneInput from '../../Inputs/TimeZoneInput';
import LocationFilterColumn from './LocationFilterColumn';

type CaseFilterProps = {
    onClick: () => void;
    localFilterOptions: CaseFilter;
    setLocalFilterOptions: (caseFilter: CaseFilter) => void;
    buttonName: string;
    widthsOfColumns: string[];
};

//TODO add categories / subcategories, add person involved support, loading animations
const AdvancedCaseFilterHelper: React.FunctionComponent<CaseFilterProps> = ({
    onClick,
    setLocalFilterOptions,
    buttonName,
    localFilterOptions,
    widthsOfColumns
}: CaseFilterProps) => {
    const { selectOptionsContext } = useSelector((state: RootState) => state.selectOptions);
    const assignedToEmployees = new Map<string, string>([]);

    const setQuery = (person: { person: string; personId: string; isName?: boolean }, index: number) => {
        return (login: string) => {
            if (person.isName) {
                if (login === person.person.slice(0, -1)) {
                    setLocalFilterOptions({
                        ...localFilterOptions,
                        personInvolved: localFilterOptions.personInvolved
                            .slice(0, index)
                            .concat({
                                person: ``,
                                personId: ``,
                                role: localFilterOptions.personInvolved[index].role,
                                isName: false
                            })
                            .concat(localFilterOptions.personInvolved.slice(index + 1))
                    });
                }
            } else {
                setLocalFilterOptions({
                    ...localFilterOptions,
                    personInvolved: localFilterOptions.personInvolved
                        .slice(0, index)
                        .concat({
                            person: login,
                            personId: login,
                            role: localFilterOptions.personInvolved[index].role,
                            isName: false
                        })
                        .concat(localFilterOptions.personInvolved.slice(index + 1))
                });
            }
        };
    };

    return (
        <Column spacing='500'>
            <Row alignmentVertical='top' widths={widthsOfColumns}>
                <Column>
                    <Text type='h100'> Case information </Text>
                    <Select
                        value={localFilterOptions.levelOfConcern}
                        onChange={(levelOfConcern) =>
                            setLocalFilterOptions({
                                ...localFilterOptions,
                                levelOfConcern
                            })
                        }
                        label='Level of concern'
                        size='xlarge'
                    >
                        {locValues.map((value) => {
                            return <SelectOption value={value} label={value} key={value} />;
                        })}
                    </Select>
                    {Array.isArray(localFilterOptions.levelOfConcern) &&
                        localFilterOptions.levelOfConcern.length > 1 && (
                            <Row wrap='down'>
                                {localFilterOptions.levelOfConcern.map((levelOfConcern) => {
                                    return (
                                        <Tag
                                            key={levelOfConcern}
                                            type='neutral'
                                            onClose={() =>
                                                /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                                                // @ts-ignore
                                                setLocalFilterOptions({
                                                    ...localFilterOptions,
                                                    levelOfConcern: localFilterOptions.levelOfConcern.filter(
                                                        (loc) => loc !== levelOfConcern
                                                    )
                                                })
                                            }
                                        >
                                            {levelOfConcern.toString()}
                                        </Tag>
                                    );
                                })}
                            </Row>
                        )}
                    <Select
                        value={localFilterOptions.status}
                        onChange={(status) =>
                            setLocalFilterOptions({
                                ...localFilterOptions,
                                status
                            })
                        }
                        label='Status'
                        size='xlarge'
                    >
                        <SelectOption value={StatusType.OPEN} label='Open' />
                        <SelectOption value={StatusType.CLOSED} label='Closed' />
                    </Select>
                    {Array.isArray(localFilterOptions.status) && localFilterOptions.status.length > 1 && (
                        <Row wrap='down'>
                            {localFilterOptions.status.map((status) => {
                                return (
                                    <Tag
                                        key={status}
                                        type='neutral'
                                        onClose={() =>
                                            /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                                            // @ts-ignore
                                            setLocalFilterOptions({
                                                ...localFilterOptions,
                                                status: localFilterOptions.status.filter(
                                                    (stats) => stats !== status
                                                )
                                            })
                                        }
                                    >
                                        {status.charAt(0).toUpperCase() + status.slice(1)}
                                    </Tag>
                                );
                            })}
                        </Row>
                    )}
                    <Checkbox
                        checked={localFilterOptions.noTmActionNeeded}
                        onChange={(noTmActionNeeded) =>
                            setLocalFilterOptions({ ...localFilterOptions, noTmActionNeeded })
                        }
                    >
                        No threat management action needed
                    </Checkbox>
                    <Select
                        value={localFilterOptions.accessLevel}
                        onChange={(accessLevel) =>
                            setLocalFilterOptions({
                                ...localFilterOptions,
                                accessLevel
                            })
                        }
                        label='Access level'
                        size='xlarge'
                    >
                        <SelectOption value={'standard'} label={'Standard'} />
                        <SelectOption value={'restricted'} label={'Restricted'} />
                    </Select>
                    {Array.isArray(localFilterOptions.accessLevel) &&
                        localFilterOptions.accessLevel.length > 1 && (
                            <Row wrap='down'>
                                {localFilterOptions.accessLevel.map((accessLevel) => {
                                    return (
                                        <Tag
                                            key={accessLevel}
                                            type='neutral'
                                            onClose={() =>
                                                /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                                                // @ts-ignore
                                                setLocalFilterOptions({
                                                    ...localFilterOptions,
                                                    accessLevel: localFilterOptions.accessLevel.filter(
                                                        (level) => level !== accessLevel
                                                    )
                                                })
                                            }
                                        >
                                            {accessLevel.charAt(0).toUpperCase() + accessLevel.slice(1)}
                                        </Tag>
                                    );
                                })}
                            </Row>
                        )}
                    <Select
                        label='Assigned to'
                        value={localFilterOptions.assignedTo}
                        onChange={(assignedTo) =>
                            setLocalFilterOptions({ ...localFilterOptions, assignedTo })
                        }
                    >
                        {selectOptionsContext.assignees.map(
                            (assignee) => (
                                assignedToEmployees.set(
                                    assignee.empId,
                                    `${assignee.firstName} ${assignee.lastName}`
                                ),
                                (
                                    <SelectOption
                                        value={assignee.empId}
                                        key={assignee.empId}
                                        label={`${assignee.firstName} ${assignee.lastName}`}
                                    />
                                )
                            )
                        )}
                    </Select>
                    {Array.isArray(localFilterOptions.assignedTo) &&
                        localFilterOptions.assignedTo.length > 1 && (
                            <Row wrap='down'>
                                {localFilterOptions.assignedTo.map((assignee) => {
                                    return (
                                        <Tag
                                            key={assignee}
                                            type='neutral'
                                            onClose={() =>
                                                /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                                                // @ts-ignore
                                                setLocalFilterOptions({
                                                    ...localFilterOptions,
                                                    assignedTo: localFilterOptions.assignedTo.filter(
                                                        (assign) => assign !== assignee
                                                    )
                                                })
                                            }
                                        >
                                            {assignedToEmployees.get(assignee)}
                                        </Tag>
                                    );
                                })}
                            </Row>
                        )}
                    <FlaggedWordsInput
                        value={localFilterOptions.flaggedWords}
                        onChange={(flaggedWords: string[]) =>
                            setLocalFilterOptions({
                                ...localFilterOptions,
                                flaggedWords
                            })
                        }
                    />
                    {Array.isArray(localFilterOptions.flaggedWords) &&
                        localFilterOptions.flaggedWords.length > 1 && (
                            <Row wrap='down'>
                                {localFilterOptions.flaggedWords.map((flagged) => {
                                    return (
                                        <Tag
                                            key={flagged}
                                            type='neutral'
                                            onClose={() =>
                                                /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                                                // @ts-ignore
                                                setLocalFilterOptions({
                                                    ...localFilterOptions,
                                                    flaggedWords: localFilterOptions.flaggedWords.filter(
                                                        (flag) => flag !== flagged
                                                    )
                                                })
                                            }
                                        >
                                            {flagged}
                                        </Tag>
                                    );
                                })}
                            </Row>
                        )}
                    <Select
                        label='Recommendations applied'
                        value={localFilterOptions.recommendations}
                        onChange={(recommendations) =>
                            setLocalFilterOptions({ ...localFilterOptions, recommendations })
                        }
                    >
                        {selectOptionsContext.recommendations.map((rec) => (
                            <SelectOption label={rec} value={rec} key={rec} />
                        ))}
                    </Select>
                    {Array.isArray(localFilterOptions.recommendations) &&
                        localFilterOptions.recommendations.length > 1 && (
                            <Row wrap='down'>
                                {localFilterOptions.recommendations.map((recommend) => {
                                    return (
                                        <Tag
                                            key={recommend}
                                            type='neutral'
                                            onClose={() =>
                                                /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                                                // @ts-ignore
                                                setLocalFilterOptions({
                                                    ...localFilterOptions,
                                                    recommendations: localFilterOptions.recommendations.filter(
                                                        (rec) => rec !== recommend
                                                    )
                                                })
                                            }
                                        >
                                            {recommend}
                                        </Tag>
                                    );
                                })}
                            </Row>
                        )}
                    <Checkbox
                        checked={localFilterOptions.simTicketLinked}
                        onChange={(simTicketLinked) =>
                            setLocalFilterOptions({ ...localFilterOptions, simTicketLinked })
                        }
                    >
                        SIM ticket linked
                    </Checkbox>
                    <Checkbox
                        checked={localFilterOptions.onlyNew}
                        onChange={(onlyNew) => setLocalFilterOptions({ ...localFilterOptions, onlyNew })}
                    >
                        <Tag> New </Tag>
                    </Checkbox>
                    <Checkbox
                        checked={localFilterOptions.onlyUpdated}
                        onChange={(onlyUpdated) =>
                            setLocalFilterOptions({ ...localFilterOptions, onlyUpdated })
                        }
                    >
                        <Tag> Updated </Tag>
                    </Checkbox>
                </Column>
                <Column>
                    <Text type='h100'> Event information </Text>
                    <Column spacing={'none'}>
                        <InputGroup>
                            <TimePicker
                                label='Time of event from'
                                value={localFilterOptions.timeFrom}
                                locale='en-GB'
                                onChange={(timeFrom) =>
                                    setLocalFilterOptions({
                                        ...localFilterOptions,
                                        timeFrom
                                    })
                                }
                            />
                            <TimePicker
                                label='Time of event to'
                                value={localFilterOptions.timeTo}
                                locale='en-GB'
                                onChange={(timeTo) =>
                                    setLocalFilterOptions({
                                        ...localFilterOptions,
                                        timeTo
                                    })
                                }
                            />
                        </InputGroup>
                        <TimeZoneInput
                            width='50%'
                            onChange={(timeZone) =>
                                setLocalFilterOptions({
                                    ...localFilterOptions,
                                    timeZone
                                })
                            }
                            value={localFilterOptions.timeZone}
                        />
                    </Column>
                    <Select
                        value={localFilterOptions.eventCategory}
                        width={SELECT_WIDTH}
                        onChange={(eventCategory) =>
                            setLocalFilterOptions({ ...localFilterOptions, eventCategory })
                        }
                        label='Category'
                    >
                        <SelectOption
                            value='Violent / Detrimental Behavior'
                            label='Violent / Detrimental Behavior (EMT)'
                            key='emt'
                        />
                        <SelectOption
                            value='MARS Investigation'
                            label='MARS Investigation (MARS)'
                            key='mars'
                        />
                    </Select>
                    {localFilterOptions.eventCategory.length > 1 && (
                        <SelectTags
                            value={localFilterOptions.eventCategory}
                            onChange={(eventCategory) =>
                                setLocalFilterOptions({ ...localFilterOptions, eventCategory })
                            }
                        />
                    )}
                    <Select
                        value={localFilterOptions.eventType}
                        width={SELECT_WIDTH}
                        onChange={(eventType) => setLocalFilterOptions({ ...localFilterOptions, eventType })}
                        label='Type'
                    >
                        {sortedEventTypes().map((value) => {
                            return <SelectOption value={value} label={value} key={value} />;
                        })}
                    </Select>
                    {localFilterOptions.eventType.length > 1 && (
                        <SelectTags
                            value={localFilterOptions.eventType}
                            onChange={(eventType: EventType[]) =>
                                setLocalFilterOptions({ ...localFilterOptions, eventType })
                            }
                        />
                    )}
                    <Select
                        value={localFilterOptions.eventItem}
                        width={SELECT_WIDTH}
                        onChange={(eventItem) => setLocalFilterOptions({ ...localFilterOptions, eventItem })}
                        label='Item'
                    >
                        {localFilterOptions.eventType.length == 0
                            ? Object.values(getAllSelectItems()).map((value) => {
                                  return <SelectOption value={value} label={value} key={value} />;
                              })
                            : Object.values(getItemsFromTypes(localFilterOptions.eventType)).map((value) => {
                                  return <SelectOption value={value} label={value} key={value} />;
                              })}
                    </Select>
                    {localFilterOptions.eventItem.length > 1 && (
                        <SelectTags
                            value={localFilterOptions.eventItem}
                            onChange={(eventItem) =>
                                setLocalFilterOptions({ ...localFilterOptions, eventItem })
                            }
                        />
                    )}
                    <Select
                        value={localFilterOptions.eventTags}
                        width={SELECT_WIDTH}
                        onChange={(eventTags) => setLocalFilterOptions({ ...localFilterOptions, eventTags })}
                        label='Tags'
                    >
                        {Object.values(ADDITIONAL_TAGS)
                            .sort()
                            .map((value) => {
                                return <SelectOption value={value} label={value} key={value} />;
                            })}
                    </Select>
                    {localFilterOptions.eventTags.length > 1 && (
                        <SelectTags
                            value={localFilterOptions.eventTags}
                            onChange={(eventTags) =>
                                setLocalFilterOptions({ ...localFilterOptions, eventTags })
                            }
                        />
                    )}
                    <Select
                        value={localFilterOptions.severity}
                        disabled={true}
                        width={SELECT_WIDTH}
                        onChange={(severity) =>
                            setLocalFilterOptions({
                                ...localFilterOptions,
                                severity: severity
                            })
                        }
                        label='Severity'
                    >
                        <SelectOption value={1} label='SEV 1' />
                        <SelectOption value={2} label='SEV 2' />
                        <SelectOption value={3} label='SEV 3' />
                        <SelectOption value={4} label='SEV 4' />
                    </Select>
                    {Array.isArray(localFilterOptions.severity) && localFilterOptions.severity.length > 1 && (
                        <Row wrap='down'>
                            {localFilterOptions.severity.map((severity) => {
                                return (
                                    <Tag
                                        key={severity}
                                        type='neutral'
                                        onClose={() =>
                                            /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                                            // @ts-ignore
                                            setLocalFilterOptions({
                                                ...localFilterOptions,
                                                severity: localFilterOptions.severity.filter(
                                                    (sev) => sev !== severity
                                                )
                                            })
                                        }
                                    >
                                        {'SEV ' + severity}
                                    </Tag>
                                );
                            })}
                        </Row>
                    )}
                    <Text type='h100'> Persons involved </Text>
                    {localFilterOptions.personInvolved.map((person, index) => (
                        <Row key={index}>
                            <InputGroup>
                                <LoginNameSearch
                                    label='Person involved'
                                    query={person.person}
                                    onBlur={() => {
                                        if (!person.isName) {
                                            setQuery(person, index)('');
                                        }
                                    }}
                                    setQuery={setQuery(person, index)}
                                    handleClear={() =>
                                        setLocalFilterOptions({
                                            ...localFilterOptions,
                                            personInvolved: localFilterOptions.personInvolved
                                                .slice(0, index)
                                                .concat({
                                                    person: ``,
                                                    personId: '',
                                                    role: localFilterOptions.personInvolved[index].role,
                                                    isName: false
                                                })
                                                .concat(localFilterOptions.personInvolved.slice(index + 1))
                                        })
                                    }
                                    onClick={(person) =>
                                        setLocalFilterOptions({
                                            ...localFilterOptions,
                                            personInvolved: localFilterOptions.personInvolved
                                                .slice(0, index)
                                                .concat({
                                                    person: `${person.firstName} ${person.lastName}`,
                                                    personId: person.personId,
                                                    role: localFilterOptions.personInvolved[index].role,
                                                    isName: true
                                                })
                                                .concat(localFilterOptions.personInvolved.slice(index + 1))
                                        })
                                    }
                                    width={'242px'}
                                />
                                <Fragment>
                                    <Select
                                        label='Role'
                                        width='146px'
                                        value={person.role}
                                        onChange={(newRole) =>
                                            setLocalFilterOptions({
                                                ...localFilterOptions,
                                                personInvolved: localFilterOptions.personInvolved
                                                    .slice(0, index)
                                                    .concat({
                                                        ...localFilterOptions.personInvolved[index],
                                                        role: newRole
                                                    })
                                                    .concat(
                                                        localFilterOptions.personInvolved.slice(index + 1)
                                                    )
                                            })
                                        }
                                    >
                                        {Object.values(PersonsInvolvedAndOtherRoles).map((value) => {
                                            return <SelectOption value={value} label={value} key={value} />;
                                        })}
                                    </Select>
                                </Fragment>
                            </InputGroup>
                            {index !== 0 && (
                                <TitledButton
                                    title={labels.removePerson}
                                    buttonProps={{
                                        onClick: () =>
                                            setLocalFilterOptions({
                                                ...localFilterOptions,
                                                personInvolved: localFilterOptions.personInvolved
                                                    .slice(0, index)
                                                    .concat(
                                                        localFilterOptions.personInvolved.slice(index + 1)
                                                    )
                                            }),
                                        type: 'secondary',
                                        size: 'small'
                                    }}
                                    iconProps={{ tokens: closeTokens }}
                                />
                            )}
                        </Row>
                    ))}
                    {localFilterOptions.personInvolved.length > 1 && (
                        <Row>
                            <Text> Cases involve </Text>
                            <Select
                                size='small'
                                width={'80px'}
                                value={localFilterOptions.allOrAny}
                                onChange={(allOrAny: string) =>
                                    setLocalFilterOptions({
                                        ...localFilterOptions,
                                        allOrAny
                                    })
                                }
                            >
                                <SelectOption value={'any'} label={'Any'} />
                                <SelectOption value={'all'} label={'All'} />
                            </Select>
                            <Text> of the selected people </Text>
                        </Row>
                    )}
                    <Row>
                        <Button
                            type='tertiary'
                            onClick={() =>
                                setLocalFilterOptions({
                                    ...localFilterOptions,
                                    personInvolved: [
                                        ...localFilterOptions.personInvolved,
                                        { person: '', personId: '' }
                                    ]
                                })
                            }
                        >
                            Add another person
                        </Button>
                    </Row>
                </Column>
                <LocationFilterColumn
                    setLocationFilter={(locationFilter) =>
                        setLocalFilterOptions({ ...localFilterOptions, locationFilter })
                    }
                    locationFilter={localFilterOptions.locationFilter}
                />
            </Row>
            <Row alignmentHorizontal='right'>
                <Button
                    type='secondary'
                    onClick={() => {
                        setLocalFilterOptions(initialAdvancedFilter);
                    }}
                >
                    Clear
                </Button>
                <Button type='primary' onClick={onClick}>
                    {buttonName}
                </Button>
            </Row>
        </Column>
    );
};

export default AdvancedCaseFilterHelper;
