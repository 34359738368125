// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint react/prop-types: 0 */
// mostly copied from https://code.amazon.com/packages/MeridianComponents/blobs/5.x/--/src/components/progress-tracker/progress-tracker-milestone.js
// edited for length to not be specified through props

import Context from '@amzn/meridian/progress-tracker/progress-tracker-context';
import { css } from 'emotion';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { memoizeTokenStyles } from './MeridianUtils';

const trackStyles = memoizeTokenStyles(
    (t, { direction, lengthInPx, widthInPx }) => {
        const row = direction === 'row';
        return css({
            display: 'flex',
            flexDirection: row ? 'row' : 'column',
            alignItems: 'center',
            width: row ? lengthInPx : undefined,
            height: 'calc(100% - 24px)',
            '& > *': {
                // HACK: IE11 does not support margin-inline-* or margin-block-* so we have
                // to use directional margin-left/right. If/when we drop IE11 we can simplify
                // these styles to be:
                // {
                //   [row ? "marginInlineStart" : "marginBottom"]: widthInPx,
                // },
                [row ? 'marginLeft' : 'marginTop']: widthInPx,
                [`[dir="rtl"] &`]: {
                    [row && 'marginRight']: widthInPx,
                    [row && 'marginLeft']: 0
                }
            },
            '& > *:last-child': {
                // HACK: IE11 does not support margin-inline-* or margin-block-* so we have
                // to use directional margin-left/right. If/when we drop IE11 we can simplify
                // these styles to be:
                // {
                //   [row ? "marginInlineStart" : "marginBottom"]: widthInPx,
                // },
                [row ? 'marginRight' : 'marginBottom']: widthInPx,
                [`[dir="rtl"] &`]: {
                    [row ? 'marginLeft' : 'marginBottom']: widthInPx
                }
            },
            '& [data-mdn-progress-tracker-dash]': {
                borderRadius: 999,
                flex: 'auto',
                width: row ? undefined : widthInPx,
                height: row ? widthInPx : undefined
            }
        });
    },
    ['direction', 'lengthInPx', 'widthInPx', 'neutral']
);

const getTrackDimensions = (t, { length, size }) => {
    const lengthInDots = 2 * Math.round(length / 2);
    const widthInPx = t('trackWidth', size);
    return {
        lengthInDots,
        lengthInPx: lengthInDots * (widthInPx * 2) + widthInPx,
        widthInPx
    };
};

function Track(props) {
    const { t } = useContext(Context);
    const { lengthInPx, widthInPx } = getTrackDimensions(t, props);
    return (
        <div
            className={trackStyles(t, {
                direction: props.direction,
                lengthInPx,
                widthInPx
            })}
        >
            <div
                data-mdn-progress-tracker-dash=''
                style={{
                    backgroundColor: t('trackBackgroundColor', props.type === 'past' ? 'neutral' : 'theme')
                }}
            />
        </div>
    );
}

Track.propTypes = {
    direction: PropTypes.oneOf(['row', 'column']),
    /**
     * Use this to adjust the step's track length. Note that this is not a pixel
     * value, this is the number of "dots" that make up the track. This must be an
     * even number.
     */
    length: PropTypes.number,
    neutral: PropTypes.bool,
    /**
     * Sets the size of the component using a preset.
     */
    size: PropTypes.oneOf(['small', 'large']),
    type: PropTypes.oneOf(['past', 'present', 'future'])
};

export default Track;
export { getTrackDimensions };
