import Select, { SelectOption } from '@amzn/meridian/select';
import React from 'react';
import { Region } from '../../lib/CaseDefinitions';

type RegionInputProps = {
    onChange: ((region: Region) => void) | ((region: Region[]) => void);
    value?: Region | Region[];
};

const RegionInput: React.FunctionComponent<RegionInputProps> = ({ onChange, value }: RegionInputProps) => {
    return (
        <Select value={value} onChange={onChange} label='Region' size='xlarge'>
            {Object.values(Region).map((value) => {
                return <SelectOption value={value} label={value} key={value} />;
            })}
        </Select>
    );
};

export default RegionInput;
