import Select, { SelectOption } from '@amzn/meridian/select';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../context/store';
import { MAX_SELECT_POPOVER_HEIGHT } from '../../lib/constants';
import LoadingInput from './LoadingInput';

type CountryInputProps = {
    onChange: ((country: string) => void) | ((country: string[]) => void);
    value?: string | string[];
};

const CountryInput: React.FunctionComponent<CountryInputProps> = ({ onChange, value }: CountryInputProps) => {
    const { selectOptionsContext } = useSelector((state: RootState) => state.selectOptions);

    return (
        <Select
            label='Country'
            value={value}
            onChange={onChange}
            popoverMaxHeight={MAX_SELECT_POPOVER_HEIGHT}
        >
            {selectOptionsContext.countries.length > 0 ? (
                selectOptionsContext.countries.map((country: string) => {
                    return <SelectOption key={country} label={country} value={country} />;
                })
            ) : (
                <LoadingInput />
            )}
        </Select>
    );
};

export default CountryInput;
