import Alert from '@amzn/meridian/alert';
import Column from '@amzn/meridian/column';
import DatePicker from '@amzn/meridian/date-picker';
import Input from '@amzn/meridian/input';
import Row from '@amzn/meridian/row';
import Select from '@amzn/meridian/select';
import Textarea from '@amzn/meridian/textarea';
import React, { Fragment } from 'react';
import { SELECT_WIDTH } from '../../lib/constants';

type DatePickerInputProps = {
    value: string;
    onChange: (prop: never) => void;
    missing: boolean;
};

type FormInputProps = {
    value: string;
    onChange: (prop: never) => void;
    missing?: boolean;
    max: number;
    label: string;
    width?: string;
    duplicated?: boolean;
    duplicatedLabel?: string;
    disabled?: boolean;
    isColumn?: boolean;
    showError?: boolean;
};

type FormSelectorProps = {
    value?: string | string[];
    onChange: (prop: never) => void;
    missing?: boolean;
    label?: string;
    placeholder?: string;
    width: string;
    isBelow?: boolean;
    onSearch?: (prop: never) => void;
    searchQuery?: string;
    isSmall?: boolean;
    children: React.ReactNode;
};

type FormTextareaProps = {
    value: string;
    onChange: (prop: never) => void;
    missing: boolean;
    max: number;
    label: string;
    rows: number;
    disabled?: false;
};

const FormDatePicker: React.FunctionComponent<DatePickerInputProps> = ({
    onChange,
    value,
    missing
}: DatePickerInputProps) => {
    return (
        <Row spacing='small'>
            <DatePicker
                width={SELECT_WIDTH}
                label='Date of event *'
                onChange={onChange}
                value={value}
                error={missing}
            />
            {missing ? (
                <Alert type='error' size='small'>
                    Date of event is required
                </Alert>
            ) : null}
        </Row>
    );
};

const FormInput: React.FunctionComponent<FormInputProps> = ({
    width = '100%',
    value,
    onChange,
    missing = false,
    max,
    label,
    duplicated,
    duplicatedLabel,
    disabled,
    isColumn = false,
    showError = true
}: FormInputProps) => {
    const children = (
        <Fragment>
            <Input
                width={width}
                label={label}
                value={value}
                onChange={onChange}
                error={missing || value.length > max || duplicated}
                disabled={disabled}
            />
            {missing && showError ? (
                <Alert type='error' size='small'>
                    {label.replace(/ \*/g, '')} is required
                </Alert>
            ) : null}
            {value.length > max && showError ? (
                <Alert type='error' size='small'>
                    Maximum {max} characters ({value.length - max} too many)
                </Alert>
            ) : null}
            {duplicated && showError ? (
                <Alert type='error' size='small'>
                    {duplicatedLabel} already exists
                </Alert>
            ) : null}
        </Fragment>
    );

    return isColumn ? (
        <Column spacing='small'> {children} </Column>
    ) : (
        <Row spacing='small' widths={['fit', 'fill']}>
            {' '}
            {children}{' '}
        </Row>
    );
};

const FormTextarea: React.FunctionComponent<FormTextareaProps> = ({
    rows,
    value,
    onChange,
    missing,
    max,
    label,
    disabled = false
}: FormTextareaProps) => {
    return (
        <Column spacing='small'>
            <Textarea
                label={label}
                rows={rows}
                value={value}
                onChange={onChange}
                error={missing || value.length > max}
                disabled={disabled}
            />
            {missing ? (
                <Alert type='error' size='small'>
                    {label.replace(/ \*/g, '')} is required
                </Alert>
            ) : null}
            {value.length > max ? (
                <Alert type='error' size='small'>
                    Maximum {max} characters ({value.length - max} too many)
                </Alert>
            ) : null}
        </Column>
    );
};

const FormSelect: React.FunctionComponent<FormSelectorProps> = ({
    width,
    value,
    onChange,
    missing,
    label,
    isBelow = false,
    onSearch,
    searchQuery,
    placeholder,
    children,
    isSmall = false
}: FormSelectorProps) => {
    const select = (
        <>
            <Select
                width={width}
                value={value}
                onChange={onChange}
                label={label}
                onSearch={onSearch}
                searchQuery={searchQuery}
                placeholder={placeholder}
                size={isSmall ? undefined : 'xlarge'}
                error={missing}
            >
                {children}
            </Select>
            {missing ? (
                <Alert type='error' size='small'>
                    {label ? label.replace(/ \*/g, '') : placeholder} is required
                </Alert>
            ) : null}
        </>
    );
    return isBelow ? <Column spacing='small'>{select}</Column> : <Row spacing='small'>{select}</Row>;
};

export { FormDatePicker, FormInput, FormTextarea, FormSelect };
