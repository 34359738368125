import Alert from '@amzn/meridian/alert';
import Column from '@amzn/meridian/column';
import Icon from '@amzn/meridian/icon';
import Link from '@amzn/meridian/link';
import Row from '@amzn/meridian/row';
import Text from '@amzn/meridian/text';
import closeTokens from '@amzn/meridian-tokens/base/icon/close';
import exportSmallTokens from '@amzn/meridian-tokens/base/icon/export-small';
import plusTokens from '@amzn/meridian-tokens/base/icon/plus';
import { DateTime } from 'luxon';
import moment from 'moment-timezone';
import React, { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import TitledButton from '../components/CustomComponents/TitledButton';
import { AppDispatch } from '../context/store';
import { caseSearch, consultationSearch } from '../slices/AdvancedSearchSlice';
import { ConsultationFilterOptions } from '../slices/ConsultationsSlice';
import { FilterOptions } from '../slices/DashboardSlice';
import {
    AbbreviatedFacility,
    Case,
    CommunicatedWith,
    Consultation,
    CreatePersonInvolved,
    EmployeeJobStatusType,
    Facility,
    LevelOfConcern,
    Location,
    OffSiteLocationAdding,
    PersonInvolved,
    SiteIdAndCode
} from './CaseDefinitions';
import { CHARACTER_LIMIT, SELECT_WIDTH } from './constants';
import {
    CaseFilter,
    CaseFilterBackend,
    ConsultationFilter,
    ConsultationFilterBackend,
    LocationInfoFilter
} from './FilterDefinitons';
import { labels } from './labels';

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
const checkIfAttributeIsInArray = (array: any[], item: any): boolean => {
    return array.length === 0 || array.includes(item);
};

export const checkIfSitesArrayEqual = (a1: SiteIdAndCode[], a2: SiteIdAndCode[]) => {
    if (a1.length != a2.length) return false;
    a1.forEach((facility: SiteIdAndCode) => {
        if (!a2.some((f) => f.siteCode == facility.siteCode)) {
            return false;
        }
    });
    return true;
};

//TODO:  Add Other filters
export const filterCasesOnOptions = (allCases: Case[], filterOptions: FilterOptions): Case[] => {
    const {
        levelOfConcern,
        region,
        flaggedWords,
        onlyNew,
        onlyUpdated,
        eventCategory,
        eventType,
        severity,
        proximity,
        siteIds
    } = filterOptions;
    return allCases.filter((caseHeader) => {
        const regions = new Set<string>();
        const siteNames = new Set<string>();
        caseHeader.primaryLocation?.forEach((facility) => {
            facility.region && regions.add(facility.region);
            siteNames.add(facility.siteName);
        });
        caseHeader.secondaryLocation?.forEach((facility) => {
            facility.region && regions.add(facility.region);
            siteNames.add(facility.siteName);
        });
        return (
            checkIfAttributeIsInArray(
                levelOfConcern,
                caseHeader.loc ? LevelOfConcern[caseHeader.loc] : undefined
            ) &&
            (severity.length === 0 ||
                (caseHeader.emtSev && severity.includes(caseHeader.emtSev) && !caseHeader.loc)) &&
            (checkIfAttributeIsInArray(region, caseHeader.location.region) ||
                region.some((r) => regions.has(r))) &&
            (siteIds.length === 0 || siteIds.some((r) => siteNames.has(r.siteCode))) &&
            checkIfAttributeIsInArray(eventCategory, caseHeader.emtCategory) &&
            checkIfAttributeIsInArray(eventType, caseHeader.emtType) &&
            ((!onlyNew && !onlyUpdated) ||
                (onlyNew && !caseHeader.loc) ||
                (onlyUpdated && caseHeader.updateBy && caseHeader.loc)) &&
            (flaggedWords.length === 0 || flaggedWords.some((r) => caseHeader.flaggedWords.includes(r))) &&
            (proximity.length === 0 || proximity.some((r) => caseHeader.proximity.includes(r)))
        );
    });
};

export const hasOverLimitCommunicatedWith = (communicatedArray: CommunicatedWith[]): boolean => {
    let res = false;
    communicatedArray.forEach((person) => {
        if (
            person.name.length > CHARACTER_LIMIT.PERSON_INVOLVED_LIMIT ||
            person.title.length > CHARACTER_LIMIT.PERSON_INVOLVED_LIMIT ||
            person.phoneNumber.length > CHARACTER_LIMIT.PERSON_INVOLVED_LIMIT ||
            person.email.length > CHARACTER_LIMIT.PERSON_INVOLVED_LIMIT
        ) {
            res = true;
        }
    });
    return res;
};

export const isDuplicatePersonAtIndex = (communicatedArray: CommunicatedWith[], index: number): boolean => {
    let i;
    let smallest = communicatedArray.length;
    for (i = 0; i < communicatedArray.length && i != index; i++) {
        const c = communicatedArray[i];
        if (
            c.name == communicatedArray[index].name &&
            c.email == communicatedArray[index].email &&
            c.phoneNumber == communicatedArray[index].phoneNumber &&
            c.title == communicatedArray[index].title
        ) {
            smallest = Math.min(smallest, i);
        }
    }
    return smallest < communicatedArray.length && index > smallest;
};

export const hasDuplicatePerson = (communicatedArray: CommunicatedWith[]): boolean => {
    const communicatedSet = new Set();
    communicatedArray.forEach((c: CommunicatedWith) => {
        communicatedSet.add(c.name + c.phoneNumber + c.title + c.email + c.login);
    });
    return communicatedSet.size < communicatedArray.length;
};

export const hasOverLimitPersonInvolved = (
    personArray: CreatePersonInvolved[] | CreatePersonInvolved
): boolean => {
    let res = false;
    Array.isArray(personArray) &&
        personArray.forEach((person) => {
            if (
                (person.name && person.name.length > CHARACTER_LIMIT.PERSON_INVOLVED_LIMIT) ||
                (person.title && person.title.length > CHARACTER_LIMIT.PERSON_INVOLVED_LIMIT) ||
                person.phoneNumber.length > CHARACTER_LIMIT.PERSON_INVOLVED_LIMIT ||
                person.email.length > CHARACTER_LIMIT.PERSON_INVOLVED_LIMIT
            ) {
                res = true;
            }
        });
    if (
        !Array.isArray(personArray) &&
        ((personArray.name && personArray.name.length > CHARACTER_LIMIT.PERSON_INVOLVED_LIMIT) ||
            (personArray.title && personArray.title.length > CHARACTER_LIMIT.PERSON_INVOLVED_LIMIT) ||
            personArray.phoneNumber.length > CHARACTER_LIMIT.PERSON_INVOLVED_LIMIT ||
            personArray.email.length > CHARACTER_LIMIT.PERSON_INVOLVED_LIMIT)
    ) {
        res = true;
    }
    return res;
};

export const getEmployeeStatus = (status: string): string => {
    switch (status) {
        case 'A':
            return 'Active';
        case 'D':
            return 'Deceased';
        case 'L':
            return 'Leave';
        case 'P':
            return 'Leave w/Pay';
        case 'Q':
            return 'Retired w/Pay';
        case 'R':
            return 'Retired';
        case 'S':
            return 'Suspended';
        case 'T':
            return 'Terminated';
        case 'U':
            return 'Term w/Pension';
        case 'V':
            return 'Term w/Pension';
        case 'W':
            return 'Short Work Break';
        case 'X':
            return 'Retired Pension Administration';
        default:
            return 'Unknown';
    }
};

export const mapFacilityToAbbreviatedFacility = (facilityList: Facility[]): AbbreviatedFacility[] => {
    return facilityList?.map((facility: Facility) => {
        return {
            siteName: facility.siteCode,
            country: facility.country ? facility.country : '',
            region: facility.region
        };
    });
};

export const mapOffSiteLocationToAbbreviatedFacility = (
    OffSiteLocation: OffSiteLocationAdding | undefined
): AbbreviatedFacility => {
    return {
        siteName: OffSiteLocation ? OffSiteLocation.state : '',
        country: OffSiteLocation && OffSiteLocation.country ? OffSiteLocation.country : '',
        region: OffSiteLocation?.region
    };
};

export const removeEmptyLine = (text: string): string => {
    const split = text.split('\n');
    let left = 0;
    let right = split.length - 1;
    while (left < split.length && split[left].length == 0) {
        left += 1;
    }
    while (right >= 0 && split[right].length == 0) {
        right -= 1;
    }
    if (left > right) return '';
    let res = '';
    while (left <= right) {
        res += split[left];
        left += 1;
        if (left <= right) {
            res += '\n';
        }
    }
    return res;
};

export const filterConsultationCasesOnOptions = (
    allConsultations: Consultation[],
    filterOptions: ConsultationFilterOptions,
    isLinked: boolean
): Consultation[] => {
    const { method, added, communicatedWith, proximity, siteIds, region } = filterOptions;
    const communicatedPersonIds = communicatedWith.map((person) => person.personId);
    return allConsultations.filter((consultationHeader) => {
        const regions = new Set<string>();
        const siteNames = new Set<string>();
        consultationHeader.primaryLocation?.forEach((facility) => {
            facility.region && regions.add(facility.region);
            siteNames.add(facility.siteName);
        });
        consultationHeader.secondaryLocation?.forEach((facility) => {
            facility.region && regions.add(facility.region);
            siteNames.add(facility.siteName);
        });
        return (
            (communicatedWith.length === 0 ||
                communicatedPersonIds.some((c) => consultationHeader.communicatedWith.includes(c))) &&
            checkIfAttributeIsInArray(method, consultationHeader.method) &&
            (added.length === 0 ||
                checkIfAttributeIsInArray(
                    added,
                    consultationHeader.added.firstName + ' ' + consultationHeader.added.lastName
                )) &&
            (isLinked
                ? consultationHeader.linkedCases.length > 0
                : consultationHeader.linkedCases.length === 0) &&
            (proximity.length === 0 || proximity.some((r) => consultationHeader.proximity.includes(r))) &&
            (checkIfAttributeIsInArray(region, consultationHeader.location.region) ||
                region.some((r) => regions.has(r))) &&
            (siteIds.length === 0 || siteIds.some((r) => siteNames.has(r.siteCode)))
        );
    });
};

export const getDateTimeFromISO = (date: string, time: string, timezone: string): DateTime => {
    return DateTime.fromISO(`${date}T${time}`, {
        zone: 'utc'
    }).setZone(timezone || moment.tz.guess());
};

export const renderDateDiff = (time: string): string => {
    const { days, hours, minutes, seconds } = DateTime.now().diff(DateTime.fromISO(time), [
        'days',
        'hours',
        'minutes',
        'seconds'
    ]);
    let returning = '';
    if (days > 0) {
        returning += days + ' days ';
    } else {
        if (hours > 0) {
            returning += hours + 'hr ';
        }
        if (minutes > 0) {
            returning += minutes + 'min ';
        }
    }
    if (returning === '') {
        if (seconds > 0) {
            returning += Math.round(seconds) + ' secs ago';
        } else {
            returning = 'Just Now';
        }
    } else {
        returning += 'ago';
    }
    return returning;
};

export const locToString = (loc: LevelOfConcern): string => {
    switch (loc) {
        case LevelOfConcern.CRITICAL:
            return 'Crit';
        case LevelOfConcern.HIGH:
            return 'High';
        case LevelOfConcern.MODERATE:
            return 'Mod';
        case LevelOfConcern.LOW:
            return 'Low';
    }
};

export const getColor = (loc: LevelOfConcern | undefined, emtSev: number | undefined = undefined): string => {
    if (loc === LevelOfConcern.CRITICAL || (!loc && emtSev === 1)) return '#EE5A46';
    else if (loc === LevelOfConcern.HIGH || (!loc && emtSev === 2)) return '#FF8F00';
    else if (loc === LevelOfConcern.MODERATE || (!loc && emtSev === 3)) return '#FCC201';
    else return '#47B120';
};

export const renderSeverity = (emtSev: number | undefined): JSX.Element => {
    return (
        <Row spacing='300'>
            <svg height='12' width='12'>
                <circle cx='6' cy='6' r='6' fill={getColor(undefined, emtSev)} />
            </svg>
            <Text type='b200'>SEV {emtSev}</Text>
        </Row>
    );
};

export const locMap = (loc: LevelOfConcern) => {
    switch (loc) {
        case LevelOfConcern.CRITICAL:
            return (
                <Fragment key={LevelOfConcern.CRITICAL}>
                    <Text type='b300'>
                        This level suggests that violence is possible and could occur within the near future
                        following any precipitating events. Immediate and continuing attention is required
                        from threat management resources to ensure violence does not occur.
                    </Text>
                    <Text type='b200' tag='ul'>
                        <li> Threat or behavior is targeted and appears to pose serious danger to others </li>
                        <li> Threat is direct, specific, detailed and plausible </li>
                        <li> Information suggests concrete preparations, target and planning </li>
                        <li> Person sees few alternatives to taking action </li>
                        <li> Communicates that intended actions are justified </li>
                        <li> Likely to qualify for immediate arrest or hospitalization </li>
                    </Text>
                </Fragment>
            );
        case LevelOfConcern.HIGH:
            return (
                <Fragment key={LevelOfConcern.HIGH}>
                    <Text type='b300' key={LevelOfConcern.HIGH}>
                        This elevated level of concern suggests that the person of concern is reaching a
                        critical point on a pathway to violence from which they perceive it may be difficult
                        to turn back.
                    </Text>
                    <Text type='b200' tag='ul'>
                        <li> Communication increases in severity and tone </li>
                        <li> Communication may be directed and fixated on a cause or a person </li>
                        <li> Person seems to be making preparations to carry out the act </li>
                        <li> An increase in warning behaviors is observed </li>
                        <li>
                            {' '}
                            The person of concern may have surpassed all criteria for the low and moderate
                            designations and now appears to have begun preparing for a violent act{' '}
                        </li>
                    </Text>
                </Fragment>
            );
        case LevelOfConcern.MODERATE:
            return (
                <Fragment key={LevelOfConcern.MODERATE}>
                    <Text type='b300'>
                        This level suggests that violence could possibly occur, although the situation is not
                        urgent. Violence cannot be ruled out. Monitoring and additional actions are necessary
                        or desirable to further evaluate and respond to the situation to a point of
                        resolution.
                    </Text>
                    <Text type='b200' tag='ul'>
                        <li>
                            {' '}
                            Some premeditation or planning with implications of place, time and/or target{' '}
                        </li>
                        <li> Reference to gun availability, preoperational surveillance or other plans </li>
                        <li> Has expressed understanding of personal issues or desire for help </li>
                        <li> Threat includes desire to cause physical injury that stops short of death </li>
                    </Text>
                </Fragment>
            );
        case LevelOfConcern.LOW:
            return (
                <Fragment key={LevelOfConcern.LOW}>
                    <Text type='b300'>
                        This level suggests that the concern for future violence is low. Additional data
                        gathering may be desirable and monitoring for any changes in violence risk factors or
                        warning behaviors may be appropriate.
                    </Text>
                    <Text type='b300'>
                        Communication has been received or reported that causes some concern about potential
                        for violence; it may be confusing, unrealistic, or make no allusions to violence at
                        all.
                    </Text>
                    <Text type='b200' tag='ul'>
                        <li> Vague or inconsistent information </li>
                        <li> Threat is implausible or lacks detail or realism </li>
                        <li> Context suggests that action is unlikely </li>
                        <li> Threats are for stress relief, bravado, or affect </li>
                    </Text>
                </Fragment>
            );
    }
};

export const renderLocation = (location: Location | OffSiteLocationAdding) => {
    return (
        <Fragment>
            <Text type='b300'> {location.address} </Text>
            <Text type='b300'>
                {' '}
                {location.city}
                {location.city && location.state && ','} {location.state}
            </Text>
            <Text type='b300'> {location.country} </Text>
            <Text type='b300'> {location.region} </Text>
        </Fragment>
    );
};

export const renderFacmanLink = (facility: SiteIdAndCode) => {
    const isBeta = process.env.REACT_APP_STAGE === 'beta' || process.env.REACT_APP_STAGE === 'dev';
    return (
        <Link
            type='secondary'
            href={
                isBeta
                    ? `https://beta.facman.guardian.amazon.dev/dashboard/facilityPage#siteId=${facility.siteId}`
                    : `https://facman.guardian.amazon.dev/dashboard/facilityPage#siteId=${facility.siteId}`
            }
            target='_blank'
            rel='noopener noreferrer'
        >
            <Row spacing='xsmall' width='fit-content'>
                <span> {facility.siteCode} </span>
                <Icon tokens={exportSmallTokens} />
            </Row>
        </Link>
    );
};

export const renderFacility = (facility: Facility) => {
    return (
        <Column spacing='none' width='180px'>
            <span style={{ width: 'fit-content' }}>{renderFacmanLink(facility)}</span>
            {renderLocation(facility)}
        </Column>
    );
};

export const renderFacilityList = (facilities: Facility[]) =>
    facilities && facilities.length > 0 ? (
        facilities.map((facility, index) => (
            <Column key={index} spacing='none'>
                <Row spacing='small' width='max-content'>
                    {renderFacmanLink(facility)}
                </Row>
            </Column>
        ))
    ) : (
        <Text color={'secondary'}> None </Text>
    );

export const CreateCaseButton: React.FunctionComponent = () => {
    const history = useHistory();
    return (
        <TitledButton
            title={labels.createCase}
            buttonProps={{
                onClick: () => history.push('/createCase'),
                type: 'primary',
                size: 'medium'
            }}
            iconProps={{ tokens: plusTokens }}
        />
    );
};

export const renderMaxAlert = (max: number, length: number, label: string) => {
    return (
        <Alert type='error' size='small' width={SELECT_WIDTH}>
            Maximum {max} characters for {label} ({length - max} too many)
        </Alert>
    );
};

const generateLocationFilter = (locationFilter: LocationInfoFilter) => {
    return {
        region: locationFilter.region,
        siteIds: locationFilter.facilityCode.map((facility) => facility.siteId),
        country: locationFilter.country,
        city: locationFilter.city,
        proximity: locationFilter.proximity,
        businessLine: locationFilter.businessLine,
        postalCode: locationFilter.postalCode,
        primary: locationFilter.primary,
        secondary: locationFilter.secondary,
        stateProvince: locationFilter.stateProvince,
        streetAddress: locationFilter.streetAddress
    };
};

export const searchOrReportOnSubmit = (
    localFilterOptions: CaseFilter,
    setIsMissingDate: (isMissing: boolean) => void,
    searchCases: (searchOpts: { caseFilter: CaseFilterBackend; isReport: boolean }) => void,
    isReport: boolean,
    dispatch: AppDispatch
) => {
    if (!localFilterOptions.dateTo || !localFilterOptions.dateFrom) {
        setIsMissingDate(true);
    } else {
        const timeTo = localFilterOptions.timeTo || '23:59';
        const timeFrom = localFilterOptions.timeFrom || '00:00';
        const dateTimeToUTC = DateTime.fromISO(`${localFilterOptions.dateTo}T${timeTo}`, {
            zone: localFilterOptions.timeZone
        })
            .setZone('utc')
            .toISO();
        const dateTimeFromUTC = DateTime.fromISO(`${localFilterOptions.dateFrom}T${timeFrom}`, {
            zone: localFilterOptions.timeZone
        })
            .setZone('utc')
            .toISO();
        dispatch(caseSearch({ caseFilter: localFilterOptions }));
        searchCases({
            caseFilter: {
                levelOfConcern: localFilterOptions.levelOfConcern,
                severity: localFilterOptions.severity,
                eventType: localFilterOptions.eventType,
                eventCategory: localFilterOptions.eventCategory,
                eventItem: localFilterOptions.eventItem,
                eventTags: localFilterOptions.eventTags,
                status: localFilterOptions.status,
                flaggedWords: localFilterOptions.flaggedWords,
                onlyNew: localFilterOptions.onlyNew,
                onlyUpdated: localFilterOptions.onlyUpdated,
                simTicketLinked: localFilterOptions.simTicketLinked,
                assignedTo: localFilterOptions.assignedTo,
                dateTimeFromUTC,
                dateTimeToUTC,
                accessLevel: localFilterOptions.accessLevel,
                personInvolved: localFilterOptions.personInvolved
                    .filter((p) => p.role != 'LP contact')
                    .map((person) => {
                        return {
                            person: person.personId,
                            role: person.role
                        };
                    }),
                lpContact: localFilterOptions.personInvolved
                    .filter((p) => p.role == 'LP contact')
                    .map((person) => {
                        return {
                            person: person.personId
                        };
                    }),
                allOrAny: localFilterOptions.allOrAny,
                recommendations: localFilterOptions.recommendations,
                locationFilter: generateLocationFilter(localFilterOptions.locationFilter),
                noTmActionNeeded: localFilterOptions.noTmActionNeeded
            },
            isReport
        });
        setIsMissingDate(false);
    }
};

export const ConsultationSearchOrReportOnSubmit = (
    localFilterOptions: ConsultationFilter,
    setIsMissingDate: (isMissing: boolean) => void,
    searchConsultations: (searchOpts: {
        consultationFilter: ConsultationFilterBackend;
        isReport: boolean;
    }) => void,
    isReport: boolean,
    dispatch: AppDispatch
) => {
    if (!localFilterOptions.dateTo || !localFilterOptions.dateFrom) {
        setIsMissingDate(true);
    } else {
        const timeTo = localFilterOptions.timeTo || '23:59';
        const timeFrom = localFilterOptions.timeFrom || '00:00';
        const dateTimeToUTC = DateTime.fromISO(`${localFilterOptions.dateTo}T${timeTo}`, {
            zone: localFilterOptions.timeZone
        })
            .setZone('utc')
            .toISO();
        const dateTimeFromUTC = DateTime.fromISO(`${localFilterOptions.dateFrom}T${timeFrom}`, {
            zone: localFilterOptions.timeZone
        })
            .setZone('utc')
            .toISO();
        dispatch(consultationSearch({ consultationFilter: localFilterOptions }));
        searchConsultations({
            consultationFilter: {
                dateTimeToUTC,
                dateTimeFromUTC,
                allOrAny: localFilterOptions.allOrAny,
                consultationTitle: localFilterOptions.consultationTitle,
                communicatedWith: localFilterOptions.communicatedWith.map((person) => person.personId),
                method: localFilterOptions.method,
                addedBy: localFilterOptions.addedBy,
                locationFilter: generateLocationFilter(localFilterOptions.locationFilter),
                status: localFilterOptions.status
            },
            isReport
        });
        setIsMissingDate(false);
    }
};

export const renderSimTicketTitle = (simId: string, title: string) => (
    <Link
        type='secondary'
        href={`https://t.corp.amazon.com/${simId}`}
        target='_blank'
        rel='noopener noreferrer'
    >
        <Row spacing='small' width='fit-content'>
            <span>
                <span className={`SmallMargin`}> {title} </span>
                <Icon tokens={exportSmallTokens} />
            </span>
        </Row>
    </Link>
);

export const convertPersonInvolvedToCreate = (personInvolved: PersonInvolved): CreatePersonInvolved => {
    return {
        personId: personInvolved.personId || '',
        title: personInvolved.title || '',
        phoneNumber: personInvolved.phoneNumber || '',
        email: personInvolved.email || '',
        name: personInvolved.name || '',
        login: personInvolved.login || '',
        role: personInvolved.role
    };
};

export const closeSheetButton = (onClick: () => void) => (
    <TitledButton
        title={labels.closeSheet}
        buttonProps={{ onClick: onClick, type: 'icon' }}
        iconProps={{ tokens: closeTokens }}
    />
);

export const getEmployeeJobStatusType = (status?: string) => {
    switch (status) {
        case EmployeeJobStatusType.Active:
            return <Text type='b300'> Active </Text>;
        case EmployeeJobStatusType.Inactive:
            return <Text type='b300'> Inactive </Text>;
        case EmployeeJobStatusType.Terminated:
            return <Text type='b300'> Terminated </Text>;
        case EmployeeJobStatusType.Deceased:
            return <Text type='b300'> Deceased </Text>;
        case EmployeeJobStatusType.Leave:
            return <Text type='b300'> Leave </Text>;
        case EmployeeJobStatusType.LeaveWithPay:
            return <Text type='b300'> Leave w/Pay </Text>;
        case EmployeeJobStatusType.RetiredWithPay:
            return <Text type='b300'> Retired w/Pay </Text>;
        case EmployeeJobStatusType.Retired:
            return <Text type='b300'> Retired </Text>;
        case EmployeeJobStatusType.Suspended:
            return <Text type='b300'> Suspended </Text>;
        case EmployeeJobStatusType.TermWithPension:
            return <Text type='b300'> Term w/Pension </Text>;
        case EmployeeJobStatusType.TermWithoutPension:
            return <Text type='b300'> Term w/Pension </Text>;
        case EmployeeJobStatusType.ShortWorkBreak:
            return <Text type='b300'> Short Work Break </Text>;
        case EmployeeJobStatusType.RetiredPensionAdministration:
            return <Text type='b300'> Retired Pension Administration </Text>;
        default:
            return <Text type='b300'> {status} </Text>;
    }
};

export const formattedTenure = (format: string | undefined) => {
    const tempYear = (Number(format) / 365).toFixed(4);
    const years = Math.trunc(Number(format) / 365);

    const tempMonth = Number(Number(tempYear).toFixed(4)) - years;
    const tempMonthDay = Number((tempMonth * 12).toFixed(4));
    const months = Math.trunc(tempMonthDay);

    const tempDays = (tempMonthDay - months).toFixed(4);
    const days = Math.ceil(Number(tempDays) * 30);

    const tenureFormat: string[] = [];
    if (years > 0) tenureFormat.push(years.toString() + ' years');
    if (months > 0) tenureFormat.push(' ' + months.toString() + ' months');
    if (days > 0) tenureFormat.push(' ' + days.toString() + ' days');

    return tenureFormat.toString();
};

export const isUserAdmin = (roles: string[]): boolean => {
    return roles.includes('admin') || roles.includes('system admin');
};
