import Column from '@amzn/meridian/column';
import Text from '@amzn/meridian/text';
import React from 'react';
import { EditCaseVars } from '../../../lib/CaseDefinitions';
import { CASE_STRINGS, CHARACTER_LIMIT, WIDTH_OF_CARD } from '../../../lib/constants';
import { FormTextarea } from '../../Inputs/FormFields';

type NewCaseEventInformationProps = {
    createCaseVars: EditCaseVars;
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    setCreateCaseVars: (vars: any) => void;
};

const NewCaseEventSummary: React.FunctionComponent<NewCaseEventInformationProps> = ({
    createCaseVars,
    setCreateCaseVars
}: NewCaseEventInformationProps) => {
    return (
        <Column width={WIDTH_OF_CARD}>
            <Text type='h300'> {CASE_STRINGS.EVENT_SUMMARY} </Text>
            <FormTextarea
                label={`${CASE_STRINGS.EVENT_SUMMARY} *`}
                rows={9}
                value={createCaseVars.eventSummary}
                onChange={(eventSummary) =>
                    setCreateCaseVars({
                        ...createCaseVars,
                        eventSummary,
                        missingFields: createCaseVars.missingFields.filter(
                            (field) => field !== 'eventSummary'
                        )
                    })
                }
                max={CHARACTER_LIMIT.EVENT_SUMMARY_LIMIT}
                missing={createCaseVars.missingFields.includes('eventSummary')}
            />
        </Column>
    );
};

export default NewCaseEventSummary;
