import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../context/store';
import { useAppDispatch } from '../../../context/store';
import { ResponsiveVariables } from '../../../lib/constants';
import { closeDetail } from '../../../slices/CaseDetailsSlice';
import { closeConsultationDetail } from '../../../slices/ConsultationsSlice';
import { toggleNav } from '../../../slices/DashboardSlice';

type ResponsiveHookProps = {
    withBothOpen: ResponsiveVariables;
};

const ResponsiveHooks: React.FunctionComponent<ResponsiveHookProps> = ({ withBothOpen }) => {
    const dispatch = useAppDispatch();
    const { displayPersonInvolvedSheet } = useSelector((state: RootState) => state.caseDetails);
    const { displayingSheet } = useSelector((state: RootState) => state.consultations);
    const { isNavCollapsed } = useSelector((state: RootState) => state.dashboard);

    useEffect(() => {
        if (
            (displayingSheet || displayPersonInvolvedSheet) &&
            !isNavCollapsed &&
            withBothOpen === ResponsiveVariables.CLOSE_ON_OPEN
        ) {
            dispatch(toggleNav());
        }
    }, [displayingSheet, displayPersonInvolvedSheet]);

    useEffect(() => {
        if (!isNavCollapsed && withBothOpen === ResponsiveVariables.CLOSE_ON_OPEN) {
            dispatch(closeConsultationDetail());
            dispatch(closeDetail());
        }
    }, [isNavCollapsed]);

    return null;
};

export default ResponsiveHooks;
