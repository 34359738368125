import Badge from '@amzn/meridian/badge';
import Row from '@amzn/meridian/row';
import SideMenu, { SideMenuLink } from '@amzn/meridian/side-menu';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import Sockette from 'sockette';
import { useAppDispatch, RootState } from '../context/store';
import { Case, LevelOfConcern, StatusType } from '../lib/CaseDefinitions';
import { NAVBAR_WIDTH } from '../lib/constants';
import { WOTMAN_WEBSOCKET_ENDPOINT } from '../lib/globals';
import navRoutes, { dashboardRoute, newCasesRoute, updatedCasesRoute } from '../lib/NavRouteConstants';
import {
    getUserInfo,
    setDashboardCases,
    setOpenCases,
    setNotificationCounts,
    removeNotifications,
    toggleHasFinishedLoading
} from '../slices/DashboardSlice';
import { addToast } from '../slices/ToastSlice';
import { useLazySearchCasesQuery } from '../slices/WotManApiSlice';

const Navigation: React.FunctionComponent = () => {
    const history = useHistory();
    const dispatch = useAppDispatch();
    const { location } = history;
    const {
        isNavCollapsed,
        openCases,
        token,
        newCasesArray,
        updatedCasesArray,
        newHighPriorityArray
    } = useSelector((state: RootState) => state.dashboard);
    const [searchCases, searchCasesEndpoint] = useLazySearchCasesQuery();

    useEffect(() => {
        if (searchCasesEndpoint.isFetching && !searchCasesEndpoint.isSuccess) {
            dispatch(toggleHasFinishedLoading(false));
        }
    }, [searchCasesEndpoint.isFetching]);

    useEffect(() => {
        if (!searchCasesEndpoint.isFetching && searchCasesEndpoint.isSuccess) {
            dispatch(setOpenCases(searchCasesEndpoint.data.cases));
        } else if (!searchCasesEndpoint.isFetching && searchCasesEndpoint.isError) {
            dispatch(
                addToast({
                    type: 'error',
                    action: 'There was an error searching cases, please try again',
                    message: '',
                    SIM: true,
                    timeout: undefined
                })
            );
        }
        dispatch(toggleHasFinishedLoading(true));
    }, [searchCasesEndpoint.data]);

    useEffect(() => {
        searchCases({
            caseFilter: {
                status: [StatusType.OPEN]
            }
        });
    }, []);

    const webSocketHandleMessage = (data: string) => {
        const { caseId, isHighPriority, hasUpdate, isNew } = JSON.parse(data);
        dispatch(
            setNotificationCounts({ caseId: caseId, hasUpdate, isNew: isNew, highPriority: isHighPriority })
        );
    };

    useEffect(() => {
        if (token == '') {
            dispatch(getUserInfo());
        } else {
            new Sockette(`${WOTMAN_WEBSOCKET_ENDPOINT}?token=${token}`, {
                maxAttempts: 5,
                onmessage: (message) => webSocketHandleMessage(message.data)
            });
        }
    }, [token]);

    useEffect(() => {
        const newHighSevCases: Case[] = [];
        const updatedHighLOCCases: Case[] = [];
        dispatch(removeNotifications());
        openCases.forEach((caseHeader) => {
            if (!caseHeader.loc && caseHeader.emtSev) {
                let isHighPriority = false;
                if (caseHeader.emtSev <= 2) {
                    isHighPriority = true;
                    newHighSevCases.push(caseHeader);
                }
                dispatch(
                    setNotificationCounts({
                        caseId: caseHeader.caseId,
                        isNew: true,
                        hasUpdate: caseHeader.updateCount > 0,
                        highPriority: isHighPriority
                    })
                );
            } else if (caseHeader.updateCount > 0) {
                let isHighPriority = false;
                if (caseHeader.loc === LevelOfConcern.CRITICAL || caseHeader.loc === LevelOfConcern.HIGH) {
                    isHighPriority = true;
                    updatedHighLOCCases.push(caseHeader);
                }
                dispatch(
                    setNotificationCounts({
                        caseId: caseHeader.caseId,
                        isNew: false,
                        hasUpdate: true,
                        highPriority: isHighPriority
                    })
                );
            }
        });

        newHighSevCases.sort(function(caseOne, caseTwo) {
            if (caseOne.emtSev != caseTwo.emtSev) {
                return caseOne.emtSev === 1 ? -1 : 1;
            }
            return caseOne.flaggedWords.length - caseTwo.flaggedWords.length;
        });

        updatedHighLOCCases.sort(function(caseOne, caseTwo) {
            if (caseOne.loc != caseTwo.loc) {
                return caseOne.loc === LevelOfConcern.CRITICAL ? -1 : 1;
            }
            return caseOne.flaggedWords.length - caseTwo.flaggedWords.length;
        });
        dispatch(setDashboardCases({ newHighSevCases, updatedHighLOCCases }));
    }, [openCases]);

    return (
        <SideMenu open={!isNavCollapsed} type='skinny' width={NAVBAR_WIDTH}>
            {navRoutes.map((navRoute) => {
                let numNotifications: number;
                switch (navRoute) {
                    case newCasesRoute:
                        numNotifications = newCasesArray.length;
                        break;
                    case updatedCasesRoute:
                        numNotifications = updatedCasesArray.length;
                        break;
                    case dashboardRoute:
                        numNotifications = newHighPriorityArray.length;
                        break;
                    default:
                        numNotifications = 0;
                }
                return (
                    <SideMenuLink
                        href={navRoute.path}
                        selected={navRoute.path === location.pathname}
                        key={navRoute.path}
                        icon={navRoute.iconTokens}
                        onClick={(href) => {
                            searchCases({
                                caseFilter: {
                                    status: [StatusType.OPEN]
                                }
                            });
                            history.push(href);
                        }}
                    >
                        <Row spacing={'300'}>
                            <span> {navRoute.label} </span>
                            {numNotifications > 0 && !isNavCollapsed && (
                                <Badge
                                    value={numNotifications}
                                    type={navRoute === dashboardRoute ? 'error' : 'theme'}
                                />
                            )}
                            {isNavCollapsed && numNotifications > 0 && navRoute.notification}
                        </Row>
                    </SideMenuLink>
                );
            })}
        </SideMenu>
    );
};

export default withRouter(Navigation);
