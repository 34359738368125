import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    CommunicationMethod,
    ConsultationDetails,
    Proximity,
    Consultation,
    PAPIShort,
    SiteIdAndCode,
    Region
} from '../lib/CaseDefinitions';
import { mapFacilityToAbbreviatedFacility, mapOffSiteLocationToAbbreviatedFacility } from '../lib/helpers';

export interface ConsultationFilterOptions {
    method: CommunicationMethod[];
    added: string[];
    communicatedWith: PAPIShort[];
    proximity: Proximity[];
    region: Region[];
    siteIds: SiteIdAndCode[];
}
export const initialConsultationFilterOptions: ConsultationFilterOptions = {
    method: [],
    added: [],
    communicatedWith: [],
    proximity: [],
    region: [],
    siteIds: []
};

interface ConsultationSliceState {
    displayingSheet: boolean;
    consultationDisplayed: ConsultationDetails;
    consultations: Consultation[];
    consultationId: number;
    displayedConsultations: Consultation[];
    consultationFilterOptions: ConsultationFilterOptions;
    localFilterOptions: ConsultationFilterOptions;
}

const initialState: ConsultationSliceState = {
    displayingSheet: false,
    consultationDisplayed: {
        consultationId: -1,
        eventTime: '',
        eventDate: '',
        proximity: Proximity.OFFSITE,
        primaryFacility: [],
        secondaryFacilityList: [],
        communicatedWith: [],
        method: CommunicationMethod.PHONE,
        title: '',
        summary: '',
        linkedCases: [],
        canEdit: false,
        timeZone: 'UTC'
    },
    consultations: [],
    consultationId: -1,
    displayedConsultations: [],
    consultationFilterOptions: initialConsultationFilterOptions,
    localFilterOptions: initialConsultationFilterOptions
};

const consultationsSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        setConsultationId: (state: ConsultationSliceState, { payload }: PayloadAction<number>) => {
            //payload is the id
            state.consultationId = payload;
            state.displayingSheet = true;
        },
        displayDetail: (state: ConsultationSliceState, { payload }: PayloadAction<ConsultationDetails>) => {
            state.consultationDisplayed = payload;
            state.displayingSheet = true;
        },
        closeConsultationDetail: (state: ConsultationSliceState) => {
            state.displayingSheet = false;
        },
        setConsultationFilterOptions: (
            state: ConsultationSliceState,
            { payload }: PayloadAction<ConsultationFilterOptions>
        ) => {
            state.consultationFilterOptions = payload;
            state.localFilterOptions = payload;
        },
        setLocalConsultationFilterOptions: (
            state: ConsultationSliceState,
            { payload }: PayloadAction<ConsultationFilterOptions>
        ) => {
            state.localFilterOptions = payload;
        },
        setDisplayedConsultations: (
            state: ConsultationSliceState,
            { payload }: PayloadAction<Consultation[]>
        ) => {
            state.displayedConsultations = payload;
        },
        setConsultationDisplayed: (
            state: ConsultationSliceState,
            { payload }: PayloadAction<ConsultationDetails>
        ) => {
            state.consultationDisplayed = payload;
        },
        setConsultations: (state: ConsultationSliceState, { payload }: PayloadAction<Consultation[]>) => {
            state.consultations = payload;
        },
        editConsultation: (
            state: ConsultationSliceState,
            { payload }: PayloadAction<ConsultationDetails>
        ) => {
            state.consultationDisplayed = payload;
            state.consultations = state.consultations.map((consult) => {
                return consult.consultationId === payload.consultationId
                    ? {
                          ...consult,
                          communicatedWith: payload.communicatedWith.map((commWith) => commWith.name),
                          method: payload.method,
                          name: payload.title,
                          linkedCases: payload.linkedCases,
                          proximity: payload.proximity,
                          primaryLocation: mapFacilityToAbbreviatedFacility(payload.primaryFacility),
                          secondaryLocation: mapFacilityToAbbreviatedFacility(payload.secondaryFacilityList),
                          location: mapOffSiteLocationToAbbreviatedFacility(payload.offsiteLocation)
                      }
                    : consult;
            });
        },
        sortDisplayedConsultations: (state: ConsultationSliceState, { payload }: PayloadAction<string>) => {
            if (payload === 'title') {
                state.consultations.sort((a, b) => a.name.trim().localeCompare(b.name.trim()));
            } else if (payload === 'addedBy') {
                state.consultations.sort(
                    (a, b) =>
                        (a.added.firstName + a.added.lastName).localeCompare(
                            b.added.firstName + b.added.lastName
                        ) || new Date(b.added.timeAdded).getTime() - new Date(a.added.timeAdded).getTime()
                );
            } else if (payload === 'method') {
                state.consultations.sort(
                    (a, b) =>
                        a.method.localeCompare(b.method) ||
                        new Date(b.added.timeAdded).getTime() - new Date(a.added.timeAdded).getTime()
                );
            } else if (payload === 'recentActivity') {
                state.consultations.sort(
                    (a, b) => new Date(b.added.timeAdded).getTime() - new Date(a.added.timeAdded).getTime()
                );
            }
        }
    }
});

export const {
    displayDetail,
    closeConsultationDetail,
    setConsultationFilterOptions,
    setDisplayedConsultations,
    setConsultations,
    setConsultationId,
    setConsultationDisplayed,
    sortDisplayedConsultations,
    setLocalConsultationFilterOptions,
    editConsultation
} = consultationsSlice.actions;

export default consultationsSlice.reducer;
