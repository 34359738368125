import React, { useState } from 'react';
import { useAppDispatch } from '../../context/store';
import { LevelOfConcern } from '../../lib/CaseDefinitions';
import { getColor, locToString, renderSeverity } from '../../lib/helpers';
import { labels } from '../../lib/labels';
import { displayLocModal } from '../../slices/CaseDetailsSlice';

type LinkCaseInputProps = {
    loc: LevelOfConcern | undefined;
    emtSev?: number;
    canEdit?: boolean;
    radius?: number;
};

const LocCircle: React.FunctionComponent<LinkCaseInputProps> = ({
    loc,
    emtSev,
    canEdit = false,
    radius = 27
}) => {
    const [isHovered, setHovered] = useState(false);
    const dispatch = useAppDispatch();
    const circleText = loc ? locToString(loc) : '-';
    const parentClass = isHovered ? 'Clickable' : '';
    const color = getColor(loc, emtSev);
    return (
        <span title={canEdit ? labels.editLoc : undefined}>
            <svg
                className={parentClass}
                height={radius * 2}
                width={radius * 2}
                xlinkTitle={canEdit ? labels.editLoc : undefined}
                onClick={() => {
                    if (canEdit) dispatch(displayLocModal());
                }}
                onMouseEnter={() => setHovered(canEdit)}
                onMouseLeave={() => setHovered(false)}
            >
                <circle
                    cx={radius}
                    cy={radius}
                    r={radius - 2}
                    fill={isHovered ? '#BBC0C1' : '#ECEDED'}
                    stroke={isHovered ? '#BBC0C1' : circleText === '-' ? '#ECEDED' : color}
                    strokeWidth='2'
                />
                {isHovered ? (
                    <svg
                        aria-hidden='true'
                        focusable='false'
                        x='15'
                        y='15'
                        width={radius - 3}
                        height={radius - 3}
                        xmlns='http://www.w3.org/2000/svg'
                    >
                        <g fill='none' fillRule='evenodd'>
                            <path
                                d='M18.22 2.92l2.86 2.86L7.16 20H4v-3.16L18.22 2.92zm0-2.41a1 1 0 00-.7.28L2 16v4a1.84 1.84 0 001.89 2H8L23.2 6.47a1 1 0 000-1.4L18.93.81a1 1 0 00-.71-.29V.51z'
                                fill='currentColor'
                                fillRule='nonzero'
                            />
                            <path
                                d='M13.33 5.02l5.65 5.65M5.03 13.31l5.66 5.66'
                                stroke='currentColor'
                                strokeWidth='2'
                            />
                        </g>
                    </svg>
                ) : (
                    <text
                        textAnchor='middle'
                        dominantBaseline='central'
                        x={radius}
                        y={radius}
                        fontSize={radius < 25 ? 'smaller' : 'medium'}
                    >
                        {circleText}
                    </text>
                )}
            </svg>
            {circleText === '-' && renderSeverity(emtSev)}
        </span>
    );
};

export default LocCircle;
