import Icon from '@amzn/meridian/icon';
import Link from '@amzn/meridian/link';
import Row from '@amzn/meridian/row';
import exportSmallTokens from '@amzn/meridian-tokens/base/icon/export-small';
import React from 'react';
import { ADMIN_DIRECTORY_LINK } from '../../lib/constants';

type AdminDirectoryLinkProps = {
    alias: string | undefined;
};

const AdminDirectoryLink: React.FunctionComponent<AdminDirectoryLinkProps> = ({
    alias
}: AdminDirectoryLinkProps) => (
    <Row spacing='small' width='fit-content'>
        <Link type='secondary' target='_blank' rel='noopener noreferrer' href={ADMIN_DIRECTORY_LINK + alias}>
            <span className='SmallMargin'> View in Admin Directory </span>
            <Icon tokens={exportSmallTokens} />
        </Link>
    </Row>
);

export default AdminDirectoryLink;
