import AppLayout from '@amzn/meridian/app-layout';
import Row from '@amzn/meridian/row';
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { LastLocationProvider } from 'react-router-last-location';
import ViewingPersonsInvolved from './components/Cases/DetailsTabs/ViewingPersonsInvolved';
import ConsultationDetailsSheet from './components/Consultations/ConsultationDetailsSheet';
import ToastAlert from './components/CustomComponents/ToastAlert';
import UnauthorizedUser from './components/ErrorPage/UnauthorizedUser';
import Footer from './components/Footer';
import Header from './components/Header';
import Navigation from './components/Navigation';
import { allRoutes } from './lib/NavRouteConstants';
import { useGetUserContextQuery } from './slices/WotManApiSlice';

const Routes: React.FunctionComponent = () => {
    const { error, isError } = useGetUserContextQuery();
    const [isValidUser, setIsValidUser] = useState(true);

    useEffect(() => {
        if (isError) {
            if (isError && error && error.status === 403) {
                setIsValidUser(false);
            }
        }
    }, [isError]);

    return (
        <div>
            {isValidUser ? (
                <Router
                    getUserConfirmation={() => {
                        return;
                    }}
                >
                    <Row
                        width='100%'
                        widths={['fit', 'fill']}
                        alignmentVertical='stretch'
                        spacing='none'
                        height='100vh'
                    >
                        <LastLocationProvider>
                            <AppLayout
                                headerComponent={Header}
                                sidebarComponent={Navigation}
                                footerComponent={Footer}
                                backgroundColor='alternateSecondary'
                                spacingInset='large'
                            >
                                <Header />
                                <Navigation />
                                {allRoutes.map((route) => (
                                    <Route
                                        exact
                                        path={route.path}
                                        component={route.component}
                                        key={route.path}
                                    />
                                ))}
                                <Footer />
                                <ToastAlert />
                            </AppLayout>
                        </LastLocationProvider>
                        <ViewingPersonsInvolved />
                        <ConsultationDetailsSheet />
                    </Row>
                </Router>
            ) : (
                <UnauthorizedUser />
            )}
        </div>
    );
};

export default Routes;
