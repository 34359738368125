import Column from '@amzn/meridian/column';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../context/store';
import { WIDTHS_OF_ADVANCED_SEARCH_COLUMNS } from '../../../lib/constants';
import { ConsultationFilter, initialConsultationFilter } from '../../../lib/FilterDefinitons';
import { ConsultationSearchOrReportOnSubmit } from '../../../lib/helpers';
import { addToast } from '../../../slices/ToastSlice';
import AdvancedConsultationFilterHelper from './AdvancedConsultationFilterHelper';
import DateRow from './DateRow';

type ConsultationFilterProps = {
    // eslint-disable-next-line
    searchConsultations: (filter: { consultationFilter: any }) => void;
};

//TODO: connect with backend for searches, add categories / subcategories, add person involved support
const AdvancedConsultationFilter: React.FunctionComponent<ConsultationFilterProps> = ({
    searchConsultations
}: ConsultationFilterProps) => {
    const { consultationFilterOptions } = useSelector((state: RootState) => state.advancedSearch);

    const dispatch = useAppDispatch();
    const [localFilterOptions, setLocalFilterOptions] = useState<ConsultationFilter>(
        initialConsultationFilter
    );
    const [missingDate, setIsMissingDate] = useState<boolean>(false);

    useEffect(() => {
        setLocalFilterOptions({ ...consultationFilterOptions });
    }, [consultationFilterOptions]);

    useEffect(() => {
        if (missingDate) {
            dispatch(
                addToast({
                    type: 'error',
                    action: 'Dates must be included in search ',
                    message: 'Correct any errors and submit again',
                    SIM: false,
                    timeout: 5000
                })
            );
        }
    }, [missingDate]);

    return (
        <Column>
            <DateRow
                filter={localFilterOptions}
                missingDate={missingDate}
                setDates={(dates) =>
                    setLocalFilterOptions({
                        ...localFilterOptions,
                        dateTo: dates[1],
                        dateFrom: dates[0]
                    })
                }
                hasTitle
            />
            <AdvancedConsultationFilterHelper
                onClick={() =>
                    ConsultationSearchOrReportOnSubmit(
                        localFilterOptions,
                        setIsMissingDate,
                        searchConsultations,
                        false,
                        dispatch
                    )
                }
                localFilterOptions={localFilterOptions}
                setLocalFilterOptions={setLocalFilterOptions}
                buttonName={'Search'}
                widthsOfColumns={WIDTHS_OF_ADVANCED_SEARCH_COLUMNS}
            />
        </Column>
    );
};

export default AdvancedConsultationFilter;
