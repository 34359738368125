import AppLayout from '@amzn/meridian/app-layout';
import Column from '@amzn/meridian/column';
import Divider from '@amzn/meridian/divider';
import Link from '@amzn/meridian/link';
import Row from '@amzn/meridian/row';
import Text from '@amzn/meridian/text';
import React from 'react';
import Footer from '../../components/Footer';
import MARSAccessDenied from '../../images/MARSAccessDenied.png';
import logo from '../../images/MARSLogoDarkwHeadline.png';

const UnauthorizedUser: React.FunctionComponent = () => {
    return (
        <AppLayout footerComponent={Footer} backgroundColor='alternateSecondary'>
            <Row alignmentHorizontal='center'>
                <Column alignmentHorizontal='left' spacingInset='600'>
                    <img src={logo} alt='MARS Logo' height='100' />
                    <Text type='h400'>You do not have permssion to view MARS</Text>
                    <Text type='b400'>
                        To learn more about the MARS application, visit the &nbsp;
                        <Link
                            href='https://w.amazon.com/bin/view/New_Technologies_for_Security_(NT4S)/Product_Management/MARS'
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            MARS wiki
                        </Link>
                        .
                    </Text>
                    <Divider />
                    <Column spacing='100'>
                        <Text type='b100'>Security Operations Software (SecOps)</Text>
                        <Text type='b100'>Global Security Operations Technology (GSOTech)</Text>
                    </Column>
                </Column>
                <Column alignmentHorizontal='center' spacingInset='600'>
                    <img src={MARSAccessDenied} alt='MARS Logo' height='300' />
                </Column>
            </Row>
            <Footer />
        </AppLayout>
    );
};

export default UnauthorizedUser;
