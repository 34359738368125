import Loader from '@amzn/meridian/loader/loader';
import Row from '@amzn/meridian/row';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { useAppDispatch, RootState } from '../../context/store';
import { WIDTH_OF_CARD, TAB_NAMES } from '../../lib/constants';
import { filterCasesOnOptions } from '../../lib/helpers';
import { initialFilterOptions, setDisplayedCases, setFilterOptions } from '../../slices/DashboardSlice';
import CaseColumn from '../Cases/CaseColumn';

const MyCases: React.FunctionComponent = () => {
    const { openCases, filterOptions, userId, hasFinishedLoading } = useSelector(
        (state: RootState) => state.dashboard
    );
    const dispatch = useAppDispatch();
    const myCases = filterCasesOnOptions(openCases, filterOptions);
    const [currentTab, setCurrentTab] = useState('assignedToMe');

    useEffect(() => {
        dispatch(setFilterOptions(initialFilterOptions));
    }, []);

    useEffect(() => {
        dispatch(
            setDisplayedCases(
                myCases.filter((myCase) => {
                    switch (currentTab) {
                        case 'assignedToMe':
                            return myCase.assignee === userId;
                        case 'bookmarked':
                            return myCase.isBookmarked;
                        case 'restricted':
                            return myCase.isRestricted;
                    }
                })
            )
        );
    }, [openCases, filterOptions, currentTab]);

    return hasFinishedLoading ? (
        <React.Fragment>
            <Helmet defer={false}>
                <title> {TAB_NAMES.MY_CASES} | MARS </title>
            </Helmet>
            <Row
                width='100%'
                alignmentVertical='top'
                alignmentHorizontal='center'
                widths={[WIDTH_OF_CARD, '272px']}
            >
                <CaseColumn
                    heading={'My Cases'}
                    tabs={{
                        myCaseTab: currentTab,
                        setMyCaseTab: setCurrentTab
                    }}
                />
            </Row>
        </React.Fragment>
    ) : (
        <Loader />
    );
};

export default MyCases;
