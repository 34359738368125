import Alert from '@amzn/meridian/alert';
import Badge from '@amzn/meridian/badge';
import Breadcrumb, { BreadcrumbGroup } from '@amzn/meridian/breadcrumb';
import Button from '@amzn/meridian/button';
import Checkbox from '@amzn/meridian/checkbox';
import Column from '@amzn/meridian/column';
import Icon from '@amzn/meridian/icon';
import Loader from '@amzn/meridian/loader';
import Popover, { PopoverHeader } from '@amzn/meridian/popover';
import Responsive from '@amzn/meridian/responsive';
import Row from '@amzn/meridian/row';
import Select, { SelectOption } from '@amzn/meridian/select';
import Tab, { TabGroup } from '@amzn/meridian/tab';
import Tag from '@amzn/meridian/tag';
import Text from '@amzn/meridian/text';
import Tile from '@amzn/meridian/tile';
import clockIconTokens from '@amzn/meridian-tokens/base/icon/clock';
import editIconTokens from '@amzn/meridian-tokens/base/icon/edit';
import geopinIconTokens from '@amzn/meridian-tokens/base/icon/geopin';
import _ from 'lodash';
import { DateTime } from 'luxon';
import moment from 'moment-timezone';
import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { useLastLocation } from 'react-router-last-location';
import { mockMarsCase } from '../../__mocks__/mockData';
import { RootState, useAppDispatch } from '../../context/store';
import { Assignee, EditCaseVars, Recommendation, StatusType } from '../../lib/CaseDefinitions';
import {
    ADDITIONAL_TAGS,
    CASE_STRINGS,
    ResponsiveVariables,
    responsiveVariables,
    WIDGET_WIDTH,
    WIDTH_OF_CARD,
    WIDTHS_OF_DETAILS_CARD
} from '../../lib/constants';
import { getDateTimeFromISO, renderDateDiff, renderSimTicketTitle } from '../../lib/helpers';
import { labels } from '../../lib/labels';
import { allRoutes } from '../../lib/NavRouteConstants';
import { Update } from '../../lib/UpdateDefinitions';
import { closeDetail, setCaseDetails, setIsStacked } from '../../slices/CaseDetailsSlice';
import { closeConsultationDetail } from '../../slices/ConsultationsSlice';
import { addToast } from '../../slices/ToastSlice';
import {
    useLazyBookmarkCaseQuery,
    useLazyGetCaseDetailsQuery,
    useLazyUpdateCaseQuery
} from '../../slices/WotManApiSlice';
import AlertModal from '../Alert/AlertModal';
import RouterPrompt from '../Alert/RouterPrompt';
import Bookmark from '../CustomComponents/Bookmark';
import CaseSiteLocations from '../CustomComponents/CaseSiteLocations';
import TitledButton from '../CustomComponents/TitledButton';
import CaseNotFound from '../ErrorPage/CaseNotFound';
import EditLocModal from '../Inputs/EditLocModal';
import LocCircle from '../Inputs/LocCircle';
import ThreatManagerInput from '../Inputs/ThreatManagerInput';
import PersonsInvolvedTab from './DetailsTabs/PersonsInvolvedTab';
import RecommendationsTab from './DetailsTabs/RecommendationsTab';
import RelatedTab from './DetailsTabs/RelatedTab';
import ResponsiveHooks from './DetailsTabs/ResponsiveHooks';
import CaseDetailsSidebar from './DetailsTabs/SideBar/CaseDetailsSidebar';
import SummaryTab from './DetailsTabs/SummaryTab';
import UpdatesTab from './DetailsTabs/UpdatesTab';

const CaseDetails: React.FunctionComponent = () => {
    const [pendingAckWarning, setPendingAckWarning] = useState(false);
    const [currentTab, setCurrentTab] = useState('updates');
    const [openStatus, setOpenStatus] = useState(false);
    const [showSummaryAlert, setShowSummaryAlert] = useState(false);
    const [showRecommendationAlert, setShowRecommendationAlert] = useState(false);
    const [shownRecOnce, setShownRecOnce] = useState(false);
    const { caseId } = useParams();
    const [showRecommendationAlertPrompt, setShowRecommendationsAlertPrompt] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [openAccessLevel, setOpenAccessLevel] = useState(false);
    const [openAssigned, setOpenAssigned] = useState(false);
    const [tempRecc, setTempRecc] = useState<Recommendation[]>([]);
    const [tempAssignedTo, setTempAssignedTo] = useState<Assignee | undefined>();
    const [tempStatus, setTempStatus] = useState(StatusType.CLOSED);
    const [tempAccess, setTempAccess] = useState<boolean>(false);
    const [displayCaseStatusValidation, setDisplayCaseStatusValidation] = useState<boolean>(false);
    const onEditStatusButton = useCallback(() => setOpenStatus(true), []);
    const onCloseEditStatus = useCallback(() => {
        setOpenStatus(false);
        setDisplayCaseStatusValidation(false);
    }, []);
    const onEditAccessButton = useCallback(() => setOpenAccessLevel(true), []);
    const onCloseEditAccess = useCallback(() => setOpenAccessLevel(false), []);
    const onEditAssignedButton = useCallback(() => setOpenAssigned(true), []);
    const onCloseEditAssigned = useCallback(() => setOpenAssigned(false), []);
    const { caseDetails, displayPersonInvolvedSheet, isStacked, isCaseOpen } = useSelector(
        (state: RootState) => state.caseDetails
    );
    const { displayingSheet } = useSelector((state: RootState) => state.consultations);
    const { isNavCollapsed } = useSelector((state: RootState) => state.dashboard);
    const dispatch = useAppDispatch();
    const history = useHistory();
    const lastLocation = useLastLocation();

    const [useGetCaseDetails, useGetCaseDetailsEndpoint] = useLazyGetCaseDetailsQuery();
    const { data, isError, error, isFetching } = useGetCaseDetailsEndpoint;
    const [useUpdateAccessLevel, updateAccessLevelEndpoint] = useLazyUpdateCaseQuery();
    const [useUpdateAssignee, updateAssigneeEndpoint] = useLazyUpdateCaseQuery();
    const [useUpdateStatus, updateStatusEndpoint] = useLazyUpdateCaseQuery();
    const [useBookmarkCase, bookmarkCaseEndpoint] = useLazyBookmarkCaseQuery();
    const [checked, setChecked] = useState(caseDetails.status === StatusType.NO_ACTION);
    const [isOpen, setIsOpen] = useState(
        caseDetails.status === StatusType.OPEN ? StatusType.OPEN : StatusType.CLOSED
    );
    const [isCaseValid, setIsCaseValid] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const [prevTabState, setPrevTabState] = useState(['']);
    const [sortValue, setSortValue] = useState('newest');
    const [tabName, setTabName] = useState('Case details');

    const incidentDate = caseDetails.incidentTime
        ? getDateTimeFromISO(caseDetails.incidentDate, caseDetails.incidentTime, caseDetails.inputTimeZone)
        : undefined;

    const [editingCaseInfo, setEditingCaseInfo] = useState<EditCaseVars>({
        eventCategory: caseDetails.emtCategory,
        eventType: caseDetails.emtType,
        eventItem: caseDetails.emtItem,
        additionalTags: caseDetails.eventTags.filter((tag) => ADDITIONAL_TAGS.includes(tag)),
        dateOfEvent: incidentDate?.toISODate() || caseDetails.incidentDate,
        timeOfEvent: incidentDate?.toISOTime() || caseDetails.incidentTime,
        eventSummary: caseDetails.eventSummary,
        missingFields: [],
        isDirty: false,
        timezone: caseDetails.inputTimeZone || moment.tz.guess()
    });

    useEffect(() => {
        useGetCaseDetails({ incidentId: caseId });
    }, [caseId]);

    useEffect(() => {
        setEditingCaseInfo({
            eventCategory: caseDetails.emtCategory,
            eventType: caseDetails.emtType,
            eventItem: caseDetails.emtItem,
            additionalTags: caseDetails.eventTags.filter((tag) => ADDITIONAL_TAGS.includes(tag)),
            dateOfEvent: incidentDate?.toISODate() || caseDetails.incidentDate,
            timeOfEvent: incidentDate?.toISOTime() || caseDetails.incidentTime,
            eventSummary: caseDetails.eventSummary,
            missingFields: [],
            isDirty: false,
            timezone: caseDetails.inputTimeZone || moment.tz.guess()
        });
    }, [caseDetails]);

    useEffect(() => {
        if (!updateAccessLevelEndpoint.isFetching && updateAccessLevelEndpoint.isSuccess) {
            dispatch(setCaseDetails(updateAccessLevelEndpoint.data));
            setOpenAccessLevel(false);
        } else if (updateAccessLevelEndpoint.isError) {
            dispatch(
                addToast({
                    type: 'error',
                    action: 'There was an error updating access level, please try again',
                    message: '',
                    SIM: true,
                    timeout: 5000
                })
            );
        }
    }, [updateAccessLevelEndpoint.isFetching]);

    useEffect(() => {
        if (!updateAssigneeEndpoint.isFetching && updateAssigneeEndpoint.isSuccess) {
            dispatch(setCaseDetails(updateAssigneeEndpoint.data));
            setOpenAssigned(false);
        } else if (updateAssigneeEndpoint.isError) {
            dispatch(
                addToast({
                    type: 'error',
                    action: 'There was an error while updating the assignee, please try again',
                    message: '',
                    SIM: true,
                    timeout: 5000
                })
            );
        }
    }, [updateAssigneeEndpoint.isFetching]);

    useEffect(() => {
        if (!updateAccessLevelEndpoint.isFetching) {
            if (updateStatusEndpoint.isSuccess) {
                dispatch(setCaseDetails(updateStatusEndpoint.data));
                setOpenStatus(false);
                setDisplayCaseStatusValidation(false);
                setPendingAckWarning(false);
            } else if (
                updateStatusEndpoint.error?.data?.error &&
                updateStatusEndpoint?.error?.data?.error?.tickets.length > 0
            ) {
                setDisplayCaseStatusValidation(true);
            } else if (updateStatusEndpoint.isError) {
                dispatch(
                    addToast({
                        type: 'error',
                        action: 'There was an error while updating status, please try again',
                        message: '',
                        SIM: true,
                        timeout: 5000
                    })
                );
            }
        }
    }, [updateStatusEndpoint.isFetching]);

    useEffect(() => {
        if (!bookmarkCaseEndpoint.isFetching && bookmarkCaseEndpoint.isSuccess) {
            dispatch(setCaseDetails(bookmarkCaseEndpoint.data));
        } else if (bookmarkCaseEndpoint.isError) {
            dispatch(
                addToast({
                    type: 'error',
                    action: 'There was an error while bookmarking a case, please try again',
                    message: '',
                    SIM: true,
                    timeout: 5000
                })
            );
        }
    }, [bookmarkCaseEndpoint.isFetching]);

    const setBookmarked = (newBookmarked: boolean) => {
        useBookmarkCase({
            isBookmarking: newBookmarked,
            caseId: caseId
        });
    };

    useEffect(() => {
        if (data && !isFetching) {
            dispatch(setCaseDetails(data));
        }
    }, [data]);

    useEffect(() => {
        if (isError) {
            if (isError && error && error.status === 500) {
                setIsCaseValid(false);
                setErrorMessage('There was a problem loading this case');
            } else if (isError && error && error.status === 403) {
                setIsCaseValid(false);
                setErrorMessage('You do not have permission to view this page');
                setTabName('Permission denied');
            } else {
                setIsCaseValid(false);
                setErrorMessage('An unexpected error has occurred');
            }
        }
    }, [isError]);

    useEffect(() => {
        setTempAssignedTo(caseDetails.assignedTo);
        setTempAccess(caseDetails.isRestricted);
        setTempStatus(caseDetails.status);
        setChecked(caseDetails.status === StatusType.NO_ACTION);
        setIsOpen(caseDetails.status === StatusType.OPEN ? StatusType.OPEN : StatusType.CLOSED);
    }, [caseDetails]);

    useEffect(() => {
        setTempRecc(caseDetails.recommendations);
    }, [caseDetails.recommendations]);

    useEffect(() => {
        setPrevTabState([...prevTabState, currentTab]);
        return function cleanup() {
            dispatch(closeDetail());
            dispatch(closeConsultationDetail());
        };
    }, [currentTab]);

    const statusRef = useRef();
    const accessLevelRef = useRef();
    const assignedRef = useRef();

    const handlerSaveStatus = () => {
        if (
            (tempStatus == StatusType.CLOSED || tempStatus == StatusType.NO_ACTION) &&
            caseDetails.updates.filter((update: Update) => update.needsAcknowledgement).length > 0
        ) {
            setPendingAckWarning(true);
        } else {
            useUpdateStatus({
                updating: {
                    type: 'status',
                    newContent: tempStatus,
                    incidentId: caseId
                }
            });
        }
    };

    const handlerCheck = () => {
        if (checked) {
            setChecked(false);
            setTempStatus(StatusType.CLOSED);
        } else {
            setChecked(true);
            setTempStatus(StatusType.NO_ACTION);
        }
    };

    useEffect(() => {
        if (currentTab !== 'summary' && editingCaseInfo.isDirty) {
            setShowSummaryAlert(true);
            setCurrentTab('summary');
        }
    }, [currentTab]);

    useEffect(() => {
        if (tempRecc.length > 0) {
            if (
                (!_.isEqual(tempRecc, caseDetails.recommendations) && currentTab !== 'recommendations') ||
                shownRecOnce
            ) {
                setShowRecommendationAlert(true);
                setShowRecommendationsAlertPrompt(true);
                setCurrentTab('recommendations');
            }
        }
    }, [tempRecc, currentTab]);

    const handleCancelSummary = () => {
        setShowSummaryAlert(false);
        setIsEditing(false);
        setCurrentTab(prevTabState[prevTabState.length - 2]);
        setPrevTabState(['']);
        setEditingCaseInfo({
            eventCategory: caseDetails.emtCategory,
            eventType: caseDetails.emtType,
            eventItem: caseDetails.emtItem,
            additionalTags: caseDetails.eventTags.filter((tag) => ADDITIONAL_TAGS.includes(tag)),
            dateOfEvent: incidentDate?.toISODate() || caseDetails.incidentDate,
            timeOfEvent: incidentDate?.toISOTime() || caseDetails.incidentTime,
            eventSummary: caseDetails.eventSummary,
            missingFields: [],
            isDirty: false,
            timezone: caseDetails.inputTimeZone || moment.tz.guess()
        });
    };

    const handleShowSummaryModal = () => {
        setIsEditing(true);
        setShowSummaryAlert(false);
    };

    const handleShowReccomendationModal = () => {
        setCurrentTab('recommendations');
        setShowRecommendationsAlertPrompt(false);
        setShownRecOnce(true);
        setShowRecommendationAlert(false);
    };

    const handleCancelRecommendation = () => {
        setShowRecommendationsAlertPrompt(false);
        setShownRecOnce(false);
        setShowRecommendationAlert(false);
        setCurrentTab(prevTabState[prevTabState.length - 2]);
        setPrevTabState(['']);
        setTempRecc(caseDetails.recommendations);
    };

    const handleCloseEditStatus = () => {
        onCloseEditStatus();
        setIsOpen(caseDetails.status === StatusType.OPEN ? StatusType.OPEN : StatusType.CLOSED);
        setTempStatus(caseDetails.status);
        setPendingAckWarning(false);
    };

    const handleCloseEditAccess = () => {
        onCloseEditAccess();
        setTempAccess(caseDetails.isRestricted);
    };

    useEffect(() => {
        if (caseDetails.caseId === mockMarsCase.caseId) {
            setTabName('Error');
        } else {
            setTabName(caseDetails.caseName);
        }
    }, [caseDetails.caseName]);

    return (
        <Fragment>
            {isFetching ? (
                <Loader />
            ) : isCaseValid ? (
                <Fragment>
                    <Responsive query='min-width' props={responsiveVariables}>
                        {({
                            withNoneOpen,
                            withNavBarOpen,
                            withSheetOpen,
                            withBothOpen
                        }: {
                            withNoneOpen: boolean;
                            withNavBarOpen: boolean;
                            withSheetOpen: boolean;
                            withBothOpen: ResponsiveVariables;
                        }) => {
                            const newStack = isNavCollapsed
                                ? displayingSheet || displayPersonInvolvedSheet
                                    ? withSheetOpen
                                    : withNoneOpen
                                : displayingSheet || displayPersonInvolvedSheet
                                ? withBothOpen === ResponsiveVariables.STACK ||
                                  withBothOpen === ResponsiveVariables.CLOSE_ON_OPEN
                                : withNavBarOpen;
                            if (isStacked != newStack) {
                                dispatch(setIsStacked(newStack));
                            }
                            return (
                                <React.Fragment>
                                    <Helmet defer={false}>
                                        <title> {tabName} | MARS </title>
                                    </Helmet>
                                    <Row
                                        alignmentVertical='top'
                                        widths={[WIDTH_OF_CARD, WIDGET_WIDTH]}
                                        alignmentHorizontal={'center'}
                                    >
                                        <ResponsiveHooks withBothOpen={withBothOpen} />
                                        <Column spacing='large'>
                                            <BreadcrumbGroup>
                                                <Breadcrumb
                                                    onClick={() =>
                                                        history.push(lastLocation?.pathname || '/')
                                                    }
                                                >
                                                    {_.find(
                                                        allRoutes,
                                                        (route) => route.path === lastLocation?.pathname
                                                    )?.label || 'All Cases'}
                                                </Breadcrumb>
                                                <Breadcrumb> {caseDetails.caseName}</Breadcrumb>
                                            </BreadcrumbGroup>
                                            <Row alignmentHorizontal='justify' widths='fit'>
                                                <Row alignmentHorizontal='left'>
                                                    <Text type='d100'> Case Details </Text>
                                                </Row>
                                                <Row alignmentHorizontal='right' alignmentVertical='baseline'>
                                                    {caseDetails.updateBy && (
                                                        <Fragment>
                                                            <Tag> Updated </Tag>
                                                            <div>
                                                                <Text>
                                                                    {' '}
                                                                    {renderDateDiff(
                                                                        caseDetails.lastModificationTime
                                                                    )}{' '}
                                                                </Text>
                                                            </div>
                                                        </Fragment>
                                                    )}
                                                </Row>
                                            </Row>
                                            <Tile layout={{ width: 'fit-content' }} spacingInset='400'>
                                                <Column spacing='large'>
                                                    <Row
                                                        widths={WIDTHS_OF_DETAILS_CARD}
                                                        alignmentVertical='top'
                                                    >
                                                        <Column>
                                                            <LocCircle
                                                                loc={caseDetails.loc}
                                                                emtSev={caseDetails.emtSev}
                                                                canEdit={isCaseOpen}
                                                            />
                                                        </Column>
                                                        <Column spacing='small'>
                                                            <Text type='h300'> {caseDetails.caseName} </Text>
                                                            <Column spacing='xsmall'>
                                                                <Text type='h100'>
                                                                    {caseDetails.emtCategory}
                                                                </Text>
                                                                <Text type='h100'>{caseDetails.emtType}</Text>
                                                                <Text>{caseDetails.emtItem}</Text>
                                                            </Column>
                                                        </Column>
                                                        <Column>
                                                            <Icon tokens={geopinIconTokens} />
                                                        </Column>
                                                        <CaseSiteLocations
                                                            caseHeader={caseDetails}
                                                            isCaseDetails={true}
                                                        />
                                                        <Column>
                                                            <Icon tokens={clockIconTokens} />
                                                        </Column>
                                                        <Column>
                                                            <Row
                                                                alignmentHorizontal='justify'
                                                                alignmentVertical='top'
                                                            >
                                                                <Column spacing='none'>
                                                                    {caseDetails.incidentDate && (
                                                                        <Fragment>
                                                                            <Text type='h100'>
                                                                                Time of Incident
                                                                            </Text>
                                                                            <Text>
                                                                                {incidentDate
                                                                                    ? incidentDate.toLocaleString(
                                                                                          DateTime.DATE_MED
                                                                                      )
                                                                                    : DateTime.fromISO(
                                                                                          caseDetails.incidentDate
                                                                                      ).toLocaleString(
                                                                                          DateTime.DATE_MED
                                                                                      )}
                                                                            </Text>
                                                                            {incidentDate && (
                                                                                <Text>
                                                                                    {' '}
                                                                                    {incidentDate.toFormat(
                                                                                        'HH:mm ZZZZ'
                                                                                    )}
                                                                                </Text>
                                                                            )}
                                                                        </Fragment>
                                                                    )}
                                                                </Column>
                                                                <Bookmark
                                                                    isBookmarked={caseDetails.isBookmarked}
                                                                    setBookmarked={setBookmarked}
                                                                    isDisabled={
                                                                        bookmarkCaseEndpoint.isFetching
                                                                    }
                                                                />
                                                            </Row>
                                                        </Column>
                                                    </Row>
                                                    <Row
                                                        widths={WIDTHS_OF_DETAILS_CARD}
                                                        alignmentVertical='top'
                                                    >
                                                        <Column />
                                                        <Column spacing='none'>
                                                            <Row alignmentVertical='top'>
                                                                <Column spacing='none'>
                                                                    <Text type='h100'>Status</Text>
                                                                    <Text>
                                                                        {' '}
                                                                        {caseDetails.status ===
                                                                        StatusType.OPEN
                                                                            ? 'Open'
                                                                            : 'Closed'}{' '}
                                                                    </Text>
                                                                </Column>
                                                                <TitledButton
                                                                    title={labels.editStatus}
                                                                    buttonProps={{
                                                                        type: 'secondary',
                                                                        size: 'small',
                                                                        onClick: onEditStatusButton,
                                                                        ref: statusRef
                                                                    }}
                                                                    iconProps={{ tokens: editIconTokens }}
                                                                />
                                                            </Row>
                                                            <Popover
                                                                anchorNode={statusRef.current}
                                                                open={openStatus}
                                                                onClose={handleCloseEditStatus}
                                                                position='bottom'
                                                            >
                                                                <PopoverHeader closeLabel='Close'>
                                                                    Status
                                                                </PopoverHeader>
                                                                <Column width='240px'>
                                                                    <Select
                                                                        width='240px'
                                                                        value={isOpen}
                                                                        onChange={(value: StatusType) => {
                                                                            setTempStatus(value);
                                                                            setIsOpen(value);
                                                                        }}
                                                                        error={
                                                                            displayCaseStatusValidation ||
                                                                            pendingAckWarning
                                                                        }
                                                                    >
                                                                        <SelectOption
                                                                            value={StatusType.OPEN}
                                                                            label='Open'
                                                                        />
                                                                        <SelectOption
                                                                            value={StatusType.CLOSED}
                                                                            label='Closed'
                                                                        />
                                                                    </Select>
                                                                    {tempStatus === StatusType.OPEN ? null : (
                                                                        <Checkbox
                                                                            checked={checked}
                                                                            onChange={handlerCheck}
                                                                        >
                                                                            <text>
                                                                                {' '}
                                                                                No threat management action
                                                                                needed
                                                                            </text>
                                                                        </Checkbox>
                                                                    )}
                                                                    {(displayCaseStatusValidation ||
                                                                        pendingAckWarning) && (
                                                                        <Fragment>
                                                                            {displayCaseStatusValidation && (
                                                                                <Alert
                                                                                    type='error'
                                                                                    size='small'
                                                                                >
                                                                                    There is an open SIM
                                                                                    ticket linked to this
                                                                                    case. Please resolve it
                                                                                    before closing this case.
                                                                                </Alert>
                                                                            )}
                                                                            {pendingAckWarning && (
                                                                                <Alert
                                                                                    type='error'
                                                                                    size='small'
                                                                                >
                                                                                    There is an unacknowledged
                                                                                    update to this case.
                                                                                    Please acknowledge it
                                                                                    before closing this case.
                                                                                </Alert>
                                                                            )}
                                                                            <Column
                                                                                className={'AlignWithAlert'}
                                                                            >
                                                                                <Text type={'b200'}>
                                                                                    {updateStatusEndpoint?.error?.data?.error?.tickets.map(
                                                                                        (ticket: {
                                                                                            simId: string;
                                                                                            title: string;
                                                                                        }) => {
                                                                                            return renderSimTicketTitle(
                                                                                                ticket.simId,
                                                                                                ticket.title
                                                                                            );
                                                                                        }
                                                                                    )}
                                                                                </Text>
                                                                            </Column>
                                                                        </Fragment>
                                                                    )}
                                                                    <Button
                                                                        minWidth='100%'
                                                                        disabled={
                                                                            caseDetails.status ===
                                                                                tempStatus ||
                                                                            updateStatusEndpoint.isFetching
                                                                        }
                                                                        onClick={handlerSaveStatus}
                                                                    >
                                                                        Save
                                                                    </Button>
                                                                </Column>
                                                            </Popover>
                                                        </Column>
                                                        <Column />
                                                        <Column spacing='none'>
                                                            <Row alignmentVertical='top'>
                                                                <Column spacing='none'>
                                                                    <Text type='h100'>Access Level</Text>
                                                                    <Text>
                                                                        {caseDetails.isRestricted
                                                                            ? 'Restricted'
                                                                            : 'Standard'}
                                                                    </Text>
                                                                </Column>
                                                                <TitledButton
                                                                    title={labels.editAccessLevel}
                                                                    buttonProps={{
                                                                        type: 'secondary',
                                                                        size: 'small',
                                                                        onClick: onEditAccessButton,
                                                                        ref: accessLevelRef
                                                                    }}
                                                                    iconProps={{ tokens: editIconTokens }}
                                                                />
                                                                <Popover
                                                                    anchorNode={accessLevelRef.current}
                                                                    open={openAccessLevel}
                                                                    onClose={handleCloseEditAccess}
                                                                    position='bottom'
                                                                >
                                                                    <PopoverHeader closeLabel='Close'>
                                                                        Access Level
                                                                    </PopoverHeader>
                                                                    <Column>
                                                                        <Select
                                                                            width='240px'
                                                                            value={tempAccess ? 1 : 0}
                                                                            onChange={(newAccess) =>
                                                                                setTempAccess(newAccess === 1)
                                                                            }
                                                                        >
                                                                            <SelectOption
                                                                                value={0}
                                                                                label={'Standard'}
                                                                            />
                                                                            <SelectOption
                                                                                value={1}
                                                                                label={'Restricted'}
                                                                            />
                                                                        </Select>
                                                                        <Button
                                                                            minWidth='100%'
                                                                            disabled={
                                                                                caseDetails.isRestricted ===
                                                                                    tempAccess ||
                                                                                updateAccessLevelEndpoint.isFetching
                                                                            }
                                                                            onClick={() => {
                                                                                useUpdateAccessLevel({
                                                                                    updating: {
                                                                                        type: 'restriction',
                                                                                        newContent: tempAccess,
                                                                                        incidentId: caseId
                                                                                    }
                                                                                });
                                                                            }}
                                                                        >
                                                                            Save
                                                                        </Button>
                                                                    </Column>
                                                                </Popover>
                                                            </Row>
                                                        </Column>
                                                        <Column />
                                                        <Column spacing='small'>
                                                            <Row alignmentVertical='top'>
                                                                <Column spacing='none'>
                                                                    <Text type='h100'>Assigned to</Text>
                                                                    <Text>
                                                                        {' '}
                                                                        {caseDetails.assignedTo
                                                                            ? `${caseDetails.assignedTo.firstName}  ${caseDetails.assignedTo.lastName}`
                                                                            : 'No one'}{' '}
                                                                    </Text>
                                                                </Column>
                                                                {isCaseOpen && (
                                                                    <TitledButton
                                                                        title={labels.editAssignee}
                                                                        buttonProps={{
                                                                            type: 'secondary',
                                                                            size: 'small',
                                                                            onClick: onEditAssignedButton,
                                                                            ref: assignedRef
                                                                        }}
                                                                        iconProps={{ tokens: editIconTokens }}
                                                                    />
                                                                )}
                                                                <Popover
                                                                    anchorNode={assignedRef.current}
                                                                    open={openAssigned}
                                                                    onClose={() => {
                                                                        onCloseEditAssigned();
                                                                        setTempAssignedTo(undefined);
                                                                    }}
                                                                    position='bottom'
                                                                >
                                                                    <PopoverHeader closeLabel='Close'>
                                                                        Assigned to
                                                                    </PopoverHeader>
                                                                    <Column>
                                                                        <ThreatManagerInput
                                                                            value={tempAssignedTo?.empId}
                                                                            width={'240px'}
                                                                            onChange={setTempAssignedTo}
                                                                            suggestedUsers={
                                                                                caseDetails.suggestedAssignees
                                                                            }
                                                                            hasPlaceholder
                                                                            isSmall
                                                                        />
                                                                        <Button
                                                                            minWidth='100%'
                                                                            disabled={
                                                                                caseDetails.assignedTo
                                                                                    ?.empId ===
                                                                                    tempAssignedTo?.empId ||
                                                                                updateAssigneeEndpoint.isFetching
                                                                            }
                                                                            onClick={() => {
                                                                                useUpdateAssignee({
                                                                                    updating: {
                                                                                        type: 'assignee',
                                                                                        newContent:
                                                                                            tempAssignedTo?.empId,
                                                                                        incidentId: caseId
                                                                                    }
                                                                                });
                                                                            }}
                                                                        >
                                                                            Save
                                                                        </Button>
                                                                    </Column>
                                                                </Popover>
                                                            </Row>
                                                        </Column>
                                                    </Row>
                                                </Column>
                                            </Tile>
                                            {isStacked && (
                                                <Row widths={WIDGET_WIDTH} alignmentVertical={'top'}>
                                                    <CaseDetailsSidebar />
                                                </Row>
                                            )}
                                            <EditLocModal />
                                            <TabGroup value={currentTab} onChange={setCurrentTab}>
                                                <Tab value='updates'>
                                                    Updates
                                                    {caseDetails &&
                                                        caseDetails.updates.filter(
                                                            (update) => update.needsAcknowledgement
                                                        ).length > 0 && (
                                                            <Badge
                                                                value={
                                                                    caseDetails.updates.filter(
                                                                        (update) =>
                                                                            update.needsAcknowledgement
                                                                    ).length
                                                                }
                                                                type='theme'
                                                            />
                                                        )}
                                                </Tab>
                                                <Tab value='summary'>{CASE_STRINGS.SUMMARY}</Tab>
                                                <Tab value='persons'>Persons Involved</Tab>
                                                <Tab value='recommendations'>Recommendations</Tab>
                                                <Tab value='related'>Related</Tab>
                                            </TabGroup>
                                            {currentTab === 'updates' && (
                                                <UpdatesTab
                                                    switchToSummary={() => setCurrentTab('summary')}
                                                    sortValue={sortValue}
                                                    setSortValue={setSortValue}
                                                />
                                            )}
                                            {currentTab === 'summary' && (
                                                <SummaryTab
                                                    setIsEditing={setIsEditing}
                                                    isEditing={isEditing}
                                                    setEditingCaseInfo={setEditingCaseInfo}
                                                    editingCaseInfo={editingCaseInfo}
                                                />
                                            )}
                                            {currentTab === 'persons' && <PersonsInvolvedTab />}
                                            {currentTab === 'recommendations' && (
                                                <RecommendationsTab
                                                    setShowRecommendationsAlertPrompt={
                                                        setShowRecommendationsAlertPrompt
                                                    }
                                                    tempRecc={tempRecc}
                                                    setTempRecc={setTempRecc}
                                                />
                                            )}
                                            {currentTab === 'related' && <RelatedTab />}
                                        </Column>
                                        {!isStacked && (
                                            <Column>
                                                <CaseDetailsSidebar />
                                            </Column>
                                        )}
                                    </Row>
                                </React.Fragment>
                            );
                        }}
                    </Responsive>
                    {showSummaryAlert && (
                        <AlertModal
                            showPrompt={showSummaryAlert}
                            handleConfirm={handleShowSummaryModal}
                            onCancel={handleCancelSummary}
                            onClose={handleShowSummaryModal}
                        ></AlertModal>
                    )}
                    {showRecommendationAlert && (
                        <AlertModal
                            showPrompt={showRecommendationAlertPrompt}
                            onCancel={handleCancelRecommendation}
                            handleConfirm={handleShowReccomendationModal}
                            onClose={handleShowReccomendationModal}
                        ></AlertModal>
                    )}
                    {showRecommendationAlertPrompt && (
                        <RouterPrompt when={showRecommendationAlertPrompt}></RouterPrompt>
                    )}
                </Fragment>
            ) : (
                <React.Fragment>
                    <Helmet defer={false}>
                        <title> {tabName} | MARS </title>
                    </Helmet>
                    <Fragment>
                        <CaseNotFound message={errorMessage} />
                    </Fragment>
                </React.Fragment>
            )}
        </Fragment>
    );
};

export default CaseDetails;
