import Button from '@amzn/meridian/button';
import Column from '@amzn/meridian/column';
import InputGroup from '@amzn/meridian/input-group';
import Row from '@amzn/meridian/row';
import Select, { SelectOption } from '@amzn/meridian/select';
import Tag from '@amzn/meridian/tag';
import Text from '@amzn/meridian/text';
import TimePicker from '@amzn/meridian/time-picker';
import closeTokens from '@amzn/meridian-tokens/base/icon/close';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../context/store';
import { CommunicationMethod } from '../../../lib/CaseDefinitions';
import { SELECT_WIDTH } from '../../../lib/constants';
import {
    ConsultationFilter,
    initialConsultationFilter,
    LocationInfoFilter
} from '../../../lib/FilterDefinitons';
import { labels } from '../../../lib/labels';
import TitledButton from '../../CustomComponents/TitledButton';
import { FormInput } from '../../Inputs/FormFields';
import LoginNameSearch from '../../Inputs/LoginNameSearch';
import TimeZoneInput from '../../Inputs/TimeZoneInput';
import LocationFilterColumn from './LocationFilterColumn';

type ConsultationFilterProps = {
    onClick: () => void;
    localFilterOptions: ConsultationFilter;
    setLocalFilterOptions: (consultationFilter: ConsultationFilter) => void;
    buttonName: string;
    widthsOfColumns: string[];
};

//TODO: connect with backend for searches, add categories / subcategories, add person involved support
const AdvancedConsultationFilterHelper: React.FunctionComponent<ConsultationFilterProps> = ({
    onClick,
    localFilterOptions,
    setLocalFilterOptions,
    buttonName,
    widthsOfColumns
}: ConsultationFilterProps) => {
    const { selectOptionsContext } = useSelector((state: RootState) => state.selectOptions);
    const addedByEmployees = new Map<string, string>([]);

    const setQuery = (person: { person: string; personId: string; isName?: boolean }, index: number) => {
        return (login: string) => {
            if (person.isName) {
                if (login === person.person.slice(0, -1)) {
                    setLocalFilterOptions({
                        ...localFilterOptions,
                        communicatedWith: localFilterOptions.communicatedWith
                            .slice(0, index)
                            .concat({
                                person: ``,
                                personId: ``,
                                isName: false
                            })
                            .concat(localFilterOptions.communicatedWith.slice(index + 1))
                    });
                }
            } else {
                setLocalFilterOptions({
                    ...localFilterOptions,
                    communicatedWith: localFilterOptions.communicatedWith
                        .slice(0, index)
                        .concat({
                            person: login,
                            personId: login,
                            isName: false
                        })
                        .concat(localFilterOptions.communicatedWith.slice(index + 1))
                });
            }
        };
    };

    return (
        <Column>
            <Row alignmentVertical='top' widths={widthsOfColumns}>
                <Column>
                    <Text type='h100'> Engagement information </Text>
                    <FormInput
                        label='Engagement title'
                        width={SELECT_WIDTH}
                        value={localFilterOptions.consultationTitle}
                        onChange={(consultationTitle) =>
                            setLocalFilterOptions({
                                ...localFilterOptions,
                                consultationTitle
                            })
                        }
                        max={255}
                    />
                    <Select
                        label='Method of communication'
                        value={localFilterOptions.method}
                        onChange={(method) =>
                            setLocalFilterOptions({
                                ...localFilterOptions,
                                method
                            })
                        }
                    >
                        {Object.values(CommunicationMethod).map((value) => {
                            return <SelectOption value={value} label={value} key={value} />;
                        })}
                    </Select>
                    {Array.isArray(localFilterOptions.method) && localFilterOptions.method.length > 1 && (
                        <Row wrap='down'>
                            {localFilterOptions.method.map((method) => {
                                return (
                                    <Tag
                                        key={method}
                                        type='neutral'
                                        onClose={() =>
                                            /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                                            // @ts-ignore
                                            setLocalFilterOptions({
                                                ...localFilterOptions,
                                                method: localFilterOptions.method.filter(
                                                    (met) => met !== method
                                                )
                                            })
                                        }
                                    >
                                        {method.toString()}
                                    </Tag>
                                );
                            })}
                        </Row>
                    )}
                    {localFilterOptions.communicatedWith.map((person, index) => (
                        <Row key={index}>
                            <InputGroup>
                                <LoginNameSearch
                                    label='Communicated with'
                                    onBlur={() => {
                                        if (!person.isName) {
                                            setQuery(person, index)('');
                                        }
                                    }}
                                    query={person.person}
                                    setQuery={setQuery(person, index)}
                                    handleClear={() =>
                                        setLocalFilterOptions({
                                            ...localFilterOptions,
                                            communicatedWith: localFilterOptions.communicatedWith
                                                .slice(0, index)
                                                .concat({
                                                    person: ``,
                                                    personId: '',
                                                    isName: false
                                                })
                                                .concat(localFilterOptions.communicatedWith.slice(index + 1))
                                        })
                                    }
                                    onClick={(person) =>
                                        setLocalFilterOptions({
                                            ...localFilterOptions,
                                            communicatedWith: localFilterOptions.communicatedWith
                                                .slice(0, index)
                                                .concat({
                                                    person: `${person.firstName} ${person.lastName}`,
                                                    personId: person.personId,
                                                    isName: true
                                                })
                                                .concat(localFilterOptions.communicatedWith.slice(index + 1))
                                        })
                                    }
                                    width={'242px'}
                                />
                            </InputGroup>
                            {index !== 0 && (
                                <TitledButton
                                    title={labels.removePerson}
                                    buttonProps={{
                                        onClick: () =>
                                            setLocalFilterOptions({
                                                ...localFilterOptions,
                                                communicatedWith: localFilterOptions.communicatedWith
                                                    .slice(0, index)
                                                    .concat(
                                                        localFilterOptions.communicatedWith.slice(index + 1)
                                                    )
                                            }),
                                        type: 'secondary',
                                        size: 'small'
                                    }}
                                    iconProps={{ tokens: closeTokens }}
                                />
                            )}
                        </Row>
                    ))}
                    {localFilterOptions.communicatedWith.length > 1 && (
                        <Column spacing={'none'}>
                            <Row>
                                <Text> Communicated with </Text>
                            </Row>
                            <Row>
                                <Select
                                    size='small'
                                    width={'80px'}
                                    value={localFilterOptions.allOrAny}
                                    onChange={(allOrAny: string) =>
                                        setLocalFilterOptions({
                                            ...localFilterOptions,
                                            allOrAny
                                        })
                                    }
                                >
                                    <SelectOption value={'any'} label={'Any'} />
                                    <SelectOption value={'all'} label={'All'} />
                                </Select>
                                <Text> of the selected people </Text>
                            </Row>
                        </Column>
                    )}
                    <Row>
                        <Button
                            type='tertiary'
                            onClick={() =>
                                setLocalFilterOptions({
                                    ...localFilterOptions,
                                    communicatedWith: [
                                        ...localFilterOptions.communicatedWith,
                                        { person: '', personId: '' }
                                    ]
                                })
                            }
                        >
                            Add another person
                        </Button>
                    </Row>
                    <Select
                        label='Added by'
                        value={localFilterOptions.addedBy}
                        onChange={(addedBy) =>
                            setLocalFilterOptions({
                                ...localFilterOptions,
                                addedBy
                            })
                        }
                    >
                        {selectOptionsContext.consultationsAddedBy.map(
                            (person) => (
                                addedByEmployees.set(person.empId, `${person.firstName} ${person.lastName}`),
                                (
                                    <SelectOption
                                        key={person.empId}
                                        label={`${person.firstName} ${person.lastName}`}
                                        value={person.empId}
                                    />
                                )
                            )
                        )}
                    </Select>
                    {Array.isArray(localFilterOptions.addedBy) && localFilterOptions.addedBy.length > 1 && (
                        <Row wrap='down'>
                            {localFilterOptions.addedBy.map((addedBy) => {
                                return (
                                    <Tag
                                        key={addedBy}
                                        type='neutral'
                                        onClose={() =>
                                            /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                                            // @ts-ignore
                                            setLocalFilterOptions({
                                                ...localFilterOptions,
                                                addedBy: localFilterOptions.addedBy.filter(
                                                    (add) => add !== addedBy
                                                )
                                            })
                                        }
                                    >
                                        {addedByEmployees.get(addedBy)}
                                    </Tag>
                                );
                            })}
                        </Row>
                    )}
                    <Select
                        label='Status'
                        value={localFilterOptions.status}
                        onChange={(status) =>
                            setLocalFilterOptions({
                                ...localFilterOptions,
                                status
                            })
                        }
                    >
                        <SelectOption key='linked' label={'Linked'} value={'linked'} />
                        <SelectOption key='unlinked' label={'Unlinked'} value={'unlinked'} />
                    </Select>
                    {Array.isArray(localFilterOptions.status) && localFilterOptions.status.length > 1 && (
                        <Row wrap='down'>
                            {localFilterOptions.status.map((status) => {
                                return (
                                    <Tag
                                        key={status}
                                        type='neutral'
                                        onClose={() =>
                                            /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                                            // @ts-ignore
                                            setLocalFilterOptions({
                                                ...localFilterOptions,
                                                status: localFilterOptions.status.filter(
                                                    (stat) => stat !== status
                                                )
                                            })
                                        }
                                    >
                                        {status.charAt(0).toUpperCase() + status.slice(1)}
                                    </Tag>
                                );
                            })}
                        </Row>
                    )}
                </Column>
                <Column>
                    <Text type='h100'> Event information </Text>
                    <Column spacing={'none'}>
                        <InputGroup>
                            <TimePicker
                                label='Time of event from'
                                value={localFilterOptions.timeFrom}
                                locale='en-GB'
                                onChange={(timeFrom) =>
                                    setLocalFilterOptions({
                                        ...localFilterOptions,
                                        timeFrom
                                    })
                                }
                            />
                            <TimePicker
                                label='Time of event to'
                                value={localFilterOptions.timeTo}
                                locale='en-GB'
                                onChange={(timeTo) =>
                                    setLocalFilterOptions({
                                        ...localFilterOptions,
                                        timeTo
                                    })
                                }
                            />
                        </InputGroup>
                        <TimeZoneInput
                            width='50%'
                            onChange={(timeZone) =>
                                setLocalFilterOptions({
                                    ...localFilterOptions,
                                    timeZone
                                })
                            }
                            value={localFilterOptions.timeZone}
                        />
                    </Column>
                </Column>
                <LocationFilterColumn
                    setLocationFilter={(locationFilter: LocationInfoFilter) =>
                        setLocalFilterOptions({ ...localFilterOptions, locationFilter })
                    }
                    locationFilter={localFilterOptions.locationFilter}
                />
            </Row>
            <Row alignmentHorizontal='right'>
                <Button
                    type='secondary'
                    onClick={() => {
                        setLocalFilterOptions(initialConsultationFilter);
                    }}
                >
                    Clear
                </Button>
                <Button type='primary' onClick={onClick}>
                    {buttonName}
                </Button>
            </Row>
        </Column>
    );
};

export default AdvancedConsultationFilterHelper;
