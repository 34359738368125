import Alert from '@amzn/meridian/alert';
import Button from '@amzn/meridian/button';
import ButtonGroup, { ButtonOption } from '@amzn/meridian/button-group';
import Checkbox from '@amzn/meridian/checkbox';
import Column from '@amzn/meridian/column';
import Row from '@amzn/meridian/row';
import Text from '@amzn/meridian/text';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState, useAppDispatch } from '../../context/store';
import { CreateCaseVars, LevelOfConcern, PersonInvolvedRole, Proximity } from '../../lib/CaseDefinitions';
import { CHARACTER_LIMIT, locValues, WIDTH_OF_CARD } from '../../lib/constants';
import { getEventItems } from '../../lib/EmtCTIDefinitions';
import { hasOverLimitPersonInvolved, locMap } from '../../lib/helpers';
import { createCaseObject, reset } from '../../slices/AddProximitySlice';
import { addToast } from '../../slices/ToastSlice';
import { useLazyCreateCaseQuery } from '../../slices/WotManApiSlice';
import RouterPrompt from '../Alert/RouterPrompt';
import AddPersonsInvolved from '../Cases/DetailsTabs/AddPersonsInvolved';
import { FormTextarea } from '../Inputs/FormFields';
import ThreatManagerInput from '../Inputs/ThreatManagerInput';
import NewCaseEventInformation from './CreateCase/NewCaseEventInformation';
import NewCaseEventSummary from './CreateCase/NewCaseEventSummary';

const CreateCase: React.FunctionComponent = () => {
    const history = useHistory();
    const { proximity, siteIds, inputLocation, secondarySiteIds } = useSelector(
        (state: RootState) => state.addProximity
    );
    const [useCreateCase, useCreateCaseEndpoint] = useLazyCreateCaseQuery();
    const [lpContact, setLpContact] = useState(false);
    const dispatch = useAppDispatch();
    const data = useCreateCaseEndpoint.data;
    const { userLogin } = useSelector((state: RootState) => state.dashboard);

    useEffect(() => {
        return () => {
            dispatch(reset());
        };
    }, []);

    const emptyPersonInvolved = {
        name: '',
        login: '',
        title: '',
        phoneNumber: '',
        email: '',
        personId: ''
    };

    const [createCaseVars, setCreateCaseVars] = useState<CreateCaseVars>({
        eventCategory: 'MARS Investigation',
        eventType: '',
        eventItem: '',
        additionalTags: [],
        timeOfEvent: '',
        dateOfEvent: '',
        personsInvolved: [],
        eventSummary: '',
        reportedBy: emptyPersonInvolved,
        assignedToEmpId: '',
        missingFields: [],
        isDirty: false,
        levelOfConcernNote: '',
        timezone: moment.tz.guess()
    });

    useEffect(() => {
        if (!useCreateCaseEndpoint.isFetching && useCreateCaseEndpoint.isSuccess) {
            dispatch(
                addToast({
                    type: 'success',
                    action: 'Created case ',
                    message: 'MARS' + data,
                    SIM: false,
                    timeout: 3000
                })
            );
            history.push(`/cases/${data}`);
        } else if (!useCreateCaseEndpoint.isFetching && useCreateCaseEndpoint.isError) {
            dispatch(
                addToast({
                    type: 'error',
                    action: 'There was an error creating case',
                    message: '',
                    SIM: true,
                    timeout: undefined
                })
            );
        }
    }, [useCreateCaseEndpoint.isFetching]);

    return (
        <Column spacing={'500'}>
            <Text type='d100'> Create Case </Text>
            <NewCaseEventInformation createCaseVars={createCaseVars} setCreateCaseVars={setCreateCaseVars} />
            <Column>
                <Text type='h300'> Persons involved </Text>
                <Row alignmentVertical='top' wrap='down' maxWidth={WIDTH_OF_CARD}>
                    <Column>
                        <AddPersonsInvolved
                            personAdding={createCaseVars.reportedBy}
                            missingName={createCaseVars.missingFields.includes('reportedByName')}
                            nameIsEmptySpaces={createCaseVars.missingFields.includes(
                                'reportedByNameHasOnlyEmptySpaces'
                            )}
                            setCaseVars={(personVars) => {
                                setCreateCaseVars({
                                    ...createCaseVars,
                                    reportedBy: personVars,
                                    missingFields: createCaseVars.missingFields.filter(
                                        (field) =>
                                            (personVars.name === '' || field !== 'reportedByName') &&
                                            ((personVars.name !== '' && !personVars.name?.trim()) ||
                                                field !== 'reportedByNameHasOnlyEmptySpaces')
                                    ),
                                    isDirty: true
                                });
                            }}
                            requiredRole={'Reported by'}
                        />
                        <Checkbox checked={lpContact} onChange={setLpContact}>
                            Set as LP contact
                        </Checkbox>
                    </Column>
                    {createCaseVars.personsInvolved.map((person, index) => {
                        return (
                            <AddPersonsInvolved
                                key={index}
                                personAdding={createCaseVars.personsInvolved[index]}
                                userRestrictedCreateCase={
                                    userLogin.toLowerCase() === person.login.toLowerCase().trim() &&
                                    (person.role === PersonInvolvedRole.VICTIM ||
                                        person.role === PersonInvolvedRole.WITNESS ||
                                        person.role === PersonInvolvedRole.PERSON_OF_INTEREST)
                                }
                                missingName={createCaseVars.missingFields.includes(
                                    `personInvolvedName${index}`
                                )}
                                missingRole={createCaseVars.missingFields.includes(
                                    `personInvolvedRole${index}`
                                )}
                                nameIsEmptySpaces={createCaseVars.missingFields.includes(
                                    `personInvolvedNameHasOnlyEmptySpaces${index}`
                                )}
                                setCaseVars={(personVars) => {
                                    setCreateCaseVars({
                                        ...createCaseVars,
                                        personsInvolved: [
                                            ...createCaseVars.personsInvolved.slice(0, index),
                                            personVars,
                                            ...createCaseVars.personsInvolved.slice(index + 1)
                                        ],
                                        missingFields: createCaseVars.missingFields.filter(
                                            (field) =>
                                                (personVars.name === '' ||
                                                    field !== `personInvolvedName${index}`) &&
                                                (personVars.role === undefined ||
                                                    field !== `personInvolvedRole${index}`) &&
                                                ((personVars.name !== '' && !personVars.name?.trim()) ||
                                                    field !== `personInvolvedNameHasOnlyEmptySpaces${index}`)
                                        ),
                                        isDirty: true
                                    });
                                }}
                                removePerson={() => {
                                    const missingFields: string[] = [];
                                    createCaseVars.missingFields.forEach((field) => {
                                        if (
                                            field.length > 18 &&
                                            field.slice(0, 18) === 'personInvolvedName'
                                        ) {
                                            const originalIndex = parseInt(field.slice(18));
                                            if (originalIndex >= index) {
                                                missingFields.push(`personInvolvedName${originalIndex - 1}`);
                                            } else if (originalIndex !== index) {
                                                missingFields.push(field);
                                            }
                                        } else if (
                                            field.length > 18 &&
                                            field.slice(0, 18) === 'personInvolvedRole'
                                        ) {
                                            const originalIndex = parseInt(field.slice(18));
                                            if (originalIndex >= index) {
                                                missingFields.push(`personInvolvedRole${originalIndex - 1}`);
                                            } else if (originalIndex !== index) {
                                                missingFields.push(field);
                                            }
                                        } else if (
                                            field.length > 18 &&
                                            field.slice(0, 18) === 'personInvolvedNameHasOnlyEmptySpaces'
                                        ) {
                                            const originalIndex = parseInt(field.slice(18));
                                            if (originalIndex >= index) {
                                                missingFields.push(
                                                    `personInvolvedNameHasOnlyEmptySpaces${originalIndex - 1}`
                                                );
                                            } else if (originalIndex !== index) {
                                                missingFields.push(field);
                                            }
                                        } else {
                                            missingFields.push(field);
                                        }
                                    });
                                    setCreateCaseVars({
                                        ...createCaseVars,
                                        personsInvolved: [
                                            ...createCaseVars.personsInvolved.slice(0, index),
                                            ...createCaseVars.personsInvolved.slice(index + 1)
                                        ],
                                        missingFields
                                    });
                                }}
                            />
                        );
                    })}
                    <Button
                        type='tertiary'
                        onClick={() =>
                            setCreateCaseVars({
                                ...createCaseVars,
                                personsInvolved: [...createCaseVars.personsInvolved, emptyPersonInvolved]
                            })
                        }
                    >
                        Add another person
                    </Button>
                </Row>
            </Column>
            <NewCaseEventSummary createCaseVars={createCaseVars} setCreateCaseVars={setCreateCaseVars} />
            <Column>
                <Text type='h300'> Case information </Text>
                <ThreatManagerInput
                    suggestedUsers={[]}
                    value={createCaseVars.assignedToEmpId}
                    onChange={(assignee) =>
                        setCreateCaseVars({
                            ...createCaseVars,
                            assignedToEmpId: assignee.empId,
                            missingFields: createCaseVars.missingFields.filter(
                                (field) => field !== 'assignedToEmpId'
                            ),
                            isDirty: true
                        })
                    }
                    missing={createCaseVars.missingFields.includes('assignedToEmpId')}
                    required
                />
                <Column spacing={'small'} width={WIDTH_OF_CARD}>
                    <Column spacing='none' width={WIDTH_OF_CARD}>
                        <Text color='secondary'> Level of concern *</Text>
                        <Row spacing='small'>
                            <ButtonGroup
                                type='outline'
                                value={createCaseVars.levelOfConcern}
                                onChange={(levelOfConcern: string) => {
                                    setCreateCaseVars({
                                        ...createCaseVars,
                                        levelOfConcern: levelOfConcern,
                                        missingFields: createCaseVars.missingFields.filter(
                                            (field) => field !== 'levelOfConcern'
                                        ),
                                        isDirty: true
                                    });
                                }}
                            >
                                {locValues.map((value) => {
                                    return (
                                        <ButtonOption key={value} value={value}>
                                            {value}
                                        </ButtonOption>
                                    );
                                })}
                            </ButtonGroup>
                            {createCaseVars.missingFields.includes('levelOfConcern') && (
                                <Alert type='error' size='small'>
                                    Level of concern is required
                                </Alert>
                            )}
                        </Row>
                    </Column>
                    {createCaseVars.levelOfConcern &&
                        locMap(LevelOfConcern[createCaseVars.levelOfConcern as keyof typeof LevelOfConcern])}
                    {createCaseVars.levelOfConcern && (
                        <FormTextarea
                            value={createCaseVars.levelOfConcernNote}
                            onChange={(levelOfConcernNote) => {
                                setCreateCaseVars({
                                    ...createCaseVars,
                                    levelOfConcernNote
                                });
                            }}
                            missing={false}
                            max={CHARACTER_LIMIT.NOTE_TEXT_LIMIT}
                            label={'Note'}
                            rows={4}
                        />
                    )}
                </Column>
            </Column>
            <Row width={WIDTH_OF_CARD} alignmentHorizontal='right'>
                <Button type='secondary' onClick={history.goBack}>
                    Cancel
                </Button>
                <Button
                    onClick={() => {
                        let userRestricted = false;
                        const currentMissedField: string[] = [];
                        if (createCaseVars.eventCategory == '') {
                            currentMissedField.push('eventCategory');
                        }
                        if (createCaseVars.eventType == '') {
                            currentMissedField.push('eventType');
                        }
                        if (
                            createCaseVars.eventType != '' &&
                            getEventItems(createCaseVars.eventType).length > 0 &&
                            createCaseVars.eventItem == ''
                        ) {
                            currentMissedField.push('eventItem');
                        }
                        if (createCaseVars.dateOfEvent == '') {
                            currentMissedField.push('dateOfEvent');
                        }
                        if (createCaseVars.eventSummary == '') {
                            currentMissedField.push('eventSummary');
                        }
                        if (createCaseVars.assignedToEmpId == '') {
                            currentMissedField.push('assignedToEmpId');
                        }
                        if (createCaseVars.levelOfConcern == undefined) {
                            currentMissedField.push('levelOfConcern');
                        }
                        if (createCaseVars.reportedBy.name === '') {
                            currentMissedField.push('reportedByName');
                        }
                        if (
                            createCaseVars.reportedBy.name !== '' &&
                            !createCaseVars.reportedBy.name?.trim()
                        ) {
                            currentMissedField.push('reportedByNameHasOnlyEmptySpaces');
                        }
                        createCaseVars.personsInvolved.forEach((personInvolved, index) => {
                            if (personInvolved.name === '')
                                currentMissedField.push(`personInvolvedName${index}`);
                            if (personInvolved.role === undefined)
                                currentMissedField.push(`personInvolvedRole${index}`);
                            if (personInvolved.name !== '' && !personInvolved.name?.trim()) {
                                currentMissedField.push(`personInvolvedNameHasOnlyEmptySpaces${index}`);
                            }
                            if (
                                personInvolved.role &&
                                personInvolved.login &&
                                personInvolved.login === userLogin &&
                                (personInvolved.role === PersonInvolvedRole.PERSON_OF_INTEREST ||
                                    personInvolved.role === PersonInvolvedRole.VICTIM ||
                                    personInvolved.role === PersonInvolvedRole.WITNESS)
                            ) {
                                userRestricted = true;
                            }
                        });
                        if (!proximity) {
                            currentMissedField.push('proximity');
                        }
                        if (proximity == Proximity.ONSITE && siteIds.length === 0) {
                            currentMissedField.push('siteId');
                        }
                        setCreateCaseVars({ ...createCaseVars, missingFields: currentMissedField });
                        if (
                            currentMissedField.length == 0 &&
                            proximity &&
                            inputLocation.address.length <= CHARACTER_LIMIT.NORMAL_TEXT_LIMIT &&
                            inputLocation.city.length <= CHARACTER_LIMIT.NORMAL_TEXT_LIMIT &&
                            inputLocation.state.length <= CHARACTER_LIMIT.NORMAL_TEXT_LIMIT &&
                            inputLocation.zipcode.length <= CHARACTER_LIMIT.ZIPCODE_LIMIT &&
                            !hasOverLimitPersonInvolved(createCaseVars.personsInvolved) &&
                            !hasOverLimitPersonInvolved(createCaseVars.reportedBy) &&
                            createCaseVars.eventSummary.length <= CHARACTER_LIMIT.EVENT_SUMMARY_LIMIT &&
                            createCaseVars.levelOfConcernNote.length <= CHARACTER_LIMIT.NOTE_TEXT_LIMIT &&
                            !userRestricted
                        ) {
                            setCreateCaseVars({ ...createCaseVars, isDirty: false });
                            const caseCreating = createCaseObject(
                                createCaseVars,
                                proximity,
                                siteIds,
                                inputLocation,
                                secondarySiteIds,
                                lpContact
                            );
                            useCreateCase({
                                incidentCreating: caseCreating
                            });
                        } else {
                            dispatch(
                                addToast({
                                    type: 'error',
                                    action: 'One or more invalid form fields. ',
                                    message: 'Correct any errors and submit again',
                                    SIM: false,
                                    timeout: 3000
                                })
                            );
                        }
                    }}
                    disabled={useCreateCaseEndpoint.isFetching}
                >
                    Create
                </Button>
            </Row>
            {createCaseVars.isDirty && <RouterPrompt when={createCaseVars.isDirty}></RouterPrompt>}
        </Column>
    );
};

export default CreateCase;
