import Alert from '@amzn/meridian/alert';
import Column from '@amzn/meridian/column';
import Row from '@amzn/meridian/row';
import Select, { SelectOption } from '@amzn/meridian/select';
import Tag from '@amzn/meridian/tag';
import Text from '@amzn/meridian/text';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { SiteIdAndCode } from '../../lib/CaseDefinitions';
import { SELECT_WIDTH } from '../../lib/constants';
import { useLazyGetFacilitiesQuery } from '../../slices/WotManApiSlice';
import LoadingInput from './LoadingInput';
import NoSearchResults from './NoSearchResults';

type FacilityInputProps = {
    onChange: ((facility: SiteIdAndCode) => void) | ((facility: SiteIdAndCode[]) => void);
    value?: SiteIdAndCode | SiteIdAndCode[];
    label?: string;
    missing?: boolean;
    placeholder?: string;
    required?: boolean;
    isFilter?: boolean;
    filterFacilityList?: SiteIdAndCode[];
    hasTag?: boolean;
};

const FacilityInput: React.FunctionComponent<FacilityInputProps> = ({
    onChange,
    value,
    placeholder = 'Search for facility code...',
    label = 'Facility code',
    missing,
    required = false,
    isFilter,
    filterFacilityList,
    hasTag
}: FacilityInputProps) => {
    const [query, setQuery] = useState<string>(Array.isArray(value) || !value ? '' : value?.siteCode);
    const [siteData, setSiteData] = useState<SiteIdAndCode[]>([]);

    const [useGetFacilities, getFacilitiesEndpoint] = useLazyGetFacilitiesQuery({ prefix: query });
    const data = getFacilitiesEndpoint.data;

    useEffect(() => {
        if (query.length > 0) {
            useGetFacilities({ prefix: query });
        }
    }, [query]);

    useEffect(() => {
        if (value && !Array.isArray(value)) {
            setQuery(value.siteCode);
            if (_.findIndex(siteData, value) === -1) {
                setSiteData([...siteData, value]);
            }
        } else if (value && Array.isArray(value)) {
            setSiteData(_.union(siteData, value));
        }
    }, [value]);

    useEffect(() => {
        if (Array.isArray(data)) {
            if (value && !Array.isArray(value)) {
                if (_.findIndex(data, value) === -1) {
                    setSiteData([...data, value]);
                } else {
                    setSiteData(data);
                }
            } else if (Array.isArray(value)) {
                setSiteData(_.union(data, value));
            } else {
                setSiteData(data);
            }
        }
    }, [data]);

    const onSubmit = (siteId: string | string[]) => {
        if (Array.isArray(siteId)) {
            const facObjs: SiteIdAndCode[] = [];
            siteId.forEach((id) => {
                const facObj = siteData.find((facility: SiteIdAndCode) => facility.siteId === id);
                if (facObj) {
                    facObjs.push(facObj);
                }
            });
            /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
            // @ts-ignore
            onChange(facObjs);
            setQuery('');
        } else {
            const facObj = siteData.find((facility: SiteIdAndCode) => facility.siteId === siteId);
            if (facObj) {
                /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                // @ts-ignore
                onChange(facObj);
            }
        }
    };

    const facilitySelect = () => (
        <Select
            value={Array.isArray(value) ? value.map((siteCodeAndId) => siteCodeAndId.siteId) : value?.siteId}
            label={required ? `${label} *` : label}
            width={isFilter ? 'fill' : SELECT_WIDTH}
            onSearch={setQuery}
            searchQuery={query}
            onChange={onSubmit}
            error={missing}
            placeholder={placeholder}
        >
            {query.length > 0 ||
            (!Array.isArray(value) && value) ||
            (Array.isArray(value) && value.length > 0) ? (
                !getFacilitiesEndpoint.isFetching ? (
                    siteData.length > 0 ? (
                        filterFacilityList ? (
                            siteData
                                .filter(
                                    (facility: SiteIdAndCode) =>
                                        !filterFacilityList.some((f) => f.siteCode == facility.siteCode)
                                )
                                .map((facility: SiteIdAndCode) => {
                                    return (
                                        <SelectOption
                                            key={facility.siteId}
                                            value={facility.siteId}
                                            label={facility.siteCode}
                                        />
                                    );
                                })
                        ) : (
                            siteData.map((facility: SiteIdAndCode) => {
                                return (
                                    <SelectOption
                                        key={facility.siteId}
                                        value={facility.siteId}
                                        label={facility.siteCode}
                                    />
                                );
                            })
                        )
                    ) : (
                        <NoSearchResults />
                    )
                ) : (
                    <LoadingInput />
                )
            ) : (
                <Column alignmentVertical='center' spacing='300' spacingInset='600 none'>
                    <Text alignment='center'>Start typing facility code...</Text>
                </Column>
            )}
        </Select>
    );

    return (
        <Column>
            {isFilter ? (
                <Column>{facilitySelect()}</Column>
            ) : (
                <Row spacing='small' widths={[SELECT_WIDTH, SELECT_WIDTH]}>
                    {facilitySelect()}
                    {missing ? (
                        <Alert type='error' size='small'>
                            Facility code is required
                        </Alert>
                    ) : null}
                </Row>
            )}
            {hasTag && Array.isArray(value) && value.length > 0 && (
                <Row wrap='down'>
                    {value.map((facility) => {
                        return (
                            <Tag
                                key={facility.siteId}
                                type='neutral'
                                /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                                // @ts-ignore
                                onClose={() => onChange(value.filter((fac) => fac !== facility))}
                            >
                                {facility.siteCode}
                            </Tag>
                        );
                    })}
                </Row>
            )}
        </Column>
    );
};

export default FacilityInput;
