import moment from 'moment-timezone';
import {
    CommunicationMethod,
    SiteIdAndCode,
    LevelOfConcern,
    Proximity,
    Region,
    CaseShort,
    ConsultationShort
} from './CaseDefinitions';
import { EventItems, EventType } from './EmtCTIDefinitions';

export interface FilterOptions {
    levelOfConcern: LevelOfConcern[];
    severity: number[];
    eventType: EventType[];
    eventCategory: string[];
    region: Region[];
    status: string[];
    flaggedWords: string[];
    onlyNew: boolean;
    onlyUpdated: boolean;
}

export const initialFilterOptions: FilterOptions = {
    levelOfConcern: [],
    severity: [],
    eventType: [],
    eventCategory: [],
    region: [],
    status: [],
    flaggedWords: [],
    onlyNew: false,
    onlyUpdated: false
};

export interface LocationFilterGeneric {
    businessLine: string[];
    proximity: Proximity[];
    stateProvince: string;
    streetAddress: string;
    city: string;
    postalCode: string;
    country: string[];
    region: Region[];
    primary: boolean;
    secondary: boolean;
}

export interface LocationInfoFilter extends LocationFilterGeneric {
    facilityCode: SiteIdAndCode[];
}

export interface LocationInfoFilterBackend extends LocationFilterGeneric {
    siteIds: string[];
}

export interface AdvancedFilter {
    dateFrom: string;
    dateTo: string;
    timeTo?: string;
    timeFrom?: string;
    locationFilter: LocationInfoFilter;
}

export interface SimpleSearch {
    displayedCases: CaseShort[];
    displayedConsultations: ConsultationShort[];
}

export interface ConsultationFilter extends AdvancedFilter {
    consultationTitle: string;
    method: CommunicationMethod[];
    timeZone: string;
    communicatedWith: {
        person: string;
        personId: string;
        isName?: boolean;
    }[];
    allOrAny: string;
    addedBy: string[];
    status: string[];
}

export interface ConsultationFilterBackend {
    dateTimeToUTC: string;
    dateTimeFromUTC: string;
    consultationTitle: string;
    method: CommunicationMethod[];
    communicatedWith: string[];
    addedBy: string[];
    status: string[];
    allOrAny: string;
    locationFilter: LocationInfoFilterBackend;
}

export interface CaseFilter extends AdvancedFilter {
    levelOfConcern: LevelOfConcern[];
    severity: number[];
    eventType: EventType[];
    eventItem: EventItems[];
    eventCategory: string[];
    eventTags: string[];
    status: string[];
    flaggedWords: string[];
    onlyNew: boolean;
    onlyUpdated: boolean;
    simTicketLinked: boolean;
    assignedTo: string[];
    accessLevel: string[];
    timeZone: string;
    personInvolved: {
        person: string;
        personId: string;
        role?: string;
        isName?: boolean;
    }[];
    allOrAny: string;
    recommendations: string[];
    noTmActionNeeded: boolean;
    lpContact: {
        person: string;
        personId: string;
    }[];
}

export interface CaseFilterBackend {
    levelOfConcern: LevelOfConcern[];
    severity: number[];
    eventType: string[];
    eventCategory: string[];
    eventItem: string[];
    status: string[];
    flaggedWords: string[];
    onlyNew: boolean;
    onlyUpdated: boolean;
    simTicketLinked: boolean;
    assignedTo: string[];
    eventTags: string[];
    dateTimeFromUTC: string;
    dateTimeToUTC: string;
    accessLevel: string[];
    personInvolved: {
        person: string;
        role?: string;
    }[];
    lpContact: {
        person: string;
    }[];
    allOrAny: string;
    recommendations: string[];
    locationFilter: LocationInfoFilterBackend;
    noTmActionNeeded: boolean;
}

const initialLocationFilter = {
    businessLine: [],
    proximity: [],
    facilityCode: [],
    country: [],
    region: [],
    stateProvince: '',
    streetAddress: '',
    city: '',
    postalCode: '',
    primary: true,
    secondary: true
};

export const initialConsultationFilter: ConsultationFilter = {
    consultationTitle: '',
    dateFrom: '',
    dateTo: '',
    allOrAny: 'any',
    timeZone: moment.tz.guess(),
    locationFilter: initialLocationFilter,
    communicatedWith: [
        {
            person: '',
            personId: ''
        }
    ],
    method: [],
    addedBy: [],
    status: []
};

export const initialAdvancedFilter: CaseFilter = {
    assignedTo: [],
    levelOfConcern: [],
    severity: [],
    eventType: [],
    eventItem: [],
    eventCategory: [],
    status: [],
    flaggedWords: [],
    accessLevel: [],
    onlyNew: false,
    onlyUpdated: false,
    simTicketLinked: false,
    timeZone: moment.tz.guess(),
    eventTags: [],
    dateFrom: '',
    dateTo: '',
    personInvolved: [{ person: '', personId: '' }],
    lpContact: [{ person: '', personId: '' }],
    allOrAny: 'any',
    locationFilter: initialLocationFilter,
    recommendations: [],
    noTmActionNeeded: false
};
