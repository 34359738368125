// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { AwsRum, AwsRumConfig } from 'aws-rum-web';
import AUTH_CONTEXT from 'GuardianAuth/lib/auth/AuthContext';
import GuardianAuth from 'GuardianAuth/lib/auth/GuardianAuth';
import createGuardianAuthStore from 'GuardianAuth/lib/store/guardianAuthStore';
import React from 'react';
import ReactDOM from 'react-dom';
import '@amzn/meridian-tokens/base/font/amazon-ember.css';
import { Provider } from 'react-redux';
import { AnyAction, Store } from 'redux';
import App from './App';
import {
    COGNITO_APP_WEB_DOMAIN,
    COGNITO_CLIENT_ID,
    COGNITO_USERPOOL,
    API_GATEWAY_API_KEY,
    COGNITO_REDIRECT_TO,
    STAGE
} from './lib/globals';

try {
    let GUEST_ROLE_ARN, IDENTITY_POOL_ID, APPLICATION_ID;
    switch (STAGE) {
        case 'dev':
            GUEST_ROLE_ARN =
                'arn:aws:iam::182289305248:role/RUM-Monitor-us-east-1-182289305248-8968160471761-Unauth';
            IDENTITY_POOL_ID = 'us-east-1:7c9479f2-539f-4063-b431-ba9d0196fcb8';
            APPLICATION_ID = 'a7adbfd4-e476-4f0e-bf38-6650c3009a3b';
            break;
        case 'beta':
            GUEST_ROLE_ARN =
                'arn:aws:iam::182289305248:role/RUM-Monitor-us-east-1-182289305248-3966570471761-Unauth';
            IDENTITY_POOL_ID = 'us-east-1:e24cb122-0790-49e4-8701-b2e3801a85a1';
            APPLICATION_ID = '040de15c-fbea-47c9-a905-57c86617ce63';
            break;
        case 'gamma':
            GUEST_ROLE_ARN =
                'arn:aws:iam::431848445618:role/RUM-Monitor-us-east-1-431848445618-8020801471761-Unauth';
            IDENTITY_POOL_ID = 'us-east-1:72bafe5a-2e00-4bba-9c95-9464519c1f81';
            APPLICATION_ID = 'a1a0a16e-be90-42c2-91b6-84417483bf4e';
            break;
        case 'prod':
            GUEST_ROLE_ARN =
                'arn:aws:iam::649034229557:role/RUM-Monitor-us-east-1-649034229557-1325406471761-Unauth';
            IDENTITY_POOL_ID = 'us-east-1:1a3f5962-6804-4a96-b111-d91dd75608f7';
            APPLICATION_ID = '8765bb6d-9d0b-43f0-8b0d-556ee04f30ea';
            break;
        default:
            throw new Error('STAGE not recognized');
    }

    const config: AwsRumConfig = {
        sessionSampleRate: 1,
        guestRoleArn: GUEST_ROLE_ARN,
        identityPoolId: IDENTITY_POOL_ID,
        endpoint: 'https://dataplane.rum.us-east-1.amazonaws.com',
        telemetries: ['performance', 'errors', 'http'],
        allowCookies: true,
        enableXRay: true
    };

    const APPLICATION_VERSION = '1.0.0';
    const APPLICATION_REGION = 'us-east-1';

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const awsRum: AwsRum = new AwsRum(APPLICATION_ID, APPLICATION_VERSION, APPLICATION_REGION, config);
} catch (error) {
    // eslint-disable-next-line no-console
    console.log(`Failed to init RUM metrics: ${error}`);
}

GuardianAuth.init(
    createGuardianAuthStore(),
    COGNITO_APP_WEB_DOMAIN,
    COGNITO_CLIENT_ID,
    COGNITO_USERPOOL,
    API_GATEWAY_API_KEY,
    COGNITO_REDIRECT_TO
);

GuardianAuth.setTitle('Tester');

export default function showPage(guardianAuthStore: Store<never, AnyAction>): void {
    ReactDOM.render(
        <Provider store={guardianAuthStore} context={AUTH_CONTEXT}>
            <App />
        </Provider>,
        document.getElementById('root')
    );
}

if (GuardianAuth.guardianAuthStore) {
    GuardianAuth.authAndShowPage(() => {
        showPage(GuardianAuth.guardianAuthStore);
    });
}
