import Column from '@amzn/meridian/column';
import Link from '@amzn/meridian/link';
import Loader from '@amzn/meridian/loader';
import Pagination from '@amzn/meridian/pagination';
import Responsive from '@amzn/meridian/responsive';
import Row from '@amzn/meridian/row';
import Tab, { TabGroup } from '@amzn/meridian/tab';
import Text from '@amzn/meridian/text';
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState, useAppDispatch } from '../../context/store';
import {
    NUM_HEADERS_PER_PAGE,
    ResponsiveVariables,
    responsiveVariables,
    WIDTH_OF_CARD,
    TAB_NAMES
} from '../../lib/constants';
import { filterConsultationCasesOnOptions } from '../../lib/helpers';
import {
    closeConsultationDetail,
    initialConsultationFilterOptions,
    setConsultationFilterOptions,
    setConsultations,
    setDisplayedConsultations
} from '../../slices/ConsultationsSlice';
import { addToast } from '../../slices/ToastSlice';
import { useLazySearchConsultationsQuery } from '../../slices/WotManApiSlice';
import ResponsiveHooks from '../Cases/DetailsTabs/ResponsiveHooks';
import AddConsultationsModal from './AddConsultationsModal';
import ConsultationFilter from './ConsultationFilter';
import ConsultationHeader from './ConsultationHeader';
import ConsultationSortingDropDown from './ConsultationSortingDropdown';

const Consultations: React.FunctionComponent = () => {
    const { consultations, consultationFilterOptions, displayingSheet } = useSelector(
        (state: RootState) => state.consultations
    );
    const { isNavCollapsed } = useSelector((state: RootState) => state.dashboard);
    const [currentTab, setCurrentTab] = useState('unlinked');
    const [currentPage, setCurrentPage] = useState(1);
    const dispatch = useAppDispatch();
    const history = useHistory();
    const allConsultations = filterConsultationCasesOnOptions(
        consultations,
        consultationFilterOptions,
        currentTab === 'linked'
    );
    const [searchConsultation, searchConsultationEndpoint] = useLazySearchConsultationsQuery();

    useEffect(() => {
        searchConsultation({
            consultationFilter: {
                lastThirtyDays: true
            }
        });
    }, []);

    useEffect(() => {
        if (!searchConsultationEndpoint.isFetching && searchConsultationEndpoint.isSuccess) {
            dispatch(setConsultations(searchConsultationEndpoint.data));
        } else if (!searchConsultationEndpoint.isFetching && searchConsultationEndpoint.isError) {
            dispatch(
                addToast({
                    type: 'error',
                    action: 'There was an error searching engagements, please try again',
                    message: '',
                    SIM: true,
                    timeout: undefined
                })
            );
        }
    }, [searchConsultationEndpoint.data]);

    useEffect(() => {
        setCurrentPage(1);
    }, [currentTab]);

    useEffect(() => {
        dispatch(setConsultationFilterOptions(initialConsultationFilterOptions));
        return function cleanup() {
            dispatch(closeConsultationDetail());
        };
    }, [currentTab]);

    useEffect(() => {
        dispatch(setDisplayedConsultations(allConsultations));
    }, [consultations, consultationFilterOptions, currentTab]);

    const numPages = Math.ceil(allConsultations.length / NUM_HEADERS_PER_PAGE);
    const startHeader = (currentPage - 1) * NUM_HEADERS_PER_PAGE;

    return (
        <Responsive query='min-width' props={responsiveVariables}>
            {({
                withNoneOpen,
                withNavBarOpen,
                withSheetOpen,
                withBothOpen
            }: {
                withNoneOpen: boolean;
                withNavBarOpen: boolean;
                withSheetOpen: boolean;
                withBothOpen: ResponsiveVariables;
            }) => {
                const isStacked = isNavCollapsed
                    ? displayingSheet
                        ? withSheetOpen
                        : withNoneOpen
                    : displayingSheet
                    ? withBothOpen === ResponsiveVariables.STACK ||
                      withBothOpen === ResponsiveVariables.CLOSE_ON_OPEN
                    : withNavBarOpen;
                return (
                    <React.Fragment>
                        <Helmet defer={false}>
                            <title> {TAB_NAMES.CONSULTATIONS} | MARS</title>
                        </Helmet>
                        <Row
                            width='100%'
                            alignmentVertical='top'
                            alignmentHorizontal='center'
                            widths={[WIDTH_OF_CARD, '272px']}
                        >
                            <ResponsiveHooks withBothOpen={withBothOpen} />
                            <Column>
                                <Row alignmentHorizontal='justify'>
                                    <Text type='d100'> Engagements </Text>
                                    <AddConsultationsModal />
                                </Row>
                                {isStacked && <ConsultationFilter horizontalOptions />}
                                <Row alignmentHorizontal='justify'>
                                    <Row alignmentHorizontal='justify'>
                                        <TabGroup value={currentTab} onChange={setCurrentTab}>
                                            <Tab value='unlinked'>Unlinked</Tab>
                                            <Tab value='linked'>Linked</Tab>
                                        </TabGroup>
                                    </Row>
                                    <Row alignmentHorizontal='right'>
                                        <Text alignment='right' type='h200'>
                                            Sort By
                                        </Text>
                                        <ConsultationSortingDropDown />
                                    </Row>
                                </Row>
                                <Column spacingInset='400 none'>
                                    {allConsultations.length > 0 ? (
                                        allConsultations
                                            .slice(startHeader, startHeader + NUM_HEADERS_PER_PAGE)
                                            .map((consultation) => (
                                                <ConsultationHeader
                                                    consultationHeader={consultation}
                                                    key={consultation.consultationId}
                                                />
                                            ))
                                    ) : searchConsultationEndpoint.isFetching ? (
                                        <Row alignmentHorizontal={'center'}>
                                            <Loader />
                                        </Row>
                                    ) : (
                                        <Column>
                                            <Text alignment='center' color='secondary'>
                                                No {currentTab} engagements to display
                                            </Text>
                                            <Text alignment='center' color='secondary'>
                                                Use{' '}
                                                {
                                                    <Link
                                                        onClick={history.push}
                                                        href={'/search#consultations'}
                                                    >
                                                        advanced search
                                                    </Link>
                                                }{' '}
                                                to find engagements added over 30 days ago.
                                            </Text>
                                        </Column>
                                    )}
                                </Column>
                                {numPages > 1 && (
                                    <Row alignmentHorizontal='center'>
                                        <Pagination
                                            numberOfPages={numPages}
                                            onChange={setCurrentPage}
                                            currentPage={currentPage}
                                        />
                                    </Row>
                                )}
                            </Column>
                            {!isStacked && <ConsultationFilter />}
                        </Row>
                    </React.Fragment>
                );
            }}
        </Responsive>
    );
};

export default Consultations;
