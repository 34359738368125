import Column from '@amzn/meridian/column';
import Loader from '@amzn/meridian/loader';
import Text from '@amzn/meridian/text';
import React from 'react';

const LoadingInput: React.FunctionComponent = () => {
    return (
        <Column alignmentHorizontal='center' alignmentVertical='center' spacing='300' spacingInset='600'>
            <Loader size='medium' />
            <Text alignment='center'>Loading results</Text>
        </Column>
    );
};

export default LoadingInput;
