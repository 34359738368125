import Column from '@amzn/meridian/column';
import Row from '@amzn/meridian/row';
import Text from '@amzn/meridian/text';
import React from 'react';
import { Helmet } from 'react-helmet';
import { TAB_NAMES } from '../../lib/constants';
import GenerateReport from './GenerateReport';

const Metrics: React.FunctionComponent = () => {
    return (
        <React.Fragment>
            <Helmet defer={false}>
                <title> {TAB_NAMES.METRICS} | MARS </title>
            </Helmet>
            <Row alignmentHorizontal={'center'}>
                <Column>
                    <Text type='d100'> Metrics </Text>
                    <GenerateReport />
                </Column>
            </Row>
        </React.Fragment>
    );
};

export default Metrics;
