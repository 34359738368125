/* eslint-disable */
import React, { Fragment, useState } from 'react';
import Box from '@amzn/meridian/box';
import Row from '@amzn/meridian/row';
import Text from '@amzn/meridian/text';
import Select, { SelectOption } from '@amzn/meridian/select';
import Column from '@amzn/meridian/column';
import Button from '@amzn/meridian/button';
import Link from '@amzn/meridian/link';
import { useSelector } from 'react-redux';
import { useAppDispatch, RootState } from '../../context/store';
import {
    ConsultationFilterOptions,
    initialConsultationFilterOptions,
    setConsultationFilterOptions,
    setLocalConsultationFilterOptions
} from '../../slices/ConsultationsSlice';
import { CommunicationMethod, PAPIShort, Proximity, Region, SiteIdAndCode } from '../../lib/CaseDefinitions';

import { useHistory } from 'react-router-dom';
import LoadingInput from '../Inputs/LoadingInput';
import Tag from '@amzn/meridian/tag';
import FacilityInput from '../Inputs/FacilityInput';
import RegionInput from '../Inputs/RegionInput';
import LoginNameSearch from '../Inputs/LoginNameSearch';

type ConsultationFilterProps = {
    horizontalOptions?: boolean;
    initialFilterState?: ConsultationFilterOptions;
};

const ConsultationFilter: React.FunctionComponent<ConsultationFilterProps> = ({
    horizontalOptions = false,
    initialFilterState
}: ConsultationFilterProps) => {
    const { displayedConsultations, localFilterOptions } = useSelector(
        (state: RootState) => state.consultations
    );
    const dispatch = useAppDispatch();
    const history = useHistory();
    const [query, setQuery] = useState('');
    const { selectOptionsContext } = useSelector((state: RootState) => state.selectOptions);

    const onClickPeopleSuggestion = (person: PAPIShort) => {
        dispatch(
            setLocalConsultationFilterOptions({
                ...localFilterOptions,
                communicatedWith: [...localFilterOptions.communicatedWith, person]
            })
        );
        setQuery('');
    };

    const selectOptions = () => (
        <Fragment>
            <Column>
                <Select
                    value={localFilterOptions.method}
                    onChange={(method) =>
                        dispatch(setLocalConsultationFilterOptions({ ...localFilterOptions, method }))
                    }
                    label='Method'
                    size='xlarge'
                >
                    {Object.values(CommunicationMethod).map((value) => {
                        return <SelectOption value={value} label={value} key={value} />;
                    })}
                </Select>
                {Array.isArray(localFilterOptions.method) && localFilterOptions.method.length > 1 && (
                    <Row wrap='down'>
                        {localFilterOptions.method.map((method) => {
                            return (
                                <Tag
                                    key={method}
                                    type='neutral'
                                    /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                                    // @ts-ignore
                                    onClose={() =>
                                        dispatch(
                                            setLocalConsultationFilterOptions({
                                                ...localFilterOptions,
                                                method: localFilterOptions.method.filter(
                                                    (met) => met !== method
                                                )
                                            })
                                        )
                                    }
                                >
                                    {method}
                                </Tag>
                            );
                        })}
                    </Row>
                )}
            </Column>
            <Column>
                <LoginNameSearch
                    onClick={onClickPeopleSuggestion}
                    onSubmit={() => setQuery('')}
                    query={query}
                    setQuery={setQuery}
                    label='Communicated with'
                    width='auto'
                />
                {Array.isArray(localFilterOptions.communicatedWith) &&
                    localFilterOptions.communicatedWith.length >= 1 && (
                        <Row wrap='down'>
                            {localFilterOptions.communicatedWith.map((person) => {
                                return (
                                    <Tag
                                        key={person.personId}
                                        type='neutral'
                                        /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                                        // @ts-ignore
                                        onClose={() =>
                                            dispatch(
                                                setLocalConsultationFilterOptions({
                                                    ...localFilterOptions,
                                                    communicatedWith: localFilterOptions.communicatedWith.filter(
                                                        (met) => met !== person
                                                    )
                                                })
                                            )
                                        }
                                    >
                                        {person.fullName}
                                    </Tag>
                                );
                            })}
                        </Row>
                    )}
            </Column>
            <Column>
                <Select
                    value={localFilterOptions.added}
                    onChange={(added) =>
                        dispatch(setLocalConsultationFilterOptions({ ...localFilterOptions, added }))
                    }
                    label='Added by'
                    size='xlarge'
                >
                    {selectOptionsContext.consultationsAddedBy ? (
                        selectOptionsContext.consultationsAddedBy.map((person) => {
                            return (
                                <SelectOption
                                    key={person.empId}
                                    label={`${person.firstName} ${person.lastName}`}
                                    value={`${person.firstName} ${person.lastName}`}
                                />
                            );
                        })
                    ) : (
                        <LoadingInput />
                    )}
                </Select>
                {Array.isArray(localFilterOptions.added) && localFilterOptions.added.length > 1 && (
                    <Row wrap='down'>
                        {localFilterOptions.added.map((added) => {
                            return (
                                <Tag
                                    key={added}
                                    type='neutral'
                                    /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                                    // @ts-ignore
                                    onClose={() =>
                                        dispatch(
                                            setLocalConsultationFilterOptions({
                                                ...localFilterOptions,
                                                added: localFilterOptions.added.filter((met) => met !== added)
                                            })
                                        )
                                    }
                                >
                                    {added}
                                </Tag>
                            );
                        })}
                    </Row>
                )}
            </Column>
            <Column>
                <FacilityInput
                    label='Facility code'
                    value={localFilterOptions.siteIds}
                    onChange={(siteIds: SiteIdAndCode[]) =>
                        dispatch(setLocalConsultationFilterOptions({ ...localFilterOptions, siteIds }))
                    }
                    isFilter={true}
                />
            </Column>
            <Column>
                <Select
                    value={localFilterOptions.proximity}
                    onChange={(proximity) =>
                        dispatch(setLocalConsultationFilterOptions({ ...localFilterOptions, proximity }))
                    }
                    label='Proximity'
                    size='xlarge'
                >
                    {Object.values(Proximity).map((value) => {
                        return <SelectOption value={value} label={value} key={value} />;
                    })}
                </Select>
            </Column>
            <Column>
                <RegionInput
                    value={localFilterOptions.region}
                    onChange={(region: Region[]) =>
                        dispatch(setLocalConsultationFilterOptions({ ...localFilterOptions, region }))
                    }
                />
            </Column>
        </Fragment>
    );

    return (
        <Column>
            <Box type='outline' spacingInset='400'>
                <Column>
                    <Row alignmentHorizontal='justify'>
                        <Text type='h300'>Filters</Text>
                        <Text type='b300'>{displayedConsultations.length} results</Text>
                    </Row>
                    <Row alignmentHorizontal='justify'>
                        <Text type='b200'>Showing results from last 30 days</Text>
                    </Row>
                    {horizontalOptions ? (
                        <Row
                            width='100%'
                            widths='33%'
                            alignmentVertical={'top'}
                            wrap={'down'}
                            children={selectOptions()}
                        />
                    ) : (
                        selectOptions()
                    )}
                    <Row alignmentHorizontal='right'>
                        <Button
                            type='secondary'
                            onClick={() => {
                                dispatch(
                                    setLocalConsultationFilterOptions(
                                        initialFilterState || initialConsultationFilterOptions
                                    )
                                );
                                setQuery('');
                            }}
                        >
                            Clear
                        </Button>
                        <Button
                            type='primary'
                            onClick={() => {
                                dispatch(setConsultationFilterOptions(localFilterOptions));
                                setQuery('');
                            }}
                        >
                            Apply
                        </Button>
                    </Row>
                </Column>
            </Box>
            <Row alignmentHorizontal='right'>
                <Link onClick={history.push} href={'/search#consultations'}>
                    Advanced search
                </Link>
            </Row>
        </Column>
    );
};

export default ConsultationFilter;
