import { SelectOption, SelectOptionGroup } from '@amzn/meridian/select';
import _ from 'lodash';
import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../context/store';
import { Assignee } from '../../lib/CaseDefinitions';
import { SELECT_WIDTH } from '../../lib/constants';
import { ThreatManager } from '../../lib/ThreatManagerDefinitions';
import { FormSelect } from './FormFields';
import LoadingInput from './LoadingInput';

type ThreatManagerInputProps = {
    onChange: (assignee: Assignee) => void;
    value?: string;
    width?: string;
    hasPlaceholder?: boolean;
    suggestedUsers: Assignee[];
    missing?: boolean;
    required?: boolean;
    isSmall?: boolean;
};

const ThreatManagerInput: React.FunctionComponent<ThreatManagerInputProps> = ({
    onChange,
    value,
    suggestedUsers,
    width = SELECT_WIDTH,
    hasPlaceholder = false,
    missing = false,
    required = false,
    isSmall = false
}: ThreatManagerInputProps) => {
    const { threatManagers } = useSelector((state: RootState) => state.selectOptions);
    const allUsers = [...threatManagers.admins, ...threatManagers.users];

    const useEmpidOnChance = (empId: string) => {
        return onChange(
            _.find([...allUsers, ...suggestedUsers], (user) => {
                return user.empId === empId;
            }) as Assignee
        );
    };

    return (
        <Fragment>
            {allUsers.length === 0 || suggestedUsers.length === 0 ? (
                <FormSelect
                    placeholder={hasPlaceholder ? 'Select a user' : undefined}
                    label={hasPlaceholder ? undefined : `Assigned to${required ? ' *' : ''}`}
                    width={width}
                    value={value}
                    onChange={useEmpidOnChance}
                    missing={missing}
                    isSmall={isSmall}
                >
                    {allUsers.length > 0 ? (
                        allUsers.map((option: ThreatManager) => {
                            return (
                                <SelectOption
                                    value={option.empId}
                                    label={`${option.firstName} ${option.lastName}`}
                                    key={option.empId}
                                />
                            );
                        })
                    ) : (
                        <LoadingInput />
                    )}
                </FormSelect>
            ) : (
                <FormSelect
                    placeholder={hasPlaceholder ? 'Select a user' : undefined}
                    label={hasPlaceholder ? undefined : `Assigned to${required ? ' *' : ''}`}
                    width={width}
                    value={value}
                    onChange={useEmpidOnChance}
                    missing={missing}
                    isSmall={isSmall}
                >
                    <SelectOptionGroup label='Suggested Users'>
                        {suggestedUsers &&
                            suggestedUsers.map((recommendation) => {
                                return (
                                    <SelectOption
                                        key={recommendation.empId}
                                        label={`${recommendation.firstName} ${recommendation.lastName}`}
                                        value={recommendation.empId}
                                    />
                                );
                            })}
                    </SelectOptionGroup>
                    <SelectOptionGroup label='Other Users'>
                        {allUsers.length > 0 &&
                            allUsers
                                .filter(
                                    (adminInfo: ThreatManager) =>
                                        _.findIndex(suggestedUsers, (user: Assignee) => {
                                            return user.empId === adminInfo.empId;
                                        }) === -1
                                )
                                .map((option: ThreatManager) => {
                                    return (
                                        <SelectOption
                                            value={option.empId}
                                            label={`${option.firstName} ${option.lastName}`}
                                            key={option.empId}
                                        />
                                    );
                                })}
                    </SelectOptionGroup>
                </FormSelect>
            )}
        </Fragment>
    );
};

export default ThreatManagerInput;
