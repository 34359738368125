import Column from '@amzn/meridian/column';
import Expander from '@amzn/meridian/expander';
import Loader from '@amzn/meridian/loader';
import Pagination from '@amzn/meridian/pagination';
import Row from '@amzn/meridian/row';
import Tab, { TabGroup } from '@amzn/meridian/tab';
import Text from '@amzn/meridian/text';
import Tile from '@amzn/meridian/tile';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useLastLocation } from 'react-router-last-location';
import { RootState, useAppDispatch } from '../../../context/store';
import { Case, Consultation } from '../../../lib/CaseDefinitions';
import { FILTER_PAGE_WIDTH, NUM_HEADERS_PER_PAGE, WIDTH_OF_CARD } from '../../../lib/constants';
import { initialAdvancedFilter, initialConsultationFilter } from '../../../lib/FilterDefinitons';
import {
    resetState,
    setCasePageNumber,
    setCasesDisplaying,
    setConsultationPageNumber,
    setConsultationsDisplaying
} from '../../../slices/AdvancedSearchSlice';
import { addToast } from '../../../slices/ToastSlice';
import { useLazySearchCasesQuery, useLazySearchConsultationsQuery } from '../../../slices/WotManApiSlice';
import ConsultationHeader from '../../Consultations/ConsultationHeader';
import CaseHeader from '../CaseHeader';
import AdvancedCaseFilter from './AdvancedCaseFilter';
import AdvancedConsultationFilter from './AdvancedConsultationFilter';

const AdvancedSearch: React.FunctionComponent = () => {
    const {
        hasAdvancedSearchCase,
        hasAdvancedSearchConsultation,
        filterOptions,
        consultationFilterOptions,
        displayedCases,
        displayedConsultations,
        casePageNumber,
        consultationPageNumber
    } = useSelector((state: RootState) => state.advancedSearch);

    const [searchCases, searchCasesEndpoint] = useLazySearchCasesQuery();
    const [searchConsultations, searchConsultationsEndpoint] = useLazySearchConsultationsQuery();
    const [openExpander, setOpenExpander] = useState(!hasAdvancedSearchCase);
    const [currentTab, setCurrentTab] = useState('#cases');
    const dispatch = useAppDispatch();

    const history = useHistory();
    const lastLocation = useLastLocation();
    const hasAdvancedSearch =
        (currentTab === '#cases' && hasAdvancedSearchCase) ||
        (currentTab === '#consultations' && hasAdvancedSearchConsultation);

    useEffect(() => {
        if (searchConsultationsEndpoint.isError) {
            dispatch(setConsultationsDisplaying({ consultations: [] }));
            dispatch(
                addToast({
                    type: 'error',
                    action: 'There was an error searching engagements',
                    message: '',
                    SIM: true,
                    timeout: 5000
                })
            );
        }
    }, [searchConsultationsEndpoint.isError]);

    useEffect(() => {
        if (searchCasesEndpoint.isError) {
            dispatch(setCasesDisplaying([]));
            dispatch(
                addToast({
                    type: 'error',
                    action: 'There was an error searching cases',
                    message: '',
                    SIM: true,
                    timeout: 5000
                })
            );
        }
    }, [searchCasesEndpoint.isError]);

    useEffect(() => {
        if (!searchConsultationsEndpoint.isFetching && searchConsultationsEndpoint.isSuccess) {
            dispatch(setConsultationsDisplaying({ consultations: searchConsultationsEndpoint.data }));
        }
    }, [searchConsultationsEndpoint.data]);

    useEffect(() => {
        if (!searchCasesEndpoint.isFetching && searchCasesEndpoint.isSuccess) {
            dispatch(setCasesDisplaying(searchCasesEndpoint.data.cases));
        }
    }, [searchCasesEndpoint.data]);

    useEffect(() => {
        if (location.hash !== '#consultations') {
            location.hash = '#cases';
        }
        setCurrentTab(location.hash);
    }, [location.hash]);

    const numPages = Math.ceil(
        currentTab === '#cases'
            ? displayedCases.length / NUM_HEADERS_PER_PAGE
            : displayedConsultations.length / NUM_HEADERS_PER_PAGE
    );
    const startHeaderConsultation = (consultationPageNumber - 1) * NUM_HEADERS_PER_PAGE;
    const startHeaderCase = (casePageNumber - 1) * NUM_HEADERS_PER_PAGE;
    const topOfSearchRef = useRef<null | HTMLElement>(null);

    let numFilters = 0;

    if (currentTab === '#cases') {
        for (const property in filterOptions) {
            if (property !== filterOptions.allOrAny) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                if (property != 'dateTo' && filterOptions[property] !== initialAdvancedFilter[property])
                    numFilters += 1;
            }
        }
        if (filterOptions.locationFilter !== initialAdvancedFilter.locationFilter) {
            numFilters -= 1;
            for (const property in filterOptions.locationFilter) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                if (filterOptions.locationFilter[property] !== initialAdvancedFilter.locationFilter[property])
                    numFilters += 1;
            }
        }
    } else {
        for (const property in consultationFilterOptions) {
            if (property !== filterOptions.allOrAny) {
                if (
                    property != 'dateTo' &&
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    consultationFilterOptions[property] !== initialConsultationFilter[property]
                )
                    numFilters += 1;
            }
        }
        const { locationFilter } = consultationFilterOptions;
        if (locationFilter !== initialConsultationFilter.locationFilter) {
            numFilters -= 1;
            for (const property in locationFilter) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                if (locationFilter[property] !== initialConsultationFilter.locationFilter[property])
                    numFilters += 1;
            }
        }
    }

    useEffect(() => {
        if (hasAdvancedSearch) setOpenExpander(false);
        if (topOfSearchRef.current) topOfSearchRef.current.scrollIntoView({ behavior: 'auto' });
    }, [searchConsultationsEndpoint.isFetching, searchCasesEndpoint.isFetching]);

    useEffect(() => {
        setOpenExpander(!hasAdvancedSearch);
    }, [currentTab]);

    useEffect(() => {
        if (!lastLocation?.pathname.startsWith('/cases')) {
            setOpenExpander(true);
            dispatch(resetState({ set: true }));
        }
    }, []);

    const noSearchResults = () => (
        <Column>
            <Text alignment='center' color='secondary'>
                No search results
            </Text>
            <Text alignment='center' color='secondary'>
                Change your search filters and try again.
            </Text>
        </Column>
    );

    return (
        <Row alignmentHorizontal={'center'}>
            <Column spacingInset='400 none'>
                <Text ref={topOfSearchRef} type='d100'>
                    {' '}
                    Advanced Search{' '}
                </Text>
                <TabGroup value={currentTab} onChange={(newTab) => history.push({ hash: newTab })}>
                    <Tab value={'#cases'}> Cases </Tab>
                    <Tab value={'#consultations'}> Engagements </Tab>
                </TabGroup>
                <Tile spacingInset='medium' layout={{ width: FILTER_PAGE_WIDTH }}>
                    <Row>
                        <Expander
                            open={openExpander}
                            onChange={setOpenExpander}
                            title={
                                <Row>
                                    <Column>Advanced search filters</Column>
                                    {!openExpander && (
                                        <Column>
                                            <Text color='primary'>{numFilters} applied</Text>
                                        </Column>
                                    )}
                                </Row>
                            }
                            type='inline'
                        >
                            {currentTab === '#cases' && <AdvancedCaseFilter searchCases={searchCases} />}
                            {currentTab === '#consultations' && (
                                <AdvancedConsultationFilter searchConsultations={searchConsultations} />
                            )}
                        </Expander>
                    </Row>
                </Tile>
                {hasAdvancedSearch && (
                    <Fragment>
                        <Row>
                            <Text type='h200'> Search results </Text>{' '}
                            <Text type='b400' color='secondary'>
                                {currentTab === '#cases'
                                    ? displayedCases.length
                                    : displayedConsultations.length}
                            </Text>
                        </Row>
                        {currentTab === '#cases' && (
                            <Fragment>
                                {!searchCasesEndpoint.isFetching ? (
                                    displayedCases.length > 0 ? (
                                        <Fragment>
                                            {displayedCases
                                                .slice(
                                                    startHeaderCase,
                                                    startHeaderCase + NUM_HEADERS_PER_PAGE
                                                )
                                                .map((caseHeader: Case) => (
                                                    <CaseHeader
                                                        caseHeader={caseHeader}
                                                        key={caseHeader.caseId}
                                                    />
                                                ))}
                                            <Row width={WIDTH_OF_CARD} alignmentHorizontal='center'>
                                                <Pagination
                                                    numberOfPages={numPages}
                                                    onChange={(page) => dispatch(setCasePageNumber({ page }))}
                                                    currentPage={casePageNumber}
                                                />
                                            </Row>
                                        </Fragment>
                                    ) : (
                                        noSearchResults()
                                    )
                                ) : (
                                    <Loader />
                                )}
                            </Fragment>
                        )}

                        {currentTab === '#consultations' && (
                            <Fragment>
                                {!searchConsultationsEndpoint.isFetching ? (
                                    displayedConsultations.length > 0 ? (
                                        <Fragment>
                                            {displayedConsultations
                                                .slice(
                                                    startHeaderConsultation,
                                                    startHeaderConsultation + NUM_HEADERS_PER_PAGE
                                                )
                                                .map((consultation: Consultation) => (
                                                    <ConsultationHeader
                                                        consultationHeader={consultation}
                                                        key={consultation.consultationId}
                                                    />
                                                ))}
                                            <Row width={WIDTH_OF_CARD} alignmentHorizontal='center'>
                                                <Pagination
                                                    numberOfPages={numPages}
                                                    onChange={(page) =>
                                                        dispatch(setConsultationPageNumber({ page }))
                                                    }
                                                    currentPage={consultationPageNumber}
                                                />
                                            </Row>
                                        </Fragment>
                                    ) : (
                                        noSearchResults()
                                    )
                                ) : (
                                    <Loader />
                                )}
                            </Fragment>
                        )}
                    </Fragment>
                )}
            </Column>
        </Row>
    );
};

export default AdvancedSearch;
