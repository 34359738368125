export const labels = {
    addSim: 'Link SIM ticket',
    removeSim: 'Unlink ticket',
    flaggedWord: 'Flagged Word',
    ackUpdate: 'Acknowledge update',
    addUpdate: 'Add update',
    editUpdate: 'Edit update',
    createCase: 'Create case',
    quickLinks: 'Quick links',
    settings: 'Settings',
    userGuide: 'User guide',
    profile: `User's Admin Directory`,
    createConsultation: 'Create engagement',
    editStatus: 'Edit status',
    editAccessLevel: 'Edit access level',
    editAssignee: 'Edit assignee',
    addLP: 'Add LP contact',
    editLP: 'Edit LP contact',
    addPerson: 'Add person involved',
    linkCase: 'Link case',
    unlinkCase: 'Unlink case',
    linkConsultation: 'Link engagement',
    unlinkConsultation: 'Unlink engagement',
    editLoc: 'Edit level of concern',
    editLocNote: 'Edit level of concern note',
    removePerson: 'Remove person',
    editConsultation: 'Edit engagement',
    editCase: 'Edit Case',
    closeSheet: 'Close sheet'
};
