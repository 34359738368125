import Button from '@amzn/meridian/button';
import Column from '@amzn/meridian/column';
import Icon from '@amzn/meridian/icon';
import Link from '@amzn/meridian/link';
import Masthead, { MastheadTitle, MastheadMenuButton } from '@amzn/meridian/masthead';
import Popover, { PopoverHeader } from '@amzn/meridian/popover';
import Row from '@amzn/meridian/row';
import SearchField, { SearchSuggestion, SearchSuggestionFooter } from '@amzn/meridian/search-field';
import Text from '@amzn/meridian/text';
import cogIconTokens from '@amzn/meridian-tokens/base/icon/cog';
import exportSmallTokens from '@amzn/meridian-tokens/base/icon/export-small';
import helpIconTokens from '@amzn/meridian-tokens/base/icon/help';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import { useAppDispatch, RootState } from '../context/store';
import logo from '../images/MARSLogoDark.png';
import { CaseShort, ConsultationShort } from '../lib/CaseDefinitions';
import { ADMIN_DIRECTORY_LINK, PHOTO_URL } from '../lib/constants';
import { linkIconTokens } from '../lib/customIcons';
import { labels } from '../lib/labels';
import { setConsultationId } from '../slices/ConsultationsSlice';
import { getUserInfo, toggleNav } from '../slices/DashboardSlice';
import { setSelectOptions, setThreatManagers } from '../slices/SelectOptionsSlice';
import { useGetThreatManagersQuery } from '../slices/WotManApiSlice';
import { useGetUserContextQuery, useLazySearchCaseNameConsultationNameQuery } from '../slices/WotManApiSlice';
import TitledButton from './CustomComponents/TitledButton';
import LoadingInput from './Inputs/LoadingInput';
import NoSearchResults from './Inputs/NoSearchResults';

interface SearchResult {
    readonly id: number;
    readonly name: string;
    readonly type: string;
}

const Header: React.FunctionComponent = () => {
    const history = useHistory();
    const dispatch = useAppDispatch();
    const quickLinkRef = useRef();
    const [openPopover, setOpenPopover] = useState(false);
    const [query, setQuery] = useState('');
    const { userId, userLogin } = useSelector((state: RootState) => state.dashboard);
    const [simpleSearch, simpleSearchEndpoint] = useLazySearchCaseNameConsultationNameQuery({ query: query });
    const { data } = useGetUserContextQuery();
    const getThreatManagers = useGetThreatManagersQuery();
    const caseResult = (simpleSearchEndpoint.data && simpleSearchEndpoint.data.displayedCases) || [];
    const consultationResult =
        (simpleSearchEndpoint.data && simpleSearchEndpoint.data.displayedConsultations) || [];
    const suggestions: SearchResult[] = [];

    caseResult.forEach((incident: CaseShort) => {
        suggestions.push({ id: incident.caseId, name: incident.caseName, type: 'Case' });
    });

    consultationResult.forEach((consultation: ConsultationShort) => {
        suggestions.push({
            id: consultation.consultationId,
            name: consultation.consultationName,
            type: 'Consultation'
        });
    });

    useEffect(() => {
        if (userId == '' || userLogin == '') {
            dispatch(getUserInfo());
        }
    });

    useEffect(() => {
        if (data) {
            dispatch(setSelectOptions(data));
        }
    }, [data]);

    useEffect(() => {
        if (getThreatManagers.data) {
            dispatch(setThreatManagers(getThreatManagers.data));
        }
    }, [getThreatManagers.data]);

    useEffect(() => {
        if (query.length > 0) {
            simpleSearch({ query: query });
        }
    }, [query]);

    return (
        <Masthead>
            <MastheadMenuButton onClick={() => dispatch(toggleNav())} open={true} />
            <MastheadTitle onClick={() => history.push('/')}>
                <img src={logo} alt='MARS Logo' height='32' />
            </MastheadTitle>
            <SearchField
                value={query}
                onChange={setQuery}
                size='medium'
                onSubmit={() => ''} //No-op
                placeholder='Search...'
            >
                {query.length > 0 ? (
                    !simpleSearchEndpoint.isFetching && simpleSearchEndpoint.isSuccess ? (
                        caseResult.length > 0 || consultationResult.length > 0 ? (
                            suggestions.map((suggestion: SearchResult) => {
                                if (suggestion.type == 'Case') {
                                    return (
                                        <SearchSuggestion
                                            key={suggestion.id}
                                            onClick={() => {
                                                history.push(`/cases/${suggestion.id}`);
                                                setQuery('');
                                            }}
                                        >
                                            {suggestion.name}
                                        </SearchSuggestion>
                                    );
                                } else {
                                    return (
                                        <SearchSuggestion
                                            key={suggestion.id}
                                            onClick={() => {
                                                dispatch(setConsultationId(suggestion.id));
                                                setQuery('');
                                            }}
                                        >
                                            {suggestion.name}
                                        </SearchSuggestion>
                                    );
                                }
                            })
                        ) : (
                            <NoSearchResults />
                        )
                    ) : (
                        <LoadingInput />
                    )
                ) : (
                    <Column alignmentVertical='center' spacing='300' spacingInset='600 none'>
                        <Text alignment='center'>Start typing case id or engagement title...</Text>
                    </Column>
                )}
                <SearchSuggestionFooter>
                    <Row alignmentHorizontal='center'>
                        <Button
                            minWidth='240px'
                            type='tertiary'
                            onClick={() => {
                                history.push('/search#cases');
                                setQuery('');
                            }}
                        >
                            Advanced search
                        </Button>
                    </Row>
                </SearchSuggestionFooter>
            </SearchField>
            <Column>
                <TitledButton
                    title={labels.quickLinks}
                    buttonProps={{
                        ref: quickLinkRef,
                        onClick: () => setOpenPopover(true),
                        type: 'icon',
                        size: 'small',
                        className: 'IconPadding'
                    }}
                    iconProps={{ tokens: linkIconTokens }}
                />
                <Popover
                    anchorNode={quickLinkRef.current}
                    open={openPopover}
                    onClose={() => setOpenPopover(false)}
                    position='bottom'
                >
                    <PopoverHeader closeLabel='Close'>
                        Quick links <Icon tokens={exportSmallTokens} />
                    </PopoverHeader>
                    <Column width='260px' spacing='xsmall'>
                        <Link href={ADMIN_DIRECTORY_LINK} target='_blank' rel='noopener noreferrer'>
                            Admin Directory
                        </Link>
                        <Link
                            href='https://case.corp.amazon.com/D3VSOC/thank.aspx?t2=ca2f75f0a9f262fc16d1c58e4deaf3f5ca5a240bbb60ca9dd9983b70375d7b57'
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            D3 Global Case Management
                        </Link>
                        <Link href='https://app.emt.amazon.dev' target='_blank' rel='noopener noreferrer'>
                            Event Management Tool
                        </Link>
                        <Link
                            href='https://facman.guardian.amazon.dev/'
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            Facility Manager
                        </Link>
                        <Link
                            href='https://fcmap.topology.a2z.com/'
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            FC Locator Map
                        </Link>
                        <Link
                            href='https://policy.amazon.com/tag/gso'
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            GSO Policy
                        </Link>
                        <Link href='https://river.amazon.com/' target='_blank' rel='noopener noreferrer'>
                            IT Issues
                        </Link>
                        <Link
                            href='https://lossprevention.amazon.com/'
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            Loss Prevention Portal
                        </Link>
                        <Link
                            href='https://oncall.corp.amazon.com/#/view/wim-threat_managers/schedule'
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            On Call Roster
                        </Link>
                        <Link
                            href='https://sim.amazon.com/issues/create?template=f361c181-86d1-41f9-b71b-5101138d673e'
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            OSINT SIM Template
                        </Link>
                        <Link href='https://phonetool.amazon.com/' target='_blank' rel='noopener noreferrer'>
                            Phone Tool
                        </Link>
                        <Link
                            href='https://quip-amazon.com/aNx2OwbT5NX8/New-REPS-Tracking-Folder'
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            REPS Tracking
                        </Link>
                        <Link
                            href='https://rightcrowd-amer.amazon.com/RightCrowd/'
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            RightCrowd
                        </Link>
                        <Link
                            href='https://w.amazon.com/index.php/Main_Page'
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            Wiki
                        </Link>
                        <Link
                            href='https://drive.corp.amazon.com/folders/WIM%20Team'
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            WIM Tool Kit
                        </Link>
                    </Column>
                </Popover>
            </Column>
            <TitledButton
                title={labels.settings}
                buttonProps={{
                    onClick: () => history.push('/settings'),
                    type: 'icon',
                    size: 'small'
                }}
                iconProps={{ tokens: cogIconTokens }}
            />
            <TitledButton
                title={labels.userGuide}
                buttonProps={{
                    type: 'icon',
                    size: 'small',
                    href: 'https://w.amazon.com/bin/view/CIOPS/Digital_Solutions/MARS/UserGuide/',
                    target: '_blank'
                }}
                iconProps={{ tokens: helpIconTokens }}
            />
            <span title={labels.profile}>
                <Button
                    type='link'
                    href={`${ADMIN_DIRECTORY_LINK}${userLogin}`}
                    target='_blank'
                    className='PhonetoolButton'
                >
                    <img
                        className='RoundedImage'
                        src={`${PHOTO_URL}${userId}`}
                        alt={`${userLogin}'s Badge Photo`}
                        width='40'
                    />
                </Button>
            </span>
        </Masthead>
    );
};
export default withRouter(Header);
