import Alert from '@amzn/meridian/alert';
import Column from '@amzn/meridian/column';
import Row from '@amzn/meridian/row';
import Select, { SelectOption } from '@amzn/meridian/select';
import closeTokens from '@amzn/meridian-tokens/base/icon/close';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../context/store';
import { CreatePersonInvolved, PersonInvolvedRole } from '../../../lib/CaseDefinitions';
import { labels } from '../../../lib/labels';
import { closeDuplicateContact } from '../../../slices/CaseDetailsSlice';
import TitledButton from '../../CustomComponents/TitledButton';
import AddPersonInput from '../../Inputs/AddPersonInput';

type CaseColumnProps = {
    personAdding: CreatePersonInvolved;
    isPersonInvolved?: boolean;
    setCaseVars: (editPersonInvolvedContact: CreatePersonInvolved) => void;
    removePerson?: () => void;
    requiredRole?: string;
    missingName: boolean;
    missingRole?: boolean;
    isDisabledInput?: boolean;
    nameIsEmptySpaces: boolean;
    userRestrictedCreateCase?: boolean;
};

const AddPersonsInvolved: React.FunctionComponent<CaseColumnProps> = ({
    personAdding,
    isPersonInvolved,
    setCaseVars,
    removePerson,
    requiredRole,
    missingName,
    missingRole = false,
    nameIsEmptySpaces,
    userRestrictedCreateCase
}: CaseColumnProps) => {
    const { isDuplicateContact } = useSelector((state: RootState) => state.caseDetails);
    const dispatch = useAppDispatch();

    return (
        <Column>
            <Row spacing='small'>
                <Select
                    value={requiredRole ? requiredRole : personAdding.role}
                    onChange={(role) => {
                        setCaseVars({
                            ...personAdding,
                            role,
                            isDirty: true
                        });
                    }}
                    label='Role *'
                    width='232px'
                    error={missingRole}
                    disabled={!!requiredRole}
                >
                    {requiredRole ? (
                        <SelectOption label={requiredRole} value={requiredRole} />
                    ) : (
                        Object.values(PersonInvolvedRole).map((value) => {
                            return <SelectOption value={value} label={value} key={value} />;
                        })
                    )}
                </Select>
                {removePerson && (
                    <TitledButton
                        title={labels.removePerson}
                        buttonProps={{
                            onClick: removePerson,
                            type: 'secondary',
                            size: 'small'
                        }}
                        iconProps={{ tokens: closeTokens }}
                    />
                )}
            </Row>
            <AddPersonInput
                duplicate={isDuplicateContact}
                isPersonInvolved={
                    personAdding.role === PersonInvolvedRole.VICTIM ||
                    personAdding.role === PersonInvolvedRole.WITNESS ||
                    personAdding.role === PersonInvolvedRole.PERSON_OF_INTEREST
                }
                duplicateMessage={'This person is already involved in this case'}
                missingName={missingName}
                personAdding={personAdding}
                isVertical={true}
                setVars={setCaseVars}
                additionalClear={() => dispatch(closeDuplicateContact())}
                nameIsEmptySpaces={nameIsEmptySpaces}
                userRestricted={userRestrictedCreateCase || isPersonInvolved}
            >
                {missingRole ? (
                    <Alert type='error' size='small'>
                        Role is required
                    </Alert>
                ) : (
                    undefined
                )}
            </AddPersonInput>
        </Column>
    );
};

export default AddPersonsInvolved;
