import Column from '@amzn/meridian/column';
import Link from '@amzn/meridian/link';
import Row from '@amzn/meridian/row';
import Text from '@amzn/meridian/text';
import React from 'react';
import logo from '../images/MARSLogoDark.png';

//TODO : Add link for report a bug

const Footer: React.FunctionComponent = () => {
    return (
        <Text tag='span' type='b200'>
            <Row
                alignmentHorizontal='center'
                alignmentVertical='top'
                spacing='xlarge'
                spacingInset='medium'
                className='Footer'
            >
                <Column spacing='100'>
                    <div>
                        <img src={logo} alt='MARS Logo' height='24' />
                    </div>
                    <div>Mitigate Violence and Assault by Reviewing Signals</div>
                    <div>Workplace Incident Management</div>
                </Column>
                <Column spacing='100'>
                    <div>
                        <strong> SIM Support </strong>
                    </div>
                    <div>
                        <Link
                            href={
                                'https://t.corp.amazon.com/create/templates/7ed05c96-c30a-47ba-adcc-f81123eb4b39'
                            }
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            General product support
                        </Link>
                    </div>
                    <div>
                        <Link
                            href={
                                'https://t.corp.amazon.com/create/templates/510e52a6-640f-448e-8dbf-7ade4e8ad47b'
                            }
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            Report a bug
                        </Link>
                    </div>
                    <div>
                        <Link
                            href={
                                'https://t.corp.amazon.com/create/templates/5a1db773-9301-47b0-9b7a-f1e26ed94cf2'
                            }
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            Enhancement request
                        </Link>
                    </div>
                </Column>
                <Column spacing='100'>
                    <div>
                        <Link
                            href={'https://w.amazon.com/bin/view/CIOPS/Digital_Solutions/MARS/'}
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            MARS Wiki
                        </Link>{' '}
                        |{' '}
                        <Link
                            href={'https://broadcast.amazon.com/channels/32407'}
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            Broadcast
                        </Link>
                    </div>
                    <div>
                        <Link
                            href={'https://w.amazon.com/bin/view/New_Technologies_for_Security_%28NT4S%29/'}
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            SecOps Software
                        </Link>
                    </div>
                    <div>2022</div>
                </Column>
            </Row>
        </Text>
    );
};

export default Footer;
