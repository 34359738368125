import Button from '@amzn/meridian/button';
import Checkbox from '@amzn/meridian/checkbox';
import Column from '@amzn/meridian/column';
import Row from '@amzn/meridian/row';
import Text from '@amzn/meridian/text';
import Textarea from '@amzn/meridian/textarea';
import _ from 'lodash';
import React, { useEffect, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../context/store';
import { Recommendation } from '../../../lib/CaseDefinitions';
import { CHARACTER_LIMIT } from '../../../lib/constants';
import { setCaseDetails } from '../../../slices/CaseDetailsSlice';
import { addToast } from '../../../slices/ToastSlice';
import { useLazyEditRecommendationsQuery } from '../../../slices/WotManApiSlice';

type RecommendationProps = {
    setShowRecommendationsAlertPrompt: (showReccomendationAlertPrompt: boolean) => void;
    tempRecc: Recommendation[];
    setTempRecc: (tempRecc: Recommendation[]) => void;
};

const RecommendationsTab: React.FunctionComponent<RecommendationProps> = ({
    setShowRecommendationsAlertPrompt,
    tempRecc,
    setTempRecc
}: RecommendationProps) => {
    const { caseDetails, isCaseOpen } = useSelector((state: RootState) => state.caseDetails);
    const dispatch = useAppDispatch();

    const [editRecommendations, editRecommendationsEndpoint] = useLazyEditRecommendationsQuery();

    const setTempReccNotFetching = (recc: Recommendation[]) => {
        if (!editRecommendationsEndpoint.isFetching) setTempRecc(recc);
    };

    useEffect(() => {
        if (!editRecommendationsEndpoint.isFetching && editRecommendationsEndpoint.isSuccess) {
            dispatch(setCaseDetails(editRecommendationsEndpoint.data));
            dispatch(
                addToast({
                    type: 'success',
                    action: 'Saved recommendations ',
                    message: `for ${caseDetails.caseName}`,
                    SIM: false,
                    timeout: 3000
                })
            );
        } else if (!editRecommendationsEndpoint.isFetching && editRecommendationsEndpoint.isError) {
            dispatch(
                addToast({
                    type: 'error',
                    action: 'There was an error saving recommendations ',
                    message: `for ${caseDetails.caseName}`,
                    SIM: true,
                    timeout: undefined
                })
            );
        }
    }, [editRecommendationsEndpoint.isFetching]);

    const halfIndex = Math.ceil(caseDetails.recommendations.length / 2);

    const sliceReccs = (startIndex: number, endIndex: number) => {
        return caseDetails.recommendations.slice(startIndex, endIndex).map((recc, index) => {
            const one = tempRecc.find((e) => e.id === recc.id);
            if (!one) {
                // eslint-disable-next-line react/jsx-key
                return <Fragment> </Fragment>;
            }
            const checked = one.note != null;
            return (
                <Fragment key={index}>
                    <Checkbox
                        checked={checked}
                        onChange={() =>
                            setTempReccNotFetching(
                                tempRecc.map((r) =>
                                    r.id === recc.id
                                        ? checked
                                            ? { ...r, note: null }
                                            : { ...r, note: r.default }
                                        : r
                                )
                            )
                        }
                        disabled={!isCaseOpen}
                    >
                        <Text type='b300'>
                            {' '}
                            {startIndex + index + 1}. {recc.title}{' '}
                        </Text>
                    </Checkbox>
                    {checked && (
                        <span className='TextBoxWrapper'>
                            <Textarea
                                value={one.note || ''}
                                onChange={(note) =>
                                    setTempReccNotFetching(
                                        tempRecc.map((r) => (recc.id === r.id ? { ...r, note } : r))
                                    )
                                }
                                error={one.note ? one.note.length > CHARACTER_LIMIT.NOTE_TEXT_LIMIT : false}
                            />
                        </span>
                    )}
                </Fragment>
            );
        });
    };

    return (
        <Column spacingInset='medium' width='700px'>
            <Row alignmentVertical='top'>
                <Column width='350px'>{sliceReccs(0, halfIndex)}</Column>
                <Column width='350px'>{sliceReccs(halfIndex, caseDetails.recommendations.length)}</Column>
            </Row>
            {isCaseOpen && (
                <Row alignmentHorizontal='right'>
                    {!_.isEqual(tempRecc, caseDetails.recommendations) &&
                        !editRecommendationsEndpoint.isFetching && (
                            <Button
                                type='secondary'
                                onClick={() => setTempReccNotFetching(caseDetails.recommendations)}
                            >
                                Cancel
                            </Button>
                        )}
                    <Button
                        disabled={
                            _.isEqual(tempRecc, caseDetails.recommendations) ||
                            editRecommendationsEndpoint.isFetching
                        }
                        onClick={() => {
                            const recommendations = tempRecc
                                .filter((recc) => recc.note !== null)
                                .map((recc) => {
                                    return { id: recc.id, note: recc.note };
                                });
                            if (
                                recommendations.some((recc) => {
                                    return recc.note && recc.note.length > CHARACTER_LIMIT.NOTE_TEXT_LIMIT;
                                })
                            ) {
                                dispatch(
                                    addToast({
                                        type: 'error',
                                        action: 'One or more invalid form fields. ',
                                        message: 'Correct any errors and submit again',
                                        SIM: false,
                                        timeout: 3000
                                    })
                                );
                                setShowRecommendationsAlertPrompt(false);
                            } else {
                                editRecommendations({ incidentId: caseDetails.caseId, recommendations });
                                setShowRecommendationsAlertPrompt(false);
                            }
                        }}
                    >
                        Save
                    </Button>
                </Row>
            )}
        </Column>
    );
};

export default RecommendationsTab;
