import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Toast } from '../lib/CaseDefinitions';

interface TemplateToastState {
    toasts: Toast[];
    id: number;
}

const initialState: TemplateToastState = {
    toasts: [],
    id: 0
};

const toastSlice = createSlice({
    name: 'toast',
    initialState,
    reducers: {
        closeToast: (state: TemplateToastState, { payload }: PayloadAction<string>) => {
            state.toasts = state.toasts.filter((t) => t.id !== payload);
        },
        addToast: (
            state: TemplateToastState,
            {
                payload
            }: PayloadAction<{
                type: string;
                action: string;
                message: string;
                SIM: boolean;
                timeout: number | undefined;
            }>
        ) => {
            const newToast: Toast = {
                SIM: payload.SIM,
                action: payload.action,
                id: state.id + payload.action,
                message: payload.message,
                timeout: payload.timeout,
                type: payload.type
            };
            state.toasts = [...state.toasts, newToast];
            state.id = state.id + 1;
        }
    }
});

export const { closeToast, addToast } = toastSlice.actions;

export default toastSlice.reducer;
