import { EventItems, EventType } from './EmtCTIDefinitions';
import { ThreatManager } from './ThreatManagerDefinitions';
import { SimStatusTypes, Update } from './UpdateDefinitions';

export enum LevelOfConcern {
    LOW = 1,
    MODERATE = 2,
    HIGH = 3,
    CRITICAL = 4
}

export enum StatusType {
    OPEN = 'Open',
    CLOSED = 'Closed',
    NO_ACTION = 'No Action'
}

export enum Region {
    AMER = 'AMER',
    EMEA = 'EMEA',
    APAC = 'APAC'
}

export enum PersonInvolvedType {
    INTERNAL_PERSON = 'InternalPersonInvolved',
    EXTERNAL_PERSON = 'ExternalPersonInvolved',
    INTERNAL_CONTACT = 'InternalContact',
    EXTERNAL_CONTACT = 'ExternalContact',
    FACMAN_CONTACT = 'FacmanContact'
}

export interface EventFocusArea {
    readonly isLifeFocused: boolean;
    readonly isPropertyFocused: boolean;
    readonly isBrandFocused: boolean;
}

export interface SiteIdAndCode {
    siteCode: string;
    siteId: string;
}

export interface IncidentIdAndName {
    incidentName: string;
    incidentId: number;
}

export interface Toast {
    id: string;
    type: string;
    timeout?: number;
    action: string;
    message: string;
    SIM: boolean;
}

export interface AbbreviatedFacility {
    readonly siteName: string;
    readonly country: string;
    readonly region?: Region;
}

export interface Case {
    readonly caseId: number;
    readonly caseName: string;
    readonly emtCategory: string;
    readonly emtType: EventType;
    readonly emtItem: EventItems;
    readonly loc?: LevelOfConcern;
    readonly locNote?: string;
    readonly emtSev?: number;
    readonly status: StatusType;
    readonly location: AbbreviatedFacility;
    readonly updateBy?: string;
    readonly updateCount: number;
    readonly flaggedWords: string[];
    readonly totalFlaggedWords: string[];
    readonly newFlaggedWords: string[];
    readonly lastModificationTime: string;
    readonly latestTimeUpdate: string;
    readonly incidentTime: string;
    readonly incidentDate: string;
    readonly inputTimeZone: string;
    readonly isBookmarked: boolean;
    readonly assignee: string;
    readonly isRestricted: boolean;
    readonly userRestrictedCases: boolean;
    readonly restrictedCases: boolean;
    readonly lpPersonId?: PAPILong | null;
    readonly proximity: Proximity;
    readonly primaryLocation: AbbreviatedFacility[];
    readonly secondaryLocation: AbbreviatedFacility[];
}

export interface EmployeeShort {
    name: string;
    login: string;
}

export interface EmployeeLong extends EmployeeShort {
    readonly title: string;
    readonly phoneNumber: string;
    readonly email: string;
    readonly personId: string;
}

export interface CommunicatedWith extends EmployeeLong {
    readonly id?: string;
    readonly disabled?: boolean;
    readonly isDirty?: boolean;
}

export interface CreatePersonInvolved extends CommunicatedWith {
    readonly role?: string;
    readonly contactId?: string;
}

export enum PersonInvolvedRole {
    PERSON_OF_INTEREST = 'Person of interest',
    VICTIM = 'Victim',
    WITNESS = 'Witness',
    CONTACT = 'Contact'
}

enum OtherRoles {
    REPORTED_BY = 'Reported by',
    LP_CONTACT = 'LP contact'
}

export const PersonsInvolvedAndOtherRoles = { ...OtherRoles, ...PersonInvolvedRole };

export type PersonInvolvedDisplaying =
    | InternalPersonInvolved
    | ExternalPersonInvolved
    | InternalContact
    | ExternalContact
    | FacmanContact;

export interface PersonInvolved {
    readonly role: string;
    readonly name: string;
    readonly login?: string;
    readonly title?: string;
    readonly phoneNumber: string;
    readonly email: string;
    readonly personId?: string;
    readonly employeeId?: string;
    readonly contactId?: number;
    readonly addedBy?: AddedByPersonInvolved;
    readonly status?: string;
    readonly futureTerminationDate?: string;
    readonly tenure?: string;
    readonly address?: string;
    readonly legalName?: string;
    readonly workEmail?: string;
    readonly personalEmail?: string;
    readonly workPhone?: string;
    readonly jobLevel?: string;
    readonly isDirty?: boolean;
    readonly terminationReason?: string;
}

export interface AddedByPersonInvolved {
    readonly id: string;
    readonly name: string;
    readonly personId: string;
    readonly canEdit?: boolean;
}

export interface InternalPersonInvolved extends PersonInvolved {
    readonly type: PersonInvolvedType.INTERNAL_PERSON;
}

export interface ExternalPersonInvolved extends PersonInvolved {
    readonly type: PersonInvolvedType.EXTERNAL_PERSON;
}

export interface InternalContact extends PersonInvolved {
    readonly type: PersonInvolvedType.INTERNAL_CONTACT;
}

export interface ExternalContact extends PersonInvolved {
    readonly type: PersonInvolvedType.EXTERNAL_CONTACT;
}

export interface FacmanContact extends PersonInvolved {
    readonly teamName: string;
    readonly notes: string;
    readonly type: PersonInvolvedType.FACMAN_CONTACT;
}

export interface ActionItems {
    readonly title: string;
    readonly description: string;
    readonly status: string;
    readonly primaryAssignee: EmployeeLong;
    readonly secondaryAssignee: EmployeeLong[];
}

export interface Recommendation {
    readonly id: string;
    readonly title: string;
    readonly note: string | null;
    readonly default: string;
}

export interface PlaceInvolved {
    readonly type: string;
    readonly name: string;
    readonly address: string;
    readonly email?: string;
    readonly phoneNumber: string;
    readonly notes?: string;
}

export interface SimTicket {
    readonly id: string;
    readonly title: string;
    readonly ticketId: string;
    readonly status: SimStatusTypes;
    readonly monitoringEndDate: string;
}

export enum Proximity {
    ONSITE = 'Onsite',
    OFFSITE = 'Offsite'
}

export enum EntryType {
    INCIDENT_NOTIFICATION = 'Incident Notification',
    INTERNAL_DOCUMENTATION = 'Internal Documentation',
    UPDATE_NOTIFICATION = 'Update Notification'
}

export interface Assignee {
    empId: string;
    firstName: string;
    lastName: string;
}

export interface MARSCase extends Case {
    eventTags: string[];
    reportTime: string;
    incidentLocation?: Location;
    primaryFacility: Facility[];
    secondaryFacilityList: Facility[];
    eventSummary: string;
    assignedTo?: Assignee;
    suggestedAssignees: Assignee[];
    recommendations: Recommendation[];
    personsInvolved: PersonInvolvedDisplaying[];
    reportedBy: PersonInvolvedDisplaying;
    linkedCases: LinkedCase;
    linkedConsultations: Consultation[];
    linkedTickets: SimTicket[];
    locHistory: { x: number; y: LevelOfConcern }[];
    updates: Update[];
    canEdit: boolean;
    timeZone?: string;
}

export interface LinkedCase {
    cases: Case[];
    restrictedCases: Case[];
    userRestrictedCases: Case[];
}

export interface EMTCase extends MARSCase {
    eventFocusArea: EventFocusArea;
    riskAssessment: string;
    privileged: boolean;
    proximity: Proximity;
    nextSteps: string;
    actionItems: ActionItems;
}

export enum CommunicationMethod {
    EMAIL = 'Email',
    PHONE = 'Phone',
    IN_PERSON = 'In person',
    CHAT = 'Chat',
    SEV_CALL = 'SEV call'
}

export interface ConsultationAdded {
    readonly firstName: string;
    readonly lastName: string;
    readonly timeAdded: string;
}

export interface Consultation {
    readonly name: string;
    readonly method: CommunicationMethod;
    readonly added: ConsultationAdded;
    readonly communicatedWith: string[];
    readonly consultationId: number;
    readonly linkedCases: IncidentIdAndName[];
    readonly primaryLocation: AbbreviatedFacility[];
    readonly secondaryLocation: AbbreviatedFacility[];
    readonly location: AbbreviatedFacility;
    proximity: Proximity;
}

export interface Location {
    readonly address: string;
    readonly city: string;
    readonly state: string;
    readonly country?: string;
    readonly zipcode: string;
    readonly region?: Region;
}

export interface OffSiteLocationAdding {
    readonly address: string;
    readonly city: string;
    readonly state: string;
    readonly country?: string;
    readonly zipcode: string;
    readonly region?: Region;
    readonly associatedSite?: SiteIdAndCode;
}

export interface Facility extends Location {
    readonly siteCode: string;
    readonly siteId: string;
    readonly contentSectionId?: number;
}

export interface ConsultationDetails {
    readonly consultationId: number;
    readonly eventTime: string;
    readonly eventDate: string;
    readonly proximity: Proximity;
    readonly primaryFacility: Facility[];
    readonly secondaryFacilityList: Facility[];
    readonly offsiteLocation?: OffSiteLocationAdding;
    readonly communicatedWith: CommunicatedWith[];
    readonly method: CommunicationMethod;
    readonly title: string;
    readonly summary: string;
    readonly linkedCases: IncidentIdAndName[];
    readonly canEdit: boolean;
    readonly timeZone: string;
}

export interface EditCaseVars {
    readonly eventCategory: string;
    readonly eventType: EventType | '';
    readonly eventItem: EventItems | '';
    readonly additionalTags: string[];
    readonly dateOfEvent: string;
    readonly timeOfEvent: string;
    readonly eventSummary: string;
    readonly missingFields: string[];
    readonly isDirty: boolean;
    readonly timezone: string;
}

export interface CreateCaseVars extends EditCaseVars {
    readonly personsInvolved: CreatePersonInvolved[];
    readonly reportedBy: CreatePersonInvolved;
    readonly assignedToEmpId: string;
    readonly levelOfConcern?: string;
    readonly levelOfConcernNote: string;
}

export interface CreateConsultationVars {
    readonly dateOfEvent: string;
    readonly timeOfEvent: string;
    readonly timezone: string;
    readonly communicatedWith: CommunicatedWith[];
    readonly method: string;
    readonly consultationTitle: string;
    readonly consultationSummary: string;
    readonly missingFields: string[];
    readonly isDirty: boolean;
}

export interface PAPIShort {
    readonly personId: string;
    readonly lastName: string;
    readonly firstName: string;
    readonly fullName: string;
    readonly login: string;
    readonly jobTitle: string;
}

export interface PAPILong extends PAPIShort {
    readonly email: string;
    readonly phone: string;
}

export interface CaseShort {
    readonly caseId: number;
    readonly caseName: string;
}

export interface ConsultationShort {
    readonly consultationId: number;
    readonly consultationName: string;
}

export enum EmployeeJobStatusType {
    Active = 'A',
    Inactive = 'I',
    Terminated = 'T',
    Deceased = 'D',
    Leave = 'L',
    LeaveWithPay = 'P',
    RetiredWithPay = 'Q',
    Retired = 'R',
    Suspended = 'S',
    TermWithPension = 'U',
    TermWithoutPension = 'V',
    ShortWorkBreak = 'W',
    RetiredPensionAdministration = 'X'
}

export interface ThreatManagerTypes {
    admins: ThreatManager[];
    users: ThreatManager[];
}

export interface SelectOptionsContext {
    flaggedWords: string[];
    recommendations: string[];
    businessLines: string[];
    countries: string[];
    subCategories: string[];
    consultationsAddedBy: Assignee[];
    assignees: Assignee[];
}

export interface SIMTicket {
    readonly title: string;
    readonly id: string;
}
