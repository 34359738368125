import Alert from '@amzn/meridian/alert';
import Button from '@amzn/meridian/button';
import Column from '@amzn/meridian/column';
import Divider from '@amzn/meridian/divider';
import Expander from '@amzn/meridian/expander';
import Icon from '@amzn/meridian/icon';
import Modal, { ModalFooter } from '@amzn/meridian/modal';
import Row from '@amzn/meridian/row';
import SearchField, { SearchSuggestion, SearchSuggestionHeader } from '@amzn/meridian/search-field';
import Text from '@amzn/meridian/text';
import Tile from '@amzn/meridian/tile';
import closeTokens from '@amzn/meridian-tokens/base/icon/close';
import exportSmallTokens from '@amzn/meridian-tokens/base/icon/export-small';
import plusTokens from '@amzn/meridian-tokens/base/icon/plus';
import { DateTime } from 'luxon';
import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../../context/store';
import { SimTicket, SIMTicket } from '../../../../lib/CaseDefinitions';
import { WIM_SIM_TEMPLATE } from '../../../../lib/constants';
import { renderSimTicketTitle } from '../../../../lib/helpers';
import { labels } from '../../../../lib/labels';
import { SimStatusTypes } from '../../../../lib/UpdateDefinitions';
import { setCaseDetails } from '../../../../slices/CaseDetailsSlice';
import { addToast } from '../../../../slices/ToastSlice';
import { useLazyLinkCaseSIMQuery, useLazySearchSIMsQuery } from '../../../../slices/WotManApiSlice';
import TitledButton from '../../../CustomComponents/TitledButton';
import LoadingInput from '../../../Inputs/LoadingInput';
import NoSearchResults from '../../../Inputs/NoSearchResults';

const SIMWidget: React.FunctionComponent = () => {
    const { caseDetails, isStacked, isCaseOpen } = useSelector((state: RootState) => state.caseDetails);
    const [openTicketModal, setOpenTicketModal] = useState(false);
    const [openUnlinkModal, setOpenUnlinkModal] = useState(false);
    const [isSimExpanded, setISimExpanded] = useState(false);
    const [displaySimValidation, setDisplaySimValidation] = useState(false);
    const [simTicketSearch, setSimTicketSearch] = useState('');
    const [simTicketUnlinking, setSimTicketUnlinking] = useState({
        id: '',
        ticketId: '',
        title: ''
    });
    const [simTicketLinking, setSimTicketLinking] = useState('');
    const [useSearchSIMs, useSearchSIMsEndpoint] = useLazySearchSIMsQuery({ prefix: simTicketSearch });
    const [useLinkSIM, useLinkSIMEndpoint] = useLazyLinkCaseSIMQuery();
    const [useUnlinkSIM, useUnlinkSIMEndpoint] = useLazyLinkCaseSIMQuery();
    const sims = useSearchSIMsEndpoint.data;
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (simTicketSearch.length > 0) {
            useSearchSIMs({ prefix: simTicketSearch });
        }
    }, [simTicketSearch]);

    const handleCancelSIM = () => {
        setSimTicketSearch('');
        setSimTicketLinking('');
        setOpenTicketModal(false);
        setDisplaySimValidation(false);
    };

    useEffect(() => {
        if (!useLinkSIMEndpoint.isFetching) {
            if (useLinkSIMEndpoint.isSuccess) {
                dispatch(
                    addToast({
                        type: 'success',
                        action: 'Linked SIM ticket ',
                        message: '',
                        SIM: false,
                        timeout: 3000
                    })
                );
                dispatch(setCaseDetails(useLinkSIMEndpoint.data));
                handleCancelSIM();
            } else if (useLinkSIMEndpoint.error?.data?.error) {
                setDisplaySimValidation(true);
            } else if (useLinkSIMEndpoint.isError) {
                dispatch(
                    addToast({
                        type: 'error',
                        action: 'There was an error linking SIM ticket ',
                        message: '',
                        SIM: true,
                        timeout: undefined
                    })
                );
            }
        }
    }, [useLinkSIMEndpoint.isFetching]);

    useEffect(() => {
        if (!useUnlinkSIMEndpoint.isFetching && useUnlinkSIMEndpoint.isSuccess) {
            dispatch(
                addToast({
                    type: 'success',
                    action: 'Unlinked SIM ticket ',
                    message: '',
                    SIM: false,
                    timeout: 3000
                })
            );
            dispatch(setCaseDetails(useUnlinkSIMEndpoint.data));
            setOpenUnlinkModal(false);
        } else if (!useUnlinkSIMEndpoint.isFetching && useUnlinkSIMEndpoint.isError) {
            dispatch(
                addToast({
                    type: 'error',
                    action: 'There was an error unlinking SIM ticket ',
                    message: '',
                    SIM: true,
                    timeout: undefined
                })
            );
        }
    }, [useUnlinkSIMEndpoint.isFetching]);

    const handleSIMClear = () => {
        setSimTicketSearch('');
    };

    const handleLinkSIM = () => {
        if (simTicketLinking.length > 0) {
            useLinkSIM({
                caseId: caseDetails.caseId,
                action: 'link',
                simId: simTicketLinking
            });
        }
    };

    const simWidget = (ticket: SimTicket, includeDivider: boolean) => (
        <Fragment key={ticket.id}>
            {includeDivider && <Divider size='small' />}
            <Column spacing={'300'}>
                <Row alignmentVertical='top' alignmentHorizontal='justify'>
                    <Column>
                        <Text type={'b300'}>{renderSimTicketTitle(ticket.ticketId, ticket.title)} </Text>
                    </Column>
                    <Column>
                        {isCaseOpen && (
                            <TitledButton
                                title={labels.removeSim}
                                buttonProps={{
                                    onClick: () => {
                                        setOpenUnlinkModal(true);
                                        setSimTicketUnlinking(ticket);
                                    },
                                    type: 'secondary',
                                    size: 'small'
                                }}
                                iconProps={{ tokens: closeTokens }}
                            />
                        )}
                    </Column>
                </Row>
                <Row alignmentVertical='top' alignmentHorizontal='justify'>
                    <Column spacing='none'>
                        <Text type='h100'>Status</Text>
                        <Text type='b200'>{ticket.status}</Text>
                    </Column>
                    <Column spacing='none'>
                        <Text type='h100'>Monitoring end date</Text>
                        <Text type='b200'>
                            {DateTime.fromISO(ticket.monitoringEndDate).toLocaleString(DateTime.DATE_MED)}
                        </Text>
                    </Column>
                </Row>
            </Column>
        </Fragment>
    );

    return (
        <Tile spacingInset='400'>
            <Modal
                width='397px'
                open={openUnlinkModal}
                onClose={setOpenUnlinkModal}
                title='Unlink SIM ticket'
            >
                <Text>
                    Unlink SIM ticket <strong>{simTicketUnlinking.title}</strong> from{' '}
                    <strong>{caseDetails.caseName}</strong>? All previous updates from this ticket will be
                    removed from this case.
                </Text>
                <Row height='20px' />
                <ModalFooter>
                    <Row alignmentHorizontal='right'>
                        <Button
                            type='secondary'
                            onClick={() => setOpenUnlinkModal(false)}
                            disabled={useUnlinkSIMEndpoint.isFetching}
                        >
                            Cancel
                        </Button>
                        <Button
                            disabled={useUnlinkSIMEndpoint.isFetching}
                            onClick={() => {
                                useUnlinkSIM({
                                    caseId: caseDetails.caseId,
                                    simId: simTicketUnlinking.ticketId,
                                    action: 'unlink',
                                    simIncidentId: simTicketUnlinking.id
                                });
                            }}
                        >
                            Unlink
                        </Button>
                    </Row>
                </ModalFooter>
            </Modal>
            <Column>
                <Row alignmentVertical='top' alignmentHorizontal='justify'>
                    <Text type='h200'> SIM ticketing </Text>
                    {isCaseOpen && (
                        <TitledButton
                            title={labels.addSim}
                            buttonProps={{
                                onClick: () => setOpenTicketModal(true),
                                type: 'primary',
                                size: 'medium'
                            }}
                            iconProps={{ tokens: plusTokens }}
                        />
                    )}
                    <Modal
                        width='560px'
                        title='Link SIM ticket'
                        onClose={handleCancelSIM}
                        open={openTicketModal}
                    >
                        <Column>
                            <Row>
                                <Button
                                    type='tertiary'
                                    href={WIM_SIM_TEMPLATE}
                                    target='_blank'
                                    rel='noopener noreferrer'
                                >
                                    Create ticket
                                    <Icon tokens={exportSmallTokens} />
                                </Button>
                            </Row>
                            <Text type='h100'> Or link existing ticket </Text>
                            <Column spacing={'200'} width='360px'>
                                <SearchField
                                    value={simTicketSearch}
                                    onChange={setSimTicketSearch}
                                    onSubmit={() => setSimTicketSearch('')}
                                    placeholder='Search for SIM ticket...'
                                    clearButton={true}
                                    onClear={handleSIMClear}
                                >
                                    <SearchSuggestionHeader>Suggested SIM tickets</SearchSuggestionHeader>
                                    {simTicketSearch.length > 0 ? (
                                        !useSearchSIMsEndpoint.isFetching &&
                                        useSearchSIMsEndpoint.isSuccess ? (
                                            sims.length > 0 ? (
                                                sims.map((sim: SIMTicket) => {
                                                    return (
                                                        <SearchSuggestion
                                                            key={sim.id}
                                                            onClick={() => {
                                                                setSimTicketSearch(sim.title);
                                                                setSimTicketLinking(sim.id);
                                                            }}
                                                        >
                                                            {sim.title}
                                                        </SearchSuggestion>
                                                    );
                                                })
                                            ) : (
                                                <NoSearchResults />
                                            )
                                        ) : (
                                            <LoadingInput />
                                        )
                                    ) : (
                                        <Column
                                            alignmentVertical='center'
                                            spacing='300'
                                            spacingInset='600 none'
                                        >
                                            <Text alignment='center'>
                                                Start typing title, alias, or URL...
                                            </Text>
                                        </Column>
                                    )}
                                </SearchField>
                                {displaySimValidation && (
                                    <Alert type='error' size='small'>
                                        Cannot link an open SIM ticket to a closed case. Please reopen the
                                        case to link an open SIM ticket.
                                    </Alert>
                                )}
                            </Column>
                        </Column>
                        <ModalFooter>
                            <Row alignmentHorizontal='right' widths='fit'>
                                <Button
                                    type='secondary'
                                    onClick={handleCancelSIM}
                                    disabled={useLinkSIMEndpoint.isFetching}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    type='primary'
                                    onClick={handleLinkSIM}
                                    disabled={useLinkSIMEndpoint.isFetching}
                                >
                                    Save
                                </Button>
                            </Row>
                        </ModalFooter>
                    </Modal>
                </Row>
                {caseDetails.linkedTickets.length > 0 ? (
                    <Fragment>
                        {caseDetails.linkedTickets
                            .slice(0, isStacked ? 1 : caseDetails.linkedTickets.length)
                            .map((ticket, index) => {
                                return simWidget(
                                    ticket,
                                    index !== 0 &&
                                        ticket.status === SimStatusTypes.RESOLVED &&
                                        caseDetails.linkedTickets[index - 1].status !==
                                            SimStatusTypes.RESOLVED
                                );
                            })}
                        {isStacked && caseDetails.linkedTickets.length > 1 && (
                            <Expander
                                open={isSimExpanded}
                                title={isSimExpanded ? 'See less' : 'See more'}
                                onChange={setISimExpanded}
                                type={'inline'}
                            >
                                <Column className={'RemoveLeftPadding'}>
                                    {' '}
                                    {caseDetails.linkedTickets.slice(1).map((ticket, index) => {
                                        return simWidget(
                                            ticket,
                                            index !== 0 &&
                                                ticket.status === SimStatusTypes.RESOLVED &&
                                                caseDetails.linkedTickets[index].status !==
                                                    SimStatusTypes.RESOLVED
                                        );
                                    })}
                                </Column>
                            </Expander>
                        )}
                    </Fragment>
                ) : (
                    <Text> No linked SIM tickets </Text>
                )}
            </Column>
        </Tile>
    );
};

export default SIMWidget;
