import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import GuardianAuth from 'GuardianAuth/lib/auth/GuardianAuth';
import { Case, LevelOfConcern, Proximity, Region, SiteIdAndCode } from '../lib/CaseDefinitions';

export interface FilterOptions {
    levelOfConcern: LevelOfConcern[];
    severity: number[];
    eventType: string[];
    eventCategory: string[];
    region: Region[];
    proximity: Proximity[];
    siteIds: SiteIdAndCode[];
    flaggedWords: string[];
    onlyNew: boolean;
    onlyUpdated: boolean;
}

interface DashboardCases {
    newHighSevCases: Case[];
    updatedHighLOCCases: Case[];
}

interface TemplateDashboardState {
    userLogin: string;
    userId: string;
    userFirstName: string;
    userLastName: string;
    token: string;
    isNavCollapsed: boolean;
    newHighPriorityCase: boolean;
    dashboardCases: DashboardCases;
    openCases: Case[];
    displayedCases: Case[];
    filterOptions: FilterOptions;
    localFilterOptions: FilterOptions;
    newCasesArray: number[];
    updatedCasesArray: number[];
    newHighPriorityArray: number[];
    hasFinishedLoading: boolean;
}

export const initialFilterOptions: FilterOptions = {
    levelOfConcern: [],
    severity: [],
    eventType: [],
    eventCategory: [],
    region: [],
    proximity: [],
    siteIds: [],
    flaggedWords: [],
    onlyNew: false,
    onlyUpdated: false
};

const initialState: TemplateDashboardState = {
    userLogin: '',
    userFirstName: '',
    userLastName: '',
    userId: '',
    token: '',
    isNavCollapsed: false,
    newHighPriorityCase: false,
    displayedCases: [],
    dashboardCases: {
        newHighSevCases: [],
        updatedHighLOCCases: []
    },
    openCases: [],
    filterOptions: initialFilterOptions,
    localFilterOptions: initialFilterOptions,
    newCasesArray: [],
    updatedCasesArray: [],
    newHighPriorityArray: [],
    hasFinishedLoading: false
};

const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        getUserInfo: (state: TemplateDashboardState) => {
            const guardianAuthJWT = GuardianAuth.getJWT();
            const jwtPayload = JSON.parse(atob(guardianAuthJWT.split('.')[1]));
            const { login, empId, firstName, lastName } = jwtPayload;
            state.userLogin = login;
            state.userId = empId;
            state.userFirstName = firstName;
            state.userLastName = lastName;
            state.token = guardianAuthJWT;
        },
        toggleNav: (state: TemplateDashboardState) => {
            state.isNavCollapsed = !state.isNavCollapsed;
        },
        toggleHasFinishedLoading: (state: TemplateDashboardState, { payload }: PayloadAction<boolean>) => {
            state.hasFinishedLoading = payload;
        },
        setFilterOptions: (state: TemplateDashboardState, { payload }: PayloadAction<FilterOptions>) => {
            state.filterOptions = payload;
            state.localFilterOptions = payload;
        },
        setLocalFilterOptions: (state: TemplateDashboardState, { payload }: PayloadAction<FilterOptions>) => {
            state.localFilterOptions = payload;
        },
        setDashboardCases: (state: TemplateDashboardState, { payload }: PayloadAction<DashboardCases>) => {
            state.dashboardCases = payload;
        },
        setDisplayedCases: (state: TemplateDashboardState, { payload }: PayloadAction<Case[]>) => {
            state.displayedCases = payload;
        },
        setOpenCases: (state: TemplateDashboardState, { payload }: PayloadAction<Case[]>) => {
            state.openCases = payload;
        },
        removeNotifications: (state: TemplateDashboardState) => {
            state.newCasesArray = [];
            state.updatedCasesArray = [];
            state.newHighPriorityArray = [];
        },
        setNotificationCounts: (
            state: TemplateDashboardState,
            {
                payload
            }: PayloadAction<{ caseId: number; hasUpdate: boolean; isNew: boolean; highPriority: boolean }>
        ) => {
            if (payload.isNew) {
                if (!state.newCasesArray.includes(payload.caseId)) {
                    state.newCasesArray.push(payload.caseId);
                }
            } else {
                state.newCasesArray = state.newCasesArray.filter((id) => id !== payload.caseId);
            }
            if (payload.hasUpdate) {
                if (!state.updatedCasesArray.includes(payload.caseId)) {
                    state.updatedCasesArray.push(payload.caseId);
                }
            } else {
                state.updatedCasesArray = state.updatedCasesArray.filter((id) => id !== payload.caseId);
            }
            if (!payload.highPriority || !(payload.isNew || payload.hasUpdate)) {
                state.newHighPriorityArray = state.newHighPriorityArray.filter((id) => id !== payload.caseId);
            } else {
                if (!state.newHighPriorityArray.includes(payload.caseId)) {
                    state.newHighPriorityArray.push(payload.caseId);
                }
            }
        },
        sortDisplayedCases: (state: TemplateDashboardState, { payload }: PayloadAction<string>) => {
            if (payload === 'emtCategory' || payload === 'caseId' || payload === 'emtType') {
                state.displayedCases.sort((a, b) => {
                    return a[payload] > b[payload]
                        ? +1
                        : b[payload] > a[payload]
                        ? -1
                        : 0 ||
                          new Date(b.lastModificationTime).getTime() -
                              new Date(a.lastModificationTime).getTime();
                });
            } else if (payload === 'loc/sev') {
                state.displayedCases.sort((a, b) => {
                    let A = a.emtSev || 0;
                    let B = b.emtSev || 0;
                    if (a.loc !== undefined) {
                        A = Math.abs(5 - a.loc);
                    }
                    if (b.loc !== undefined) {
                        B = Math.abs(5 - b.loc);
                    }

                    return (
                        A - B ||
                        new Date(b.lastModificationTime).getTime() -
                            new Date(a.lastModificationTime).getTime()
                    );
                });
            } else if (payload === 'flaggedWords') {
                state.displayedCases.sort(
                    (a, b) =>
                        b.flaggedWords.length - a.flaggedWords.length ||
                        new Date(b.lastModificationTime).getTime() -
                            new Date(a.lastModificationTime).getTime()
                );
            } else if (payload === 'lastModificationTime') {
                state.displayedCases.sort(
                    (a, b) =>
                        new Date(b.lastModificationTime).getTime() -
                        new Date(a.lastModificationTime).getTime()
                );
            }
        }
    }
});

export const {
    getUserInfo,
    toggleNav,
    toggleHasFinishedLoading,
    setFilterOptions,
    setDashboardCases,
    setDisplayedCases,
    setOpenCases,
    sortDisplayedCases,
    setLocalFilterOptions,
    setNotificationCounts,
    removeNotifications
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
