import Select, { SelectOption } from '@amzn/meridian/select';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../context/store';
import { MAX_SELECT_POPOVER_HEIGHT } from '../../lib/constants';
import LoadingInput from './LoadingInput';

type CountryInputProps = {
    onChange: ((flaggedWord: string) => void) | ((flaggedWords: string[]) => void);
    value?: string | string[];
};

const FlaggedWordsInput: React.FunctionComponent<CountryInputProps> = ({
    onChange,
    value
}: CountryInputProps) => {
    const { selectOptionsContext } = useSelector((state: RootState) => state.selectOptions);

    return (
        <Select
            label='Flagged words'
            value={value}
            onChange={onChange}
            popoverMaxHeight={MAX_SELECT_POPOVER_HEIGHT}
        >
            {selectOptionsContext.flaggedWords.length > 0 ? (
                selectOptionsContext.flaggedWords.map((flaggedWord: string) => {
                    return <SelectOption key={flaggedWord} label={flaggedWord} value={flaggedWord} />;
                })
            ) : (
                <LoadingInput />
            )}
        </Select>
    );
};

export default FlaggedWordsInput;
