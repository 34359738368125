import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SelectOptionsContext, ThreatManagerTypes } from '../lib/CaseDefinitions';

interface UserContextState {
    selectOptionsContext: SelectOptionsContext;
    userRoles: string[];
    threatManagers: ThreatManagerTypes;
}

const initialState: UserContextState = {
    selectOptionsContext: {
        flaggedWords: [],
        recommendations: [],
        businessLines: [],
        countries: [],
        subCategories: [],
        consultationsAddedBy: [],
        assignees: []
    },
    threatManagers: {
        admins: [],
        users: []
    },
    userRoles: []
};

const selectOptionsSlice = createSlice({
    name: 'selectOptions',
    initialState,
    reducers: {
        setSelectOptions: (state: UserContextState, { payload }: PayloadAction<UserContextState>) => {
            state.selectOptionsContext = payload.selectOptionsContext;
            state.userRoles = payload.userRoles;
        },

        setThreatManagers: (state: UserContextState, { payload }: PayloadAction<ThreatManagerTypes>) => {
            state.threatManagers = payload;
        }
    }
});

export const { setSelectOptions, setThreatManagers } = selectOptionsSlice.actions;

export default selectOptionsSlice.reducer;
