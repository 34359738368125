import Column from '@amzn/meridian/column';
import Link from '@amzn/meridian/link';
import Row from '@amzn/meridian/row';
import Text from '@amzn/meridian/text';
import React from 'react';
import CaseDetailsError from '../../images/MARSCaseDetailsError.png';
import { SIM_LINK } from '../../lib/constants';

type CaseErrorProps = {
    message: string;
};

const CaseNotFound: React.FunctionComponent<CaseErrorProps> = ({ message }: CaseErrorProps) => {
    return (
        <Row alignmentHorizontal='center'>
            <Column height='250px' heights='fill' alignmentHorizontal='left' alignmentVertical='center'>
                <Column>
                    <Text type='h200'>{message}</Text>
                    <Text type='b300'>
                        Try again or&nbsp;
                        <Link href={SIM_LINK} target='_blank' rel='noopener noreferrer'>
                            open a SIM ticket
                        </Link>
                    </Text>
                </Column>
            </Column>
            <Column spacingInset='600'>
                <img src={CaseDetailsError} alt='Case Not Found' height='255px' />
            </Column>
        </Row>
    );
};

export default CaseNotFound;
