import Button from '@amzn/meridian/button';
import Column from '@amzn/meridian/column';
import Link from '@amzn/meridian/link';
import Popover, { PopoverHeader } from '@amzn/meridian/popover';
import Text from '@amzn/meridian/text';
import React, { Fragment, useRef, useState } from 'react';
import { EmployeeLong, PersonInvolved } from '../../../lib/CaseDefinitions';

interface EmployeePopoverProps {
    employee: EmployeeLong | PersonInvolved;
}

const EmployeePopover: React.FunctionComponent<EmployeePopoverProps> = ({
    employee
}: EmployeePopoverProps) => {
    const [openPopover, setOpenPopover] = useState(false);
    const linkRef = useRef();
    return (
        <Fragment>
            <span>
                <Link ref={linkRef} onClick={() => setOpenPopover(true)}>
                    {employee.name}
                </Link>
            </span>
            <Popover
                anchorNode={linkRef.current}
                open={openPopover}
                onClose={setOpenPopover}
                position='bottom'
            >
                <PopoverHeader closeLabel='Close'>{employee.name}</PopoverHeader>
                <Column>
                    <Column spacing='none'>
                        {employee.login && <Text> {employee.login} </Text>}
                        <Text> {employee.title} </Text>
                        <Text> {employee.phoneNumber} </Text>
                        <Text> {employee.email} </Text>
                    </Column>
                    {employee.login && (
                        <Button minWidth='100%' type='tertiary'>
                            View in Admin Directory
                        </Button>
                    )}
                </Column>
            </Popover>
        </Fragment>
    );
};
export default EmployeePopover;
