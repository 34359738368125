import Button from '@amzn/meridian/button';
import Column from '@amzn/meridian/column';
import InputGroup from '@amzn/meridian/input-group';
import Modal, { ModalFooter } from '@amzn/meridian/modal';
import Row from '@amzn/meridian/row';
import { SelectOption } from '@amzn/meridian/select';
import Text from '@amzn/meridian/text';
import TimePicker from '@amzn/meridian/time-picker';
import plusTokens from '@amzn/meridian-tokens/base/icon/plus';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../context/store';
import { CommunicationMethod, CreateConsultationVars, Proximity } from '../../lib/CaseDefinitions';
import { CHARACTER_LIMIT, MODAL_WIDTH, SELECT_WIDTH, WIDTH_OF_CARD } from '../../lib/constants';
import {
    hasDuplicatePerson,
    hasOverLimitCommunicatedWith,
    isDuplicatePersonAtIndex,
    removeEmptyLine
} from '../../lib/helpers';
import { labels } from '../../lib/labels';
import { createAddingConsultationObject, reset } from '../../slices/AddProximitySlice';
import { setConsultations } from '../../slices/ConsultationsSlice';
import { addToast } from '../../slices/ToastSlice';
import { useHasConsultationNameQuery, useLazyCreateConsultationQuery } from '../../slices/WotManApiSlice';
import AlertModal from '../Alert/AlertModal';
import TitledButton from '../CustomComponents/TitledButton';
import AddPersonInput from '../Inputs/AddPersonInput';
import { FormDatePicker, FormInput, FormSelect, FormTextarea } from '../Inputs/FormFields';
import ProximityInput from '../Inputs/ProximityInput';
import TimeZoneInput from '../Inputs/TimeZoneInput';

const AddConsultationsModal: React.FunctionComponent = () => {
    const [open, setOpen] = useState(false);
    const [page, setPage] = useState(0);
    const [showAlertPrompt, setShowAlertPrompt] = useState(false);
    const { proximity, siteIds, inputLocation, secondarySiteIds } = useSelector(
        (state: RootState) => state.addProximity
    );
    const { consultations } = useSelector((state: RootState) => state.consultations);
    const [useCreateConsultation, useCreateConsultationEndpoint] = useLazyCreateConsultationQuery();

    const dispatch = useAppDispatch();

    const emptyPersonInvolved = {
        name: '',
        login: '',
        title: '',
        phoneNumber: '',
        email: '',
        personId: ''
    };

    const initialSte = {
        timeOfEvent: '',
        dateOfEvent: '',
        timezone: moment.tz.guess(),
        communicatedWith: [emptyPersonInvolved],
        method: '',
        consultationTitle: '',
        consultationSummary: '',
        threatManagerId: '',
        missingFields: [],
        isDirty: false
    };

    const [createConsultationVars, setCreateConsultationVars] = useState<CreateConsultationVars>(initialSte);
    const { data } = useHasConsultationNameQuery({ name: createConsultationVars.consultationTitle });

    const handleCancel = () => {
        setShowAlertPrompt(false);
        setOpen(false);
        setCreateConsultationVars(initialSte);
        dispatch(reset());
        setPage(0);
    };

    const isFormFilled = () => {
        if (createConsultationVars.isDirty) setShowAlertPrompt(true);
        else handleCancel();
    };

    const handleShowModal = () => {
        setShowAlertPrompt(false);
    };

    const handleSubmit = () => {
        const currentMissedField: string[] = [];
        if (createConsultationVars.method == '') {
            currentMissedField.push('method');
        }
        if (createConsultationVars.consultationTitle == '') {
            currentMissedField.push('title');
        }
        if (createConsultationVars.consultationSummary == '') {
            currentMissedField.push('summary');
        }
        createConsultationVars.communicatedWith.forEach((communicateWith, index) => {
            if (communicateWith.name === '' && communicateWith.personId === '')
                currentMissedField.push(`communicateWithName${index}`);
            if (communicateWith.name !== '' && !communicateWith.name?.trim())
                currentMissedField.push(`communicateWithNameHasOnlySpaces${index}`);
        });
        setCreateConsultationVars({
            ...createConsultationVars,
            missingFields: currentMissedField,
            isDirty: true
        });
        if (
            currentMissedField.length == 0 &&
            proximity &&
            !data &&
            inputLocation.address.length <= CHARACTER_LIMIT.NORMAL_TEXT_LIMIT &&
            inputLocation.city.length <= CHARACTER_LIMIT.NORMAL_TEXT_LIMIT &&
            inputLocation.state.length <= CHARACTER_LIMIT.NORMAL_TEXT_LIMIT &&
            inputLocation.zipcode.length <= CHARACTER_LIMIT.ZIPCODE_LIMIT &&
            !hasOverLimitCommunicatedWith(createConsultationVars.communicatedWith) &&
            createConsultationVars.consultationTitle.length <= CHARACTER_LIMIT.NORMAL_TEXT_LIMIT &&
            createConsultationVars.consultationSummary.length <= CHARACTER_LIMIT.CONSULTATION_SUMMARY_LIMIT &&
            !hasDuplicatePerson(createConsultationVars.communicatedWith)
        ) {
            const createConsultation = createAddingConsultationObject(
                {
                    ...createConsultationVars,
                    consultationSummary: removeEmptyLine(createConsultationVars.consultationSummary)
                },
                proximity,
                siteIds,
                secondarySiteIds,
                inputLocation
            );
            useCreateConsultation({ consultationCreating: createConsultation });
        } else {
            dispatch(
                addToast({
                    type: 'error',
                    action: 'One or more invalid form fields. ',
                    message: 'Correct any errors and submit again',
                    SIM: false,
                    timeout: 3000
                })
            );
        }
    };

    const handleNext = () => {
        const currentMissedField: string[] = [];
        if (page == 0) {
            if (!createConsultationVars.dateOfEvent || createConsultationVars.dateOfEvent == '') {
                currentMissedField.push('dateOfEvent');
            }
            if (!proximity) {
                currentMissedField.push('proximity');
            }
            if (proximity == Proximity.ONSITE && siteIds.length === 0) {
                currentMissedField.push('siteId');
            }
        }
        if (page == 1) {
            createConsultationVars.communicatedWith.forEach((communicatedWith, index) => {
                if (communicatedWith.name == '' && communicatedWith.personId == '') {
                    currentMissedField.push(`personInvolvedName${index}`);
                }
                if (communicatedWith.name !== '' && !communicatedWith.name?.trim()) {
                    currentMissedField.push(`personInvolvedNameHasOnlySpaces${index}`);
                }
            });
        }
        setCreateConsultationVars({
            ...createConsultationVars,
            missingFields: currentMissedField,
            isDirty: true
        });
        if (
            currentMissedField.length == 0 &&
            page == 0 &&
            inputLocation.address.length <= CHARACTER_LIMIT.NORMAL_TEXT_LIMIT &&
            inputLocation.city.length <= CHARACTER_LIMIT.NORMAL_TEXT_LIMIT &&
            inputLocation.state.length <= CHARACTER_LIMIT.NORMAL_TEXT_LIMIT &&
            inputLocation.zipcode.length <= CHARACTER_LIMIT.ZIPCODE_LIMIT
        ) {
            setPage(1);
        } else if (
            currentMissedField.length == 0 &&
            page == 1 &&
            !hasOverLimitCommunicatedWith(createConsultationVars.communicatedWith) &&
            !hasDuplicatePerson(createConsultationVars.communicatedWith)
        ) {
            setPage(2);
        } else {
            dispatch(
                addToast({
                    type: 'error',
                    action: 'One or more invalid form fields. ',
                    message: 'Correct any errors and submit again',
                    SIM: false,
                    timeout: 3000
                })
            );
        }
    };

    useEffect(() => {
        if (!useCreateConsultationEndpoint.isFetching && useCreateConsultationEndpoint.isSuccess) {
            dispatch(
                addToast({
                    type: 'success',
                    action: 'Added engagement ',
                    message: createConsultationVars.consultationTitle,
                    SIM: false,
                    timeout: 3000
                })
            );
            dispatch(setConsultations([useCreateConsultationEndpoint.data, ...consultations]));
            handleCancel();
        } else if (!useCreateConsultationEndpoint.isFetching && useCreateConsultationEndpoint.isError) {
            dispatch(
                addToast({
                    type: 'error',
                    action: 'There was an error creating engagement',
                    message: '',
                    SIM: true,
                    timeout: undefined
                })
            );
        }
    }, [useCreateConsultationEndpoint.isFetching]);

    return (
        <React.Fragment>
            <TitledButton
                title={labels.createConsultation}
                buttonProps={{
                    onClick: () => setOpen(true),
                    type: 'primary',
                    size: 'medium'
                }}
                iconProps={{ tokens: plusTokens }}
            />
            <Modal
                title='Add engagement'
                width={MODAL_WIDTH}
                open={open}
                onClose={isFormFilled}
                scrollContainer='modal'
            >
                {page === 0 && (
                    <Column>
                        <Text type='h200'>Event information</Text>
                        <Text color='secondary' type='b200'>
                            {"We'll use this information to suggest a matching case."}
                        </Text>
                        <FormDatePicker
                            missing={createConsultationVars.missingFields.includes('dateOfEvent')}
                            value={createConsultationVars.dateOfEvent}
                            onChange={(dateOfEvent) =>
                                setCreateConsultationVars({
                                    ...createConsultationVars,
                                    dateOfEvent: dateOfEvent,
                                    missingFields: createConsultationVars.missingFields.filter(
                                        (field) => field !== 'dateOfEvent'
                                    ),
                                    isDirty: true
                                })
                            }
                        />
                        <InputGroup width={`${2 * parseInt(SELECT_WIDTH.slice(0, -2))}px`}>
                            <TimePicker
                                label='Time of event'
                                value={createConsultationVars.timeOfEvent}
                                locale='en-GB'
                                onChange={(timeOfEvent) => {
                                    setCreateConsultationVars({
                                        ...createConsultationVars,
                                        timeOfEvent,
                                        isDirty: true
                                    });
                                }}
                            />
                            <div>
                                <TimeZoneInput
                                    onChange={(timezone) =>
                                        setCreateConsultationVars({
                                            ...createConsultationVars,
                                            timezone,
                                            isDirty: true
                                        })
                                    }
                                    value={createConsultationVars.timezone}
                                />
                            </div>
                        </InputGroup>
                        <ProximityInput
                            missingFields={createConsultationVars.missingFields}
                            proximityOnChange={() =>
                                setCreateConsultationVars({
                                    ...createConsultationVars,
                                    missingFields: createConsultationVars.missingFields.filter(
                                        (field) => field !== 'proximity'
                                    ),
                                    isDirty: true
                                })
                            }
                            siteIdOnChange={() =>
                                setCreateConsultationVars({
                                    ...createConsultationVars,
                                    missingFields: createConsultationVars.missingFields.filter(
                                        (field) => field !== 'siteId'
                                    ),
                                    isDirty: true
                                })
                            }
                        />
                    </Column>
                )}
                {page === 1 && (
                    <Column>
                        <Text type='h200'>Communicated with</Text>
                        <Row alignmentVertical='top' wrap='down' maxWidth={WIDTH_OF_CARD}>
                            {createConsultationVars.communicatedWith.map((person, index) => {
                                return (
                                    <AddPersonInput
                                        key={index}
                                        personAdding={createConsultationVars.communicatedWith[index]}
                                        missingName={createConsultationVars.missingFields.includes(
                                            `personInvolvedName${index}`
                                        )}
                                        nameIsEmptySpaces={createConsultationVars.missingFields.includes(
                                            `personInvolvedNameHasOnlySpaces${index}`
                                        )}
                                        setVars={(personVars) => {
                                            setCreateConsultationVars({
                                                ...createConsultationVars,
                                                communicatedWith: [
                                                    ...createConsultationVars.communicatedWith.slice(
                                                        0,
                                                        index
                                                    ),
                                                    personVars,
                                                    ...createConsultationVars.communicatedWith.slice(
                                                        index + 1
                                                    )
                                                ],
                                                missingFields: createConsultationVars.missingFields.filter(
                                                    (field) =>
                                                        (personVars.name === '' ||
                                                            field !== `personInvolvedName${index}`) &&
                                                        ((personVars.name !== '' &&
                                                            !personVars.name?.trim()) ||
                                                            field !==
                                                                `personInvolvedNameHasOnlySpaces${index}`)
                                                ),
                                                isDirty: true
                                            });
                                        }}
                                        removePerson={
                                            index === 0
                                                ? undefined
                                                : () => {
                                                      const missingFields: string[] = [];
                                                      createConsultationVars.missingFields.forEach(
                                                          (field) => {
                                                              if (
                                                                  field.length > 18 &&
                                                                  field.slice(0, 18) === 'personInvolvedName'
                                                              ) {
                                                                  const originalIndex = parseInt(
                                                                      field.slice(18)
                                                                  );
                                                                  if (originalIndex > index) {
                                                                      missingFields.push(
                                                                          `personInvolvedName${originalIndex -
                                                                              1}`
                                                                      );
                                                                  } else if (originalIndex !== index) {
                                                                      missingFields.push(field);
                                                                  }
                                                              } else if (
                                                                  field.length > 18 &&
                                                                  field.slice(0, 18) ===
                                                                      'personInvolvedNameHasOnlySpaces'
                                                              ) {
                                                                  const originalIndex = parseInt(
                                                                      field.slice(18)
                                                                  );
                                                                  if (originalIndex > index) {
                                                                      missingFields.push(
                                                                          `personInvolvedNameHasOnlySpaces${originalIndex -
                                                                              1}`
                                                                      );
                                                                  } else if (originalIndex !== index) {
                                                                      missingFields.push(field);
                                                                  }
                                                              } else {
                                                                  missingFields.push(field);
                                                              }
                                                          }
                                                      );
                                                      setCreateConsultationVars({
                                                          ...createConsultationVars,
                                                          communicatedWith: [
                                                              ...createConsultationVars.communicatedWith.slice(
                                                                  0,
                                                                  index
                                                              ),
                                                              ...createConsultationVars.communicatedWith.slice(
                                                                  index + 1
                                                              )
                                                          ],
                                                          missingFields,
                                                          isDirty: true
                                                      });
                                                  }
                                        }
                                        duplicate={isDuplicatePersonAtIndex(
                                            createConsultationVars.communicatedWith,
                                            index
                                        )}
                                    />
                                );
                            })}
                        </Row>
                        <Row>
                            <Button
                                type='tertiary'
                                onClick={() =>
                                    setCreateConsultationVars({
                                        ...createConsultationVars,
                                        communicatedWith: [
                                            ...createConsultationVars.communicatedWith,
                                            emptyPersonInvolved
                                        ],
                                        isDirty: true
                                    })
                                }
                            >
                                Add another person
                            </Button>
                        </Row>
                    </Column>
                )}
                {page === 2 && (
                    <Column>
                        <Text type='h200'>Engagement information</Text>
                        <FormSelect
                            width={SELECT_WIDTH}
                            value={createConsultationVars.method}
                            onChange={(method) =>
                                setCreateConsultationVars({
                                    ...createConsultationVars,
                                    method: method,
                                    missingFields: createConsultationVars.missingFields.filter(
                                        (field) => field !== 'method'
                                    ),
                                    isDirty: true
                                })
                            }
                            missing={createConsultationVars.missingFields.includes('method')}
                            label='Method *'
                        >
                            {Object.values(CommunicationMethod).map((value) => {
                                return <SelectOption value={value} label={value} key={value} />;
                            })}
                        </FormSelect>
                        <FormInput
                            width={SELECT_WIDTH}
                            value={createConsultationVars.consultationTitle}
                            onChange={(consultationTitle) =>
                                setCreateConsultationVars({
                                    ...createConsultationVars,
                                    consultationTitle: consultationTitle,
                                    missingFields: createConsultationVars.missingFields.filter(
                                        (field) => field !== 'title'
                                    ),
                                    isDirty: true
                                })
                            }
                            missing={createConsultationVars.missingFields.includes('title')}
                            duplicated={data}
                            max={CHARACTER_LIMIT.NORMAL_TEXT_LIMIT}
                            label='Title *'
                            duplicatedLabel='Engagement title'
                        />
                        <FormTextarea
                            value={createConsultationVars.consultationSummary}
                            onChange={(consultationSummary) =>
                                setCreateConsultationVars({
                                    ...createConsultationVars,
                                    consultationSummary,
                                    missingFields: createConsultationVars.missingFields.filter(
                                        (field) => field !== 'summary'
                                    ),
                                    isDirty: true
                                })
                            }
                            missing={createConsultationVars.missingFields.includes('summary')}
                            max={CHARACTER_LIMIT.CONSULTATION_SUMMARY_LIMIT}
                            label='Engagement summary *'
                            rows={6}
                        />
                    </Column>
                )}

                <ModalFooter>
                    <Row alignmentHorizontal='justify' widths='fit'>
                        <Row alignmentHorizontal='left'>
                            <Button type='secondary' onClick={isFormFilled}>
                                Cancel
                            </Button>
                        </Row>
                        <Row alignmentHorizontal='right'>
                            {page > 0 && (
                                <Button type='secondary' onClick={() => setPage(page - 1)}>
                                    Previous
                                </Button>
                            )}
                            {page < 2 && (
                                <Button type='primary' onClick={handleNext}>
                                    Next
                                </Button>
                            )}
                            {page === 2 && (
                                <Button
                                    type='primary'
                                    onClick={handleSubmit}
                                    disabled={useCreateConsultationEndpoint.isFetching}
                                >
                                    Submit
                                </Button>
                            )}
                        </Row>
                    </Row>
                </ModalFooter>
            </Modal>
            {showAlertPrompt && (
                <AlertModal
                    showPrompt={showAlertPrompt}
                    handleConfirm={handleShowModal}
                    onCancel={handleCancel}
                    onClose={handleShowModal}
                ></AlertModal>
            )}
        </React.Fragment>
    );
};

export default AddConsultationsModal;
