import Link from '@amzn/meridian/link';
import Row from '@amzn/meridian/row';
import Text from '@amzn/meridian/text';
import React from 'react';
import chimeLogo from '../../images/chimeLogo.png';
import { CHIME_LINK } from '../../lib/constants';

type ChimeLinkProps = {
    alias: string;
};

const ChimeLink: React.FunctionComponent<ChimeLinkProps> = ({ alias }: ChimeLinkProps) => (
    <Row>
        <Link target='_blank' rel='noopener noreferrer' href={`${CHIME_LINK}${alias}@amazon.com`}>
            <Row alignmentVertical='center' spacing='300'>
                <img src={chimeLogo} alt='chime Logo' height='32' />
                <Text type='b300'>Message on Chime</Text>
            </Row>
        </Link>
    </Row>
);

export default ChimeLink;
