import Column from '@amzn/meridian/column';
import HighlightSubstring from '@amzn/meridian/highlight-substring';
import Link from '@amzn/meridian/link';
import Row from '@amzn/meridian/row';
import SearchField, { SearchSuggestion, SearchSuggestionFooter } from '@amzn/meridian/search-field';
import Text from '@amzn/meridian/text';
import React, { useEffect, useState } from 'react';
import { PAPIShort } from '../../lib/CaseDefinitions';
import { SELECT_WIDTH } from '../../lib/constants';
import { useLazySearchForEmployeesQuery } from '../../slices/WotManApiSlice';
import LoadingInput from './LoadingInput';
import NoSearchResults from './NoSearchResults';

type LoginNameSearchProps = {
    handleClear?: () => void;
    onClick: (person: PAPIShort) => void;
    onBlur?: () => void;
    onSubmit?: () => void;
    label?: string;
    placeholder?: string;
    width?: string;
    query: string;
    setQuery: (query: string) => void;
    isSuggestionDisabledCallback?: (person: PAPIShort) => boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    providedSearchForEmployees?: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    providedSearchForEmployeesEndpoint?: any;
};

const LoginNameSearch: React.FunctionComponent<LoginNameSearchProps> = ({
    handleClear,
    onClick,
    onBlur,
    onSubmit = () => '',
    setQuery,
    label = 'Alias',
    placeholder = 'Search for person...',
    query,
    width = SELECT_WIDTH,
    isSuggestionDisabledCallback = () => false,
    providedSearchForEmployees,
    providedSearchForEmployeesEndpoint
}: LoginNameSearchProps) => {
    const [useSearchForEmployees, useSearchForEmployeesEndpoint] =
        providedSearchForEmployees && providedSearchForEmployeesEndpoint
            ? [providedSearchForEmployees, providedSearchForEmployeesEndpoint]
            : useLazySearchForEmployeesQuery({
                  prefix: query
              });
    const data = useSearchForEmployeesEndpoint.data;
    const [seeMore, setSeeMore] = useState(false);

    useEffect(() => {
        if (query.length > 0) {
            useSearchForEmployees({ prefix: query });
            setSeeMore(false);
        }
    }, [query]);

    const renderSearchSuggestion = (person: PAPIShort) => (
        <SearchSuggestion
            key={person.personId}
            onClick={() => onClick(person)}
            disabled={isSuggestionDisabledCallback(person)}
        >
            {({ query = '' }) => (
                <Column spacing='100'>
                    <Text>
                        <HighlightSubstring match={query}>
                            {person.fullName} {person.login ? '(' + person.login.toLowerCase() + '@)' : ''}
                        </HighlightSubstring>
                    </Text>
                    <Text type='b200'>{person.jobTitle}</Text>
                </Column>
            )}
        </SearchSuggestion>
    );

    return (
        <SearchField
            value={query}
            onChange={setQuery}
            onBlur={onBlur}
            onSubmit={() => onSubmit}
            width={width}
            label={label}
            placeholder={placeholder}
            clearButton={true}
            onClear={handleClear}
            popoverMaxHeight={325}
        >
            {query.length > 0 ? (
                !useSearchForEmployeesEndpoint.isFetching && useSearchForEmployeesEndpoint.isSuccess ? (
                    data.length > 0 ? (
                        seeMore ? (
                            data.map((person: PAPIShort) => renderSearchSuggestion(person))
                        ) : (
                            data.slice(0, 10).map((person: PAPIShort) => renderSearchSuggestion(person))
                        )
                    ) : (
                        <NoSearchResults />
                    )
                ) : (
                    <LoadingInput />
                )
            ) : (
                <Column alignmentVertical='center' spacing='300' spacingInset='600 none'>
                    <Text alignment='center'>Start typing name or alias...</Text>
                </Column>
            )}
            {query.length > 0 &&
                !useSearchForEmployeesEndpoint.isFetching &&
                useSearchForEmployeesEndpoint.isSuccess &&
                data.length > 10 &&
                !seeMore && (
                    <SearchSuggestionFooter>
                        <Row>
                            <Link type='secondary' onClick={() => setSeeMore(true)}>
                                See more
                            </Link>
                        </Row>
                    </SearchSuggestionFooter>
                )}
        </SearchField>
    );
};

export default LoginNameSearch;
