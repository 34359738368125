/* eslint-disable */
import React, { Fragment } from 'react';
import Box from '@amzn/meridian/box';
import Row from '@amzn/meridian/row';
import Text from '@amzn/meridian/text';
import Select, { SelectOption } from '@amzn/meridian/select';
import Column from '@amzn/meridian/column';
import Button from '@amzn/meridian/button';
import Checkbox from '@amzn/meridian/checkbox';
import Tag from '@amzn/meridian/tag';
import Link from '@amzn/meridian/link';
import { useSelector } from 'react-redux';
import { useAppDispatch, RootState } from '../../context/store';
import { Proximity, Region, SiteIdAndCode } from '../../lib/CaseDefinitions';
import { locValues } from '../../lib/constants';
import {
    FilterOptions,
    initialFilterOptions,
    setFilterOptions,
    setLocalFilterOptions
} from '../../slices/DashboardSlice';
import { useHistory } from 'react-router-dom';
import RegionInput from '../Inputs/RegionInput';
import FlaggedWordsInput from '../Inputs/FlaggedWordsInput';
import FacilityInput from '../Inputs/FacilityInput';
import { sortedEventTypes } from '../../lib/EmtCTIDefinitions';

type CaseFilterProps = {
    initialFilterState?: FilterOptions;
    checkBoxesDisabled?: boolean;
    horizontalOptions?: boolean;
};

const CaseFilter: React.FunctionComponent<CaseFilterProps> = ({
    horizontalOptions = false,
    initialFilterState,
    checkBoxesDisabled
}: CaseFilterProps) => {
    const { displayedCases, localFilterOptions } = useSelector((state: RootState) => state.dashboard);
    const dispatch = useAppDispatch();
    const history = useHistory();

    const filterUI = () => (
        <Fragment>
            {!initialFilterState?.onlyNew && (
                <Select
                    value={localFilterOptions.levelOfConcern}
                    onChange={(levelOfConcern) =>
                        dispatch(
                            setLocalFilterOptions({
                                ...localFilterOptions,
                                levelOfConcern
                            })
                        )
                    }
                    label='Level of concern'
                    size='xlarge'
                >
                    {locValues.map((value) => {
                        return <SelectOption value={value} label={value} key={value} />;
                    })}
                </Select>
            )}
            {!initialFilterState?.onlyUpdated && (
                <Select
                    value={localFilterOptions.severity}
                    disabled={true}
                    onChange={(severity) =>
                        dispatch(
                            setLocalFilterOptions({
                                ...localFilterOptions,
                                severity: severity
                            })
                        )
                    }
                    label='Severity'
                    size='xlarge'
                >
                    <SelectOption value={1} label='SEV 1' />
                    <SelectOption value={2} label='SEV 2' />
                    <SelectOption value={3} label='SEV 3' />
                    <SelectOption value={4} label='SEV 4' />
                </Select>
            )}
            <Select
                value={localFilterOptions.eventCategory}
                onChange={(eventCategory) =>
                    dispatch(setLocalFilterOptions({ ...localFilterOptions, eventCategory }))
                }
                label='Category'
                size='xlarge'
            >
                <SelectOption
                    value='Violent / Detrimental Behavior'
                    label='Violent / Detrimental Behavior (EMT)'
                    key='emt'
                />
                <SelectOption value='MARS Investigation' label='MARS Investigation (MARS)' key='mars' />
            </Select>
            <Select
                value={localFilterOptions.eventType}
                onChange={(eventType) =>
                    dispatch(setLocalFilterOptions({ ...localFilterOptions, eventType }))
                }
                label='Type'
                size='xlarge'
            >
                {sortedEventTypes().map((value) => {
                    return <SelectOption value={value} label={value} key={value} />;
                })}
            </Select>
            <RegionInput
                value={localFilterOptions.region}
                onChange={(region: Region[]) =>
                    dispatch(setLocalFilterOptions({ ...localFilterOptions, region }))
                }
            />
            <Select
                value={localFilterOptions.proximity}
                onChange={(proximity) =>
                    dispatch(setLocalFilterOptions({ ...localFilterOptions, proximity }))
                }
                label='Proximity'
                size='xlarge'
            >
                {Object.values(Proximity).map((value) => {
                    return <SelectOption value={value} label={value} key={value} />;
                })}
            </Select>
            <FacilityInput
                label='Facility code'
                value={localFilterOptions.siteIds}
                onChange={(siteIds: SiteIdAndCode[]) =>
                    dispatch(setLocalFilterOptions({ ...localFilterOptions, siteIds }))
                }
                isFilter={true}
            />
            <FlaggedWordsInput
                value={localFilterOptions.flaggedWords}
                onChange={(flaggedWords: string[]) =>
                    dispatch(setLocalFilterOptions({ ...localFilterOptions, flaggedWords }))
                }
            />
            <Checkbox
                disabled={checkBoxesDisabled}
                checked={localFilterOptions.onlyNew}
                onChange={(onlyNew) => dispatch(setLocalFilterOptions({ ...localFilterOptions, onlyNew }))}
            >
                <Tag> New </Tag>
            </Checkbox>
            <Checkbox
                disabled={checkBoxesDisabled}
                checked={localFilterOptions.onlyUpdated}
                onChange={(onlyUpdated) =>
                    dispatch(setLocalFilterOptions({ ...localFilterOptions, onlyUpdated }))
                }
            >
                <Tag> Updated </Tag>
            </Checkbox>
        </Fragment>
    );

    return (
        <Column>
            <Box type='outline' spacingInset='400'>
                <Column>
                    <Row alignmentHorizontal='justify'>
                        <Text type='h300'>Filters</Text>
                        <Text type='b300'>{displayedCases.length} results</Text>
                    </Row>
                    {horizontalOptions ? (
                        <Row
                            width='100%'
                            widths='33%'
                            alignmentVertical={'top'}
                            wrap={'down'}
                            children={filterUI()}
                        />
                    ) : (
                        filterUI()
                    )}
                    <Row alignmentHorizontal='right'>
                        <Button
                            type='secondary'
                            onClick={() =>
                                dispatch(setLocalFilterOptions(initialFilterState || initialFilterOptions))
                            }
                        >
                            Clear
                        </Button>
                        <Button type='primary' onClick={() => dispatch(setFilterOptions(localFilterOptions))}>
                            Apply
                        </Button>
                    </Row>
                </Column>
            </Box>
            <Row alignmentHorizontal='right'>
                <Link onClick={history.push} href={'/search#cases'}>
                    Advanced search
                </Link>
            </Row>
        </Column>
    );
};

export default CaseFilter;
