import Checkbox from '@amzn/meridian/checkbox';
import Column from '@amzn/meridian/column';
import Row from '@amzn/meridian/row';
import Select, { SelectOption } from '@amzn/meridian/select';
import Tag from '@amzn/meridian/tag';
import Text from '@amzn/meridian/text';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../context/store';
import { Proximity, Region, SiteIdAndCode } from '../../../lib/CaseDefinitions';
import { CHARACTER_LIMIT } from '../../../lib/constants';
import { LocationInfoFilter } from '../../../lib/FilterDefinitons';
import SelectTags from '../../CustomComponents/SelectTags';
import CountryInput from '../../Inputs/CountryInput';
import FacilityInput from '../../Inputs/FacilityInput';
import { FormInput } from '../../Inputs/FormFields';
import RegionInput from '../../Inputs/RegionInput';

type LocationInputProps = {
    setLocationFilter: (newFilter: LocationInfoFilter) => void;
    locationFilter: LocationInfoFilter;
};

const LocationFilterColumn: React.FunctionComponent<LocationInputProps> = ({
    setLocationFilter,
    locationFilter
}: LocationInputProps) => {
    const { selectOptionsContext } = useSelector((state: RootState) => state.selectOptions);

    return (
        <Column>
            <Text type='h100'> Location </Text>
            <Select
                label='Proximity'
                value={locationFilter.proximity}
                onChange={(proximity) =>
                    setLocationFilter({
                        ...locationFilter,
                        proximity
                    })
                }
            >
                {Object.values(Proximity).map((value) => {
                    return <SelectOption value={value} label={value} key={value} />;
                })}
            </Select>
            {Array.isArray(locationFilter.proximity) && locationFilter.proximity.length > 1 && (
                <Row wrap='down'>
                    {locationFilter.proximity.map((proximity) => {
                        return (
                            <Tag
                                key={proximity}
                                type='neutral'
                                onClose={() =>
                                    /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                                    // @ts-ignore
                                    setLocationFilter({
                                        ...locationFilter,
                                        proximity: locationFilter.proximity.filter(
                                            (proxy) => proxy !== proximity
                                        )
                                    })
                                }
                            >
                                {proximity.toString()}
                            </Tag>
                        );
                    })}
                </Row>
            )}
            <Select
                label={'Business line'}
                value={locationFilter.businessLine}
                onChange={(businessLine) =>
                    setLocationFilter({
                        ...locationFilter,
                        businessLine
                    })
                }
            >
                {selectOptionsContext.businessLines.map((line) => (
                    <SelectOption label={line} value={line} key={line} />
                ))}
            </Select>
            {locationFilter.businessLine.length > 1 && (
                <SelectTags
                    value={locationFilter.businessLine}
                    onChange={(businessLine) => setLocationFilter({ ...locationFilter, businessLine })}
                />
            )}
            <FacilityInput
                value={locationFilter.facilityCode}
                onChange={(facilityCode: SiteIdAndCode[]) =>
                    setLocationFilter({
                        ...locationFilter,
                        facilityCode
                    })
                }
                hasTag={true}
            />
            {locationFilter.facilityCode && locationFilter.facilityCode.length > 0 && (
                <Row>
                    <Checkbox
                        checked={locationFilter.primary}
                        onChange={(primary) =>
                            setLocationFilter({
                                ...locationFilter,
                                primary
                            })
                        }
                    >
                        Primary
                    </Checkbox>
                    <Checkbox
                        checked={locationFilter.secondary}
                        onChange={(secondary) =>
                            setLocationFilter({
                                ...locationFilter,
                                secondary
                            })
                        }
                    >
                        Secondary
                    </Checkbox>
                </Row>
            )}
            <FormInput
                label='Street address'
                value={locationFilter.streetAddress}
                onChange={(streetAddress) =>
                    setLocationFilter({
                        ...locationFilter,
                        streetAddress
                    })
                }
                max={255}
                isColumn
            />
            <FormInput
                label='City'
                value={locationFilter.city}
                onChange={(city) =>
                    setLocationFilter({
                        ...locationFilter,
                        city
                    })
                }
                max={255}
                isColumn
            />
            <FormInput
                label='State/Province'
                value={locationFilter.stateProvince}
                onChange={(stateProvince) =>
                    setLocationFilter({
                        ...locationFilter,
                        stateProvince
                    })
                }
                max={255}
                isColumn
            />
            <CountryInput
                value={locationFilter.country}
                onChange={(country: string[]) =>
                    setLocationFilter({
                        ...locationFilter,
                        country
                    })
                }
            />
            {locationFilter.country.length > 1 && (
                <SelectTags
                    value={locationFilter.country}
                    onChange={(country) => setLocationFilter({ ...locationFilter, country })}
                />
            )}
            <FormInput
                label='Postal code'
                value={locationFilter.postalCode}
                onChange={(postalCode) =>
                    setLocationFilter({
                        ...locationFilter,
                        postalCode
                    })
                }
                max={CHARACTER_LIMIT.ZIPCODE_LIMIT}
                isColumn
            />
            <RegionInput
                value={locationFilter.region}
                onChange={(region: Region[]) =>
                    setLocationFilter({
                        ...locationFilter,
                        region
                    })
                }
            />
            {Array.isArray(locationFilter.region) && locationFilter.region.length > 1 && (
                <Row wrap='down'>
                    {locationFilter.region.map((region) => {
                        return (
                            <Tag
                                key={region}
                                type='neutral'
                                onClose={() =>
                                    /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                                    // @ts-ignore
                                    setLocationFilter({
                                        ...locationFilter,
                                        region: locationFilter.region.filter((reg) => reg !== region)
                                    })
                                }
                            >
                                {region}
                            </Tag>
                        );
                    })}
                </Row>
            )}
        </Column>
    );
};

export default LocationFilterColumn;
