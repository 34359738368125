import Column from '@amzn/meridian/column';
import Select, { SelectOption } from '@amzn/meridian/select';
import Text from '@amzn/meridian/text';
import moment from 'moment-timezone';
import React, { useState } from 'react';
import { allTimezones } from '../../lib/constants';

type TimeInputProps = {
    onChange: (timeZone: string) => void;
    value: string;
    width?: string;
};

const TimeZoneInput: React.FunctionComponent<TimeInputProps> = ({
    onChange,
    value,
    width
}: TimeInputProps) => {
    const [searchQuery, setSearchQuery] = useState('');

    const matchedOptions = allTimezones.filter(function filter(timezone) {
        const fullName = 'UTC' + moment.tz(timezone).format('Z') + timezone;
        const zoneName = fullName
            .toString()
            .replace(/_/g, ' ')
            .toLowerCase();
        const searchWord = searchQuery.toLowerCase();
        const abbreviation = moment
            .tz(timezone)
            .format('z')
            .toLowerCase();
        return zoneName.includes(searchWord) || abbreviation.includes(searchWord);
    });

    return (
        <Select
            searchQuery={searchQuery}
            onSearch={setSearchQuery}
            placeholder={moment.tz(value).format('z')}
            size='xlarge'
            label='Time zone'
            width={width}
            value={value}
            onChange={onChange}
        >
            {matchedOptions.map((option) => {
                // We take all matched options and pass them as SelectOptions
                return (
                    <SelectOption value={option} label={moment.tz(option).format('z')} key={option}>
                        {() => (
                            <React.Fragment>
                                (UTC{moment.tz(option).format('Z')}) {option.toString().replace(/_/g, ' ')}
                            </React.Fragment>
                        )}
                    </SelectOption>
                );
            })}
            {!matchedOptions.length ? (
                // If there are no matched options, we display a message to our
                // users saying their query returned no results
                <Column alignmentVertical='center' spacing='300' spacingInset='600'>
                    <Text alignment='center'>No results</Text>
                </Column>
            ) : null}
        </Select>
    );
};

export default TimeZoneInput;
