import Column from '@amzn/meridian/column';
import Text from '@amzn/meridian/text';
import React from 'react';

const NoSearchResults: React.FunctionComponent = () => {
    return (
        <Column alignmentVertical='center' spacing='300' spacingInset='600'>
            <Text alignment='center'>No results found</Text>
        </Column>
    );
};

export default NoSearchResults;
