import Button from '@amzn/meridian/button';
import Column from '@amzn/meridian/column';
import Divider from '@amzn/meridian/divider';
import Icon from '@amzn/meridian/icon';
import Link from '@amzn/meridian/link';
import Loader from '@amzn/meridian/loader';
import Row from '@amzn/meridian/row';
import SearchField, { SearchSuggestion } from '@amzn/meridian/search-field';
import Sheet from '@amzn/meridian/sheet';
import Text from '@amzn/meridian/text';
import closeTokens from '@amzn/meridian-tokens/base/icon/close';
import { DateTime } from 'luxon';
import moment from 'moment-timezone';
import React, { useState, Fragment, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState, useAppDispatch } from '../../context/store';
import { IncidentIdAndName, Proximity } from '../../lib/CaseDefinitions';
import { SHEET_WIDTH } from '../../lib/constants';
import { closeSheetButton, renderFacilityList, renderLocation } from '../../lib/helpers';
import { closeConsultationDetail, displayDetail, editConsultation } from '../../slices/ConsultationsSlice';
import { addToast } from '../../slices/ToastSlice';
import {
    useLazyGetConsultationDetailsQuery,
    useLazyLinkCaseConsultationQuery,
    useSearchIncidentNameQuery
} from '../../slices/WotManApiSlice';
import LoadingInput from '../Inputs/LoadingInput';
import NoSearchResults from '../Inputs/NoSearchResults';
import EditConsultationsModal from './EditConsultationModal';

const ConsultationDetailsSheet: React.FunctionComponent = () => {
    const { displayingSheet, consultationDisplayed, consultationId } = useSelector(
        (state: RootState) => state.consultations
    );
    const [query, setQuery] = useState('');
    const dispatch = useAppDispatch();
    const history = useHistory();

    const { data } = useSearchIncidentNameQuery({ prefix: query });
    const [getConsultationDetails, getConsultationEndpoint] = useLazyGetConsultationDetailsQuery();
    const consultationDetails = getConsultationEndpoint.data;
    const [useLinkConsultation, useLinkConsultationEndpoint] = useLazyLinkCaseConsultationQuery();

    const incidentDate = consultationDisplayed.eventTime
        ? DateTime.fromISO(`${consultationDisplayed.eventDate}T${consultationDisplayed.eventTime}`, {
              zone: 'utc'
          }).setZone(consultationDisplayed.timeZone || moment.tz.guess())
        : undefined;

    useEffect(() => {
        if (consultationId !== -1) {
            getConsultationDetails({ consultationId: consultationId });
        }
    }, [consultationId]);

    useEffect(() => {
        if (!useLinkConsultationEndpoint.isFetching && useLinkConsultationEndpoint.isSuccess) {
            dispatch(editConsultation(useLinkConsultationEndpoint.data));
        } else if (!useLinkConsultationEndpoint.isFetching && useLinkConsultationEndpoint.isError) {
            dispatch(
                addToast({
                    type: 'error',
                    action: 'There was an error linking an engagement, please try again',
                    message: '',
                    SIM: true,
                    timeout: undefined
                })
            );
        }
    }, [useLinkConsultationEndpoint.isFetching]);

    useEffect(() => {
        if (consultationDetails) {
            dispatch(displayDetail(consultationDetails));
        }
    }, [consultationDetails]);

    return (
        <Sheet
            type='push'
            side='right'
            open={displayingSheet}
            onClose={() => dispatch(closeConsultationDetail())}
        >
            {!getConsultationEndpoint.isFetching && getConsultationEndpoint.isSuccess && consultationDetails && (
                <Column width={SHEET_WIDTH} spacing='400'>
                    <Row alignmentHorizontal='justify'>
                        <Text alignment='left' type='h300'>
                            Engagement details
                        </Text>
                        {closeSheetButton(() => dispatch(closeConsultationDetail()))}
                    </Row>
                    <Row alignmentHorizontal='justify' alignmentVertical='top'>
                        <Text type='h200'> Event information </Text>
                        <EditConsultationsModal />
                    </Row>
                    <Fragment>
                        <Row alignmentVertical='top' widths={['50%', '50%']}>
                            <Column spacing='none'>
                                <Text type='h100'> Time of event </Text>
                                <Text>
                                    {incidentDate
                                        ? incidentDate.toLocaleString(DateTime.DATE_MED)
                                        : DateTime.fromISO(consultationDisplayed.eventDate).toLocaleString(
                                              DateTime.DATE_MED
                                          )}
                                </Text>
                                {incidentDate && <Text> {incidentDate.toFormat('HH:mm ZZZZ')}</Text>}
                            </Column>
                        </Row>
                        <Column spacing='none'>
                            <Text type='h100'> Proximity </Text>
                            <Text type='b300'> {consultationDisplayed.proximity} </Text>
                        </Column>
                        {consultationDisplayed.offsiteLocation &&
                            consultationDisplayed.proximity === Proximity.OFFSITE && (
                                <>
                                    <Column spacing='none'>
                                        <Text type='h100'> Event Location </Text>
                                        {renderLocation(consultationDisplayed.offsiteLocation)}
                                        {consultationDisplayed.offsiteLocation.address == '' &&
                                            consultationDisplayed.offsiteLocation.city == '' &&
                                            consultationDisplayed.offsiteLocation.country == null &&
                                            consultationDisplayed.offsiteLocation.region == null &&
                                            consultationDisplayed.offsiteLocation.state == '' &&
                                            consultationDisplayed.offsiteLocation.zipcode == '' && (
                                                <Text color={'secondary'}> None </Text>
                                            )}
                                    </Column>
                                </>
                            )}
                        <>
                            <Text type='h100'> Primary facility </Text>
                            {renderFacilityList(consultationDisplayed.primaryFacility)}
                        </>
                        <>
                            <Text type='h100'> Secondary facility </Text>
                            {renderFacilityList(consultationDisplayed.secondaryFacilityList)}
                        </>
                    </Fragment>
                    <Divider size='small' />
                    <Column spacing='300'>
                        <Row alignmentHorizontal='justify' alignmentVertical='top'>
                            <Text type='h200'> Communicated with </Text>
                        </Row>
                        <Row widths='100%' wrap='down'>
                            {consultationDisplayed.communicatedWith.map((person, index) => (
                                <Column key={index} spacing='none'>
                                    <Text> {person.login} </Text>
                                    <Text> {person.name} </Text>
                                    <Text> {person.title} </Text>
                                    <Text> {person.phoneNumber} </Text>
                                    <Text> {person.email} </Text>
                                </Column>
                            ))}
                        </Row>
                    </Column>
                    <Divider size='small' />
                    <Row alignmentHorizontal='justify' alignmentVertical='top'>
                        <Text type='h200'> Engagement information </Text>
                    </Row>
                    <Fragment>
                        <Column spacing='none'>
                            <Text type='h100'> Method of communication </Text>
                            <Text> {consultationDisplayed.method} </Text>
                        </Column>
                        <Column spacing='none'>
                            <Text type='h100'> Title </Text>
                            <Text> {consultationDisplayed.title} </Text>
                        </Column>
                        <Column spacing='none'>
                            <Text type='h100'> Summary </Text>
                            <Text>
                                {consultationDisplayed.summary.split('\n').map((item) => (
                                    <span key={item}>
                                        {item}
                                        <br />
                                    </span>
                                ))}
                            </Text>
                        </Column>
                    </Fragment>
                    <Divider size='small' />
                    <Text type='h100'> Linked to </Text>
                    <SearchField
                        value={query}
                        onChange={setQuery}
                        width='272px'
                        placeholder='Search for incident ID...'
                        onSubmit={(caseId) => {
                            useLinkConsultation({
                                consultationId: consultationDisplayed.consultationId,
                                caseId: caseId,
                                action: 'link'
                            });
                            setQuery('');
                        }}
                    >
                        {data ? (
                            data.length > 0 ? (
                                data.map((incident: IncidentIdAndName) => (
                                    <SearchSuggestion
                                        key={incident.incidentId}
                                        onClick={() =>
                                            useLinkConsultation({
                                                consultationId: consultationDisplayed.consultationId,
                                                caseId: incident.incidentId,
                                                action: 'link'
                                            })
                                        }
                                    >
                                        {incident.incidentName}
                                    </SearchSuggestion>
                                ))
                            ) : (
                                <NoSearchResults />
                            )
                        ) : (
                            <LoadingInput />
                        )}
                    </SearchField>
                    {consultationDisplayed.linkedCases.map((linkedCase: IncidentIdAndName) => (
                        <Row key={linkedCase.incidentId} alignmentHorizontal={'justify'}>
                            <Link onClick={history.push} href={`/cases/${linkedCase.incidentId}`}>
                                {linkedCase.incidentName}
                            </Link>
                            <Button
                                type='secondary'
                                size='small'
                                onClick={() => {
                                    useLinkConsultation({
                                        consultationId: consultationDisplayed.consultationId,
                                        caseId: linkedCase.incidentId,
                                        action: 'unlink'
                                    });
                                }}
                            >
                                <Icon tokens={closeTokens} />
                            </Button>
                        </Row>
                    ))}
                </Column>
            )}
            {getConsultationEndpoint.isFetching && (
                <Column
                    alignmentHorizontal={'center'}
                    alignmentVertical={'center'}
                    height={'100%'}
                    width={SHEET_WIDTH}
                >
                    <Loader />
                </Column>
            )}
        </Sheet>
    );
};

export default ConsultationDetailsSheet;
