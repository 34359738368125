import Select, { SelectOption } from '@amzn/meridian/select';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../context/store';
import { sortDisplayedConsultations } from '../../slices/ConsultationsSlice';

const ConsultationSortingDropDown: React.FunctionComponent = () => {
    const { consultations } = useSelector((state: RootState) => state.consultations);
    const dispatch = useAppDispatch();
    const [value, setValue] = useState('recentActivity');

    useEffect(() => {
        dispatch(sortDisplayedConsultations(value));
    }, [value, consultations]);

    return (
        <Select value={value} onChange={setValue} placeholder={'Select criteria'} width={176}>
            <SelectOption value='title' label='Title' />
            <SelectOption value='method' label='Method' />
            <SelectOption value='addedBy' label='Added by' />
            <SelectOption value='recentActivity' label='Recent activity' />
        </Select>
    );
};
export default ConsultationSortingDropDown;
