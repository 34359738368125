import Button from '@amzn/meridian/button';
import Column from '@amzn/meridian/column';
import Divider from '@amzn/meridian/divider';
import Expander from '@amzn/meridian/expander';
import Modal, { ModalFooter } from '@amzn/meridian/modal';
import Row from '@amzn/meridian/row';
import Sheet from '@amzn/meridian/sheet';
import Text from '@amzn/meridian/text';
import editIconTokens from '@amzn/meridian-tokens/base/icon/edit';
import _ from 'lodash';
import { DateTime } from 'luxon';
import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../context/store';
import { CreatePersonInvolved, PersonInvolvedType } from '../../../lib/CaseDefinitions';
import { MODAL_WIDTH, SELECT_WIDTH, SHEET_WIDTH, USER_ROLES } from '../../../lib/constants';
import {
    closeSheetButton,
    convertPersonInvolvedToCreate,
    formattedTenure,
    getEmployeeJobStatusType
} from '../../../lib/helpers';
import { labels } from '../../../lib/labels';
import {
    closeDetail,
    closePersonInvolvedModal,
    setCaseDetails,
    setCreatePersonInvolvedViewing
} from '../../../slices/CaseDetailsSlice';
import { addToast } from '../../../slices/ToastSlice';
import { useLazyAddEditContactQuery } from '../../../slices/WotManApiSlice';
import AlertModal from '../../Alert/AlertModal';
import AdminDirectoryLink from '../../CustomComponents/AdminDirectoryLink';
import ChimeLink from '../../CustomComponents/ChimeLink';
import TitledButton from '../../CustomComponents/TitledButton';
import AddPersonsInvolved from './AddPersonsInvolved';

const ViewingPersonsInvolved: React.FunctionComponent = () => {
    const {
        caseDetails,
        displayPersonInvolvedSheet,
        displayReportedBy,
        personInvolvedDisplaying,
        displayPersonsInvolvedModal,
        isCaseOpen
    } = useSelector((state: RootState) => state.caseDetails);
    const dispatch = useAppDispatch();
    const [open, setOpen] = useState(false);
    const [openTerminationReason, setOpenTerminationReason] = useState(false);
    const [createPersonInvolved, setCreatePersonInvolved] = useState<CreatePersonInvolved>(
        convertPersonInvolvedToCreate(personInvolvedDisplaying)
    );

    const [missingRole, setMissingRole] = useState<boolean>(false);
    const [missingName, setMissingName] = useState<boolean>(false);
    const [showAlertPrompt, setShowAlertPrompt] = useState(false);
    const [nameIsEmptySpaces, setNameIsEmptySpaces] = useState<boolean>(false);
    const [useEditContact, useEditContactEndpoint] = useLazyAddEditContactQuery();
    const [personId, setPersonId] = useState<number | undefined>(undefined);
    const { threatManagers } = useSelector((state: RootState) => state.selectOptions);
    const { userId } = useSelector((state: RootState) => state.dashboard);
    const isAdmin = _.some(threatManagers.admins, { empId: userId });
    const user =
        _.find(threatManagers.admins, { empId: userId }) || _.find(threatManagers.users, { empId: userId });
    const userRole = isAdmin ? USER_ROLES.ADMIN : user ? USER_ROLES.STANDARD_USER : USER_ROLES.SYSTEM_ADMIN;
    const isSystemAdmin = userRole.includes(USER_ROLES.SYSTEM_ADMIN);
    const setAndConvertPersonInvolved = () => {
        setCreatePersonInvolved(convertPersonInvolvedToCreate(personInvolvedDisplaying));
    };

    const handleShowModal = () => {
        setShowAlertPrompt(false);
    };

    const handleCancel = () => {
        setShowAlertPrompt(false);
        setMissingRole(false);
        setMissingName(false);
        setNameIsEmptySpaces(false);
        dispatch(closePersonInvolvedModal());
        setAndConvertPersonInvolved();
    };

    const isFormFilled = () => {
        if (createPersonInvolved.isDirty) setShowAlertPrompt(true);
        else {
            handleCancel();
        }
    };

    const editButton = () => (
        <TitledButton
            title={labels.editConsultation}
            buttonProps={{
                onClick: () => {
                    setAndConvertPersonInvolved();
                    dispatch(setCreatePersonInvolvedViewing());
                    setPersonId(personInvolvedDisplaying.contactId);
                },
                type: 'secondary',
                size: 'small'
            }}
            iconProps={{ tokens: editIconTokens }}
        />
    );

    useEffect(() => {
        if (!useEditContactEndpoint.isFetching && useEditContactEndpoint.isSuccess) {
            dispatch(setCaseDetails(useEditContactEndpoint.data));
            dispatch(closePersonInvolvedModal());
            dispatch(closeDetail());
            dispatch(
                addToast({
                    type: 'success',
                    action:
                        'Edited ' +
                        createPersonInvolved.role?.toLowerCase() +
                        ' ' +
                        createPersonInvolved.name,
                    message: '',
                    SIM: false,
                    timeout: 3000
                })
            );
        } else if (!useEditContactEndpoint.isFetching && useEditContactEndpoint.isError) {
            dispatch(
                addToast({
                    type: 'error',
                    action: useEditContactEndpoint.error.data,
                    message: '',
                    SIM: true,
                    timeout: undefined
                })
            );
        }
    }, [useEditContactEndpoint.isFetching]);

    const addEditContact = () => {
        if (!createPersonInvolved.role) {
            setMissingRole(true);
        }
        if (createPersonInvolved.name == '') {
            setMissingName(true);
        }
        if (createPersonInvolved.name !== '' && !createPersonInvolved.name?.trim()) {
            setNameIsEmptySpaces(true);
        }
        if (
            createPersonInvolved.role &&
            createPersonInvolved.name != '' &&
            createPersonInvolved.name?.trim()
        ) {
            useEditContact({
                personInvolved: {
                    contactId: personId,
                    role: createPersonInvolved.role,
                    name: createPersonInvolved.name,
                    login: createPersonInvolved.login,
                    title: createPersonInvolved.title,
                    phoneNumber: createPersonInvolved.phoneNumber,
                    email: createPersonInvolved.email,
                    personId: createPersonInvolved.personId,
                    incidentId: caseDetails.caseId
                },
                personId: personId
            });
            setMissingRole(false);
            setMissingName(false);
            setNameIsEmptySpaces(false);
        }
    };

    const canEdit =
        personInvolvedDisplaying.addedBy && personInvolvedDisplaying.addedBy.canEdit && isCaseOpen;

    const EditPersonInvolvedModal = (props: { isAddPersonsDisabled: boolean }) => (
        <Column>
            <Modal
                title={'Edit person involved'}
                open={displayPersonsInvolvedModal}
                width={MODAL_WIDTH}
                onClose={() => isFormFilled()}
            >
                <Column width={SELECT_WIDTH}>
                    <AddPersonsInvolved
                        personAdding={createPersonInvolved}
                        setCaseVars={setCreatePersonInvolved}
                        missingName={missingName && createPersonInvolved.name == ''}
                        missingRole={missingRole && createPersonInvolved.role == undefined}
                        isDisabledInput={props.isAddPersonsDisabled}
                        nameIsEmptySpaces={
                            nameIsEmptySpaces &&
                            createPersonInvolved.name !== '' &&
                            !createPersonInvolved.name?.trim()
                        }
                    />
                </Column>
                <ModalFooter>
                    <Row alignmentHorizontal={'justify'}>
                        <Button type='secondary' onClick={() => isFormFilled()}>
                            Cancel
                        </Button>
                        <Button disabled={useEditContactEndpoint.isFetching} onClick={() => addEditContact()}>
                            Save
                        </Button>
                    </Row>
                </ModalFooter>
            </Modal>
        </Column>
    );

    const AlertPrompt = () => (
        <AlertModal
            showPrompt={true}
            handleConfirm={handleShowModal}
            onCancel={handleCancel}
            onClose={handleShowModal}
        />
    );

    const InfoSection = (props: { label: string; value: string | undefined }) => (
        <Row alignmentVertical='top'>
            <Column spacing='none'>
                <Text type='h100'> {props.label} </Text>
                <Text type='b300'> {props.value} </Text>
            </Column>
        </Row>
    );

    switch (personInvolvedDisplaying.type) {
        // TODO: Implement UI for Internal Person with updates
        case PersonInvolvedType.INTERNAL_PERSON:
            return (
                <Fragment>
                    <EditPersonInvolvedModal isAddPersonsDisabled={true} />
                    <Sheet
                        type='push'
                        side='right'
                        open={displayPersonInvolvedSheet}
                        onClose={() => dispatch(closeDetail())}
                    >
                        {personInvolvedDisplaying && (
                            <Column width={SHEET_WIDTH} spacing='400'>
                                <Row alignmentHorizontal='justify'>
                                    <Text alignment='left' type='h300'>
                                        {' '}
                                        {displayReportedBy && 'Report By'}
                                        {personInvolvedDisplaying &&
                                            !displayReportedBy &&
                                            personInvolvedDisplaying.role}
                                    </Text>
                                    {closeSheetButton(() => dispatch(closeDetail()))}
                                </Row>
                                <Row alignmentHorizontal='justify' alignmentVertical='top'>
                                    <Text type='h200'> Basic information </Text>
                                    {canEdit && editButton()}
                                </Row>
                                <Fragment>
                                    <Row alignmentHorizontal='justify'>
                                        <Column spacing='none'>
                                            <Text type='h100'> Name </Text>

                                            <Text type='b300'> {personInvolvedDisplaying.name} </Text>
                                            {!isSystemAdmin &&
                                                personInvolvedDisplaying.legalName != '' &&
                                                personInvolvedDisplaying.legalName !=
                                                    personInvolvedDisplaying.name && (
                                                    <Expander
                                                        open={open}
                                                        onChange={setOpen}
                                                        title='View legal name'
                                                        type='inline'
                                                    >
                                                        <Text>{personInvolvedDisplaying.legalName}</Text>
                                                    </Expander>
                                                )}
                                        </Column>
                                    </Row>
                                    <InfoSection label='Alias' value={personInvolvedDisplaying.login} />
                                    <InfoSection
                                        label='Employee ID'
                                        value={personInvolvedDisplaying.employeeId}
                                    />
                                </Fragment>

                                <Divider size='small' />

                                <Row alignmentHorizontal='justify' alignmentVertical='top'>
                                    <Text type='h200'> Employment information </Text>
                                </Row>
                                <Fragment>
                                    <Column spacing='400'>
                                        <InfoSection
                                            label='Title'
                                            value={
                                                personInvolvedDisplaying.title +
                                                ' ' +
                                                personInvolvedDisplaying.jobLevel
                                                    ? '(L' + personInvolvedDisplaying.jobLevel + ')'
                                                    : ''
                                            }
                                        />
                                        <Row alignmentVertical='top'>
                                            <Column spacing='none'>
                                                <Text type='h100'> Status </Text>
                                                {getEmployeeJobStatusType(personInvolvedDisplaying.status)}
                                                {!isSystemAdmin &&
                                                    personInvolvedDisplaying.terminationReason &&
                                                    personInvolvedDisplaying.terminationReason != '' && (
                                                        <Expander
                                                            open={openTerminationReason}
                                                            onChange={setOpenTerminationReason}
                                                            title='View termination reason'
                                                            type='inline'
                                                        >
                                                            <Text>
                                                                {personInvolvedDisplaying.terminationReason}
                                                            </Text>
                                                        </Expander>
                                                    )}
                                            </Column>
                                        </Row>
                                        {!isSystemAdmin &&
                                            personInvolvedDisplaying.futureTerminationDate &&
                                            personInvolvedDisplaying.futureTerminationDate != '' && (
                                                <InfoSection
                                                    label='Future Termination Date'
                                                    value={DateTime.fromISO(
                                                        personInvolvedDisplaying.futureTerminationDate
                                                    ).toLocaleString(DateTime.DATE_MED)}
                                                />
                                            )}
                                        <InfoSection
                                            label='Tenure'
                                            value={formattedTenure(personInvolvedDisplaying.tenure)}
                                        />
                                    </Column>
                                </Fragment>

                                <Divider size='small' />

                                <Row alignmentHorizontal='justify' alignmentVertical='top'>
                                    <Text type='h200'> Contact information </Text>
                                </Row>
                                <Fragment>
                                    {personInvolvedDisplaying.workEmail !== '' ||
                                    personInvolvedDisplaying.workPhone !== '' ? (
                                        <Fragment>
                                            <Column spacing='none'>
                                                <Text type='h100'> Work contact </Text>
                                                <Text> {personInvolvedDisplaying.workEmail} </Text>
                                                <Text> {personInvolvedDisplaying.workPhone} </Text>
                                            </Column>
                                            {!isSystemAdmin &&
                                                (personInvolvedDisplaying.personalEmail !== '' ||
                                                personInvolvedDisplaying.phoneNumber !== '' ? (
                                                    <Column spacing='none'>
                                                        <Text type='h100'> Personal contact </Text>
                                                        <Text>
                                                            {' '}
                                                            {personInvolvedDisplaying.personalEmail}{' '}
                                                        </Text>
                                                        <Text> {personInvolvedDisplaying.phoneNumber} </Text>
                                                        <Text> {personInvolvedDisplaying.address} </Text>
                                                    </Column>
                                                ) : (
                                                    <Column spacing='none'>
                                                        <Text type='h100'> Personal contact </Text>
                                                        <Text color='secondary'> None </Text>
                                                    </Column>
                                                ))}
                                        </Fragment>
                                    ) : (
                                        <Fragment>
                                            <Column spacing='none'>
                                                <Text type='h100'> Work contact </Text>
                                                <Text color='secondary'> None </Text>
                                            </Column>
                                            {!isSystemAdmin &&
                                                (personInvolvedDisplaying.personalEmail !== '' ||
                                                personInvolvedDisplaying.phoneNumber !== '' ? (
                                                    <Column spacing='none'>
                                                        <Text type='h100'> Personal contact </Text>
                                                        <Text>
                                                            {' '}
                                                            {personInvolvedDisplaying.personalEmail}{' '}
                                                        </Text>
                                                        <Text> {personInvolvedDisplaying.phoneNumber} </Text>
                                                        <Text> {personInvolvedDisplaying.address} </Text>
                                                    </Column>
                                                ) : (
                                                    <Column spacing='none'>
                                                        <Text type='h100'> Personal contact </Text>
                                                        <Text color='secondary'> None </Text>
                                                    </Column>
                                                ))}
                                        </Fragment>
                                    )}
                                </Fragment>

                                <Divider size='small' />
                                <AdminDirectoryLink alias={personInvolvedDisplaying.login} />
                            </Column>
                        )}
                    </Sheet>
                    {showAlertPrompt && <AlertPrompt />}
                </Fragment>
            );

        // TODO: Implement UI for External Person with updates
        case PersonInvolvedType.EXTERNAL_PERSON:
            return (
                <Fragment>
                    <EditPersonInvolvedModal isAddPersonsDisabled={false} />
                    <Sheet
                        type='push'
                        side='right'
                        open={displayPersonInvolvedSheet}
                        onClose={() => dispatch(closeDetail())}
                    >
                        {personInvolvedDisplaying && (
                            <Column width={SHEET_WIDTH} spacing='400'>
                                <Row alignmentHorizontal='justify'>
                                    <Text alignment='left' type='h300'>
                                        Person of interest
                                    </Text>
                                    {closeSheetButton(() => dispatch(closeDetail()))}
                                </Row>
                                <Row alignmentHorizontal='justify' alignmentVertical='top'>
                                    <Text type='h200'> Basic information </Text>
                                    {canEdit && editButton()}
                                </Row>
                                <Fragment>
                                    <InfoSection label='Name' value={personInvolvedDisplaying.name} />
                                    <InfoSection label='Title' value={personInvolvedDisplaying.title} />
                                </Fragment>

                                <Divider size='small' />

                                <Column spacing='300'>
                                    <Row alignmentHorizontal='justify' alignmentVertical='top'>
                                        <Text type='h200'> Contact information </Text>
                                    </Row>
                                    <Fragment>
                                        <Row alignmentVertical='top'>
                                            <Column spacing='none'>
                                                <Text type='b300'> {personInvolvedDisplaying.email} </Text>
                                                <Text type='b300'>
                                                    {' '}
                                                    {personInvolvedDisplaying.phoneNumber}{' '}
                                                </Text>
                                            </Column>
                                        </Row>
                                    </Fragment>
                                </Column>
                            </Column>
                        )}
                    </Sheet>
                    {showAlertPrompt && <AlertPrompt />}
                </Fragment>
            );
        case PersonInvolvedType.INTERNAL_CONTACT:
            return (
                <Fragment>
                    <EditPersonInvolvedModal isAddPersonsDisabled={true} />
                    <Sheet
                        type='push'
                        side='right'
                        open={displayPersonInvolvedSheet}
                        onClose={() => dispatch(closeDetail())}
                    >
                        {personInvolvedDisplaying && (
                            <Column width={SHEET_WIDTH} spacing='400'>
                                <Row alignmentHorizontal='justify'>
                                    <Text alignment='left' type='h300'>
                                        {' '}
                                        {personInvolvedDisplaying && !displayReportedBy
                                            ? personInvolvedDisplaying.role
                                            : 'Report By'}
                                    </Text>
                                    {closeSheetButton(() => dispatch(closeDetail()))}
                                </Row>
                                <Row alignmentHorizontal='justify' alignmentVertical='top'>
                                    <Text type='h200'> Basic information </Text>
                                    {canEdit && editButton()}
                                </Row>
                                <Fragment>
                                    <InfoSection label='Name' value={personInvolvedDisplaying.name} />
                                    <InfoSection label='Alias' value={personInvolvedDisplaying.login} />
                                    <InfoSection
                                        label='Title'
                                        value={
                                            personInvolvedDisplaying.title +
                                            ' ' +
                                            (personInvolvedDisplaying.jobLevel
                                                ? '(L' + personInvolvedDisplaying.jobLevel + ')'
                                                : '')
                                        }
                                    />
                                </Fragment>
                                <Divider size='small' />
                                <Row alignmentHorizontal='justify' alignmentVertical='top'>
                                    <Text type='h200'> Contact information </Text>
                                </Row>
                                <Fragment>
                                    <Column spacing='none'>
                                        <Text> {personInvolvedDisplaying.email} </Text>
                                        <Text> {personInvolvedDisplaying.phoneNumber} </Text>
                                    </Column>
                                </Fragment>
                                {personInvolvedDisplaying.login && (
                                    <ChimeLink alias={personInvolvedDisplaying.login} />
                                )}
                            </Column>
                        )}
                    </Sheet>
                    {showAlertPrompt && <AlertPrompt />}
                </Fragment>
            );

        // TODO: Implement UI for External contact with updates
        case PersonInvolvedType.EXTERNAL_CONTACT:
            return (
                <Fragment>
                    <EditPersonInvolvedModal isAddPersonsDisabled={false} />
                    <Sheet
                        type='push'
                        side='right'
                        open={displayPersonInvolvedSheet}
                        onClose={() => dispatch(closeDetail())}
                    >
                        {personInvolvedDisplaying && (
                            <Column width={SHEET_WIDTH} spacing='400'>
                                <Row alignmentHorizontal='justify'>
                                    <Text alignment='left' type='h300'>
                                        {' '}
                                        Contact
                                    </Text>
                                    {closeSheetButton(() => dispatch(closeDetail()))}
                                </Row>
                                <Row alignmentHorizontal='justify' alignmentVertical='top'>
                                    <Text type='h200'> Basic information </Text>
                                    {canEdit && editButton()}
                                </Row>
                                <Fragment>
                                    <InfoSection label='Name' value={personInvolvedDisplaying.name} />
                                    <InfoSection label='Alias' value={personInvolvedDisplaying.login} />
                                    <InfoSection label='Title' value={personInvolvedDisplaying.title} />
                                </Fragment>

                                <Divider size='small' />

                                <Row alignmentHorizontal='justify' alignmentVertical='top'>
                                    <Text type='h200'> Contact information </Text>
                                </Row>
                                <Fragment>
                                    <Column spacing='none'>
                                        <Text> {personInvolvedDisplaying.email} </Text>
                                        <Text> {personInvolvedDisplaying.phoneNumber} </Text>
                                    </Column>
                                </Fragment>
                            </Column>
                        )}
                    </Sheet>
                    {showAlertPrompt && <AlertPrompt />}
                </Fragment>
            );

        case PersonInvolvedType.FACMAN_CONTACT:
            return (
                <Sheet
                    type='push'
                    side='right'
                    open={displayPersonInvolvedSheet}
                    onClose={() => dispatch(closeDetail())}
                >
                    {personInvolvedDisplaying && (
                        <Column width={SHEET_WIDTH} spacing='400'>
                            <Row alignmentHorizontal='justify'>
                                <Text alignment='left' type='h300'>
                                    {' '}
                                    Contact
                                </Text>
                                {closeSheetButton(() => dispatch(closeDetail()))}
                            </Row>
                            <Row alignmentHorizontal='justify' alignmentVertical='top'>
                                <Text type='h200'> Basic information </Text>
                                {canEdit && editButton()}
                            </Row>
                            <Fragment>
                                <InfoSection label='Name' value={personInvolvedDisplaying.name} />
                                <InfoSection label='Title' value={personInvolvedDisplaying.teamName} />
                                <InfoSection label='Notes' value={personInvolvedDisplaying.notes} />
                            </Fragment>

                            <Divider size='small' />

                            <Row alignmentHorizontal='justify' alignmentVertical='top'>
                                <Text type='h200'> Contact information </Text>
                            </Row>
                            <Fragment>
                                <InfoSection label='Email' value={personInvolvedDisplaying.email} />
                                <InfoSection
                                    label='PhoneNumber'
                                    value={personInvolvedDisplaying.phoneNumber}
                                />
                                <InfoSection label='Address' value={personInvolvedDisplaying.address} />
                            </Fragment>
                        </Column>
                    )}
                    {showAlertPrompt && <AlertPrompt />}
                </Sheet>
            );
    }
    return null;
};

export default ViewingPersonsInvolved;
