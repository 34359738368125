import Button from '@amzn/meridian/button';
import Column from '@amzn/meridian/column';
import Popover, { PopoverHeader } from '@amzn/meridian/popover';
import Select, { SelectOption } from '@amzn/meridian/select';
import _ from 'lodash';
import React, { useState } from 'react';
import { IncidentIdAndName } from '../../lib/CaseDefinitions';
import { useSearchIncidentNameQuery } from '../../slices/WotManApiSlice';
import LoadingInput from './LoadingInput';
import NoSearchResults from './NoSearchResults';

type LinkCaseInputProps = {
    setOpenCasePopover: (isOpen: boolean) => void;
    caseRef: React.MutableRefObject<undefined>;
    openCasePopover: boolean;
    onSubmit: (incidentId: number) => void;
    isDisabled: boolean;
    casesNotDisplaying: number[];
    setCaseName: (name: string) => void;
};

const LinkCaseInput: React.FunctionComponent<LinkCaseInputProps> = ({
    setOpenCasePopover,
    caseRef,
    openCasePopover,
    onSubmit,
    isDisabled,
    casesNotDisplaying,
    setCaseName
}: LinkCaseInputProps) => {
    const [query, setQuery] = useState<string>('');
    const [value, setValue] = useState<number | undefined>(undefined);

    const { data } = useSearchIncidentNameQuery({ prefix: query });
    const linkedCaseOptions =
        data &&
        data.filter((incident: IncidentIdAndName) => !casesNotDisplaying.includes(incident.incidentId));

    const handleSave = () => {
        if (value) {
            onSubmit(value);
            const caseToLink = _.find(linkedCaseOptions, (incident) => incident.incidentId === value);
            setCaseName(caseToLink?.incidentName);
        }
    };

    return (
        <Popover
            anchorNode={caseRef.current}
            open={openCasePopover}
            onClose={() => {
                setOpenCasePopover(false);
                setQuery('');
                setValue(undefined);
            }}
            position='bottom'
        >
            <PopoverHeader>Link case</PopoverHeader>
            <Column>
                <Select
                    width='330px'
                    value={value}
                    onChange={setValue}
                    placeholder='Search for case ID...'
                    searchQuery={query}
                    onSearch={setQuery}
                >
                    {linkedCaseOptions ? (
                        linkedCaseOptions.length > 0 ? (
                            linkedCaseOptions.map((incident: IncidentIdAndName) => {
                                return (
                                    <SelectOption
                                        key={incident.incidentId}
                                        value={incident.incidentId}
                                        label={incident.incidentName}
                                    />
                                );
                            })
                        ) : (
                            <NoSearchResults />
                        )
                    ) : (
                        <LoadingInput />
                    )}
                </Select>
                <Button minWidth='100%' onClick={handleSave} disabled={isDisabled || !value}>
                    {' '}
                    Save{' '}
                </Button>
            </Column>
        </Popover>
    );
};

export default LinkCaseInput;
