import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';
import {
    CreateCaseVars,
    CreateConsultationVars,
    OffSiteLocationAdding,
    Proximity,
    SiteIdAndCode
} from '../lib/CaseDefinitions';

interface AddProximityState {
    proximity?: Proximity;
    siteIds: SiteIdAndCode[];
    secondarySiteIds: SiteIdAndCode[];
    inputLocation: OffSiteLocationAdding;
}

const blankInput = {
    address: '',
    city: '',
    state: '',
    zipcode: ''
};

const initialState: AddProximityState = {
    inputLocation: blankInput,
    siteIds: [],
    secondarySiteIds: []
};

//Todo: backend for getFacilityFromCodes
const addProximity = createSlice({
    name: 'advancedSearch',
    initialState,
    reducers: {
        changeProximity: (state: AddProximityState, { payload }: PayloadAction<Proximity>) => {
            state.proximity = payload;
            switch (payload) {
                case Proximity.ONSITE:
                    state.siteIds = [];
                    state.secondarySiteIds = [];
                    state.inputLocation = blankInput;
                    break;
                case Proximity.OFFSITE:
                    state.siteIds = [];
                    state.secondarySiteIds = [];
                    state.inputLocation = blankInput;
                    break;
            }
        },
        setSiteIds: (state: AddProximityState, { payload }: PayloadAction<SiteIdAndCode[]>) => {
            state.siteIds = payload;
        },
        setSecondarySiteIds: (state: AddProximityState, { payload }: PayloadAction<SiteIdAndCode[]>) => {
            state.secondarySiteIds = payload;
        },
        reset(state: AddProximityState) {
            state.inputLocation = blankInput;
            state.siteIds = [];
            state.proximity = undefined;
            state.secondarySiteIds = [];
        },
        setInputLocation: (state: AddProximityState, { payload }: PayloadAction<OffSiteLocationAdding>) => {
            state.inputLocation = payload;
        }
    }
});

export const {
    changeProximity,
    setSiteIds,
    reset,
    setInputLocation,
    setSecondarySiteIds
} = addProximity.actions;

export const createCaseObject = (
    caseCreating: CreateCaseVars,
    proximity: Proximity,
    siteIds: SiteIdAndCode[],
    offsiteLocation: OffSiteLocationAdding | undefined,
    secondarySiteIds: SiteIdAndCode[],
    lpContact: boolean
) => {
    if (proximity === Proximity.ONSITE) {
        offsiteLocation = undefined;
    }
    let dateUtc;
    let timeUtc;
    let timeZone;
    if (caseCreating.timeOfEvent) {
        const dateTimeOfEvent = DateTime.fromISO(caseCreating.dateOfEvent + 'T' + caseCreating.timeOfEvent, {
            zone: caseCreating.timezone
        }).toUTC();
        dateUtc = dateTimeOfEvent.toISODate();
        timeUtc = dateTimeOfEvent.toISOTime();
        timeZone = caseCreating.timezone;
    } else {
        if (caseCreating.dateOfEvent) {
            dateUtc = caseCreating.dateOfEvent;
        }
    }
    return {
        eventType: caseCreating.eventType,
        eventItem: caseCreating.eventItem,
        eventTags: caseCreating.additionalTags,
        dateUtc,
        timeUtc,
        timeZone,
        eventSummary: caseCreating.eventSummary,
        personsInvolved: caseCreating.personsInvolved.map((person) => {
            if (person.personId == '') {
                return {
                    role: person.role,
                    name: person.name,
                    login: person.login,
                    title: person.title,
                    phoneNumber: person.phoneNumber,
                    email: person.email,
                    personId: person.personId
                };
            } else {
                return {
                    role: person.role,
                    name: '',
                    login: person.login,
                    title: '',
                    phoneNumber: '',
                    email: '',
                    personId: person.personId
                };
            }
        }),
        reportedBy:
            caseCreating.reportedBy.personId == ''
                ? caseCreating.reportedBy
                : {
                      role: caseCreating.reportedBy.role,
                      name: '',
                      login: caseCreating.reportedBy.login,
                      title: '',
                      phoneNumber: '',
                      email: '',
                      personId: caseCreating.reportedBy.personId
                  },
        lpContact:
            lpContact && caseCreating.reportedBy.personId != ''
                ? caseCreating.reportedBy.personId
                : undefined,
        assignedToEmpId: caseCreating.assignedToEmpId,
        levelOfConcern: caseCreating.levelOfConcern,
        levelOfConcernNote: caseCreating.levelOfConcernNote,
        proximity,
        siteIds: siteIds.map((facility) => facility.siteId),
        secondarySiteIds: secondarySiteIds.map((facility) => facility.siteId),
        offsiteLocation: offsiteLocation
            ? {
                  ...offsiteLocation
              }
            : undefined
    };
};

export const createAddingConsultationObject = (
    consultationCreating: CreateConsultationVars,
    proximity: Proximity,
    siteIds: SiteIdAndCode[],
    secondarySiteIds: SiteIdAndCode[],
    offsiteLocation: OffSiteLocationAdding | undefined
) => {
    if (proximity === Proximity.ONSITE) {
        offsiteLocation = undefined;
    }
    let dateUtc;
    let timeUtc;
    if (consultationCreating.timeOfEvent) {
        const dateTimeOfEvent = DateTime.fromISO(
            consultationCreating.dateOfEvent + 'T' + consultationCreating.timeOfEvent,
            {
                zone: consultationCreating.timezone
            }
        ).toUTC();
        dateUtc = dateTimeOfEvent.toISODate();
        timeUtc = dateTimeOfEvent.toISOTime();
    } else {
        if (consultationCreating.dateOfEvent) {
            dateUtc = consultationCreating.dateOfEvent;
        }
    }
    return {
        dateUtc,
        timeUtc,
        communicatedWith: consultationCreating.communicatedWith.map((commWith) => {
            if (commWith.personId == '') {
                return {
                    name: commWith.name,
                    login: commWith.login,
                    title: commWith.title,
                    phoneNumber: commWith.phoneNumber,
                    email: commWith.email,
                    personId: commWith.personId
                };
            } else {
                return {
                    name: '',
                    login: commWith.login,
                    title: '',
                    phoneNumber: '',
                    email: '',
                    personId: commWith.personId
                };
            }
        }),
        method: consultationCreating.method,
        consultationTitle: consultationCreating.consultationTitle,
        consultationSummary: consultationCreating.consultationSummary,
        proximity,
        siteIds: Array.isArray(siteIds) ? siteIds.map((facility) => facility.siteId) : undefined,
        secondarySiteIds: Array.isArray(secondarySiteIds)
            ? secondarySiteIds.map((facility) => facility.siteId)
            : undefined,
        offsiteLocation: offsiteLocation
            ? {
                  ...offsiteLocation
              }
            : undefined
    };
};

//todo: call backend editConsultation

export default addProximity.reducer;
