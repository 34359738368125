import Column from '@amzn/meridian/column';
import Input from '@amzn/meridian/input';
import InputGroup from '@amzn/meridian/input-group';
import Row from '@amzn/meridian/row';
import Select, { SelectOption } from '@amzn/meridian/select';
import React, { Fragment, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../context/store';
import { Facility, SiteIdAndCode, Proximity, Region } from '../../lib/CaseDefinitions';
import { CHARACTER_LIMIT, SELECT_WIDTH } from '../../lib/constants';
import { renderMaxAlert } from '../../lib/helpers';
import {
    setSiteIds,
    changeProximity,
    setInputLocation,
    setSecondarySiteIds
} from '../../slices/AddProximitySlice';
import { useLazyGetFacilityDetailsQuery } from '../../slices/WotManApiSlice';
import CountryInput from './CountryInput';
import FacilityInput from './FacilityInput';
import { FormSelect } from './FormFields';
import RegionInput from './RegionInput';

type ProximityProps = {
    missingFields?: string[];
    proximityOnChange?: (prop: never) => void;
    siteIdOnChange?: () => void;
};

const ProximityInput: React.FunctionComponent<ProximityProps> = ({
    missingFields,
    proximityOnChange,
    siteIdOnChange
}: ProximityProps) => {
    const { proximity, siteIds, inputLocation, secondarySiteIds } = useSelector(
        (state: RootState) => state.addProximity
    );
    const dispatch = useAppDispatch();

    const [getPrimaryFacilityDetails, getPrimaryFacilityDetailsEndpoint] = useLazyGetFacilityDetailsQuery({
        facilities: siteIds.map((facility) => facility.siteId)
    });

    const [getSecondaryFacilityDetails, getSecondaryFacilityDetailsEndpoint] = useLazyGetFacilityDetailsQuery(
        {
            facilities: secondarySiteIds.map((facility) => facility.siteId)
        }
    );

    const primaryData = getPrimaryFacilityDetailsEndpoint.data;

    const secondaryData = getSecondaryFacilityDetailsEndpoint.data;

    useEffect(() => {
        if (siteIds != null) {
            getPrimaryFacilityDetails({
                facilities: siteIds.map((facility) => facility.siteId)
            });
        }
    }, [siteIds]);

    useEffect(() => {
        if (secondarySiteIds != null) {
            getSecondaryFacilityDetails({
                facilities: secondarySiteIds.map((facility) => facility.siteId)
            });
        }
    }, [secondarySiteIds]);

    const facilityOptions = (isOnsite: boolean) => {
        return (
            <Column>
                <InputGroup direction='column' width={SELECT_WIDTH}>
                    <FacilityInput
                        label={isOnsite ? 'Primary facility *' : 'Primary facility'}
                        value={siteIds}
                        onChange={(siteIds: SiteIdAndCode[]) => {
                            if (siteIds.length < 2) dispatch(setSiteIds(siteIds));
                            if (isOnsite && siteIdOnChange) {
                                siteIdOnChange();
                            }
                        }}
                        filterFacilityList={
                            secondarySiteIds && Array.isArray(secondarySiteIds) && secondarySiteIds.length > 0
                                ? secondarySiteIds
                                : undefined
                        }
                        missing={missingFields?.includes('siteId')}
                        hasTag={false}
                    />
                    {siteIds && primaryData && primaryData.length > 0 && (
                        <Input label='Street Address' value={primaryData[0].address} disabled />
                    )}
                    {siteIds && primaryData && primaryData.length > 0 && (
                        <Input label='City' value={primaryData[0].city} disabled />
                    )}
                    {siteIds && primaryData && primaryData.length > 0 && (
                        <Input label='State/Province' value={primaryData[0].state} disabled />
                    )}
                    {siteIds && primaryData && primaryData.length > 0 && (
                        <Select label='Country' value={primaryData[0].country} disabled>
                            <SelectOption label={primaryData[0].country} value={primaryData[0].country} />
                        </Select>
                    )}
                    {siteIds && primaryData && primaryData.length > 0 && (
                        <Input label='Postal code' value={primaryData[0].zipcode} disabled />
                    )}
                    {siteIds && primaryData && primaryData.length > 0 && (
                        <Select label='Region' value={primaryData[0].region} disabled>
                            <SelectOption label={primaryData[0].region} value={primaryData[0].region} />
                        </Select>
                    )}
                </InputGroup>
                <FacilityInput
                    label='Secondary facilities'
                    value={secondarySiteIds}
                    onChange={(secondarySiteIdes: SiteIdAndCode[]) =>
                        dispatch(setSecondarySiteIds(secondarySiteIdes))
                    }
                    filterFacilityList={siteIds && !Array.isArray(siteIds) ? [siteIds] : undefined}
                    hasTag={true}
                />
                {secondaryData && Array.isArray(secondaryData) && secondaryData.length > 0 && (
                    <Row wrap='down'>
                        {(secondaryData as Facility[]).map((facility) => {
                            return (
                                <InputGroup key={facility.siteCode} direction='column' width={SELECT_WIDTH}>
                                    <Input label='Street Address' value={facility.address} disabled />
                                    <Input label='City' value={facility.city} disabled />
                                    <Input label='State/Province' value={facility.state} disabled />
                                    <Select label='Country' value={facility.country} disabled>
                                        {facility.country && (
                                            <SelectOption label={facility.country} value={facility.country} />
                                        )}
                                    </Select>
                                    <Input label='Postal code' value={facility.zipcode} disabled />
                                    <Select label='Region' value={facility.region} disabled>
                                        {facility.region && (
                                            <SelectOption label={facility.region} value={facility.region} />
                                        )}
                                    </Select>
                                </InputGroup>
                            );
                        })}
                    </Row>
                )}
            </Column>
        );
    };

    const renderFacility = () => {
        switch (proximity) {
            case Proximity.ONSITE:
                return facilityOptions(true);
            case Proximity.OFFSITE:
                return (
                    <Fragment>
                        <Column>
                            <Row alignmentVertical='top'>
                                <InputGroup direction='column' width={SELECT_WIDTH}>
                                    <Input
                                        label='Street Address'
                                        value={inputLocation.address}
                                        onChange={(address) => {
                                            dispatch(
                                                setInputLocation({
                                                    ...inputLocation,
                                                    address
                                                })
                                            );
                                        }}
                                        width='100%'
                                        error={inputLocation.address.length > 255}
                                    />
                                    <Input
                                        label='City'
                                        value={inputLocation.city}
                                        onChange={(city) => {
                                            dispatch(
                                                setInputLocation({
                                                    ...inputLocation,
                                                    city
                                                })
                                            );
                                        }}
                                        width='100%'
                                        error={inputLocation.city.length > 255}
                                    />
                                    <Input
                                        label='State/Province'
                                        value={inputLocation.state}
                                        onChange={(state) => {
                                            dispatch(
                                                setInputLocation({
                                                    ...inputLocation,
                                                    state
                                                })
                                            );
                                        }}
                                        width='100%'
                                        error={inputLocation.state.length > 255}
                                    />
                                    <CountryInput
                                        value={inputLocation.country}
                                        onChange={(country: string) => {
                                            dispatch(
                                                setInputLocation({
                                                    ...inputLocation,
                                                    country
                                                })
                                            );
                                        }}
                                    />
                                    <Input
                                        label='Postal code'
                                        value={inputLocation.zipcode}
                                        onChange={(zipcode) => {
                                            dispatch(
                                                setInputLocation({
                                                    ...inputLocation,
                                                    zipcode
                                                })
                                            );
                                        }}
                                        width='100%'
                                        error={inputLocation.zipcode.length > CHARACTER_LIMIT.ZIPCODE_LIMIT}
                                    />
                                    <RegionInput
                                        value={inputLocation.region}
                                        onChange={(region: Region) => {
                                            dispatch(
                                                setInputLocation({
                                                    ...inputLocation,
                                                    region
                                                })
                                            );
                                        }}
                                    />
                                </InputGroup>
                            </Row>
                            {inputLocation.address.length > CHARACTER_LIMIT.NORMAL_TEXT_LIMIT &&
                                renderMaxAlert(
                                    CHARACTER_LIMIT.NORMAL_TEXT_LIMIT,
                                    inputLocation.address.length,
                                    'Street Address'
                                )}
                            {inputLocation.city.length > CHARACTER_LIMIT.NORMAL_TEXT_LIMIT &&
                                renderMaxAlert(
                                    CHARACTER_LIMIT.NORMAL_TEXT_LIMIT,
                                    inputLocation.city.length,
                                    'City'
                                )}
                            {inputLocation.state.length > CHARACTER_LIMIT.NORMAL_TEXT_LIMIT &&
                                renderMaxAlert(
                                    CHARACTER_LIMIT.NORMAL_TEXT_LIMIT,
                                    inputLocation.state.length,
                                    'State/Province'
                                )}
                            {inputLocation.zipcode.length > CHARACTER_LIMIT.ZIPCODE_LIMIT &&
                                renderMaxAlert(
                                    CHARACTER_LIMIT.ZIPCODE_LIMIT,
                                    inputLocation.zipcode.length,
                                    'Postal code'
                                )}
                            {facilityOptions(false)}
                        </Column>
                    </Fragment>
                );
        }
    };

    return (
        <Column>
            <FormSelect
                label='Proximity *'
                width={SELECT_WIDTH}
                value={proximity}
                onChange={(proximity) => {
                    dispatch(changeProximity(proximity));
                    if (proximityOnChange) {
                        proximityOnChange(proximity);
                    }
                }}
                missing={missingFields?.includes('proximity')}
            >
                {Object.values(Proximity).map((value) => {
                    return <SelectOption value={value} label={value} key={value} />;
                })}
            </FormSelect>
            {renderFacility()}
        </Column>
    );
};

export default ProximityInput;
