export const COGNITO_REDIRECT_TO = process.env.REACT_APP_COGNITO_REDIRECT_TO;
export const COGNITO_APP_WEB_DOMAIN = process.env.REACT_APP_COGNITO_APP_WEB_DOMAIN;
export const COGNITO_CLIENT_ID = process.env.REACT_APP_COGNITO_CLIENT_ID;
export const COGNITO_USERPOOL = process.env.REACT_APP_COGNITO_USERPOOL;
export const API_GATEWAY_API_KEY = process.env.REACT_APP_API_GATEWAY_API_KEY;
export const STAGE = process.env.REACT_APP_STAGE;
export const REACT_APP_WOTMAN_SERVICE_ENDPOINT = process.env.REACT_APP_WOTMAN_SERVICE_ENDPOINT;
export const WOTMAN_WEBSOCKET_ENDPOINT = process.env.REACT_APP_WOTMAN_WEBSOCKET_ENDPOINT;
export const CREATE_INCIDENT_ENDPOINT = '/createNewIncident';
export const GET_FACILITIES_ENDPOINT = '/searchForFacilities';
export const GET_FACILITY_DETAILS_ENDPOINT = '/getFacilityDetails';
export const GET_ALL_SELECT_OPTIONS = '/getAllSelectOptions';
export const GET_USER_CONTEXT = '/getUserContext';
export const CREATE_CONSULTATION_ENDPOINT = '/createNewConsultation';
export const GET_ALL_THREAT_MANAGERS_ENDPOINT = '/getAllThreatManagers';
export const SEARCH_INCIDENT_NAME_ENDPOINT = '/searchForIncidentName';
export const LINK_CASE_CONSULTATION_ENDPOINT = '/caseToConsultationLink';
export const GET_CONSULTATION_DETAILS_ENDPOINT = '/getConsultationDetails';
export const GET_CASE_DETAILS_ENDPOINT = '/getCaseDetails';
export const EDIT_RECOMMENDATIONS_ENDPOINT = '/editRecommendations';
export const EDIT_LPCONTACT_ENDPOINT = '/editLPContact';
export const UPDATE_CASE_ENDPOINT = '/updateCase';
export const EDIT_CONSULTATION_ENDPOINT = '/editConsultationDetails';
export const SEARCH_CONSULTATION_NAME_ENDPOINT = '/searchForConsultationName';
export const BOOKMARK_CASE_ENDPOINT = '/bookmarkCase';
export const LINK_CASES_ENDPOINT = '/caseToCaseLink';
export const ADD_MANUAL_UPDATE_ENDPOINT = '/addManualUpdate';
export const HAS_CONSULTATION_NAME_ENDPOINT = '/hasConsultationName';
export const SEARCH_FOR_EMPLOYEES = '/searchForEmployees';
export const GET_EMPLOYEE_DETAILS = '/getEmployeeDetails';
export const SEARCH_CASES_ENDPOINT = '/searchCases';
export const SEARCH_CONSULTATIONS_ENDPOINT = '/searchConsultations';
export const EDIT_MARS_CASE_ENDPOINT = '/editMarsCase';
export const SEARCH_CASE_NAME_CONSULTATION_NAME_ENDPOINT = '/searchCasesAndConsultations';
export const ACKNOWLEDGE_UPDATE_ENDPOINT = '/acknowledgeUpdate';
export const ADD_EDIT_CONTACT_ENDPOINT = '/addEditContact';
export const GET_FILE_DATA = '/getFileData';
export const SEARCH_SIM_ENDPOINT = '/searchSIMs';
export const LINK_SIM_ENDPOINT = '/caseToSIMLink';
