import { Action, configureStore } from '@reduxjs/toolkit';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { useDispatch } from 'react-redux';
import { ThunkAction } from 'redux-thunk';
import { STAGE } from '../lib/globals';
import { wotManServiceApi } from '../slices/WotManApiSlice';
import rootReducer from './rootReducer';

export const history = createBrowserHistory({});

export const store = configureStore({
    reducer: {
        ...rootReducer,
        [wotManServiceApi.reducerPath]: wotManServiceApi.reducer
    },
    devTools: STAGE !== 'prod',
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({ serializableCheck: false })
            .concat(routerMiddleware(history))
            .concat(wotManServiceApi.middleware)
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>();
