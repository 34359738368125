import {
    Case,
    Consultation,
    LevelOfConcern,
    PersonInvolved,
    Proximity,
    SiteIdAndCode,
    Location
} from './CaseDefinitions';

export interface Update {
    updatedId: number;
    updater: string;
    timestamp: string;
    hasBeenSeen: boolean;
    type:
        | SimAttachment
        | SimComment
        | SimCreate
        | SimStatus
        | SimDate
        | LinkedCaseUpdate
        | LinkedConsultationUpdate
        | LocUpdate
        | RecommendationsUpdate
        | GenericUpdate
        | LPContactUpdate
        | IncidentCreation
        | AssigneeChange
        | StatusUpdate
        | EMTUpdateNotification
        | RestrictionUpdate
        | MarsEventUpdate
        | ContactUpdate
        | LinkedSIMUpdate
        | EmployeeStatusUpdate;
    needsAcknowledgement: boolean;
}

export enum SimUpdate {
    SimDate = 'SimDate',
    SimAttachment = 'SimAttachment',
    SimComment = 'SimComment',
    SimCreate = 'SimCreate',
    SimStatus = 'SimStatus'
}

export interface SimTicketUpdate {
    name: SimUpdate;
    simId: string;
    editorName: string;
    title: string;
    editDate?: string;
}

export interface EmployeeStatusUpdate {
    name: 'EmployeeStatusUpdate';
    status: string;
    employee: PersonInvolved;
}

export interface SimAttachment extends SimTicketUpdate {
    name: SimUpdate.SimAttachment;
    data: {
        fileName: string;
        id: string;
        type: string;
    };
}

export interface SimComment extends SimTicketUpdate {
    name: SimUpdate.SimComment;
    data: {
        message: string;
        thread: string;
    };
}

export interface SimCreate extends SimTicketUpdate {
    name: SimUpdate.SimCreate;
    data: string;
}

export enum SimStatusTypes {
    OPEN = 'Open',
    RESOLVED = 'Resolved'
}

export interface SimStatus extends SimTicketUpdate {
    name: SimUpdate.SimStatus;
    data: SimStatusTypes;
}

export interface SimDate extends SimTicketUpdate {
    name: SimUpdate.SimDate;
    data: string;
}

export interface LinkedCaseUpdate {
    name: 'LinkedCaseUpdate';
    action: 'link' | 'unlink';
    case: Case | undefined;
}

export interface LinkedConsultationUpdate {
    name: 'LinkedConsultationUpdate';
    action: 'link' | 'unlink';
    consultation: Consultation;
}

export interface LinkedSIMUpdate {
    name: 'LinkedSIMUpdate';
    action: 'link' | 'unlink';
    simId: string;
    title: string;
}

export interface LPContactUpdate {
    name: 'LPContactUpdate';
    action: 'add' | 'edit' | 'delete';
    lpPersonId: string;
}

export interface LocUpdate {
    name: 'LocUpdate';
    loc: keyof typeof LevelOfConcern;
    note: string;
    canEdit?: boolean;
    history?: string[];
}

export interface ContactUpdate {
    name: 'ContactUpdate';
    type:
        | PersonInvolvedType.INTERNAL_CONTACT
        | PersonInvolvedType.INTERNAL_PERSON
        | PersonInvolvedType.EXTERNAL_PERSON
        | PersonInvolvedType.EXTERNAL_CONTACT
        | PersonInvolvedType.FACMAN_CONTACT;
    action: 'added' | 'edited';
    oldValue?: PersonInvolved;
    newValue?: PersonInvolved;
    updateId?: number;
    contactId?: number;
}

export enum PersonInvolvedType {
    INTERNAL_PERSON = 'InternalPersonInvolved',
    EXTERNAL_PERSON = 'ExternalPersonInvolved',
    INTERNAL_CONTACT = 'InternalContact',
    EXTERNAL_CONTACT = 'ExternalContact',
    FACMAN_CONTACT = 'FacmanContact'
}

export interface RecommendationsUpdate {
    name: 'RecommendationsUpdate';
    recommendations: {
        name: string;
        description: string;
        action: 'removed' | 'added' | 'edited';
    }[];
}

export interface FileUpdate {
    fileId?: number;
    bytes?: string;
    type: string;
    name: string;
    size: number;
}

export interface GenericUpdate {
    name: 'GenericUpdate';
    title: string;
    note: string;
    files?: FileUpdate[];
    empId?: string;
    canEdit?: boolean;
    history?: string[];
}

export interface StatusUpdate {
    name: 'StatusUpdate';
    action: 'Close' | 'Open';
}

export interface IncidentCreation {
    name: 'IncidentCreation';
}

export interface AssigneeChange {
    name: 'AssigneeChange';
    action: 'set' | 'edited';
    assignee: string;
}

export interface EMTUpdateNotification {
    name: 'EMTUpdateNotification';
    emtId: string;
    updates: { title: string; note: string }[];
}

export interface RestrictionUpdate {
    name: 'RestrictionUpdate';
    action: 'Restricted' | 'Standard';
}

interface OldNewString {
    old: string;
    new: string;
}

export interface MarsEventUpdate {
    name: 'MarsEventUpdate';
    eventCategory?: OldNewString;
    eventType?: OldNewString;
    eventTags?: {
        old: string[];
        new: string[];
    };
    eventItem?: OldNewString;
    incidentTime?: OldNewString;
    proximity?: {
        old: Proximity;
        new: Proximity;
    };
    eventSummary?: OldNewString;
    location?: {
        old: Location;
        new: Location;
    };
    primaryFacility?: {
        old: SiteIdAndCode[];
        new: SiteIdAndCode[];
    };
    secondaryFacility?: {
        old: SiteIdAndCode[];
        new: SiteIdAndCode[];
    };
    associatedFacility?: {
        old?: SiteIdAndCode;
        new?: SiteIdAndCode;
    };
    incidentDate?: OldNewString;
}
