import Column from '@amzn/meridian/column';
import Expander from '@amzn/meridian/expander';
import Responsive from '@amzn/meridian/responsive';
import Row from '@amzn/meridian/row';
import Table, { TableRow, TableCell, TableSectionHeader } from '@amzn/meridian/table';
import Text from '@amzn/meridian/text';
import Tile from '@amzn/meridian/tile';
import _ from 'lodash';
import React, { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../context/store';
import {
    PHOTO_URL,
    ResponsiveVariables,
    responsiveVariables,
    WIDGET_WIDTH,
    WIDTH_OF_CARD
} from '../../lib/constants';
import { ThreatManager } from '../../lib/ThreatManagerDefinitions';
import ResponsiveHooks from '../Cases/DetailsTabs/ResponsiveHooks';

const Settings: React.FunctionComponent = () => {
    const [openRegionsExpander, setOpenRegionsExpander] = useState(false);
    const [openCountriesExpander, setOpenCountriesExpander] = useState(false);
    const [openStatesExpander, setOpenStatesExpander] = useState(false);
    // const [openEditModal, setOpenEditModal] = useState(false);
    // const [threatManagerEditing, setThreatManagerEditing] = useState<ThreatManager>({
    //     empId: '',
    //     firstName: '',
    //     lastName: '',
    //     login: '',
    //     areaOfResponsibility: []
    // });
    const { userId, userLogin, userFirstName, userLastName } = useSelector(
        (state: RootState) => state.dashboard
    );
    const { threatManagers } = useSelector((state: RootState) => state.selectOptions);
    const isAdmin = _.some(threatManagers.admins, { empId: userId });
    const user =
        _.find(threatManagers.admins, { empId: userId }) || _.find(threatManagers.users, { empId: userId });
    const { displayingSheet } = useSelector((state: RootState) => state.consultations);
    const { isNavCollapsed } = useSelector((state: RootState) => state.dashboard);

    const renderThreatManagerList = (managers: ThreatManager[]) => {
        return managers.map((user) => {
            return (
                <TableRow key={user.empId}>
                    <TableCell backgroundColor={'transparent'}>
                        {user.firstName} {user.lastName}
                    </TableCell>
                    <TableCell backgroundColor={'transparent'}>{user.login}</TableCell>
                    <TableCell backgroundColor={'transparent'}>
                        {user.areaOfResponsibility.length === 0 ? (
                            <Text color='secondary'> None </Text>
                        ) : (
                            <Column spacing='none'>
                                {user.areaOfResponsibility.filter((aor) => !aor.country).length > 0 && (
                                    <Row>
                                        <Text type='h100'> Regions: </Text>
                                        <Text>
                                            {' '}
                                            {user.areaOfResponsibility
                                                .filter((aor) => !aor.country)
                                                .map((aor) => aor.region)
                                                .join(', ')}{' '}
                                        </Text>
                                    </Row>
                                )}
                                {user.areaOfResponsibility.filter(
                                    (aor) => aor.country && !aor.stateOrProvince
                                ).length > 0 && (
                                    <Row>
                                        <Text type='h100'> Countries: </Text>
                                        <Text>
                                            {' '}
                                            {user.areaOfResponsibility
                                                .filter((aor) => aor.country && !aor.stateOrProvince)
                                                .map((aor) => aor.country)
                                                .join(', ')}{' '}
                                        </Text>
                                    </Row>
                                )}
                                {user.areaOfResponsibility.filter((aor) => aor.country && aor.stateOrProvince)
                                    .length > 0 && (
                                    <Row>
                                        <Text type='h100'> States/Provinces: </Text>
                                        <Text>
                                            {' '}
                                            {user.areaOfResponsibility
                                                .filter((aor) => aor.country && aor.stateOrProvince)
                                                .map((aor) => aor.stateOrProvince)
                                                .join(', ')}{' '}
                                        </Text>
                                    </Row>
                                )}
                            </Column>
                        )}
                    </TableCell>
                    <TableCell backgroundColor={'transparent'}>
                        {/*<Button*/}
                        {/*    type='secondary'*/}
                        {/*    size='small'*/}
                        {/*    onClick={() => {*/}
                        {/*        setOpenEditModal(true);*/}
                        {/*        setThreatManagerEditing(user);*/}
                        {/*    }}*/}
                        {/*>*/}
                        {/*    <Icon tokens={editIconTokens} />*/}
                        {/*</Button>*/}
                    </TableCell>
                </TableRow>
            );
        });
    };

    const userTileContent = () => (
        <Fragment>
            <Row alignmentVertical={'top'}>
                <img
                    className='RoundedImage'
                    src={`${PHOTO_URL}${userId}`}
                    alt={`${userLogin}'s Badge Photo`}
                    width='64'
                />
                <Column spacing='200'>
                    <Text type='h100'>
                        {' '}
                        {userFirstName} {userLastName}{' '}
                    </Text>
                    <Text type='b300'> {userLogin} </Text>
                </Column>
            </Row>
            <Row>
                <Column spacing='200'>
                    <Text type='h100'> Access level </Text>
                    <Text type='b300'> {isAdmin ? 'Admin' : user ? 'Standard' : 'System Admin'} </Text>
                </Column>
            </Row>
            <Row>
                <Column spacing='200'>
                    <Text type='h100'> Area of responsibility </Text>
                    {!user || user.areaOfResponsibility.length === 0 ? (
                        <Text color='secondary'> None </Text>
                    ) : (
                        <Fragment>
                            {user.areaOfResponsibility.filter((aor) => !aor.country).length !== 0 && (
                                <Expander
                                    open={openRegionsExpander}
                                    title={'Regions'}
                                    type={'inline'}
                                    onChange={setOpenRegionsExpander}
                                >
                                    <Text tag='ul'>
                                        {user.areaOfResponsibility
                                            .filter((aor) => !aor.country)
                                            .map((region) => {
                                                return <li key={region.region}>{region.region}</li>;
                                            })}
                                    </Text>
                                </Expander>
                            )}
                            {user.areaOfResponsibility.filter((aor) => aor.country && !aor.stateOrProvince)
                                .length !== 0 && (
                                <Expander
                                    open={openCountriesExpander}
                                    title={'Countries'}
                                    type={'inline'}
                                    onChange={setOpenCountriesExpander}
                                >
                                    <Text tag='ul'>
                                        {user.areaOfResponsibility
                                            .filter((aor) => aor.country && !aor.stateOrProvince)
                                            .map((country) => {
                                                return <li key={country.country}>{country.country}</li>;
                                            })}
                                    </Text>
                                </Expander>
                            )}
                            {user.areaOfResponsibility.filter((aor) => aor.stateOrProvince).length !== 0 && (
                                <Expander
                                    open={openStatesExpander}
                                    title={'States/Provinces'}
                                    type={'inline'}
                                    onChange={setOpenStatesExpander}
                                >
                                    <Row>
                                        <Column>
                                            <Text tag='ul'>
                                                {user.areaOfResponsibility
                                                    .filter((aor) => aor.stateOrProvince)
                                                    .map((stateOrProvince) => {
                                                        return (
                                                            <li key={stateOrProvince.stateOrProvince}>
                                                                {stateOrProvince.stateOrProvince}
                                                            </li>
                                                        );
                                                    })}
                                            </Text>
                                        </Column>
                                    </Row>
                                </Expander>
                            )}
                        </Fragment>
                    )}
                </Column>
            </Row>
        </Fragment>
    );

    return (
        <Responsive query='min-width' props={responsiveVariables}>
            {({
                withNoneOpen,
                withNavBarOpen,
                withSheetOpen,
                withBothOpen
            }: {
                withNoneOpen: boolean;
                withNavBarOpen: boolean;
                withSheetOpen: boolean;
                withBothOpen: ResponsiveVariables;
            }) => {
                const isStacked = isNavCollapsed
                    ? displayingSheet
                        ? withSheetOpen
                        : withNoneOpen
                    : displayingSheet
                    ? withBothOpen === ResponsiveVariables.STACK ||
                      withBothOpen === ResponsiveVariables.CLOSE_ON_OPEN
                    : withNavBarOpen;
                return (
                    <Row
                        width='100%'
                        alignmentVertical='top'
                        alignmentHorizontal='center'
                        widths={[WIDTH_OF_CARD, WIDGET_WIDTH]}
                    >
                        <ResponsiveHooks withBothOpen={withBothOpen} />
                        <Column spacingInset='400 none'>
                            <Text type='d100'> Settings </Text>
                            {isStacked && (
                                <Tile spacingInset='400' layout={{ width: 'max-content' }}>
                                    <Row alignmentVertical={'top'}>{userTileContent()}</Row>
                                </Tile>
                            )}
                            <Text type='h300'> User management </Text>
                            <Table headerRows={1}>
                                <TableRow>
                                    <TableCell backgroundColor={'transparent'}>Name</TableCell>
                                    <TableCell backgroundColor={'transparent'}>Alias</TableCell>
                                    <TableCell backgroundColor={'transparent'}>
                                        Area of responsibility
                                    </TableCell>
                                    <TableCell backgroundColor={'transparent'}></TableCell>
                                </TableRow>
                                <TableSectionHeader> Admin users</TableSectionHeader>
                                {renderThreatManagerList(threatManagers.admins)}
                                <TableSectionHeader>Standard users</TableSectionHeader>
                                {renderThreatManagerList(threatManagers.users)}
                            </Table>
                        </Column>
                        {!isStacked && (
                            <Tile spacingInset='400'>
                                <Column>{userTileContent()}</Column>
                            </Tile>
                        )}
                        {/*<AreaOfResponsibilityModal*/}
                        {/*    isOpen={openEditModal}*/}
                        {/*    setIsOpen={setOpenEditModal}*/}
                        {/*    threatManager={threatManagerEditing}*/}
                        {/*/>*/}
                    </Row>
                );
            }}
        </Responsive>
    );
};

export default Settings;
