import Alert from '@amzn/meridian/alert';
import Column from '@amzn/meridian/column';
import InputGroup from '@amzn/meridian/input-group';
import Row from '@amzn/meridian/row';
import closeTokens from '@amzn/meridian-tokens/base/icon/close';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../context/store';
import { CommunicatedWith, PAPIShort } from '../../lib/CaseDefinitions';
import { CHARACTER_LIMIT, SELECT_WIDTH } from '../../lib/constants';
import { renderMaxAlert } from '../../lib/helpers';
import { labels } from '../../lib/labels';
import { addToast } from '../../slices/ToastSlice';
import { useLazyGetEmployeeDetailsQuery } from '../../slices/WotManApiSlice';
import TitledButton from '../CustomComponents/TitledButton';
import { FormInput } from './FormFields';
import LoginNameSearch from './LoginNameSearch';

type AddPersonProps = {
    personAdding: CommunicatedWith;
    isPersonInvolved?: boolean;
    setVars: (createPersonInvolved: CommunicatedWith) => void;
    removePerson?: () => void;
    missingName: boolean;
    duplicate: boolean;
    children?: JSX.Element;
    isVertical?: boolean;
    duplicateMessage?: string;
    additionalClear?: () => void;
    nameIsEmptySpaces: boolean;
    userRestricted?: boolean;
};

const AddPersonInput: React.FunctionComponent<AddPersonProps> = ({
    personAdding,
    isPersonInvolved,
    setVars,
    removePerson,
    missingName,
    duplicate,
    isVertical,
    children,
    duplicateMessage,
    additionalClear,
    nameIsEmptySpaces,
    userRestricted
}: AddPersonProps) => {
    const [query, setQuery] = useState(personAdding.login);
    const [useGetEmployeeDetail, useGetEmployeeDetailEndpoint] = useLazyGetEmployeeDetailsQuery();
    const dispatch = useAppDispatch();
    const { userLogin } = useSelector((state: RootState) => state.dashboard);

    useEffect(() => {
        if (!useGetEmployeeDetailEndpoint.isFetching && useGetEmployeeDetailEndpoint.isSuccess) {
            setVars({
                ...personAdding,
                name: useGetEmployeeDetailEndpoint.data.fullName,
                title: useGetEmployeeDetailEndpoint.data.jobTitle,
                email: useGetEmployeeDetailEndpoint.data.email,
                phoneNumber: useGetEmployeeDetailEndpoint.data.phone,
                login: useGetEmployeeDetailEndpoint.data.login,
                personId: useGetEmployeeDetailEndpoint.data.personId
            });
            setQuery(useGetEmployeeDetailEndpoint.data.login);
        } else if (useGetEmployeeDetailEndpoint.isError) {
            dispatch(
                addToast({
                    type: 'error',
                    action: 'There was an error fetching employee details, please try again',
                    message: '',
                    SIM: true,
                    timeout: 5000
                })
            );
        }
    }, [useGetEmployeeDetailEndpoint.isFetching]);

    const handleClear = () => {
        setVars({
            ...personAdding,
            name: '',
            title: '',
            email: '',
            phoneNumber: '',
            login: '',
            personId: '',
            isDirty: true
        });
        if (additionalClear) additionalClear();
    };

    const handleRemove = () => {
        removePerson && removePerson();
    };

    const isFormDisabled = personAdding.login !== '' || useGetEmployeeDetailEndpoint.isFetching;

    const onClickSuggestion = (person: PAPIShort) => {
        useGetEmployeeDetail({
            personId: person.personId
        });
        setVars({
            ...personAdding,
            personId: person.personId,
            isDirty: true
        });
    };

    const isSuggestionDisabled = (person: PAPIShort) => {
        return person.login && isPersonInvolved
            ? Object.is(person.login.toLowerCase(), userLogin.toLowerCase().trim())
            : false;
    };

    return (
        <Column>
            <Row spacing='small' alignmentVertical='top'>
                <InputGroup direction='column'>
                    <Row>
                        <LoginNameSearch
                            handleClear={handleClear}
                            onClick={onClickSuggestion}
                            onBlur={() => {
                                if (!useGetEmployeeDetailEndpoint.isFetching) {
                                    setQuery(personAdding.login);
                                }
                            }}
                            setQuery={setQuery}
                            label='Name or alias'
                            placeholder='Search for name/alias...'
                            query={query}
                            isSuggestionDisabledCallback={isSuggestionDisabled}
                        />
                        {removePerson && (
                            <TitledButton
                                title={labels.removePerson}
                                buttonProps={{
                                    onClick: () => handleRemove(),
                                    type: 'secondary',
                                    size: 'small'
                                }}
                                iconProps={{ tokens: closeTokens }}
                            />
                        )}
                    </Row>
                    <FormInput
                        label='Name *'
                        width={SELECT_WIDTH}
                        value={useGetEmployeeDetailEndpoint.isFetching ? 'Loading...' : personAdding.name}
                        onChange={(name) => {
                            setVars({
                                ...personAdding,
                                name,
                                isDirty: true
                            });
                        }}
                        max={CHARACTER_LIMIT.PERSON_INVOLVED_LIMIT}
                        missing={missingName}
                        disabled={isFormDisabled}
                        showError={!isVertical}
                    />
                    <FormInput
                        label='Title'
                        width={SELECT_WIDTH}
                        value={useGetEmployeeDetailEndpoint.isFetching ? 'Loading...' : personAdding.title}
                        onChange={(title) => {
                            setVars({
                                ...personAdding,
                                title,
                                isDirty: true
                            });
                        }}
                        max={CHARACTER_LIMIT.PERSON_INVOLVED_LIMIT}
                        disabled={isFormDisabled}
                        showError={!isVertical}
                    />
                    <FormInput
                        label='Phone number'
                        width={SELECT_WIDTH}
                        value={
                            useGetEmployeeDetailEndpoint.isFetching ? 'Loading...' : personAdding.phoneNumber
                        }
                        onChange={(phoneNumber) => {
                            setVars({
                                ...personAdding,
                                phoneNumber,
                                isDirty: true
                            });
                        }}
                        max={CHARACTER_LIMIT.PERSON_INVOLVED_LIMIT}
                        disabled={isFormDisabled}
                        showError={!isVertical}
                    />
                    <FormInput
                        label='Email'
                        width={SELECT_WIDTH}
                        value={useGetEmployeeDetailEndpoint.isFetching ? 'Loading...' : personAdding.email}
                        onChange={(email) => {
                            setVars({
                                ...personAdding,
                                email,
                                isDirty: true
                            });
                        }}
                        max={CHARACTER_LIMIT.PERSON_INVOLVED_LIMIT}
                        disabled={isFormDisabled}
                        showError={!isVertical}
                    />
                </InputGroup>
            </Row>
            <Row spacing='small' width={SELECT_WIDTH} alignmentVertical='top'>
                {isVertical &&
                    personAdding.name &&
                    personAdding.name.length > CHARACTER_LIMIT.PERSON_INVOLVED_LIMIT &&
                    renderMaxAlert(CHARACTER_LIMIT.PERSON_INVOLVED_LIMIT, personAdding.name.length, 'Name')}
                {isVertical &&
                    personAdding.title &&
                    personAdding.title.length > CHARACTER_LIMIT.PERSON_INVOLVED_LIMIT &&
                    renderMaxAlert(CHARACTER_LIMIT.PERSON_INVOLVED_LIMIT, personAdding.title.length, 'Title')}
                {isVertical &&
                    personAdding.phoneNumber.length > CHARACTER_LIMIT.PERSON_INVOLVED_LIMIT &&
                    renderMaxAlert(
                        CHARACTER_LIMIT.PERSON_INVOLVED_LIMIT,
                        personAdding.phoneNumber.length,
                        'Phone number'
                    )}
                {isVertical &&
                    personAdding.email.length > CHARACTER_LIMIT.PERSON_INVOLVED_LIMIT &&
                    renderMaxAlert(CHARACTER_LIMIT.PERSON_INVOLVED_LIMIT, personAdding.email.length, 'Email')}
                {isVertical && missingName && (
                    <Alert type='error' size='small'>
                        Name is Required
                    </Alert>
                )}
                {nameIsEmptySpaces && (
                    <Alert type='error' size='small'>
                        Name must contain at least one character
                    </Alert>
                )}
                {duplicate && (
                    <Alert type='error' size='small'>
                        {duplicateMessage || 'You have already entered this person.'}
                    </Alert>
                )}
                {userRestricted && (
                    <Alert type='error' size='small'>
                        You do not have permissions to add yourself to this case as a POI, Witness or Victim.
                    </Alert>
                )}
                {children}
            </Row>
        </Column>
    );
};

export default AddPersonInput;
