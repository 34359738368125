import Row from '@amzn/meridian/row';
import Tag from '@amzn/meridian/tag';
import React from 'react';
import { SELECT_WIDTH } from '../../lib/constants';

type selectTagsProps = {
    value: string[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onChange: (prop: any[]) => void;
};

const SelectTags: React.FunctionComponent<selectTagsProps> = ({ value, onChange }: selectTagsProps) => {
    return (
        <Row wrap='down' width={SELECT_WIDTH}>
            {value.map((item) => {
                return (
                    <Tag
                        key={item}
                        type='neutral'
                        /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                        // @ts-ignore
                        onClose={() => onChange(value.filter((field) => field !== item))}
                    >
                        {item}
                    </Tag>
                );
            })}
        </Row>
    );
};

export default SelectTags;
