import Column from '@amzn/meridian/column';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../context/store';
import { WIDTHS_OF_ADVANCED_SEARCH_COLUMNS } from '../../../lib/constants';
import { initialAdvancedFilter } from '../../../lib/FilterDefinitons';
import { searchOrReportOnSubmit } from '../../../lib/helpers';
import { addToast } from '../../../slices/ToastSlice';
import AdvancedCaseFilterHelper from './AdvancedCaseFilterHelper';
import DateRow from './DateRow';

type CaseFilterProps = {
    // eslint-disable-next-line
    searchCases: (filter: { caseFilter: any }) => void;
};

//TODO add categories / subcategories, add person involved support, loading animations
const AdvancedCaseFilter: React.FunctionComponent<CaseFilterProps> = ({ searchCases }: CaseFilterProps) => {
    const { filterOptions } = useSelector((state: RootState) => state.advancedSearch);
    const [missingDate, setIsMissingDate] = useState<boolean>(false);

    const [localFilterOptions, setLocalFilterOptions] = useState(initialAdvancedFilter);
    const dispatch = useAppDispatch();

    useEffect(() => {
        setLocalFilterOptions({ ...filterOptions });
    }, [filterOptions]);

    useEffect(() => {
        if (missingDate) {
            dispatch(
                addToast({
                    type: 'error',
                    action: 'Dates must be included in search ',
                    message: 'Correct any errors and submit again',
                    SIM: false,
                    timeout: 5000
                })
            );
        }
    }, [missingDate]);

    return (
        <Column>
            <DateRow
                filter={localFilterOptions}
                missingDate={missingDate}
                setDates={(dates) => {
                    setIsMissingDate(false);
                    setLocalFilterOptions({
                        ...localFilterOptions,
                        dateTo: dates[1],
                        dateFrom: dates[0]
                    });
                }}
                hasTitle
            />
            <AdvancedCaseFilterHelper
                onClick={() => {
                    searchOrReportOnSubmit(
                        localFilterOptions,
                        setIsMissingDate,
                        searchCases,
                        false,
                        dispatch
                    );
                }}
                localFilterOptions={localFilterOptions}
                setLocalFilterOptions={setLocalFilterOptions}
                buttonName={'Search'}
                widthsOfColumns={WIDTHS_OF_ADVANCED_SEARCH_COLUMNS}
            />
        </Column>
    );
};

export default AdvancedCaseFilter;
