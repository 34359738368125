// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint react/prop-types: 0 */
// mostly copied from https://code.amazon.com/packages/MeridianComponents/blobs/5.x/--/src/components/progress-tracker/progress-tracker-milestone.js
// some changes to make colors match mockups

import Icon from '@amzn/meridian/icon';
import Context from '@amzn/meridian/progress-tracker/progress-tracker-context';
import checkTokens from '@amzn/meridian-tokens/base/icon/check-large';
import exclamationPointTokens from '@amzn/meridian-tokens/base/icon/exclamation-point';
import { css } from 'emotion';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { memoizeByKeys } from './MeridianUtils';

/**
 * Circle
 */

const circleStyles = memoizeByKeys(
    ({ diameter, borderRadius, borderWidth, borderColor, backgroundColor, foregroundColor }) =>
        css({
            boxSizing: 'border-box',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: diameter,
            height: diameter,
            borderRadius,
            border: `${borderWidth}px solid ${borderColor}`,
            backgroundColor,
            color: foregroundColor
        }),
    ['diameter', 'borderRadius', 'borderWidth', 'borderColor', 'backgroundColor', 'foregroundColor']
);

function Circle(props) {
    return <div className={circleStyles(props)}>{props.children}</div>;
}

Circle.propTypes = {
    backgroundColor: PropTypes.string,
    borderColor: PropTypes.string,
    borderRadius: PropTypes.number,
    borderWidth: PropTypes.number,
    children: PropTypes.node,
    diameter: PropTypes.number,
    foregroundColor: PropTypes.string
};

function Milestone(props) {
    const { t } = useContext(Context);
    const colorState = [
        props.type === 'past' ? 'neutral' : 'theme',
        props.type === 'present' ? 'past' : props.type === 'past' ? 'past' : props.type,
        props.type === 'past' ? props.size : undefined,
        props.alert
    ].filter(Boolean);
    const shapeProps = {
        diameter: t('milestoneDiameter', props.size),
        foregroundColor: '#232F3E',
        backgroundColor: props.type === 'past' ? '#E7E9E9' : '#B9E8F7',
        borderColor: t('milestoneBorderColor', colorState),
        borderRadius: t('milestoneBorderRadius'),
        borderWidth: t('milestoneBorderWidth')
    };
    const showIcon = props.showIcon && props.size === 'large';
    const iconTokens =
        showIcon && props.type === 'past'
            ? checkTokens
            : showIcon && props.type === 'present'
            ? exclamationPointTokens
            : null;
    return props.alert === 'error' && props.size === 'large' ? (
        <Circle {...shapeProps}>
            <Icon tokens={exclamationPointTokens} />
        </Circle>
    ) : (
        <Circle {...shapeProps}>{iconTokens ? <Icon tokens={iconTokens} /> : null}</Circle>
    );
}

Milestone.propTypes = {
    alert: PropTypes.oneOf(['error', 'warning', 'none']),
    neutral: PropTypes.bool,
    showIcon: PropTypes.bool,
    /**
     * Sets the size of the component using a preset.
     */
    size: PropTypes.oneOf(['small', 'large']),
    type: PropTypes.oneOf(['past', 'present', 'future'])
};

export default Milestone;
