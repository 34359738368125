import Card from '@amzn/meridian/card';
import Text from '@amzn/meridian/text';
import React from 'react';
import { useAppDispatch } from '../../context/store';
import { PersonInvolvedDisplaying } from '../../lib/CaseDefinitions';
import { setPersonsInvolvedDisplaying } from '../../slices/CaseDetailsSlice';

type PersonCardProps = {
    person: PersonInvolvedDisplaying;
    displayAddedBy?: boolean;
    dispatchCallback?: (p: PersonInvolvedDisplaying) => void;
    cardWidth?: string;
};

const PersonCard: React.FunctionComponent<PersonCardProps> = ({
    person,
    displayAddedBy = true,
    dispatchCallback = setPersonsInvolvedDisplaying,
    cardWidth = '280px'
}) => {
    const dispatch = useAppDispatch();

    return (
        <Card onClick={() => dispatch(dispatchCallback(person))} spacingInset='300' width={cardWidth}>
            <Text type='h200'>{person.name}</Text>
            {person.title && <Text> {person.title} </Text>}
            {displayAddedBy && person.addedBy && (
                <Text type='b300' color='secondary'>
                    Added by {person.addedBy.name}
                </Text>
            )}
        </Card>
    );
};

export default PersonCard;
