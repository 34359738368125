import Button from '@amzn/meridian/button';
import Column from '@amzn/meridian/column';
import Expander from '@amzn/meridian/expander';
import FileInput, { FileDetails } from '@amzn/meridian/file-input';
import Icon from '@amzn/meridian/icon';
import Input from '@amzn/meridian/input';
import Link from '@amzn/meridian/link';
import Loader from '@amzn/meridian/loader';
import Modal, { ModalFooter } from '@amzn/meridian/modal';
import ProgressTracker from '@amzn/meridian/progress-tracker';
import Row from '@amzn/meridian/row';
import Select, { SelectOption } from '@amzn/meridian/select';
import Text from '@amzn/meridian/text';
import downloadSmallTokens from '@amzn/meridian-tokens/base/icon/download-small';
import editIconTokens from '@amzn/meridian-tokens/base/icon/edit';
import plusTokens from '@amzn/meridian-tokens/base/icon/plus';
import { saveAs } from 'file-saver';
import { isArray, lowerCase } from 'lodash';
import { DateTime } from 'luxon';
import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../context/store';
import {
    LevelOfConcern,
    Location,
    PersonInvolved,
    PersonInvolvedDisplaying,
    PersonInvolvedType,
    SiteIdAndCode
} from '../../../lib/CaseDefinitions';
import { CHARACTER_LIMIT, MAX_FILE_SIZE_MB, SELECT_WIDTH, WIDTH_OF_CARD } from '../../../lib/constants';
import { replaceFlaggedWords } from '../../../lib/flaggedWordHighlighter';
import {
    getEmployeeJobStatusType,
    renderFacmanLink,
    renderLocation,
    renderSimTicketTitle
} from '../../../lib/helpers';
import { labels } from '../../../lib/labels';
import {
    ContactUpdate,
    FileUpdate,
    LinkedSIMUpdate,
    SimStatusTypes,
    SimTicketUpdate,
    SimUpdate,
    Update
} from '../../../lib/UpdateDefinitions';
import { setCaseDetails } from '../../../slices/CaseDetailsSlice';
import { addToast } from '../../../slices/ToastSlice';
import {
    useLazyAddManualUpdateQuery,
    useLazyGetFileDataQuery,
    useLazyUpdateCaseQuery
} from '../../../slices/WotManApiSlice';
import ConsultationHeader from '../../Consultations/ConsultationHeader';
import AcknowledgeableProgressTrackerStep from '../../CustomComponents/AcknowledgeableProgressTrackerStep';
import PersonCard from '../../CustomComponents/PersonCard';
import TitledButton from '../../CustomComponents/TitledButton';
import { FormTextarea } from '../../Inputs/FormFields';
import LocCircle from '../../Inputs/LocCircle';
import CaseHeader from '../CaseHeader';

type UpdatesTabProp = {
    switchToSummary: () => void;
    sortValue: string;
    setSortValue: (sortValue: string) => void;
};

const UpdatesTab: React.FunctionComponent<UpdatesTabProp> = ({
    switchToSummary,
    sortValue,
    setSortValue
}: UpdatesTabProp) => {
    const { caseDetails, isCaseOpen } = useSelector((state: RootState) => state.caseDetails);
    const [openModal, setOpenModal] = useState(false);
    const [openLocModal, setOpenLocModal] = useState(false);
    const [updateLocNote, setUpdateLocNote] = useState('');
    const [tempLocNote, setTempLocNote] = useState('');
    const [updateLoc, setUpdateLoc] = useState<keyof typeof LevelOfConcern | undefined>();
    const [locNoteUpdateId, setLocNoteUpdateId] = useState<number | undefined>();
    const [updateEditing, setUpdateEditing] = useState<Update | undefined>();
    const [newUpdateTitle, setNewUpdateTitle] = useState('');
    const [newUpdateSummary, setNewUpdateSummary] = useState('');
    const [currentDownloadingFile, setCurrentDownloadingFile] = useState<FileUpdate | undefined>(undefined);
    const [updates, setUpdates] = useState(caseDetails.updates);
    const [files, setFiles] = React.useState<FileUpdate[]>([]);
    const [useAddManualUpdate, addManualUpdateEndpoint] = useLazyAddManualUpdateQuery();
    const [useUpdateLocNote, updateLocNoteEndpoint] = useLazyUpdateCaseQuery();
    const [useGetFileData, getFileDataEndpoint] = useLazyGetFileDataQuery();
    const dispatch = useAppDispatch();

    const resetLoc = () => {
        setLocNoteUpdateId(undefined);
        setUpdateLoc(undefined);
        setTempLocNote('');
        setUpdateLocNote('');
        setOpenLocModal(false);
        setFiles([]);
    };

    const onClose = () => {
        setOpenModal(false);
        setFiles([]);
    };

    const filterUserInvolvedCaseUpdates = (update: Update) => {
        return !(update.type.name === 'LinkedCaseUpdate' && !update.type.case);
    };

    useEffect(() => {
        setUpdates(caseDetails.updates);
    }, [caseDetails.updates]);

    useEffect(() => {
        if (!addManualUpdateEndpoint.isFetching && addManualUpdateEndpoint.isSuccess) {
            dispatch(setCaseDetails(addManualUpdateEndpoint.data));
            setOpenModal(false);
        } else if (!addManualUpdateEndpoint.isFetching && addManualUpdateEndpoint.isError) {
            dispatch(
                addToast({
                    type: 'error',
                    action: 'There was an error adding a manual update',
                    message: '',
                    SIM: true,
                    timeout: undefined
                })
            );
        }
    }, [addManualUpdateEndpoint.isFetching]);

    useEffect(() => {
        if (!updateLocNoteEndpoint.isFetching && updateLocNoteEndpoint.isSuccess && updateLoc) {
            dispatch(setCaseDetails(updateLocNoteEndpoint.data));
            dispatch(
                addToast({
                    type: 'success',
                    action: 'Level of concern saved ',
                    message: `for ${caseDetails.caseName}`,
                    SIM: false,
                    timeout: 3000
                })
            );
            resetLoc();
        } else if (!updateLocNoteEndpoint.isFetching && updateLocNoteEndpoint.isError) {
            dispatch(
                addToast({
                    type: 'error',
                    action: 'There was an error saving level of concern',
                    message: '',
                    SIM: true,
                    timeout: undefined
                })
            );
        }
    }, [updateLocNoteEndpoint.isFetching]);

    useEffect(() => {
        if (!getFileDataEndpoint.isFetching) {
            setCurrentDownloadingFile(undefined);

            if (getFileDataEndpoint.isSuccess) {
                const { data, type, name } = getFileDataEndpoint.data;
                const byteCharacters = atob(data);

                const buffer = new ArrayBuffer(byteCharacters.length);
                const view = new Uint8Array(buffer);

                // fill the view, using the decoded base64
                for (let n = 0; n < byteCharacters.length; n++) {
                    view[n] = byteCharacters.charCodeAt(n);
                }

                const blob = new Blob([view], {
                    type: type
                });

                saveAs(blob, `${name}`);
            } else if (getFileDataEndpoint.isError) {
                dispatch(
                    addToast({
                        type: 'error',
                        action: 'There was an error, please try again',
                        message: '',
                        SIM: true,
                        timeout: undefined
                    })
                );
            }
        }
    }, [getFileDataEndpoint.isFetching]);

    useEffect(() => {
        const newUpdates = [...caseDetails.updates];
        if (sortValue === 'newest') {
            setUpdates(
                newUpdates.sort((update1, update2) => {
                    return (
                        DateTime.fromISO(update2.timestamp).toMillis() -
                        DateTime.fromISO(update1.timestamp).toMillis()
                    );
                })
            );
        } else {
            setUpdates(
                newUpdates.sort((update1, update2) => {
                    return (
                        DateTime.fromISO(update1.timestamp).toMillis() -
                        DateTime.fromISO(update2.timestamp).toMillis()
                    );
                })
            );
        }
    }, [sortValue, caseDetails.updates]);

    const renderSimUpdateBody = (update: SimTicketUpdate | LinkedSIMUpdate, children?: JSX.Element) => {
        return (
            <Column spacing={'200'}>
                {renderSimTicketTitle(update.simId, update.title)}
                {children}
            </Column>
        );
    };

    const renderEditedString = (isoDate: string) => {
        return (
            <Text type='b100' color='secondary'>
                {' '}
                Edited {DateTime.fromISO(isoDate).toLocaleString(DateTime.DATETIME_FULL)}{' '}
            </Text>
        );
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const ControlledExpander = (props: any) => {
        const [open, setOpen] = useState();
        return <Expander open={open} onChange={setOpen} {...props} />;
    };

    const renderEditedStringArray = (isoDate: string[]) => {
        const lastEdit = (
            <Text type='b100' color='secondary'>
                {' '}
                Edited {DateTime.fromISO(isoDate[0]).toLocaleString(DateTime.DATETIME_FULL)}{' '}
            </Text>
        );
        return isoDate.length == 1 ? (
            lastEdit
        ) : (
            <ControlledExpander title={lastEdit}>
                {isoDate.slice(1).map((date) => {
                    return (
                        <Text type='b100' color='secondary' key={date}>
                            {' '}
                            Edited {DateTime.fromISO(date).toLocaleString(DateTime.DATETIME_FULL)}{' '}
                        </Text>
                    );
                })}
            </ControlledExpander>
        );
    };

    const makePersonInvolvedDisplaying = (
        updateType: PersonInvolvedType,
        person?: PersonInvolved
    ): PersonInvolvedDisplaying => {
        return {
            workPhone: person?.workPhone,
            workEmail: person?.workEmail,
            legalName: person?.legalName,
            employeeId: person?.employeeId,
            contactId: person?.contactId,
            role: person?.role ?? '',
            name: person?.name ?? '',
            login: person?.login,
            title: person?.title,
            phoneNumber: person?.phoneNumber ?? '',
            email: person?.email ?? '',
            addedBy: person?.addedBy,
            status: person?.status,
            tenure: person?.tenure,
            jobLevel: person?.jobLevel,
            futureTerminationDate: person?.futureTerminationDate,
            terminationReason: person?.terminationReason,
            address: person?.address,
            personalEmail: person?.personalEmail,
            type: updateType,
            teamName: '',
            notes: ''
        };
    };

    const renderUpdateTitle = (update: Update) => {
        let updateTitle;
        switch (update.type.name) {
            case 'LinkedCaseUpdate':
                updateTitle = (
                    <Text>
                        {' '}
                        <b> {update.updater} </b> {update.type.action}ed a case{' '}
                    </Text>
                );
                break;
            case 'LinkedConsultationUpdate':
                updateTitle = (
                    <Text>
                        {' '}
                        <b> {update.updater} </b> {update.type.action}ed an engagement{' '}
                    </Text>
                );
                break;
            case SimUpdate.SimCreate:
                updateTitle = (
                    <Text>
                        <b> SIM </b> ticket opened
                    </Text>
                );
                break;
            case SimUpdate.SimStatus: {
                let action: string = update.type.data;
                switch (update.type.data) {
                    case SimStatusTypes.OPEN:
                        action = 'opened';
                        break;
                    case SimStatusTypes.RESOLVED:
                        action = 'resolved';
                }

                updateTitle = (
                    <Text>
                        <b> SIM </b> ticket {action}
                    </Text>
                );
                break;
            }
            case SimUpdate.SimAttachment:
            case SimUpdate.SimComment:
            case SimUpdate.SimDate:
                updateTitle = (
                    <Text>
                        {' '}
                        <b> SIM </b> ticket updated
                    </Text>
                );
                break;
            case 'LocUpdate': {
                const { loc, note } = update.type;
                updateTitle = (
                    <Row>
                        <LocCircle loc={LevelOfConcern[loc]} canEdit={false} radius={21} />
                        <Text>
                            {' '}
                            <b> {update.updater} </b> edited level of concern{' '}
                        </Text>
                        {update.type.canEdit && isCaseOpen && (
                            <TitledButton
                                title={labels.editLocNote}
                                buttonProps={{
                                    onClick: () => {
                                        setUpdateLocNote(note);
                                        setTempLocNote(note);
                                        setUpdateLoc(loc);
                                        setOpenLocModal(true);
                                        setLocNoteUpdateId(update.updatedId);
                                    },
                                    type: 'secondary',
                                    size: 'small'
                                }}
                                iconProps={{ tokens: editIconTokens }}
                            />
                        )}
                    </Row>
                );
                break;
            }
            case 'LPContactUpdate':
                updateTitle = (
                    <Row>
                        <Text>
                            {' '}
                            <b> {update.updater} </b>{' '}
                            {update.type.action == 'delete' ? 'delet' : update.type.action}ed LP contact{' '}
                        </Text>
                    </Row>
                );
                break;
            case 'ContactUpdate':
                updateTitle = (
                    <Text>
                        {' '}
                        <b> {update.updater} </b> {update.type.action}{' '}
                        {update.type.action === 'edited'
                            ? lowerCase(update.type.oldValue?.role)
                            : lowerCase(update.type.newValue?.role)}{' '}
                    </Text>
                );
                break;
            case 'RecommendationsUpdate':
                updateTitle = (
                    <Text>
                        {' '}
                        <b> {update.updater} </b> edited recommendations{' '}
                    </Text>
                );
                break;
            case 'GenericUpdate': {
                const { note, title, files } = update.type;
                updateTitle = (
                    <Row>
                        <Text>
                            {' '}
                            <b> {update.updater} </b> added update{' '}
                        </Text>
                        {update.type.canEdit && isCaseOpen && (
                            <TitledButton
                                title={labels.editUpdate}
                                buttonProps={{
                                    onClick: () => {
                                        setUpdateEditing(update);
                                        setNewUpdateTitle(title);
                                        setNewUpdateSummary(note);
                                        if (files) {
                                            setFiles(files);
                                        } else {
                                            setFiles([]);
                                        }
                                        setOpenModal(true);
                                    },
                                    type: 'secondary',
                                    size: 'small'
                                }}
                                iconProps={{ tokens: editIconTokens }}
                            />
                        )}
                    </Row>
                );
                break;
            }
            case 'IncidentCreation':
                updateTitle = (
                    <Text>
                        {update.updater === null ? (
                            <Text>
                                <b> EMT </b> incident notification{' '}
                            </Text>
                        ) : (
                            <Text>
                                <b> {update.updater} </b> created case{' '}
                            </Text>
                        )}
                    </Text>
                );
                break;
            case 'AssigneeChange':
                updateTitle = (
                    <Text>
                        <b> {update.updater} </b> {update.type.action} assignee
                    </Text>
                );
                break;
            case 'LinkedSIMUpdate':
                updateTitle = (
                    <Text>
                        <b> SIM </b> ticket {update.type.action}ed
                    </Text>
                );
                break;
            case 'EmployeeStatusUpdate':
                updateTitle =
                    update.type.status == 'future termination notice' ? (
                        <Text>
                            <b> Person of interest </b> employee status changed to{' '}
                            <b>future termination notice</b>
                        </Text>
                    ) : (
                        <Text>
                            <b> Person of interest </b> employee status changed to{' '}
                            <b>{getEmployeeJobStatusType(update.type.status)}</b>
                        </Text>
                    );
                break;
            case 'EMTUpdateNotification':
                updateTitle = (
                    <Text>
                        <b> EMT </b> update notification
                    </Text>
                );
                break;
            case 'StatusUpdate':
                updateTitle = (
                    <Text>
                        <b> {update.updater} </b> edited status
                    </Text>
                );
                break;
            case 'RestrictionUpdate':
                updateTitle = (
                    <Text>
                        <b> {update.updater} </b> edited access level
                    </Text>
                );
                break;
            case 'MarsEventUpdate':
                updateTitle = (
                    <Text>
                        <b> {update.updater} </b> edited case details
                    </Text>
                );
                break;
        }
        return (
            <Row alignmentHorizontal='justify' width='100%'>
                {updateTitle}
                <Column alignmentHorizontal='right' spacing='none'>
                    <Text color={'secondary'} type={'b200'}>
                        {DateTime.fromISO(update.timestamp).toLocaleString(DateTime.DATE_MED)}
                    </Text>
                    <Text color={'secondary'} type={'b200'}>
                        {DateTime.fromISO(update.timestamp).toFormat('HH:mm ZZZZ')}
                    </Text>
                </Column>
            </Row>
        );
    };

    const renderUpdateBody = (update: Update) => {
        switch (update.type.name) {
            case SimUpdate.SimComment:
                return renderSimUpdateBody(
                    update.type,
                    <>
                        <Text type={'h100'}>
                            {' '}
                            {update.type.editorName} commented in {update.type.data.thread}{' '}
                        </Text>
                        {update.type.editDate && renderEditedString(update.type.editDate)}
                        <Text type={'b300'}>
                            {replaceFlaggedWords(update.type.data.message, caseDetails.totalFlaggedWords)}
                        </Text>
                    </>
                );
            case SimUpdate.SimAttachment: {
                const url = `https://maxis-file-service-prod-pdx.pdx.proxy.amazon.com/issues/${update.type.simId}/attachments/${update.type.data.id}`;
                return renderSimUpdateBody(
                    update.type,
                    <>
                        <Text type={'h100'}> {update.type.editorName} attached file </Text>
                        <Link href={url} target='_blank' rel='noopener noreferrer'>
                            {update.type.data.fileName}
                        </Link>
                        {/*<FilePreviewer file={{ url }} />*/}
                    </>
                );
            }
            case SimUpdate.SimCreate:
                return renderSimUpdateBody(
                    update.type,
                    <>
                        <Text type={'h100'}> Description </Text>
                        {update.type.editDate && renderEditedString(update.type.editDate)}
                        <Text type={'b300'}>{update.type.data}</Text>
                    </>
                );
            case 'LinkedSIMUpdate':
            case SimUpdate.SimStatus:
                return renderSimUpdateBody(update.type);
            case SimUpdate.SimDate:
                return renderSimUpdateBody(
                    update.type,
                    <>
                        <Text type={'h100'}> {update.type.editorName} changed monitoring end date </Text>
                        <Text>{DateTime.fromISO(update.type.data).toLocaleString(DateTime.DATE_MED)}</Text>
                    </>
                );
            case 'LinkedCaseUpdate':
                return (
                    <span className='UpdateHeader'>
                        <CaseHeader caseHeader={update.type.case} />
                    </span>
                );
            case 'LinkedConsultationUpdate':
                return (
                    <span className='UpdateHeader'>
                        <ConsultationHeader consultationHeader={update.type.consultation} />
                    </span>
                );
            case 'LocUpdate':
                return (
                    <Column spacing='small'>
                        <b> Note </b>
                        {update.type.history &&
                            update.type.history.length > 0 &&
                            renderEditedStringArray(update.type.history)}
                        {update.type.note === '' ? (
                            <Text color='secondary'> None </Text>
                        ) : (
                            <Text>
                                {' '}
                                {replaceFlaggedWords(update.type.note, caseDetails.totalFlaggedWords)}{' '}
                            </Text>
                        )}
                    </Column>
                );
            case 'LPContactUpdate':
                return <Text> {update.type.lpPersonId} </Text>;
            case 'RecommendationsUpdate': {
                const editedList = update.type.recommendations.filter((recc) => recc.action === 'edited');
                const removedList = update.type.recommendations.filter((recc) => recc.action === 'removed');
                const addedList = update.type.recommendations.filter((recc) => recc.action === 'added');

                return (
                    <Column>
                        {addedList.length > 0 && (
                            <Column spacing={'small'}>
                                <b> Added recommendations </b>
                                <Text tag='ul'>
                                    {addedList.map((recommendation) => {
                                        return (
                                            <li key={recommendation.name}>
                                                <Column spacing={'none'}>
                                                    <Text> {recommendation.name} </Text>
                                                    <Text> {recommendation.description} </Text>
                                                </Column>
                                            </li>
                                        );
                                    })}
                                </Text>
                            </Column>
                        )}
                        {editedList.length > 0 && (
                            <Column spacing={'small'}>
                                <b> Edited recommendations </b>
                                <Text tag='ul'>
                                    {editedList.map((recommendation) => {
                                        return (
                                            <li key={recommendation.name}>
                                                <Column spacing={'none'}>
                                                    <Text> {recommendation.name} </Text>
                                                    <Text> {recommendation.description} </Text>
                                                </Column>
                                            </li>
                                        );
                                    })}
                                </Text>
                            </Column>
                        )}
                        {removedList.length > 0 && (
                            <Column spacing={'small'}>
                                <b> Removed recommendations </b>
                                <Text tag='ul'>
                                    {removedList.map((recommendation) => {
                                        return (
                                            <li key={recommendation.name}>
                                                <Column spacing={'none'}>
                                                    <Text> {recommendation.name} </Text>
                                                    <Text> {recommendation.description} </Text>
                                                </Column>
                                            </li>
                                        );
                                    })}
                                </Text>
                            </Column>
                        )}
                    </Column>
                );
            }
            case 'GenericUpdate':
                return (
                    <Column spacing={'small'}>
                        <b> {update.type.title} </b>
                        {update.type.history &&
                            update.type.history.length > 0 &&
                            renderEditedStringArray(update.type.history)}
                        <Text> {replaceFlaggedWords(update.type.note, caseDetails.totalFlaggedWords)} </Text>
                        {update.type.files &&
                            update.type.files.length > 0 &&
                            update.type.files.map((file, index) => (
                                <Row key={index} spacing='200'>
                                    <Text>{file.name}</Text>
                                    <Button
                                        onClick={() => {
                                            useGetFileData({
                                                fileId: file.fileId
                                            });
                                            setCurrentDownloadingFile(file);
                                        }}
                                        type='link'
                                    >
                                        <Icon tokens={downloadSmallTokens} />
                                    </Button>
                                    {currentDownloadingFile === file && (
                                        <Loader type='circular' size='small' />
                                    )}
                                </Row>
                            ))}
                    </Column>
                );
            case 'IncidentCreation':
                return (
                    <Button onClick={switchToSummary} type={'tertiary'}>
                        View summary
                    </Button>
                );
            case 'EmployeeStatusUpdate':
                if (update.type.employee) {
                    const person: PersonInvolvedDisplaying = {
                        ...update.type.employee,
                        type: PersonInvolvedType.INTERNAL_PERSON
                    };
                    return <PersonCard person={person} displayAddedBy={false} />;
                } else {
                    return <Text> Invalid employee </Text>;
                }
            case 'ContactUpdate': {
                const contactUpdate = update.type as ContactUpdate;

                return contactUpdate.action === 'added' ? (
                    <PersonCard
                        person={makePersonInvolvedDisplaying(contactUpdate.type, contactUpdate.newValue)}
                        displayAddedBy={false}
                    />
                ) : (
                    <Row alignmentVertical='top'>
                        <Column spacing='200'>
                            <Text type='h100'> Old </Text>
                            <PersonCard
                                person={makePersonInvolvedDisplaying(
                                    contactUpdate.type,
                                    contactUpdate.oldValue
                                )}
                                displayAddedBy={false}
                            />
                        </Column>
                        <Column spacing='200'>
                            <Text type='h100'> New </Text>
                            <PersonCard
                                person={makePersonInvolvedDisplaying(
                                    contactUpdate.type,
                                    contactUpdate.newValue
                                )}
                                displayAddedBy={false}
                            />
                        </Column>
                    </Row>
                );
            }
            case 'AssigneeChange':
                return <Text> {update.type.assignee} </Text>;
            case 'EMTUpdateNotification':
                return (
                    <Column>
                        <b> EMT{update.type.emtId} </b>
                        {update.type.updates.map((update, index) => {
                            return (
                                <Column key={index} spacing='none'>
                                    <b> {update.title} </b>
                                    <Text> {update.note} </Text>
                                </Column>
                            );
                        })}
                    </Column>
                );
            case 'StatusUpdate':
                return (
                    <Column spacing='none'>
                        <b> Status </b>
                        <Text> {update.type.action} </Text>
                    </Column>
                );
            case 'RestrictionUpdate':
                return (
                    <Column spacing='none'>
                        <b> Access Level </b>
                        <Text> {update.type.action} </Text>
                    </Column>
                );
            case 'MarsEventUpdate': {
                const renderNone = () => (
                    <Text tag='span' color='secondary'>
                        None
                    </Text>
                );
                const renderTitle = (title: string, isNew: boolean) => (
                    <Text tag={'span'} type={'h100'}>
                        {' '}
                        {title}{' '}
                        <Text tag={'span'} color={'secondary'}>
                            {' '}
                            ({isNew ? 'New' : 'Old'})
                        </Text>{' '}
                    </Text>
                );
                const MARS_UPDATE_SMALL_WIDTH = '200px';
                const renderLocationUpdate = (
                    location: SiteIdAndCode[] | Location,
                    isNew: boolean,
                    title: string
                ) => (
                    <Column spacing='none' width={MARS_UPDATE_SMALL_WIDTH}>
                        {renderTitle(title, isNew)}
                        <Column spacing={'none'}>
                            {isArray(location) && location.length > 0
                                ? location.map((facility, index) => {
                                      return <Fragment key={index}>{renderFacmanLink(facility)}</Fragment>;
                                  })
                                : isArray(location)
                                ? renderNone()
                                : renderLocation(location)}
                            {!isArray(location) &&
                                location.state == '' &&
                                location.city == '' &&
                                location.state == '' &&
                                location.zipcode == '' &&
                                !location.country &&
                                !location.region && <Text color={'secondary'}>No address</Text>}
                        </Column>
                    </Column>
                );
                return (
                    <Column>
                        {update.type.eventCategory && (
                            <Row>
                                <Column spacing='none' width={MARS_UPDATE_SMALL_WIDTH}>
                                    {renderTitle('Event category', false)}
                                    <Text> {update.type.eventCategory.old} </Text>
                                </Column>
                                <Column spacing='none' width={MARS_UPDATE_SMALL_WIDTH}>
                                    {renderTitle('Event category', true)}
                                    <Text> {update.type.eventCategory.new} </Text>
                                </Column>
                            </Row>
                        )}
                        {update.type.eventType && (
                            <Row>
                                <Column spacing='none' width={MARS_UPDATE_SMALL_WIDTH}>
                                    {renderTitle('Event type', false)}
                                    <Text>{update.type.eventType.old}</Text>
                                </Column>
                                <Column spacing='none' width={MARS_UPDATE_SMALL_WIDTH}>
                                    {renderTitle('Event type', true)}
                                    <Text>{update.type.eventType.new}</Text>
                                </Column>
                            </Row>
                        )}
                        {update.type.eventItem && (
                            <Row>
                                <Column spacing='none' width={MARS_UPDATE_SMALL_WIDTH}>
                                    {renderTitle('Event item', false)}
                                    <Text> {update.type.eventItem.old} </Text>
                                </Column>
                                <Column spacing='none' width={MARS_UPDATE_SMALL_WIDTH}>
                                    {renderTitle('Event item', true)}
                                    <Text> {update.type.eventItem.new} </Text>
                                </Column>
                            </Row>
                        )}
                        {update.type.incidentDate && (
                            <Row alignmentVertical='top'>
                                <Column spacing='none' width={MARS_UPDATE_SMALL_WIDTH}>
                                    {renderTitle('Date of event', false)}
                                    <Text> {update.type.incidentDate.old || renderNone()} </Text>
                                </Column>
                                <Column spacing='none' width={MARS_UPDATE_SMALL_WIDTH}>
                                    {renderTitle('Date of event', true)}
                                    <Text> {update.type.incidentDate.new || renderNone()} </Text>
                                </Column>
                            </Row>
                        )}
                        {update.type.incidentTime && (
                            <Row alignmentVertical='top'>
                                <Column spacing='none' width={MARS_UPDATE_SMALL_WIDTH}>
                                    {renderTitle('Time of event', false)}
                                    <Text> {update.type.incidentTime.old || renderNone()} </Text>
                                </Column>
                                <Column spacing='none' width={MARS_UPDATE_SMALL_WIDTH}>
                                    {renderTitle('Time of event', true)}
                                    <Text> {update.type.incidentTime.new || renderNone()} </Text>
                                </Column>
                            </Row>
                        )}
                        {update.type.proximity && (
                            <Row>
                                <Column spacing='none' width={MARS_UPDATE_SMALL_WIDTH}>
                                    {renderTitle('Proximity', false)}
                                    <Text> {update.type.proximity.old} </Text>
                                </Column>
                                <Column spacing='none' width={MARS_UPDATE_SMALL_WIDTH}>
                                    {renderTitle('Proximity', true)}
                                    <Text> {update.type.proximity.new} </Text>
                                </Column>
                            </Row>
                        )}
                        {update.type.location && (
                            <Row alignmentVertical={'top'}>
                                {update.type.location.old &&
                                    renderLocationUpdate(update.type.location.old, false, 'Event location')}
                                {update.type.location.new &&
                                    renderLocationUpdate(update.type.location.new, true, 'Event location')}
                            </Row>
                        )}
                        {update.type.primaryFacility && (
                            <Row alignmentVertical={'top'}>
                                {renderLocationUpdate(
                                    update.type.primaryFacility.old,
                                    false,
                                    'Primary facility'
                                )}
                                {renderLocationUpdate(
                                    update.type.primaryFacility.new,
                                    true,
                                    'Primary facility'
                                )}
                            </Row>
                        )}
                        {update.type.secondaryFacility && (
                            <Row alignmentVertical={'top'}>
                                {renderLocationUpdate(
                                    update.type.secondaryFacility.old,
                                    false,
                                    'Secondary facilities'
                                )}
                                {renderLocationUpdate(
                                    update.type.secondaryFacility.new,
                                    true,
                                    'Secondary facilities'
                                )}
                            </Row>
                        )}
                        {update.type.eventTags && (
                            <Row alignmentVertical='top'>
                                <Column spacing='none' width={MARS_UPDATE_SMALL_WIDTH}>
                                    {renderTitle('Tags', false)}
                                    {update.type.eventTags.old.map((tag) => (
                                        <Text key={tag}> {tag} </Text>
                                    ))}
                                </Column>
                                <Column spacing='none' width={MARS_UPDATE_SMALL_WIDTH}>
                                    {renderTitle('Tags', true)}
                                    {update.type.eventTags.new.map((tag) => (
                                        <Text key={tag}> {tag} </Text>
                                    ))}
                                </Column>
                            </Row>
                        )}
                        {update.type.eventSummary && (
                            <Row alignmentVertical='top'>
                                <Column spacing='none' width={'400px'}>
                                    {renderTitle('Summary', false)}
                                    <Text>
                                        {' '}
                                        {replaceFlaggedWords(
                                            update.type.eventSummary.old,
                                            caseDetails.totalFlaggedWords
                                        )}{' '}
                                    </Text>
                                </Column>
                                <Column spacing='none' width={'400px'}>
                                    {renderTitle('Summary', true)}
                                    <Text>
                                        {' '}
                                        {replaceFlaggedWords(
                                            update.type.eventSummary.new,
                                            caseDetails.totalFlaggedWords
                                        )}{' '}
                                    </Text>
                                </Column>
                            </Row>
                        )}
                    </Column>
                );
            }
        }
    };

    return (
        <Column maxWidth={WIDTH_OF_CARD} spacing={'500'}>
            <Modal width={'560px'} title='Add Update' open={openModal} onClose={() => onClose()}>
                <Column>
                    <Input
                        label='Title *'
                        width={SELECT_WIDTH}
                        value={newUpdateTitle}
                        disabled={addManualUpdateEndpoint.isFetching}
                        onChange={setNewUpdateTitle}
                        error={newUpdateTitle.length > CHARACTER_LIMIT.NORMAL_TEXT_LIMIT}
                    />
                    <FormTextarea
                        rows={5}
                        value={newUpdateSummary}
                        disabled={addManualUpdateEndpoint.isFetching}
                        onChange={setNewUpdateSummary}
                        label={'Summary *'}
                        max={CHARACTER_LIMIT.MANUAL_UPDATE_LIMIT}
                        missing={false}
                    />
                    <Row widths={['152px', '360px']} alignmentVertical={'top'}>
                        <Column alignmentVertical={'center'}>
                            <FileInput
                                uploadButtonDisabled={addManualUpdateEndpoint.isFetching}
                                accept={
                                    '.jpeg, .png, .webp, .mp3, .mp4, .m4a, .wav, .amr, .flac, .eml, .msg, .pdf, .txt, .xls, .xlsx, .csv, .doc, .docx'
                                }
                                onFileAttached={(acceptedFiles) => {
                                    const fileData: Promise<ArrayBuffer>[] = [];
                                    acceptedFiles.forEach((file: File) => {
                                        if (file.size > CHARACTER_LIMIT.FILE_SIZE) {
                                            dispatch(
                                                addToast({
                                                    type: 'error',
                                                    action: `File '${file.name}' was greater than the max file size (${MAX_FILE_SIZE_MB}MB)`,
                                                    message: '',
                                                    SIM: false,
                                                    timeout: 3000
                                                })
                                            );
                                        } else {
                                            fileData.push(file.arrayBuffer());
                                        }
                                    });
                                    Promise.all(fileData).then((values) => {
                                        const fileList: FileUpdate[] = [];
                                        for (let x = 0; x < values.length; x++) {
                                            const uint = btoa(
                                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                                // @ts-ignore
                                                [].reduce.call(
                                                    new Uint8Array(values[x]),
                                                    function(p, c) {
                                                        return p + String.fromCharCode(c);
                                                    },
                                                    ''
                                                )
                                            );
                                            fileList.push({
                                                type: acceptedFiles[x].type,
                                                bytes: uint,
                                                name: acceptedFiles[x].name,
                                                size: acceptedFiles[x].size
                                            });
                                        }
                                        setFiles([...files, ...fileList]);
                                    });
                                }}
                            />
                            <Text> Total max size: {MAX_FILE_SIZE_MB}MB </Text>
                        </Column>
                        <Column>
                            {files.map((file, index) => (
                                <FileDetails
                                    error={false}
                                    file={{ name: file.name, size: file.size }}
                                    key={file.name}
                                    onClickRemoveFile={
                                        addManualUpdateEndpoint.isFetching
                                            ? undefined
                                            : () => {
                                                  setFiles(
                                                      files.filter((file, indexFile) => index !== indexFile)
                                                  );
                                              }
                                    }
                                    uploadComplete={true}
                                />
                            ))}
                        </Column>
                    </Row>
                </Column>
                <ModalFooter>
                    <Row alignmentHorizontal='right'>
                        <Button
                            type='secondary'
                            disabled={addManualUpdateEndpoint.isFetching}
                            onClick={() => onClose()}
                        >
                            Cancel
                        </Button>
                        <Button
                            disabled={
                                addManualUpdateEndpoint.isFetching ||
                                newUpdateTitle.length === 0 ||
                                newUpdateSummary.length === 0 ||
                                (typeof updateEditing != 'undefined' &&
                                    updateEditing.type.name === 'GenericUpdate' &&
                                    updateEditing.type.title === newUpdateTitle &&
                                    updateEditing.type.note === newUpdateSummary &&
                                    updateEditing.type.files === files)
                            }
                            onClick={() => {
                                let size = 0;
                                const fileArr: {
                                    bytes?: string;
                                    name: string;
                                    type: string;
                                    fileId?: number;
                                }[] = [];
                                files.forEach((file) => {
                                    size += file.size;
                                    fileArr.push({
                                        bytes: file.bytes,
                                        name: file.name,
                                        type: file.type,
                                        fileId: file.fileId
                                    });
                                });
                                if (size > CHARACTER_LIMIT.FILE_SIZE) {
                                    dispatch(
                                        addToast({
                                            type: 'error',
                                            action: `Upload files with a total max size of ${MAX_FILE_SIZE_MB}MB`,
                                            message: '',
                                            SIM: false,
                                            timeout: 3000
                                        })
                                    );
                                } else {
                                    useAddManualUpdate({
                                        updateId: updateEditing && updateEditing.updatedId,
                                        incidentId: caseDetails.caseId,
                                        genericUpdate: {
                                            title: newUpdateTitle,
                                            note: newUpdateSummary,
                                            files: fileArr
                                        }
                                    });
                                    setFiles([]);
                                }
                            }}
                        >
                            {updateEditing ? 'Save' : 'Submit'}
                        </Button>
                    </Row>
                </ModalFooter>
            </Modal>
            <Modal
                title='Set level of concern note'
                width={'560px'}
                open={openLocModal}
                scrollContainer='modal'
                onClose={() => setOpenLocModal(false)}
            >
                <Column>
                    <FormTextarea
                        label='Note'
                        value={updateLocNote}
                        onChange={setUpdateLocNote}
                        missing={false}
                        max={CHARACTER_LIMIT.NOTE_TEXT_LIMIT}
                        rows={2}
                    />
                </Column>
                <ModalFooter>
                    <Row alignmentHorizontal='right'>
                        <Button type='secondary' onClick={() => resetLoc()}>
                            Cancel
                        </Button>
                        <Button
                            type='primary'
                            disabled={updateLocNoteEndpoint.isFetching || updateLocNote === tempLocNote}
                            onClick={() => {
                                if (
                                    updateLoc &&
                                    (!updateLocNote ||
                                        updateLocNote.length <= CHARACTER_LIMIT.NOTE_TEXT_LIMIT)
                                ) {
                                    useUpdateLocNote({
                                        updating: {
                                            type: 'loc note',
                                            newContent: updateLoc,
                                            note: updateLocNote,
                                            incidentId: caseDetails.caseId,
                                            updateId: locNoteUpdateId
                                        }
                                    });
                                }
                            }}
                        >
                            Save
                        </Button>
                    </Row>
                </ModalFooter>
            </Modal>
            <Row alignmentHorizontal={'justify'}>
                <Row spacing={'400'}>
                    <Text tag={'span'} type={'h100'}>
                        {' '}
                        Updates{' '}
                    </Text>
                    <Text color={'secondary'}>{updates.length}</Text>
                </Row>
                <Row>
                    <Text type={'h100'}> Sort by </Text>
                    <Select value={sortValue} onChange={setSortValue} width={176} size={'small'}>
                        <SelectOption value='newest' label='Newest' />
                        <SelectOption value='oldest' label='Oldest' />
                    </Select>
                    {isCaseOpen && (
                        <TitledButton
                            title={labels.addUpdate}
                            buttonProps={{
                                onClick: () => {
                                    setUpdateEditing(undefined);
                                    setNewUpdateSummary('');
                                    setNewUpdateTitle('');
                                    setOpenModal(true);
                                },
                                type: 'primary',
                                size: 'medium'
                            }}
                            iconProps={{ tokens: plusTokens }}
                        />
                    )}
                </Row>
            </Row>
            <ProgressTracker>
                {updates.map((update) => {
                    return (
                        filterUserInvolvedCaseUpdates(update) && (
                            <AcknowledgeableProgressTrackerStep
                                key={update.updatedId}
                                updateId={update.updatedId}
                                caseId={caseDetails.caseId}
                                caseName={caseDetails.caseName}
                                type={update.needsAcknowledgement ? 'present' : 'past'}
                                label={renderUpdateTitle(update)}
                                needsAcknowledgement={update.needsAcknowledgement}
                            >
                                {renderUpdateBody(update)}
                            </AcknowledgeableProgressTrackerStep>
                        )
                    );
                })}
            </ProgressTracker>
        </Column>
    );
};

export default UpdatesTab;
