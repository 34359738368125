import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { mockMarsCase, mockInternalContactInvolvedDisplaying } from '../__mocks__/mockData';
import {
    Case,
    Consultation,
    EMTCase,
    MARSCase,
    PersonInvolvedDisplaying,
    StatusType
} from '../lib/CaseDefinitions';

interface CaseDetailsSliceState {
    caseDetails: MARSCase | EMTCase;
    consultationUnlinking?: Consultation;
    caseUnlinking?: Case;
    displayingUnlinkModal?: string;
    isDisplayingEditLOCModal: boolean;
    displayPersonInvolvedSheet: boolean;
    displayReportedBy: boolean;
    personInvolvedDisplaying: PersonInvolvedDisplaying;
    displayPersonsInvolvedModal: boolean;
    isDuplicateContact: boolean;
    isStacked: boolean;
    isCaseOpen: boolean;
}

const initialState: CaseDetailsSliceState = {
    caseDetails: mockMarsCase,
    isDisplayingEditLOCModal: false,
    displayPersonInvolvedSheet: false,
    displayReportedBy: false,
    personInvolvedDisplaying: mockInternalContactInvolvedDisplaying,
    displayPersonsInvolvedModal: false,
    isDuplicateContact: false,
    isStacked: false,
    isCaseOpen: false
};

const caseDetailsSlice = createSlice({
    name: 'caseDetails',
    initialState,
    reducers: {
        setCaseDetails: (state: CaseDetailsSliceState, { payload }: PayloadAction<MARSCase | EMTCase>) => {
            state.caseDetails = payload;
            state.isDisplayingEditLOCModal = false;
            state.isCaseOpen = payload.status == StatusType.OPEN;
        },
        setPersonsInvolvedDisplaying: (
            state: CaseDetailsSliceState,
            { payload }: PayloadAction<PersonInvolvedDisplaying>
        ) => {
            state.personInvolvedDisplaying = payload;
            state.displayPersonInvolvedSheet = true;
            state.displayReportedBy = false;
        },
        setCreatePersonInvolvedViewing: (state: CaseDetailsSliceState) => {
            state.displayPersonsInvolvedModal = true;
        },
        setReportedByDisplaying: (
            state: CaseDetailsSliceState,
            { payload }: PayloadAction<PersonInvolvedDisplaying>
        ) => {
            state.personInvolvedDisplaying = payload;
            state.displayPersonInvolvedSheet = true;
            state.displayReportedBy = true;
        },
        closeDetail: (state: CaseDetailsSliceState) => {
            state.displayPersonInvolvedSheet = false;
            state.displayReportedBy = false;
        },
        closePersonInvolvedModal: (state: CaseDetailsSliceState) => {
            state.displayPersonsInvolvedModal = false;
        },
        displayLocModal: (state: CaseDetailsSliceState) => {
            state.isDisplayingEditLOCModal = true;
        },
        closeLocModal: (state: CaseDetailsSliceState) => {
            state.isDisplayingEditLOCModal = false;
        },
        setConsultationUnlinking: (
            state: CaseDetailsSliceState,
            { payload }: PayloadAction<Consultation>
        ) => {
            state.consultationUnlinking = payload;
        },
        setDisplayingUnlinkModal: (
            state: CaseDetailsSliceState,
            { payload }: PayloadAction<string | undefined>
        ) => {
            state.displayingUnlinkModal = payload;
        },
        setCaseUnlinking: (state: CaseDetailsSliceState, { payload }: PayloadAction<Case>) => {
            state.caseUnlinking = payload;
        },
        setDuplicateContact: (state: CaseDetailsSliceState) => {
            state.isDuplicateContact = true;
        },
        closeDuplicateContact: (state: CaseDetailsSliceState) => {
            state.isDuplicateContact = false;
        },
        setIsStacked: (state: CaseDetailsSliceState, { payload }: PayloadAction<boolean>) => {
            state.isStacked = payload;
        }
    }
});

export const {
    setCaseDetails,
    setPersonsInvolvedDisplaying,
    setReportedByDisplaying,
    closeDetail,
    displayLocModal,
    closeLocModal,
    setConsultationUnlinking,
    setCaseUnlinking,
    setDisplayingUnlinkModal,
    setCreatePersonInvolvedViewing,
    closePersonInvolvedModal,
    setDuplicateContact,
    closeDuplicateContact,
    setIsStacked
} = caseDetailsSlice.actions;

export default caseDetailsSlice.reducer;
