/* eslint-disable  @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import AlertModal from './AlertModal';

type RouterPromptProps = {
    when: boolean;
};

const RouterPrompt: React.FunctionComponent<RouterPromptProps> = ({ when }: RouterPromptProps) => {
    const history = useHistory();
    const [showPrompt, setShowPrompt] = useState(false);
    const [currentPath, setCurrentPath] = useState('');
    const unblockRef: any = useRef();

    const handleShowModal = () => {
        setShowPrompt(true);
    };

    const handleConfirm = () => {
        setShowPrompt(false);
    };

    useEffect(() => {
        unblockRef.current = history.block((location: any) => {
            if (when) {
                setCurrentPath(location.pathname);
                handleShowModal();
                return 'false';
            }
            return 'true';
        });
        return () => {
            unblockRef.current && unblockRef.current();
        };
    }, [when]);

    const onCancel = () => {
        if (unblockRef) {
            unblockRef.current();
        }
        setShowPrompt(false);
        history.push(currentPath);
    };

    return showPrompt ? (
        <AlertModal
            showPrompt={showPrompt}
            onCancel={onCancel}
            handleConfirm={handleConfirm}
            onClose={handleConfirm}
        ></AlertModal>
    ) : null;
};

export default RouterPrompt;
