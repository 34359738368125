import Button from '@amzn/meridian/button';
import Column from '@amzn/meridian/column';
import Heading from '@amzn/meridian/heading';
import Input from '@amzn/meridian/input';
import InputGroup from '@amzn/meridian/input-group';
import Link from '@amzn/meridian/link';
import Loader from '@amzn/meridian/loader';
import Modal, { ModalFooter } from '@amzn/meridian/modal';
import Row from '@amzn/meridian/row';
import Text from '@amzn/meridian/text';
import Theme from '@amzn/meridian/theme';
import Tile from '@amzn/meridian/tile';
import editIconTokens from '@amzn/meridian-tokens/base/icon/edit';
import plusTokens from '@amzn/meridian-tokens/base/icon/plus';
import orangeLightTokens from '@amzn/meridian-tokens/theme/orange-light';
import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { VictoryAxis, VictoryChart, VictoryLine } from 'victory';
import { RootState, useAppDispatch } from '../../../../context/store';
import { PAPILong, PAPIShort } from '../../../../lib/CaseDefinitions';
import { SELECT_WIDTH } from '../../../../lib/constants';
import { getColor, locToString } from '../../../../lib/helpers';
import { labels } from '../../../../lib/labels';
import { setCaseDetails } from '../../../../slices/CaseDetailsSlice';
import { addToast } from '../../../../slices/ToastSlice';
import {
    useLazyEditLPContactQuery,
    useLazyGetEmployeeDetailsQuery,
    useLazySearchForEmployeesQuery
} from '../../../../slices/WotManApiSlice';
import AlertModal from '../../../Alert/AlertModal';
import AdminDirectoryLink from '../../../CustomComponents/AdminDirectoryLink';
import ChimeLink from '../../../CustomComponents/ChimeLink';
import TitledButton from '../../../CustomComponents/TitledButton';
import LoginNameSearch from '../../../Inputs/LoginNameSearch';
import SIMWidget from './SIMWidget';

const CaseDetailsSidebar: React.FunctionComponent = () => {
    const { caseDetails, isCaseOpen } = useSelector((state: RootState) => state.caseDetails);
    const [openModal, setOpenModal] = useState(false);
    const [showAlertPrompt, setShowAlertPrompt] = useState<boolean>(false);
    const [openLPContactModal, setOpenLPContactModal] = useState(false);
    const [page, setPage] = useState(0);
    const [query, setQuery] = useState('');
    const [disabled, setDisabled] = useState(true);
    const dispatch = useAppDispatch();
    const [useSearchForEmployees, useSearchForEmployeesEndpoint] = useLazySearchForEmployeesQuery({
        prefix: query
    });
    const [useGetEmployeeDetail, useGetEmployeeDetailEndpoint] = useLazyGetEmployeeDetailsQuery();
    const [useEditLPContact, useEditLPContactEndpoint] = useLazyEditLPContactQuery();
    const [useAddLPContact, useAddLPContactEndpoint] = useLazyEditLPContactQuery();
    const [useDeleteLPContact, useDeleteLPContactEndpoint] = useLazyEditLPContactQuery();

    const emptyLPContact = {
        personId: '',
        email: '',
        lastName: '',
        firstName: '',
        fullName: '',
        login: '',
        phone: '',
        jobTitle: ''
    };
    const [createLPContact, setCreateLPContact] = useState<PAPILong>(emptyLPContact);

    useEffect(() => {
        if (!useGetEmployeeDetailEndpoint.isFetching && useGetEmployeeDetailEndpoint.isSuccess) {
            setCreateLPContact({
                personId: useGetEmployeeDetailEndpoint.data.personId,
                email: useGetEmployeeDetailEndpoint.data.email,
                lastName: useGetEmployeeDetailEndpoint.data.lastName,
                firstName: useGetEmployeeDetailEndpoint.data.firstName,
                fullName: useGetEmployeeDetailEndpoint.data.fullName,
                login: useGetEmployeeDetailEndpoint.data.login,
                phone: useGetEmployeeDetailEndpoint.data.phone,
                jobTitle: useGetEmployeeDetailEndpoint.data.jobTitle
            });
            setQuery(useGetEmployeeDetailEndpoint.data.fullName);
            setDisabled(false);
        } else if (!useGetEmployeeDetailEndpoint.isFetching && useGetEmployeeDetailEndpoint.isError) {
            dispatch(
                addToast({
                    type: 'error',
                    action: 'There was an error fetching the employee details',
                    message: '',
                    SIM: true,
                    timeout: undefined
                })
            );
        }
    }, [useGetEmployeeDetailEndpoint.isFetching]);

    useEffect(() => {
        if (!useEditLPContactEndpoint.isFetching && useEditLPContactEndpoint.isSuccess) {
            dispatch(setCaseDetails(useEditLPContactEndpoint.data));
            dispatch(
                addToast({
                    type: 'success',
                    action: 'Saved LP contact ',
                    message: createLPContact.fullName,
                    SIM: false,
                    timeout: 3000
                })
            );
            setOpenLPContactModal(false);
        } else if (!useEditLPContactEndpoint.isFetching && useEditLPContactEndpoint.isError) {
            dispatch(
                addToast({
                    type: 'error',
                    action: 'There was an error editing LP contact',
                    message: '',
                    SIM: true,
                    timeout: undefined
                })
            );
        }
    }, [useEditLPContactEndpoint.isFetching]);

    useEffect(() => {
        if (!useAddLPContactEndpoint.isFetching && useAddLPContactEndpoint.isSuccess) {
            dispatch(setCaseDetails(useAddLPContactEndpoint.data));
            dispatch(
                addToast({
                    type: 'success',
                    action: 'Added LP contact ',
                    message: createLPContact.fullName,
                    SIM: false,
                    timeout: 3000
                })
            );
            setOpenLPContactModal(false);
        } else if (!useAddLPContactEndpoint.isFetching && useAddLPContactEndpoint.isError) {
            dispatch(
                addToast({
                    type: 'error',
                    action: 'There was an error editing LP contact',
                    message: '',
                    SIM: true,
                    timeout: undefined
                })
            );
        }
    }, [useAddLPContactEndpoint.isFetching]);

    useEffect(() => {
        if (!useDeleteLPContactEndpoint.isFetching && useDeleteLPContactEndpoint.isSuccess) {
            caseDetails.lpPersonId &&
                dispatch(
                    addToast({
                        type: 'success',
                        action: 'Deleted LP contact ',
                        message: caseDetails.lpPersonId.fullName,
                        SIM: false,
                        timeout: 3000
                    })
                );
            dispatch(setCaseDetails(useDeleteLPContactEndpoint.data));
            setOpenLPContactModal(false);
            setPage(0);
            setQuery('');
            setDisabled(true);
        } else if (!useDeleteLPContactEndpoint.isFetching && useDeleteLPContactEndpoint.isError) {
            dispatch(
                addToast({
                    type: 'error',
                    action: 'There was an error editing LP contact',
                    message: '',
                    SIM: true,
                    timeout: undefined
                })
            );
        }
    }, [useDeleteLPContactEndpoint.isFetching]);

    const handleClear = () => {
        setCreateLPContact(emptyLPContact);
        setQuery('');
        setDisabled(true);
    };

    const handleShowModal = () => {
        setShowAlertPrompt(false);
    };

    const handleCancel = () => {
        setShowAlertPrompt(false);
        setCreateLPContact(emptyLPContact);
        setQuery('');
        setDisabled(true);
        setOpenLPContactModal(false);
        setPage(0);
    };

    const isFormFilled = () => {
        if (
            (caseDetails.lpPersonId == null
                ? createLPContact.personId == ''
                : createLPContact.personId == caseDetails.lpPersonId.login ||
                  createLPContact.personId == '') ||
            caseDetails.lpPersonId?.login !== createLPContact.login
        )
            setShowAlertPrompt(true);
        else handleCancel();
    };

    const handlerSave = () => {
        if (caseDetails.lpPersonId == null && createLPContact.personId != '') {
            useAddLPContact({
                incidentId: caseDetails.caseId,
                personId: createLPContact.personId
            });
        }
        if (
            caseDetails.lpPersonId != null &&
            createLPContact.personId != caseDetails.lpPersonId.personId &&
            createLPContact.personId != ''
        ) {
            useEditLPContact({
                incidentId: caseDetails.caseId,
                personId: createLPContact.personId
            });
        }
    };

    const handDelete = () => {
        if (caseDetails.lpPersonId) {
            setCreateLPContact(emptyLPContact);
            useDeleteLPContact({
                incidentId: caseDetails.caseId,
                personId: ''
            });
        }
    };

    return (
        <Fragment>
            <Modal open={openModal} onClose={setOpenModal}>
                <Text type='h100'> Level of concern </Text>
                <VictoryChart domainPadding={[20, 20]} scale={{ x: 'time' }}>
                    <VictoryAxis
                        style={{
                            tickLabels: {
                                fontSize: 12,
                                fontWeight: 'bold',
                                fontFamily: 'AmazonEmberDisplay-Bold'
                            }
                        }}
                    />
                    <VictoryAxis
                        dependentAxis
                        tickValues={[0, 1, 2, 3, 4]}
                        tickFormat={(x) => locToString(x)}
                        style={{
                            grid: { stroke: '#F0F1F2' },
                            axis: { stroke: 'none' },
                            tickLabels: {
                                fontSize: 12,
                                fontWeight: 'bold',
                                fontFamily: 'AmazonEmberDisplay-Bold'
                            }
                        }}
                    />
                    <VictoryLine
                        data={caseDetails.locHistory}
                        interpolation='catmullRom'
                        domain={{ y: [0.75, 4.25] }}
                        style={{
                            data: {
                                stroke: getColor(caseDetails.loc),
                                strokeWidth: 3,
                                strokeLinecap: 'round'
                            }
                        }}
                    />
                </VictoryChart>
            </Modal>
            <Fragment>
                {caseDetails.locHistory.length > 0 ? (
                    <Tile spacingInset='400' onClick={() => setOpenModal(true)}>
                        <Heading color='secondary' level={5}>
                            Level of concern
                        </Heading>
                        <VictoryLine
                            data={caseDetails.locHistory}
                            interpolation='catmullRom'
                            style={{
                                data: {
                                    stroke: getColor(caseDetails.loc),
                                    strokeWidth: 6,
                                    strokeLinecap: 'round'
                                }
                            }}
                            domain={{ y: [0.75, 4.25] }}
                            domainPadding={[20, 20]}
                            padding={0}
                            height={118}
                        />
                    </Tile>
                ) : (
                    <Row height='128px' />
                )}
                <Tile spacingInset='400'>
                    <Column>
                        <Row alignmentVertical='top' alignmentHorizontal='justify'>
                            <Column>
                                <Text type='h200'> LP contact </Text>
                            </Column>
                            {caseDetails.lpPersonId == null && isCaseOpen && (
                                <TitledButton
                                    title={labels.addLP}
                                    buttonProps={{
                                        onClick: () => {
                                            setOpenLPContactModal(true);
                                        },
                                        type: 'primary',
                                        size: 'medium'
                                    }}
                                    iconProps={{ tokens: plusTokens }}
                                />
                            )}
                            <Modal
                                width='560px'
                                title={
                                    caseDetails.lpPersonId == null
                                        ? 'Add LP contact'
                                        : page == 0
                                        ? 'Edit LP contact'
                                        : 'Delete LP contact'
                                }
                                open={openLPContactModal}
                                onClose={isFormFilled}
                            >
                                {page == 0 && (
                                    <InputGroup direction='column' width={SELECT_WIDTH}>
                                        <LoginNameSearch
                                            onClick={(person: PAPIShort) => {
                                                useGetEmployeeDetail({
                                                    personId: person.personId
                                                });
                                            }}
                                            onSubmit={() => setQuery('')}
                                            label='LP contact'
                                            query={query}
                                            setQuery={setQuery}
                                            width={SELECT_WIDTH}
                                            handleClear={handleClear}
                                            providedSearchForEmployees={useSearchForEmployees}
                                            providedSearchForEmployeesEndpoint={useSearchForEmployeesEndpoint}
                                        />
                                        {!disabled && !useGetEmployeeDetailEndpoint.isFetching && (
                                            <Input
                                                label='Alias'
                                                value={createLPContact.login}
                                                disabled={true}
                                            />
                                        )}
                                        {!disabled && !useGetEmployeeDetailEndpoint.isFetching && (
                                            <Input
                                                label='Title'
                                                value={createLPContact.jobTitle}
                                                disabled={true}
                                            />
                                        )}
                                        {!disabled && !useGetEmployeeDetailEndpoint.isFetching && (
                                            <Input
                                                label='Phone number'
                                                value={createLPContact.phone}
                                                disabled={true}
                                            />
                                        )}
                                        {!disabled && !useGetEmployeeDetailEndpoint.isFetching && (
                                            <Input
                                                label='Email'
                                                value={createLPContact.email}
                                                disabled={true}
                                            />
                                        )}
                                    </InputGroup>
                                )}
                                {useGetEmployeeDetailEndpoint.isFetching && (
                                    <Column
                                        width={SELECT_WIDTH}
                                        alignmentHorizontal='center'
                                        alignmentVertical='center'
                                        spacing='300'
                                        spacingInset='300'
                                    >
                                        <Loader size='medium' />
                                    </Column>
                                )}
                                {page == 1 && caseDetails.lpPersonId && (
                                    <Row spacing='xsmall'>
                                        <Text> Delete LP contact </Text>
                                        <Text type='h100'> {caseDetails.lpPersonId.fullName} </Text>
                                        <Text>from this case?</Text>
                                    </Row>
                                )}
                                <ModalFooter>
                                    {page == 0 && (
                                        <Row
                                            alignmentHorizontal={caseDetails.lpPersonId ? 'justify' : 'right'}
                                            widths='fit'
                                        >
                                            {caseDetails.lpPersonId != null && (
                                                <Row alignmentHorizontal='left'>
                                                    <Theme tokens={orangeLightTokens}>
                                                        <Link onClick={() => setPage(1)}>Delete</Link>
                                                    </Theme>
                                                </Row>
                                            )}
                                            <Row alignmentHorizontal='right'>
                                                <Button type='secondary' onClick={isFormFilled}>
                                                    Cancel
                                                </Button>
                                                <Button
                                                    type='primary'
                                                    onClick={handlerSave}
                                                    disabled={
                                                        (caseDetails.lpPersonId == null
                                                            ? createLPContact.personId == ''
                                                            : createLPContact.personId ==
                                                                  caseDetails.lpPersonId.login ||
                                                              createLPContact.personId == '') ||
                                                        useSearchForEmployeesEndpoint.isFetching ||
                                                        useAddLPContactEndpoint.isFetching ||
                                                        useEditLPContactEndpoint.isFetching
                                                    }
                                                >
                                                    {caseDetails.lpPersonId == null ? 'Submit' : 'Save'}
                                                </Button>
                                            </Row>
                                        </Row>
                                    )}
                                    {page == 1 && (
                                        <Row alignmentHorizontal='right'>
                                            <Button type='secondary' onClick={handleCancel}>
                                                Cancel
                                            </Button>
                                            <Button
                                                type='primary'
                                                onClick={handDelete}
                                                disabled={useDeleteLPContactEndpoint.isFetching}
                                            >
                                                Delete
                                            </Button>
                                        </Row>
                                    )}
                                </ModalFooter>
                            </Modal>
                        </Row>
                        {showAlertPrompt && (
                            <AlertModal
                                showPrompt={showAlertPrompt}
                                onCancel={handleCancel}
                                handleConfirm={handleShowModal}
                                onClose={handleCancel}
                            ></AlertModal>
                        )}
                        {caseDetails.lpPersonId == null ? (
                            <Text> No saved LP contact</Text>
                        ) : (
                            <Column>
                                <Row alignmentVertical='top' alignmentHorizontal='justify'>
                                    <Column spacing='small'>
                                        <Column spacing='none'>
                                            <Text type='h100'>{caseDetails.lpPersonId.fullName}</Text>
                                            <Text>{caseDetails.lpPersonId.login}</Text>
                                            <Text>{caseDetails.lpPersonId.jobTitle}</Text>
                                            <Text>{caseDetails.lpPersonId.phone}</Text>
                                            <Text>{caseDetails.lpPersonId.email}</Text>
                                        </Column>
                                    </Column>
                                    {isCaseOpen && (
                                        <TitledButton
                                            title={labels.editLP}
                                            buttonProps={{
                                                onClick: () => {
                                                    setOpenLPContactModal(true);
                                                    setDisabled(false);
                                                    if (caseDetails.lpPersonId) {
                                                        setQuery(caseDetails.lpPersonId.fullName);
                                                        setCreateLPContact(caseDetails.lpPersonId);
                                                    }
                                                },
                                                type: 'secondary',
                                                size: 'small'
                                            }}
                                            iconProps={{ tokens: editIconTokens }}
                                        />
                                    )}
                                </Row>
                                <AdminDirectoryLink alias={caseDetails.lpPersonId.login} />
                                <ChimeLink alias={caseDetails.lpPersonId.login} />
                            </Column>
                        )}
                    </Column>
                </Tile>
                <SIMWidget />
            </Fragment>
        </Fragment>
    );
};

export default CaseDetailsSidebar;
