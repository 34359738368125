import Button from '@amzn/meridian/button';
import { ButtonPropsWithRef } from '@amzn/meridian/button/button';
import Icon from '@amzn/meridian/icon';
import { IconProps } from '@amzn/meridian/icon/icon';
import React from 'react';

type TitledButtonProps = {
    title: string;
    buttonProps: Omit<ButtonPropsWithRef, 'children'>;
    iconProps: IconProps;
};

const TitledButton: React.FunctionComponent<TitledButtonProps> = ({
    title,
    buttonProps,
    iconProps
}: TitledButtonProps) => {
    return (
        <span title={title}>
            <Button label={title} {...buttonProps}>
                <Icon {...iconProps} />
            </Button>
        </span>
    );
};

export default TitledButton;
