import { DateTime } from 'luxon';
import {
    Case,
    CommunicationMethod,
    Consultation,
    ConsultationDetails,
    EMTCase,
    ExternalContact,
    ExternalPersonInvolved,
    FacmanContact,
    InternalContact,
    InternalPersonInvolved,
    LevelOfConcern,
    MARSCase,
    PersonInvolved,
    PersonInvolvedRole,
    Proximity,
    Region,
    StatusType
} from '../lib/CaseDefinitions';
import { EventItems, EventType } from '../lib/EmtCTIDefinitions';
import { SimpleSearch } from '../lib/FilterDefinitons';
import { PersonInvolvedType, Update } from '../lib/UpdateDefinitions';

export const mockFlaggedWorks = ['gun', 'death', 'dangerous', 'angry', 'knife', 'armed'];

export const mockOpenCases: Case[] = [
    {
        emtSev: 1,
        caseId: 4313,
        caseName: 'MARS4313',
        emtCategory: 'MARS Investigation',
        emtType: EventType.ConcerningBehavior,
        emtItem: EventItems.ByAmazonAffiliate,
        location: {
            siteName: 'AZA2',
            country: 'USA',
            region: Region.AMER
        },
        status: StatusType.OPEN,
        flaggedWords: ['gun', 'death'],
        newFlaggedWords: [],
        totalFlaggedWords: ['gun', 'death'],
        lastModificationTime: DateTime.now()
            .minus({ minutes: 15 })
            .toISO(),
        latestTimeUpdate: DateTime.now()
            .minus({ minutes: 15 })
            .toISO(),
        incidentDate: '2022-07-15',
        incidentTime: '13:47:30',
        inputTimeZone: 'UTC',
        isBookmarked: true,
        assignee: 'griffee',
        isRestricted: true,
        userRestrictedCases: false,
        restrictedCases: false,
        proximity: Proximity.ONSITE,
        primaryLocation: [],
        secondaryLocation: [],
        updateCount: 0
    },
    {
        emtSev: 3,
        caseId: 24601,
        caseName: 'MARS24601',
        emtCategory: 'MARS Investigation',
        emtType: EventType.ConcerningBehavior,
        emtItem: EventItems.ByAmazonAffiliate,
        loc: LevelOfConcern.CRITICAL,
        location: {
            siteName: 'HSE3',
            country: 'Germany',
            region: Region.EMEA
        },
        status: StatusType.OPEN,
        flaggedWords: ['gun', 'death', 'dangerous', 'angry'],
        totalFlaggedWords: ['gun', 'death'],
        newFlaggedWords: [],
        lastModificationTime: DateTime.now()
            .minus({ minutes: 45 })
            .toISO(),
        latestTimeUpdate: DateTime.now()
            .minus({ minutes: 15 })
            .toISO(),
        incidentDate: '2022-07-15',
        incidentTime: '13:47:30',
        inputTimeZone: 'UTC',
        isBookmarked: true,
        assignee: 'griffee',
        isRestricted: true,
        userRestrictedCases: false,
        restrictedCases: false,
        proximity: Proximity.OFFSITE,
        primaryLocation: [{ siteName: 'AZA2', country: 'United States', region: Region.AMER }],
        secondaryLocation: [
            { siteName: 'AZA2', country: 'United States', region: Region.AMER },
            { siteName: 'HSE3', country: 'Germany', region: Region.EMEA }
        ],
        updateCount: 0
    },
    {
        emtSev: 1,
        caseId: 3241,
        caseName: 'MARS3241',
        emtCategory: 'MARS Investigation',
        emtType: EventType.ConcerningBehavior,
        emtItem: EventItems.ByAmazonAffiliate,
        loc: LevelOfConcern.CRITICAL,
        location: {
            siteName: 'HSE3',
            country: 'Germany',
            region: Region.EMEA
        },
        status: StatusType.OPEN,
        flaggedWords: ['gun', 'death', 'knife', 'armed', 'dangerous', 'angry'],
        totalFlaggedWords: ['gun', 'death'],
        newFlaggedWords: [],
        updateBy: 'griffee',
        lastModificationTime: DateTime.now()
            .minus({ minutes: 20 })
            .toISO(),
        latestTimeUpdate: DateTime.now()
            .minus({ minutes: 15 })
            .toISO(),
        incidentDate: '2022-07-15',
        incidentTime: '13:47:30',
        inputTimeZone: 'UTC',
        isBookmarked: true,
        assignee: 'griffee',
        isRestricted: true,
        userRestrictedCases: false,
        restrictedCases: false,
        proximity: Proximity.ONSITE,
        primaryLocation: [],
        secondaryLocation: [],
        updateCount: 0
    },
    {
        emtSev: 4,
        caseId: 242,
        caseName: 'MARS242',
        emtCategory: 'MARS Investigation',
        emtType: EventType.ConcerningBehavior,
        emtItem: EventItems.ByAmazonAffiliate,
        loc: LevelOfConcern.LOW,
        location: {
            siteName: 'HSE3',
            country: 'Germany',
            region: Region.EMEA
        },
        status: StatusType.OPEN,
        flaggedWords: ['gun', 'death', 'knife', 'armed', 'dangerous', 'angry'],
        totalFlaggedWords: ['gun', 'death', 'knife', 'armed', 'dangerous', 'angry'],
        newFlaggedWords: [],
        lastModificationTime: DateTime.now()
            .minus({ hours: 20, minutes: 5 })
            .toISO(),
        latestTimeUpdate: DateTime.now()
            .minus({ minutes: 15 })
            .toISO(),
        incidentDate: '2022-07-15',
        incidentTime: '13:47:30',
        inputTimeZone: 'UTC',
        isBookmarked: true,
        assignee: 'griffee',
        isRestricted: true,
        userRestrictedCases: false,
        restrictedCases: false,
        proximity: Proximity.ONSITE,
        primaryLocation: [],
        secondaryLocation: [],
        updateCount: 0
    },
    {
        emtSev: 4,
        caseId: 12222,
        caseName: 'MARS12222',
        emtCategory: 'MARS Investigation',
        emtType: EventType.ConcerningBehavior,
        emtItem: EventItems.ByAmazonAffiliate,
        loc: LevelOfConcern.LOW,
        location: {
            siteName: 'HSE3',
            country: 'Germany',
            region: Region.EMEA
        },
        status: StatusType.OPEN,
        flaggedWords: [],
        totalFlaggedWords: ['gun', 'death', 'knife', 'armed', 'dangerous', 'angry'],
        newFlaggedWords: [],
        updateBy: 'EMT',
        lastModificationTime: DateTime.now()
            .minus({ hours: 1, minutes: 5 })
            .toISO(),
        latestTimeUpdate: DateTime.now()
            .minus({ minutes: 15 })
            .toISO(),
        incidentDate: '2022-07-15',
        incidentTime: '13:47:30',
        inputTimeZone: 'UTC',
        isBookmarked: true,
        assignee: 'griffee',
        isRestricted: true,
        userRestrictedCases: false,
        restrictedCases: false,
        proximity: Proximity.ONSITE,
        primaryLocation: [],
        secondaryLocation: [],
        updateCount: 0
    }
];

export const mockedLinkConsultations: Consultation[] = [
    {
        name: 'Test Name',
        method: CommunicationMethod.EMAIL,
        added: {
            firstName: 'Ethan',
            lastName: 'Griffee',
            timeAdded: DateTime.now()
                .minus({ hours: 5 })
                .toISO()
        },
        communicatedWith: ['James Jones'],
        linkedCases: [
            {
                incidentId: 14343,
                incidentName: 'MARS1434'
            }
        ],
        consultationId: 2463434,
        location: {
            siteName: 'HSE3',
            country: 'Germany',
            region: Region.EMEA
        },
        proximity: Proximity.ONSITE,
        primaryLocation: [],
        secondaryLocation: []
    },
    {
        name: 'Test Name24601',
        method: CommunicationMethod.PHONE,
        added: {
            firstName: 'Ethan',
            lastName: 'Griffee',
            timeAdded: DateTime.now()
                .minus({ hours: 5 })
                .toISO()
        },
        communicatedWith: ['James Jones'],
        linkedCases: [
            {
                incidentId: 14343,
                incidentName: 'MARS1434'
            }
        ],
        location: {
            siteName: 'HSE3',
            country: 'Germany',
            region: Region.EMEA
        },
        proximity: Proximity.OFFSITE,
        primaryLocation: [
            {
                siteName: 'AZA2',
                country: 'United States',
                region: Region.AMER
            }
        ],
        secondaryLocation: [
            {
                siteName: 'ORK1',
                country: 'Ireland',
                region: Region.EMEA
            },
            {
                siteName: 'AZA2',
                country: 'United States',
                region: Region.AMER
            },
            {
                siteName: 'HSE3',
                country: 'Germany',
                region: Region.EMEA
            }
        ],
        consultationId: 2463433092893289
    }
];

export const mockedLinkCase: Case[] = [
    {
        emtSev: 1,
        caseId: 3241,
        caseName: 'MARS3241',
        emtCategory: 'MARS Investigation',
        emtType: EventType.ConcerningBehavior,
        emtItem: EventItems.ByAmazonAffiliate,
        loc: LevelOfConcern.CRITICAL,
        location: {
            siteName: 'HSE3',
            country: 'Germany',
            region: Region.EMEA
        },
        status: StatusType.OPEN,
        flaggedWords: ['gun', 'death', 'knife', 'armed', 'dangerous', 'angry'],
        totalFlaggedWords: ['gun', 'death', 'knife', 'armed', 'dangerous', 'angry'],
        newFlaggedWords: [],
        updateBy: 'griffee',
        lastModificationTime: DateTime.now()
            .minus({ minutes: 5 })
            .toISO(),
        latestTimeUpdate: DateTime.now()
            .minus({ minutes: 15 })
            .toISO(),
        incidentDate: '2022-07-15',
        incidentTime: '13:47:30',
        inputTimeZone: 'UTC',
        isBookmarked: true,
        assignee: 'griffee',
        isRestricted: true,
        restrictedCases: false,
        userRestrictedCases: false,
        proximity: Proximity.ONSITE,
        primaryLocation: [],
        secondaryLocation: [],
        updateCount: 0
    },
    {
        emtSev: 4,
        caseId: 242,
        caseName: 'MARS242',
        emtCategory: 'MARS Investigation',
        emtType: EventType.ConcerningBehavior,
        emtItem: EventItems.ByAmazonAffiliate,
        loc: LevelOfConcern.LOW,
        location: {
            siteName: 'HSE3',
            country: 'Germany',
            region: Region.EMEA
        },
        status: StatusType.OPEN,
        flaggedWords: ['gun', 'death', 'knife', 'armed', 'dangerous', 'angry'],
        totalFlaggedWords: ['gun', 'death', 'knife', 'armed', 'dangerous', 'angry'],
        newFlaggedWords: [],
        lastModificationTime: DateTime.now()
            .minus({ minutes: 5 })
            .toISO(),
        latestTimeUpdate: DateTime.now()
            .minus({ minutes: 15 })
            .toISO(),
        incidentDate: '2022-07-15',
        incidentTime: '13:47:30',
        inputTimeZone: 'UTC',
        isBookmarked: true,
        assignee: 'griffee',
        isRestricted: true,
        userRestrictedCases: false,
        restrictedCases: false,
        proximity: Proximity.ONSITE,
        primaryLocation: [],
        secondaryLocation: [],
        updateCount: 0
    }
];

export const mockConsultations: Consultation[] = [
    {
        name: 'Sexual Harassment Next Steps',
        method: CommunicationMethod.EMAIL,
        added: {
            firstName: 'Ethan',
            lastName: 'Griffee',
            timeAdded: DateTime.now()
                .minus({ hours: 2 })
                .toISO()
        },
        communicatedWith: ['James Jones'],
        linkedCases: [],
        location: {
            siteName: 'HSE3',
            country: 'Germany',
            region: Region.EMEA
        },
        proximity: Proximity.ONSITE,
        primaryLocation: [],
        secondaryLocation: [],
        consultationId: 24601
    },
    {
        name: 'Test Name',
        method: CommunicationMethod.EMAIL,
        added: {
            firstName: 'Ethan',
            lastName: 'Griffee',
            timeAdded: DateTime.now()
                .minus({ hours: 5 })
                .toISO()
        },
        communicatedWith: ['James Jones'],
        location: {
            siteName: 'HSE3',
            country: 'Germany',
            region: Region.EMEA
        },
        proximity: Proximity.ONSITE,
        primaryLocation: [],
        secondaryLocation: [],
        linkedCases: [
            {
                incidentId: 14343,
                incidentName: 'MARS1434'
            }
        ],
        consultationId: 2463434
    },
    {
        name: 'Test Name24601',
        method: CommunicationMethod.PHONE,
        added: {
            firstName: 'Ethan',
            lastName: 'Griffee',
            timeAdded: DateTime.now()
                .minus({ hours: 5 })
                .toISO()
        },
        communicatedWith: ['James Jones'],
        linkedCases: [
            {
                incidentId: 14343,
                incidentName: 'MARS1434'
            }
        ],
        location: {
            siteName: 'HSE3',
            country: 'Germany',
            region: Region.EMEA
        },
        proximity: Proximity.ONSITE,
        primaryLocation: [],
        secondaryLocation: [],
        consultationId: 2463433092893289
    },
    {
        name: 'Test Name3',
        method: CommunicationMethod.EMAIL,
        added: {
            firstName: 'Ethan',
            lastName: 'Griffee',
            timeAdded: DateTime.now()
                .minus({ hours: 5 })
                .toISO()
        },
        communicatedWith: ['James Jones'],
        linkedCases: [
            {
                incidentId: 14343,
                incidentName: 'MARS1434'
            }
        ],
        location: {
            siteName: 'HSE3',
            country: 'Germany',
            region: Region.EMEA
        },
        proximity: Proximity.ONSITE,
        primaryLocation: [],
        secondaryLocation: [],
        consultationId: 12
    },
    {
        name: 'Test Name4',
        method: CommunicationMethod.EMAIL,
        added: {
            firstName: 'Ethan',
            lastName: 'Griffee',
            timeAdded: DateTime.now()
                .minus({ hours: 5 })
                .toISO()
        },
        communicatedWith: ['James Jones'],
        linkedCases: [
            {
                incidentId: 14343,
                incidentName: 'MARS1434'
            }
        ],
        location: {
            siteName: 'HSE3',
            country: 'Germany',
            region: Region.EMEA
        },
        proximity: Proximity.ONSITE,
        primaryLocation: [],
        secondaryLocation: [],
        consultationId: 2
    },
    {
        name: 'Test Name 5',
        method: CommunicationMethod.EMAIL,
        added: {
            firstName: 'Ethan',
            lastName: 'Griffee',
            timeAdded: DateTime.now()
                .minus({ hours: 5 })
                .toISO()
        },
        communicatedWith: ['James Jones'],
        linkedCases: [
            {
                incidentId: 14343,
                incidentName: 'MARS1434'
            }
        ],
        location: {
            siteName: 'HSE3',
            country: 'Germany',
            region: Region.EMEA
        },
        proximity: Proximity.ONSITE,
        primaryLocation: [],
        secondaryLocation: [],
        consultationId: 1
    },
    {
        name: 'Test Name 6',
        method: CommunicationMethod.EMAIL,
        added: {
            firstName: 'Ethan',
            lastName: 'Griffee',
            timeAdded: DateTime.now()
                .minus({ hours: 5 })
                .toISO()
        },
        communicatedWith: ['James Jones'],
        linkedCases: [
            {
                incidentId: 14343,
                incidentName: 'MARS1434'
            }
        ],
        location: {
            siteName: 'HSE3',
            country: 'Germany',
            region: Region.EMEA
        },
        proximity: Proximity.ONSITE,
        primaryLocation: [],
        secondaryLocation: [],
        consultationId: 21
    },
    {
        name: 'Test Name 7',
        method: CommunicationMethod.EMAIL,
        added: {
            firstName: 'Ethan',
            lastName: 'Griffee',
            timeAdded: DateTime.now()
                .minus({ hours: 5 })
                .toISO()
        },
        communicatedWith: ['James Jones'],
        linkedCases: [
            {
                incidentId: 14343,
                incidentName: 'MARS1434'
            }
        ],
        location: {
            siteName: 'HSE3',
            country: 'Germany',
            region: Region.EMEA
        },
        proximity: Proximity.ONSITE,
        primaryLocation: [],
        secondaryLocation: [],
        consultationId: 221
    },
    {
        name: 'Test Name 8',
        method: CommunicationMethod.EMAIL,
        added: {
            firstName: 'Ethan',
            lastName: 'Griffee',
            timeAdded: DateTime.now()
                .minus({ hours: 5 })
                .toISO()
        },
        communicatedWith: ['James Jones'],
        linkedCases: [
            {
                incidentId: 14343,
                incidentName: 'MARS1434'
            }
        ],
        location: {
            siteName: 'HSE3',
            country: 'Germany',
            region: Region.EMEA
        },
        proximity: Proximity.ONSITE,
        primaryLocation: [],
        secondaryLocation: [],
        consultationId: 231
    },
    {
        name: 'Test Name 9',
        method: CommunicationMethod.EMAIL,
        added: {
            firstName: 'Ethan',
            lastName: 'Griffee',
            timeAdded: DateTime.now()
                .minus({ hours: 5 })
                .toISO()
        },
        communicatedWith: ['James Jones'],
        linkedCases: [
            {
                incidentId: 14343,
                incidentName: 'MARS1434'
            }
        ],
        location: {
            siteName: 'HSE3',
            country: 'Germany',
            region: Region.EMEA
        },
        proximity: Proximity.ONSITE,
        primaryLocation: [],
        secondaryLocation: [],
        consultationId: 241
    },
    {
        name: 'Test Name 25',
        method: CommunicationMethod.EMAIL,
        added: {
            firstName: 'Ethan',
            lastName: 'Griffee',
            timeAdded: DateTime.now()
                .minus({ hours: 5 })
                .toISO()
        },
        communicatedWith: ['James Jones'],
        linkedCases: [
            {
                incidentId: 14343,
                incidentName: 'MARS1434'
            }
        ],
        location: {
            siteName: 'HSE3',
            country: 'Germany',
            region: Region.EMEA
        },
        proximity: Proximity.ONSITE,
        primaryLocation: [],
        secondaryLocation: [],
        consultationId: 251
    },
    {
        name: 'Test Name 215',
        method: CommunicationMethod.EMAIL,
        added: {
            firstName: 'Ethan',
            lastName: 'Griffee',
            timeAdded: DateTime.now()
                .minus({ hours: 5 })
                .toISO()
        },
        communicatedWith: ['James Jones'],
        linkedCases: [
            {
                incidentId: 14343,
                incidentName: 'MARS1434'
            }
        ],
        location: {
            siteName: 'HSE3',
            country: 'Germany',
            region: Region.EMEA
        },
        proximity: Proximity.ONSITE,
        primaryLocation: [],
        secondaryLocation: [],
        consultationId: 261
    },
    {
        name: 'Test Name 225',
        method: CommunicationMethod.EMAIL,
        added: {
            firstName: 'Ethan',
            lastName: 'Griffee',
            timeAdded: DateTime.now()
                .minus({ hours: 5 })
                .toISO()
        },
        communicatedWith: ['James Jones'],
        linkedCases: [
            {
                incidentId: 14343,
                incidentName: 'MARS1434'
            }
        ],
        location: {
            siteName: 'HSE3',
            country: 'Germany',
            region: Region.EMEA
        },
        proximity: Proximity.ONSITE,
        primaryLocation: [],
        secondaryLocation: [],
        consultationId: 271
    },
    {
        name: 'Test Name 1115',
        method: CommunicationMethod.EMAIL,
        added: {
            firstName: 'Ethan',
            lastName: 'Griffee',
            timeAdded: DateTime.now()
                .minus({ hours: 5 })
                .toISO()
        },
        communicatedWith: ['James Jones'],
        linkedCases: [
            {
                incidentId: 14343,
                incidentName: 'MARS1434'
            }
        ],
        location: {
            siteName: 'HSE3',
            country: 'Germany',
            region: Region.EMEA
        },
        proximity: Proximity.ONSITE,
        primaryLocation: [],
        secondaryLocation: [],
        consultationId: 111111
    },
    {
        name: 'Test Name 21215',
        method: CommunicationMethod.EMAIL,
        added: {
            firstName: 'Ethan',
            lastName: 'Griffee',
            timeAdded: DateTime.now()
                .minus({ hours: 5 })
                .toISO()
        },
        communicatedWith: ['James Jones'],
        linkedCases: [
            {
                incidentId: 14343,
                incidentName: 'MARS1434'
            }
        ],
        location: {
            siteName: 'HSE3',
            country: 'Germany',
            region: Region.EMEA
        },
        proximity: Proximity.ONSITE,
        primaryLocation: [],
        secondaryLocation: [],
        consultationId: 1111
    },
    {
        name: 'Test Name 95',
        method: CommunicationMethod.EMAIL,
        added: {
            firstName: 'Ethan',
            lastName: 'Griffee',
            timeAdded: DateTime.now()
                .minus({ hours: 5 })
                .toISO()
        },
        communicatedWith: ['James Jones'],
        linkedCases: [
            {
                incidentId: 14343,
                incidentName: 'MARS1434'
            }
        ],
        location: {
            siteName: 'HSE3',
            country: 'Germany',
            region: Region.EMEA
        },
        proximity: Proximity.ONSITE,
        primaryLocation: [],
        secondaryLocation: [],
        consultationId: 91
    },
    {
        name: 'Test Name 85',
        method: CommunicationMethod.EMAIL,
        added: {
            firstName: 'Ethan',
            lastName: 'Griffee',
            timeAdded: DateTime.now()
                .minus({ hours: 5 })
                .toISO()
        },
        communicatedWith: ['James Jones'],
        linkedCases: [
            {
                incidentId: 14343,
                incidentName: 'MARS1434'
            }
        ],
        location: {
            siteName: 'HSE3',
            country: 'Germany',
            region: Region.EMEA
        },
        proximity: Proximity.ONSITE,
        primaryLocation: [],
        secondaryLocation: [],
        consultationId: 81
    },
    {
        name: 'Test Name 825',
        method: CommunicationMethod.EMAIL,
        added: {
            firstName: 'Ethan',
            lastName: 'Griffee',
            timeAdded: DateTime.now()
                .minus({ hours: 5 })
                .toISO()
        },
        communicatedWith: ['James Jones'],
        linkedCases: [
            {
                incidentId: 14343,
                incidentName: 'MARS1434'
            }
        ],
        location: {
            siteName: 'HSE3',
            country: 'Germany',
            region: Region.EMEA
        },
        proximity: Proximity.ONSITE,
        primaryLocation: [],
        secondaryLocation: [],
        consultationId: 821
    }
];
export const mockFacilityDetails = [
    {
        siteCode: 'AZA2',
        siteId: 'fab7a1f9-c804-4d04-8afa-07d71eb8a14b'
    },
    {
        siteCode: 'ABE2',
        siteId: '6860f7be-6ecf-43f1-a1f7-baa7b212621a'
    },
    {
        siteCode: 'LAX9',
        siteId: '5fe4f313-f706-4477-b0d0-f3958aaa2856'
    }
];
export const mockConsultationDetails: ConsultationDetails = {
    consultationId: 0,
    eventTime: '2021-W33-4T04:45:32.343',
    eventDate: '',
    proximity: Proximity.ONSITE,
    primaryFacility: [],
    secondaryFacilityList: [],
    communicatedWith: [
        {
            id: '10',
            login: 'griffee',
            name: 'Ethan Griffee',
            title: 'CFO of Cacti and Scorpions',
            phoneNumber: '4478049269',
            email: 'griffee@amazon.com',
            personId: '77a8dcd6-6111-43cd-ba8d-ed4b7311eaf8'
        }
    ],
    method: CommunicationMethod.PHONE,
    canEdit: true,
    title: 'Discussion About Outside Domestic Violence',
    linkedCases: [
        {
            incidentId: 12434,
            incidentName: 'MARS12343'
        }
    ],
    timeZone: 'UTC',
    summary:
        'Donec tincidunt non leo eget ultricies. Cras ultricies nec dolor a mattis. Donec interdum, odio ut eleifend ultrices, mauris nisl dictum velit, quis dictum mauris felis vehicula dolor. Vivamus lacinia lorem nibh, sit amet tincidunt quam vestibulum nec. Fusce felis nulla, finibus ac rutrum quis, molestie ac turpis. Donec laoreet volutpat nibh. Fusce tincidunt libero nisl, fermentum venenatis lacus vulputate nec. Sed porta efficitur risus et lacinia. Nam ipsum diam, fermentum id ante sed, pharetra condimentum ipsum. Integer ante enim, venenatis non hendrerit sit amet, auctor eu lacus. Duis semper orci ut finibus tempus. Quisque quis augue volutpat, accumsan ex et, consequat nisi. Quisque pulvinar placerat tortor, at faucibus urna. Phasellus a ex ac tellus consequat placerat non a est. Nullam non porttitor ipsum, sit amet malesuada velit.'
};
export const mockFacilities = [
    { code: 'AZA2', id: 'fab7a1f9-c804-4d04-8afa-07d71eb8a14b' },
    { code: 'ABE2', id: '6860f7be-6ecf-43f1-a1f7-baa7b212621a' },
    { code: 'LAX9', id: '5fe4f313-f706-4477-b0d0-f3958aaa2856' }
];
export const mockCountries = ['Mexico', 'Panama', 'United States'];
export const mockSubCategories = ['Written Threats', 'Spoken Threats', 'Race/Bias Involved'];

export const mockInternalPersonInvolvedDisplaying: InternalPersonInvolved = {
    name: 'John Jacob',
    login: 'joysil',
    legalName: 'Dr. John M Jacob',
    contactId: 0,
    role: PersonInvolvedRole.PERSON_OF_INTEREST,
    title: 'The Jingleheimerschmidt',
    status: 'Inactive (Terminated)',
    tenure: '10 years, 110 days',
    workEmail: 'jjacob@gmail.com',
    workPhone: '+1 (555) 804 5828',
    email: 'jjacob@gmail.com',
    phoneNumber: '+1 (555) 804 5828',
    address: '555 Litchfield AZ',
    type: PersonInvolvedType.INTERNAL_PERSON,
    isDirty: false
};

export const mockContactInvolvedDisplaying: PersonInvolved = {
    name: 'Joyce Silvera',
    login: 'joysil',
    contactId: 145,
    role: PersonInvolvedRole.CONTACT,
    title: 'Jr.SDE-mock',
    email: 'mock@gmail.com',
    phoneNumber: '+1 (555) 804 5828',
    addedBy: {
        id: '110820682',
        name: 'Joyce Silvera',
        personId: 'c152daee-caea-4cb2-9ec5-f1b251c36058'
    }
};

export const mockInternalContactInvolvedDisplaying: InternalContact = {
    name: 'Joyce Silvera',
    login: 'joysil',
    contactId: 0,
    role: PersonInvolvedRole.CONTACT,
    title: 'The Jingleheimerschmidt',
    email: 'jjacob@gmail.com',
    phoneNumber: '+1 (555) 804 5828',
    type: PersonInvolvedType.INTERNAL_CONTACT
};

export const mockExternalPersonInvolvedDisplaying: ExternalPersonInvolved = {
    login: '',
    name: 'Ex John Jacob',
    role: PersonInvolvedRole.PERSON_OF_INTEREST,
    title: 'The Jingleheimerschmidt',
    email: 'jjacob@gmail.com',
    phoneNumber: '+1 (555) 804 5828',
    type: PersonInvolvedType.EXTERNAL_PERSON
};

export const mockExternalContactInvolvedDisplaying: ExternalContact = {
    name: 'John Jacob',
    role: PersonInvolvedRole.CONTACT,
    title: 'The Jingleheimerschmidt',
    email: 'jjacob@gmail.com',
    phoneNumber: '+1 (555) 804 5828',
    type: PersonInvolvedType.EXTERNAL_CONTACT
};

export const mockFacmanContactInvolvedDisplaying: FacmanContact = {
    name: 'John Jacob',
    teamName: 'Local Police',
    notes: 'this is a note',
    address: '555 Litchfield AZ',
    role: PersonInvolvedRole.CONTACT,
    email: 'jjacob@gmail.com',
    phoneNumber: '+1 (555) 804 5828',
    type: PersonInvolvedType.FACMAN_CONTACT
};

export const mockMarsCase: MARSCase = {
    caseId: 2343,
    caseName: 'IF YOU ARE SEEING THIS AN ERROR HAS OCCURED',
    emtCategory: 'MARS Investigation',
    emtType: EventType.ConcerningBehavior,
    emtItem: EventItems.ByAmazonAffiliate,
    flaggedWords: [],
    totalFlaggedWords: [],
    newFlaggedWords: [],
    status: StatusType.CLOSED,
    loc: LevelOfConcern.LOW,
    location: {
        siteName: 'AR',
        country: 'United States',
        region: Region.AMER
    },
    primaryLocation: [],
    secondaryLocation: [],
    lastModificationTime: DateTime.now()
        .minus({ minutes: 5 })
        .toISO(),
    latestTimeUpdate: DateTime.now()
        .minus({ minutes: 15 })
        .toISO(),
    incidentTime: '04:45:32.343',
    incidentDate: '2022-01-10',
    inputTimeZone: 'UTC',
    reportTime: '2020-W33-4T06:45:32.343',
    proximity: Proximity.OFFSITE,
    eventSummary: 'rain rain go away',
    eventTags: ['WIM', 'Hazmat'],
    canEdit: false,
    incidentLocation: {
        address: '1234 Chestnut Rost',
        city: 'Springfield',
        state: 'AZ',
        country: 'United States',
        zipcode: '12345',
        region: Region.AMER
    },
    primaryFacility: [
        {
            siteCode: 'AZA2',
            siteId: 'fab7a1f9-c804-4d04-8afa-07d71eb8a14b',
            address: '555 N Litchfield Road',
            city: 'Goodyear',
            state: 'AZ',
            country: 'United States',
            zipcode: '85338',
            region: Region.AMER
        }
    ],
    secondaryFacilityList: [],
    suggestedAssignees: [
        {
            firstName: 'Ethan',
            lastName: 'Griffee',
            empId: '439854357439534'
        },
        {
            firstName: 'Stuart',
            lastName: 'Hostler',
            empId: '4398543574434339534'
        }
    ],
    recommendations: [
        {
            id: '0',
            title: 'Continue to investigate',
            note: 'The matter is ongoing, investigation will continue with an interview with Ethan Griffee',
            default: 'The matter is ongoing, investigation will continue with an interview with ....'
        },
        {
            id: '7',
            title: 'Police',
            note: 'The police has been advised',
            default: 'The police has been advised'
        }
    ],
    personsInvolved: [
        mockInternalPersonInvolvedDisplaying,
        mockExternalPersonInvolvedDisplaying,
        mockInternalContactInvolvedDisplaying,
        mockExternalContactInvolvedDisplaying,
        mockFacmanContactInvolvedDisplaying
    ],
    reportedBy: mockInternalPersonInvolvedDisplaying,
    linkedCases: {
        cases: mockedLinkCase,
        restrictedCases: mockedLinkCase,
        userRestrictedCases: mockedLinkCase
    },
    linkedConsultations: mockedLinkConsultations,
    linkedTickets: [],
    locHistory: [
        {
            x: DateTime.now()
                .minus({ hours: 5 })
                .toMillis(),
            y: LevelOfConcern.LOW
        },
        {
            x: DateTime.now()
                .minus({ days: 105 })
                .toMillis(),
            y: LevelOfConcern.MODERATE
        },
        {
            x: DateTime.now()
                .minus({ days: 222 })
                .toMillis(),
            y: LevelOfConcern.CRITICAL
        },
        {
            x: DateTime.now()
                .minus({ days: 250 })
                .toMillis(),
            y: LevelOfConcern.MODERATE
        },
        {
            x: DateTime.now()
                .minus({ days: 281 })
                .toMillis(),
            y: LevelOfConcern.LOW
        },
        {
            x: DateTime.now()
                .minus({ days: 400 })
                .toMillis(),
            y: LevelOfConcern.MODERATE
        }
    ],
    isBookmarked: true,
    assignee: 'griffee',
    isRestricted: true,
    userRestrictedCases: false,
    restrictedCases: false,
    updates: [],
    updateCount: 0
};

export const mockEMTCase: EMTCase = {
    ...mockMarsCase,
    emtSev: 3,
    actionItems: {
        title: 'Police Follow Up',
        description:
            'Contact the local police department before the end of the week to share internal information and document responses.',
        status: 'Assigned',
        primaryAssignee: {
            name: 'Stuart Hostler',
            login: 'shostler',
            title: 'Global Security Operations',
            phoneNumber: '+1 (555) 555-5555',
            email: 'shostler@amazon.com',
            personId: 'a3ba6a15-bbc9-4fac-9d60-ddc5b296ac50'
        },
        secondaryAssignee: [
            {
                name: 'Meow Meow',
                login: 'kittycat',
                title: 'Global Security Operations',
                phoneNumber: '+20 (555) 555-5555',
                email: 'kittycat@amazon.com',
                personId: 'b4cq5f24-iiu7-7ytr-9d60-ddc5b296ac50'
            },
            {
                name: 'Ethan Griffee',
                login: 'griffee',
                title: 'Global Security Operations',
                phoneNumber: '+20 (555) 555-5555',
                email: 'griffee@amazon.com',
                personId: '77a8dcd6-6111-43cd-ba8d-ed4b7311eaf8'
            }
        ]
    },
    privileged: false,
    eventFocusArea: {
        isBrandFocused: false,
        isLifeFocused: true,
        isPropertyFocused: true
    },
    riskAssessment:
        'Nulla in orci felis. Vestibulum sit amet purus laoreet, congue tellus dignissim, blandit nisl. Phasellus vitae bibendum turpis. Nunc eu lacinia nisi. Proin congue arcu sed tristique mollis. Ut sed leo id nibh scelerisque rhoncus. Duis ac dolor consequat, ornare lectus quis, eleifend leo. Aenean dignissim maximus hendrerit. Phasellus lorem risus, condimentum mattis sagittis nec, pellentesque eget ante.',
    nextSteps:
        'Morbi eu varius eros, vel ultricies nibh. Phasellus semper ligula eu sem ullamcorper tincidunt. Nam sit amet tristique ligula. Sed interdum lacus diam.',
    incidentLocation: undefined,
    primaryFacility: [
        {
            siteCode: 'AZA2',
            siteId: 'fab7a1f9-c804-4d04-8afa-07d71eb8a14b',
            address: '555 N Litchfield Road',
            city: 'Goodyear',
            state: 'AZ',
            country: 'United States',
            zipcode: '85338',
            region: Region.AMER
        }
    ],
    secondaryFacilityList: [
        {
            siteCode: 'AZA5',
            siteId: 'fab7a1f9-c804-4d04-8afa-07d71eb8a14b',
            address: '555 N Litchfield Road',
            city: 'Goodyear',
            state: 'AZ',
            country: 'United States',
            zipcode: '85338',
            region: Region.AMER
        },
        {
            siteCode: 'AZA3',
            siteId: 'fab7a1f9-c804-4d04-8afa-3232',
            address: '555 N Litchfield Road',
            city: 'Little Rock',
            state: 'AR',
            country: 'United States',
            zipcode: '85338',
            region: Region.AMER
        }
    ],
    suggestedAssignees: [
        {
            firstName: 'Ethan',
            lastName: 'Griffee',
            empId: '439854357439534'
        },
        {
            firstName: 'Stuart',
            lastName: 'Hostler',
            empId: '4398543574434339534'
        }
    ],
    recommendations: [
        {
            id: '0',
            title: 'Continue to investigate',
            note: 'The matter is ongoing, investigation will continue with an interview with Ethan Griffee',
            default: 'The matter is ongoing, investigation will continue with an interview with ....'
        },
        {
            id: '7',
            title: 'Police',
            note: 'The police has been advised',
            default: 'The police has been advised'
        }
    ],
    personsInvolved: [
        mockInternalPersonInvolvedDisplaying,
        mockExternalPersonInvolvedDisplaying,
        mockInternalContactInvolvedDisplaying,
        mockExternalContactInvolvedDisplaying,
        mockFacmanContactInvolvedDisplaying
    ],
    reportedBy: mockInternalPersonInvolvedDisplaying,
    linkedCases: {
        cases: mockedLinkCase,
        restrictedCases: mockedLinkCase,
        userRestrictedCases: mockedLinkCase
    },
    linkedConsultations: mockedLinkConsultations,
    linkedTickets: [],
    locHistory: [
        {
            x: DateTime.now()
                .minus({ hours: 5 })
                .toMillis(),
            y: LevelOfConcern.LOW
        },
        {
            x: DateTime.now()
                .minus({ days: 105 })
                .toMillis(),
            y: LevelOfConcern.MODERATE
        },
        {
            x: DateTime.now()
                .minus({ days: 222 })
                .toMillis(),
            y: LevelOfConcern.CRITICAL
        },
        {
            x: DateTime.now()
                .minus({ days: 250 })
                .toMillis(),
            y: LevelOfConcern.MODERATE
        },
        {
            x: DateTime.now()
                .minus({ days: 281 })
                .toMillis(),
            y: LevelOfConcern.LOW
        },
        {
            x: DateTime.now()
                .minus({ days: 400 })
                .toMillis(),
            y: LevelOfConcern.MODERATE
        }
    ]
};

export const mockUpdates: Update[] = [
    {
        updatedId: 2,
        updater: 'Ethan Griffee',
        timestamp: DateTime.now()
            .minus({ hours: 5 })
            .toISO(),
        hasBeenSeen: true,
        needsAcknowledgement: false,
        type: {
            name: 'LinkedCaseUpdate',
            action: 'unlink',
            case: {
                caseName: 'MARS4313',
                emtSev: 1,
                caseId: 4313,
                emtCategory: 'MARS Investigation',
                emtType: EventType.ConcerningBehavior,
                emtItem: EventItems.ByAmazonAffiliate,
                location: {
                    siteName: 'AZA2',
                    country: 'USA',
                    region: Region.AMER
                },
                status: StatusType.OPEN,
                flaggedWords: ['gun', 'death'],
                totalFlaggedWords: ['gun', 'death', 'knife', 'armed', 'dangerous', 'angry'],
                newFlaggedWords: [],
                lastModificationTime: DateTime.now()
                    .minus({ minutes: 15 })
                    .toISO(),
                latestTimeUpdate: DateTime.now()
                    .minus({ minutes: 15 })
                    .toISO(),
                incidentDate: '2022-07-15',
                incidentTime: '13:47:30',
                inputTimeZone: 'UTC',
                isBookmarked: true,
                assignee: 'griffee',
                isRestricted: true,
                userRestrictedCases: false,
                restrictedCases: false,
                proximity: Proximity.ONSITE,
                primaryLocation: [],
                secondaryLocation: [],
                updateCount: 0
            }
        }
    },
    {
        updatedId: 3,
        updater: 'Stuart Hostler',
        timestamp: DateTime.now()
            .minus({ hours: 6 })
            .toISO(),
        hasBeenSeen: true,
        needsAcknowledgement: false,
        type: {
            name: 'LinkedConsultationUpdate',
            action: 'link',
            consultation: {
                name: 'Test Name',
                method: CommunicationMethod.EMAIL,
                added: {
                    firstName: 'Ethan',
                    lastName: 'Griffee',
                    timeAdded: DateTime.now()
                        .minus({ hours: 5 })
                        .toISO()
                },
                communicatedWith: ['James Jones'],
                linkedCases: [
                    {
                        incidentId: 14343,
                        incidentName: 'MARS1434'
                    }
                ],
                location: {
                    siteName: 'HSE3',
                    country: 'Germany',
                    region: Region.EMEA
                },
                proximity: Proximity.ONSITE,
                primaryLocation: [],
                secondaryLocation: [],
                consultationId: 2463434
            }
        }
    },
    {
        updatedId: 4,
        updater: 'Stuart Hostler',
        timestamp: DateTime.now()
            .minus({ days: 1, hours: 4, minutes: 12 })
            .toISO(),
        hasBeenSeen: true,
        needsAcknowledgement: false,
        type: {
            name: 'LocUpdate',
            loc: 'LOW',
            note:
                'Meow to be let in chase mice jump on fridge so meowsiers, cats are fats i like to pets them they like to meow back and flee in terror at cucumber discovered on floor but pose purrfectly to show my beauty. Plan your travel purr like a car engine oh yes, there is my human servant woman she does best pats ever that all i like about her hiss meow .'
        }
    },
    {
        updatedId: 5,
        updater: 'Stuart Hostler',
        timestamp: DateTime.now()
            .minus({ days: 1, hours: 5 })
            .toISO(),
        hasBeenSeen: true,
        needsAcknowledgement: false,
        type: {
            name: 'ContactUpdate',
            type: PersonInvolvedType.INTERNAL_PERSON,
            oldValue: {
                contactId: 1,
                name: 'Jacob Jones',
                role: PersonInvolvedRole.WITNESS,
                title: 'The Best',
                login: 'jjones',
                phoneNumber: '+1 (552) 804 5828',
                email: 'jjones@amazon.com'
            },
            action: 'added'
        }
    },
    {
        updatedId: 6,
        updater: 'Yifeng Xiong',
        timestamp: DateTime.now()
            .minus({ days: 1, hours: 13, minutes: 22 })
            .toISO(),
        hasBeenSeen: true,
        needsAcknowledgement: false,
        type: {
            name: 'RecommendationsUpdate',
            recommendations: [
                {
                    name: 'Monitoring of parties',
                    description:
                        'The POI is to be monitored by… The victim is to be monitored by… The witnesses are to be monitored by…',
                    action: 'added'
                },
                {
                    name: 'Other parties engaged',
                    description:
                        'Legal has been informed… Customer service engaged… BAC advised… Protective Services advised… AWS-SOC advised…',
                    action: 'removed'
                }
            ]
        }
    },
    {
        updatedId: 7,
        updater: 'Amish Papneja',
        timestamp: DateTime.now()
            .minus({ days: 2, hours: 1, minutes: 1 })
            .toISO(),
        hasBeenSeen: true,
        needsAcknowledgement: false,
        type: {
            name: 'GenericUpdate',
            title: 'Eat a rug and furry furry hairs everywhere',
            note:
                "Human is in bath tub, emergency! drowning! meooowww! allways wanting food and attack feet, for brown cats with pink ears or lick master's hand at first then bite because im moody, all of a sudden cat goes crazy get suspicious of own shadow then go play with toilette paper. Stare at owner accusingly then wink hell is other people yet who's the baby, for annoy owner until he gives you food say meow repeatedly until belly rubs, feels good you are a captive audience while sitting on the toilet, pet me love and coo around boyfriend who purrs and makes the perfect moonlight eyes so i can purr and swat the glittery gleaming yarn to him (the yarn is from a $125 sweater), but commence midnight zoomies."
        }
    },
    {
        updatedId: 8,
        updater: 'EMT',
        timestamp: DateTime.now()
            .minus({ days: 3, hours: 11, minutes: 54 })
            .toISO(),
        hasBeenSeen: true,
        needsAcknowledgement: false,
        type: {
            name: 'IncidentCreation'
        }
    },
    {
        updatedId: 9,
        updater: 'Kendra Raczek',
        timestamp: DateTime.now()
            .minus({ days: 3, hours: 15, minutes: 22 })
            .toISO(),
        hasBeenSeen: true,
        needsAcknowledgement: false,
        type: {
            name: 'AssigneeChange',
            action: 'set',
            assignee: 'Ethan Griffee'
        }
    },
    {
        updatedId: 10,
        updater: 'Ethan Griffee',
        timestamp: DateTime.now()
            .minus({ days: 3, hours: 16, minutes: 22 })
            .toISO(),
        hasBeenSeen: true,
        needsAcknowledgement: false,
        type: {
            name: 'EMTUpdateNotification',
            emtId: '124343',
            updates: [
                {
                    title: 'Event Summary',
                    note:
                        'In a following encounter, POI displayed similar behavior to a different victim as a follow-up to the initial incident. Morbi ultrices id orci ut dignissim. Fusce tempus congue tempor. Mauris ac ligula tempus, varius odio eu, sollicitudin quam. Sed in justo ut nisi condimentum placerat eget eu nibh. Nullam eu mi sit amet eros mollis venenatis. Sed ac pretium ex. Vivamus in ligula in justo blandit facilisis feugiat quis eros.'
                },
                {
                    title: 'Next Steps',
                    note:
                        'Donec ornare vehicula eros eget scelerisque. Curabitur pretium congue eros, non faucibus Leo imperdiet venenatis. Pellentesque finibus luctus purus.'
                }
            ]
        }
    },
    {
        updatedId: 12,
        updater: 'Ethan Griffee',
        timestamp: DateTime.now()
            .minus({ days: 4, hours: 1, minutes: 1 })
            .toISO(),
        hasBeenSeen: true,
        needsAcknowledgement: false,
        type: {
            name: 'StatusUpdate',
            action: 'Open'
        }
    }
];

export const mockSimpleSearchResult: SimpleSearch = {
    displayedCases: [
        {
            caseId: 1234,
            caseName: 'MARS-1234'
        },
        {
            caseId: 1256,
            caseName: 'MARS-1256'
        }
    ],
    displayedConsultations: [
        {
            consultationId: 2463434,
            consultationName: 'Test Name - 1'
        },
        {
            consultationId: 121333,
            consultationName: 'Test Name - 2'
        }
    ]
};
