import Badge from '@amzn/meridian/badge';
import Card from '@amzn/meridian/card';
import Column from '@amzn/meridian/column';
import Icon from '@amzn/meridian/icon';
import Row from '@amzn/meridian/row';
import Tag from '@amzn/meridian/tag';
import Text from '@amzn/meridian/text';
import Tooltip from '@amzn/meridian/tooltip';
import clockIconTokens from '@amzn/meridian-tokens/base/icon/clock';
import closeTokens from '@amzn/meridian-tokens/base/icon/close';
import lockTokens from '@amzn/meridian-tokens/base/icon/lock';
import { DateTime } from 'luxon';
import React, { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppDispatch } from '../../context/store';
import { Case, StatusType } from '../../lib/CaseDefinitions';
import { WIDTH_OF_CARD, WIDTHS_OF_CASES_HEADER_COLUMNS } from '../../lib/constants';
import { getDateTimeFromISO, renderDateDiff } from '../../lib/helpers';
import { labels } from '../../lib/labels';
import { setCaseUnlinking, setDisplayingUnlinkModal } from '../../slices/CaseDetailsSlice';
import CaseSiteLocations from '../CustomComponents/CaseSiteLocations';
import TitledButton from '../CustomComponents/TitledButton';
import LocCircle from '../Inputs/LocCircle';

type CaseHeaderProps = {
    caseHeader?: Case;
    isUnlinkable?: boolean;
};

const CaseHeader: React.FunctionComponent<CaseHeaderProps> = ({
    caseHeader,
    isUnlinkable = false
}: CaseHeaderProps) => {
    const history = useHistory();
    const dispatch = useAppDispatch();
    const incidentDate =
        caseHeader && caseHeader.incidentTime
            ? getDateTimeFromISO(caseHeader.incidentDate, caseHeader.incidentTime, caseHeader.inputTimeZone)
            : undefined;

    const MAX_FLAG_WORDS_DISPLAYING = 4;

    const renderCaseDateTimeComponent = (incidentDate: DateTime | undefined, incident: Case): JSX.Element => {
        return (
            <Column spacing='none'>
                <Row spacing='small' alignmentVertical='top'>
                    <Icon tokens={clockIconTokens} />
                    {incident.incidentDate && (
                        <Column spacing='none'>
                            <Text>
                                {incidentDate
                                    ? incidentDate.toLocaleString(DateTime.DATE_MED)
                                    : DateTime.fromISO(incident.incidentDate).toLocaleString(
                                          DateTime.DATE_MED
                                      )}
                            </Text>
                            {incidentDate && <Text> {incidentDate.toFormat('HH:mm ZZZZ')}</Text>}
                        </Column>
                    )}
                </Row>
            </Column>
        );
    };

    let flaggedWordsElement;
    if (caseHeader) {
        if (caseHeader.newFlaggedWords.length <= MAX_FLAG_WORDS_DISPLAYING) {
            flaggedWordsElement = (
                <div>
                    {caseHeader.newFlaggedWords.map((value: string, index: number) => {
                        return (
                            <Fragment key={value}>
                                <span className='Orange'> {value}</span>
                                {caseHeader.flaggedWords.length - 1 > index && <span>, </span>}
                            </Fragment>
                        );
                    })}
                </div>
            );
        } else {
            flaggedWordsElement = (
                <div>
                    {caseHeader.newFlaggedWords
                        .slice(0, MAX_FLAG_WORDS_DISPLAYING)
                        .map((value: string, index: number) => {
                            return (
                                <Fragment key={value}>
                                    <span className='Orange'> {value}</span>
                                    {MAX_FLAG_WORDS_DISPLAYING - 1 > index && <span>, </span>}
                                </Fragment>
                            );
                        })}
                    <Tooltip position='right' title={caseHeader.newFlaggedWords.join(', ')}>
                        <Text tag='span' color='secondary'>
                            , and {caseHeader.newFlaggedWords.length - MAX_FLAG_WORDS_DISPLAYING} more{' '}
                        </Text>
                    </Tooltip>
                </div>
            );
        }
    }

    //TODO : Render circle differently as currently is not centered in firefox
    return (
        <Card
            maxWidth={WIDTH_OF_CARD}
            width={'100%'}
            spacingInset='400'
            onClick={() =>
                caseHeader && !caseHeader?.restrictedCases && history.push(`/cases/${caseHeader.caseId}`)
            }
            href={caseHeader && `/cases/${caseHeader.caseId}`}
            tag={'a'}
        >
            {caseHeader && !caseHeader?.restrictedCases ? (
                <Row widths={WIDTHS_OF_CASES_HEADER_COLUMNS} alignmentVertical='stretch' spacing='small'>
                    <Column spacing='none'>
                        <LocCircle loc={caseHeader.loc} emtSev={caseHeader.emtSev} />
                    </Column>
                    <Column spacing='200'>
                        <Text type='h200'>{caseHeader.caseName}</Text>
                        <Text type='h100'>{caseHeader.emtType}</Text>
                        <Text type='b300'>{caseHeader.emtItem}</Text>
                    </Column>
                    <Column spacing='none'>
                        <CaseSiteLocations caseHeader={caseHeader} isCaseDetails={false} />
                    </Column>
                    <Column spacing='200'>
                        <Text type='h200'> Status </Text>
                        <Text type='b300'>
                            {' '}
                            {caseHeader.status === StatusType.OPEN ? StatusType.OPEN : StatusType.CLOSED}{' '}
                        </Text>
                    </Column>
                    <Column spacing='200'>
                        {caseHeader.newFlaggedWords.length > 0 && (
                            <Fragment>
                                <Text type='h200'> Flagged words </Text>
                                {flaggedWordsElement}
                            </Fragment>
                        )}
                    </Column>
                    <Column spacing='200' heights={['100%']}>
                        {isUnlinkable ? (
                            <Row alignmentHorizontal='right' alignmentVertical='center'>
                                <TitledButton
                                    title={labels.unlinkCase}
                                    buttonProps={{
                                        onClick: () => {
                                            dispatch(setCaseUnlinking(caseHeader));
                                            dispatch(setDisplayingUnlinkModal('case'));
                                        },
                                        type: 'secondary',
                                        size: 'small'
                                    }}
                                    iconProps={{ tokens: closeTokens }}
                                />
                            </Row>
                        ) : !caseHeader.loc ? (
                            <Column>
                                <div>
                                    <Tag>New</Tag>
                                </div>
                                <div> {renderDateDiff(caseHeader.lastModificationTime)} </div>
                            </Column>
                        ) : caseHeader.updateBy ? (
                            <Column spacing='none'>
                                <Row spacing='none'>
                                    <div>
                                        <Tag>Updated</Tag>
                                    </div>
                                    {caseHeader.updateCount && caseHeader.updateCount > 1 && (
                                        <Badge value={caseHeader.updateCount} type='theme' />
                                    )}
                                </Row>
                                <div> {renderDateDiff(caseHeader.latestTimeUpdate)} </div>
                                by {caseHeader.updateBy}
                            </Column>
                        ) : (
                            renderCaseDateTimeComponent(incidentDate, caseHeader)
                        )}
                    </Column>
                </Row>
            ) : (
                <Row width='100%' widths={['20%', '70%']} spacing='small'>
                    <Column spacing='200'>
                        <Text type='h200'> {caseHeader?.caseName} </Text>
                    </Column>
                    <Row alignmentHorizontal={'center'} alignmentVertical={'center'} height={'76px'}>
                        <Icon tokens={lockTokens} />
                        <Text color={'secondary'}>{`You don't have access to view restricted case`}</Text>
                    </Row>
                </Row>
            )}
        </Card>
    );
};

export default CaseHeader;
