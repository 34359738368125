import Button from '@amzn/meridian/button';
import Column from '@amzn/meridian/column';
import Link from '@amzn/meridian/link';
import Modal, { ModalFooter } from '@amzn/meridian/modal';
import Popover, { PopoverHeader } from '@amzn/meridian/popover';
import Row from '@amzn/meridian/row';
import Select, { SelectOption } from '@amzn/meridian/select';
import Text from '@amzn/meridian/text';
import plusTokens from '@amzn/meridian-tokens/base/icon/plus';
import _ from 'lodash';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState, useAppDispatch } from '../../../context/store';
import { Case, Consultation } from '../../../lib/CaseDefinitions';
import { SMALL_MODAL_WIDTH, WIDTH_OF_CARD } from '../../../lib/constants';
import { labels } from '../../../lib/labels';
import { setCaseDetails, setDisplayingUnlinkModal } from '../../../slices/CaseDetailsSlice';
import { addToast } from '../../../slices/ToastSlice';
import {
    useLazyLinkCaseConsultationQuery,
    useLazyLinkCasesQuery,
    useSearchConsultationNameQuery
} from '../../../slices/WotManApiSlice';
import ConsultationHeader from '../../Consultations/ConsultationHeader';
import TitledButton from '../../CustomComponents/TitledButton';
import LinkCaseInput from '../../Inputs/LinkCaseInput';
import LoadingInput from '../../Inputs/LoadingInput';
import CaseHeader from '../CaseHeader';

const RelatedTab: React.FunctionComponent = () => {
    const { caseDetails, caseUnlinking, consultationUnlinking, displayingUnlinkModal } = useSelector(
        (state: RootState) => state.caseDetails
    );
    const [openCasePopover, setOpenCasePopover] = useState(false);
    const [openConsultationPopover, setOpenConsultationPopover] = useState(false);
    const [consultationToLink, setConsultationToLink] = useState(-1);
    const [consultationNameToLink, setConsultationNameToLink] = useState('');
    const [caseNameToLink, setCaseNameToLink] = useState('');
    const [query, setQuery] = useState('');
    const caseRef = useRef();
    const consultationRef = useRef();
    const [useLinkConsultation, useLinkConsultationEndpoint] = useLazyLinkCaseConsultationQuery();
    const [useUnLinkConsultation, useUnLinkConsultationEndpoint] = useLazyLinkCaseConsultationQuery();
    const { data } = useSearchConsultationNameQuery({ prefix: query });
    const [useLinkCases, linkCasesEndpoint] = useLazyLinkCasesQuery();
    const [useUnlinkCases, unlinkCasesEndpoint] = useLazyLinkCasesQuery();

    const dispatch = useAppDispatch();
    const history = useHistory();

    useEffect(() => {
        if (!linkCasesEndpoint.isFetching && linkCasesEndpoint.isSuccess) {
            dispatch(setCaseDetails(linkCasesEndpoint.data));
            dispatch(
                addToast({
                    type: 'success',
                    action: 'Linked case ',
                    message: `${caseNameToLink} to ${caseDetails.caseName}`,
                    SIM: false,
                    timeout: 3000
                })
            );
            setCaseNameToLink('');
            setOpenCasePopover(false);
        } else if (!linkCasesEndpoint.isFetching && linkCasesEndpoint.isError) {
            dispatch(
                addToast({
                    type: 'error',
                    action: 'There was an error linking case',
                    message: '',
                    SIM: true,
                    timeout: undefined
                })
            );
        }
    }, [linkCasesEndpoint.isFetching]);

    useEffect(() => {
        if (!unlinkCasesEndpoint.isFetching && unlinkCasesEndpoint.isSuccess) {
            dispatch(setCaseDetails(unlinkCasesEndpoint.data));
            dispatch(setDisplayingUnlinkModal(undefined));
        } else if (!unlinkCasesEndpoint.isFetching && unlinkCasesEndpoint.isError) {
            dispatch(
                addToast({
                    type: 'error',
                    action: 'There was an error unlinking a case',
                    message: '',
                    SIM: true,
                    timeout: undefined
                })
            );
        }
    }, [unlinkCasesEndpoint.isFetching]);

    useEffect(() => {
        if (!useUnLinkConsultationEndpoint.isFetching && useUnLinkConsultationEndpoint.isSuccess) {
            dispatch(setCaseDetails(useUnLinkConsultationEndpoint.data));
            dispatch(setDisplayingUnlinkModal(undefined));
        } else if (!useUnLinkConsultationEndpoint.isFetching && useUnLinkConsultationEndpoint.isError) {
            dispatch(
                addToast({
                    type: 'error',
                    action: 'There was an error unlinking an engagement',
                    message: '',
                    SIM: true,
                    timeout: undefined
                })
            );
        }
    }, [useUnLinkConsultationEndpoint.isFetching]);

    useEffect(() => {
        if (!useLinkConsultationEndpoint.isFetching && useLinkConsultationEndpoint.isSuccess) {
            dispatch(setCaseDetails(useLinkConsultationEndpoint.data));
            dispatch(
                addToast({
                    type: 'success',
                    action: 'Linked engagement ',
                    message: `${consultationNameToLink} to ${caseDetails.caseName}`,
                    SIM: false,
                    timeout: 3000
                })
            );
            setOpenConsultationPopover(false);
        } else if (!useLinkConsultationEndpoint.isFetching && useLinkConsultationEndpoint.isError) {
            dispatch(
                addToast({
                    type: 'error',
                    action: 'There was an error linking engagement',
                    message: '',
                    SIM: true,
                    timeout: undefined
                })
            );
        }
    }, [useLinkConsultationEndpoint.isFetching]);

    const handleSaveLinkConsultation = () => {
        if (consultationToLink != -1) {
            const consultation = _.find(
                data,
                (consultation) => consultation.consultationId === consultationToLink
            );
            setConsultationNameToLink(consultation.name);
            useLinkConsultation({
                consultationId: consultationToLink,
                caseId: caseDetails.caseId,
                action: 'link',
                returnCase: true
            });
            setQuery('');
            setConsultationToLink(-1);
        }
    };

    return (
        <Column width={WIDTH_OF_CARD}>
            <Modal
                width={SMALL_MODAL_WIDTH}
                open={displayingUnlinkModal === 'case'}
                onClose={() => dispatch(setDisplayingUnlinkModal(undefined))}
                title='Unlink case'
            >
                <Text>
                    {' '}
                    Unlink <b> {caseUnlinking?.caseName} </b> from this case?{' '}
                </Text>
                <Row height='40px' />
                <ModalFooter>
                    <Row alignmentHorizontal='right'>
                        <Button
                            type='secondary'
                            disabled={unlinkCasesEndpoint.isFetching}
                            onClick={() => dispatch(setDisplayingUnlinkModal(undefined))}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={() => {
                                useUnlinkCases({
                                    caseIdOne: caseUnlinking?.caseId,
                                    caseIdTwo: caseDetails.caseId,
                                    action: 'unlink'
                                });
                            }}
                            disabled={unlinkCasesEndpoint.isFetching}
                        >
                            Unlink
                        </Button>
                    </Row>
                </ModalFooter>
            </Modal>
            <Modal
                width={SMALL_MODAL_WIDTH}
                open={displayingUnlinkModal === 'consultation'}
                onClose={() => dispatch(setDisplayingUnlinkModal(undefined))}
                title='Unlink consultation'
            >
                <Text>
                    {' '}
                    Unlink <b> {consultationUnlinking?.name} </b> from this case?{' '}
                </Text>
                <Row height='40px' />
                <ModalFooter>
                    <Row alignmentHorizontal='right'>
                        <Button
                            type='secondary'
                            onClick={() => dispatch(setDisplayingUnlinkModal(undefined))}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={() => {
                                useUnLinkConsultation({
                                    consultationId:
                                        consultationUnlinking && consultationUnlinking.consultationId,
                                    caseId: caseDetails.caseId,
                                    action: 'unlink',
                                    returnCase: true
                                });
                            }}
                            disabled={useUnLinkConsultationEndpoint.isFetching}
                        >
                            Unlink
                        </Button>
                    </Row>
                </ModalFooter>
            </Modal>
            <Row alignmentHorizontal='justify'>
                <Text type='h100'> Linked cases </Text>
                <LinkCaseInput
                    setOpenCasePopover={setOpenCasePopover}
                    caseRef={caseRef}
                    openCasePopover={openCasePopover}
                    casesNotDisplaying={[
                        // eslint-disable-next-line no-unsafe-optional-chaining
                        ...caseDetails.linkedCases?.cases
                            .filter((caseHead) => caseHead)
                            // Filter removed the undefined cases
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            .map((caseHead: Case) => caseHead.caseId),
                        caseDetails.caseId
                    ]}
                    onSubmit={(incidentId) => {
                        useLinkCases({
                            caseIdOne: incidentId,
                            caseIdTwo: caseDetails.caseId,
                            action: 'link'
                        });
                    }}
                    isDisabled={linkCasesEndpoint.isFetching}
                    setCaseName={setCaseNameToLink}
                />
                <TitledButton
                    title={labels.linkCase}
                    buttonProps={{
                        ref: caseRef,
                        onClick: () => {
                            setOpenCasePopover(true);
                            setQuery('');
                        },
                        type: 'primary',
                        size: 'medium'
                    }}
                    iconProps={{ tokens: plusTokens }}
                />
            </Row>
            {/*eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
            {/*@ts-ignore*/}
            {caseDetails.linkedCases.cases.length > 0 ? (
                caseDetails.linkedCases.cases.map((caseHeader, index) => (
                    // eslint-disable-next-line react/jsx-key
                    <Fragment>
                        <CaseHeader key={index} caseHeader={caseHeader} isUnlinkable />
                    </Fragment>
                ))
            ) : (
                <Row alignmentHorizontal='center'>
                    <Text color='secondary'>No linked cases</Text>
                </Row>
            )}
            {caseDetails.linkedCases.userRestrictedCases.length > 0 &&
                caseDetails.linkedCases.userRestrictedCases.map((caseHeader, index) => (
                    // eslint-disable-next-line react/jsx-key
                    <Fragment>
                        <CaseHeader key={index} caseHeader={caseHeader} isUnlinkable />
                    </Fragment>
                ))}
            {caseDetails.linkedCases.restrictedCases.length > 0 &&
                caseDetails.linkedCases.restrictedCases.map((caseHeader, index) => (
                    // eslint-disable-next-line react/jsx-key
                    <Fragment>
                        <CaseHeader key={index} caseHeader={caseHeader} isUnlinkable />
                    </Fragment>
                ))}
            <Row alignmentHorizontal='justify'>
                <Text type='h100'> Linked engagements </Text>
                <Popover
                    anchorNode={consultationRef.current}
                    open={openConsultationPopover}
                    onClose={() => {
                        setOpenConsultationPopover(false);
                        setConsultationToLink(-1);
                    }}
                    position='bottom'
                >
                    <PopoverHeader>Link engagement</PopoverHeader>
                    <Column>
                        <Text>Searching engagements from last 30 days </Text>
                        <Select
                            width='330px'
                            value={consultationToLink}
                            onSearch={setQuery}
                            searchQuery={query}
                            placeholder='Search for engagement title...'
                            onChange={setConsultationToLink}
                        >
                            {data ? (
                                data.length > 0 ? (
                                    data
                                        .filter(
                                            (consultation: Consultation) =>
                                                ![
                                                    ...caseDetails.linkedConsultations.map(
                                                        (consultation) => consultation.consultationId
                                                    )
                                                ].includes(consultation.consultationId)
                                        )
                                        .map((consultation: Consultation) => {
                                            return (
                                                <SelectOption
                                                    key={consultation.consultationId}
                                                    value={consultation.consultationId}
                                                    label={consultation.name}
                                                />
                                            );
                                        })
                                ) : (
                                    <Column
                                        width='330px'
                                        spacing='300'
                                        height='150px'
                                        alignmentVertical='center'
                                    >
                                        <Text alignment='center' color='primary'>
                                            No results found
                                        </Text>
                                        <Text alignment='center' color='primary'>
                                            Use{' '}
                                            <Link onClick={history.push} href={'/search#consultations'}>
                                                advanced search
                                            </Link>{' '}
                                            to find engagements added over 30 days ago.
                                        </Text>
                                    </Column>
                                )
                            ) : (
                                <LoadingInput />
                            )}
                        </Select>
                        <Button
                            minWidth='100%'
                            onClick={handleSaveLinkConsultation}
                            disabled={consultationToLink == -1}
                        >
                            Save
                        </Button>
                    </Column>
                </Popover>
                <TitledButton
                    title={labels.linkConsultation}
                    buttonProps={{
                        ref: consultationRef,
                        onClick: () => setOpenConsultationPopover(true),
                        type: 'primary',
                        size: 'medium'
                    }}
                    iconProps={{ tokens: plusTokens }}
                />
            </Row>
            {caseDetails.linkedConsultations.length > 0 ? (
                caseDetails.linkedConsultations.map((consultation) => (
                    <ConsultationHeader
                        key={consultation.consultationId}
                        consultationHeader={consultation}
                        isUnlinkable
                    />
                ))
            ) : (
                <Row alignmentHorizontal='center'>
                    <Text color='secondary'>No linked engagements</Text>
                </Row>
            )}
        </Column>
    );
};

export default RelatedTab;
