import Select, { SelectOption } from '@amzn/meridian/select';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../context/store';
import { useAppDispatch } from '../context/store';
import { sortDisplayedCases } from '../slices/DashboardSlice';

const SortingDropDown: React.FunctionComponent = () => {
    const { displayedCases } = useSelector((state: RootState) => state.dashboard);
    const dispatch = useAppDispatch();
    const [value, setValue] = useState('lastModificationTime');

    useEffect(() => {
        dispatch(sortDisplayedCases(value));
    }, [value, displayedCases]);

    return (
        <Select value={value} onChange={setValue} placeholder={'Select criteria'} width={176}>
            <SelectOption value='loc/sev' label='Level of concern/ severity' />
            <SelectOption value='caseId' label='Case ID' />
            <SelectOption value='emtCategory' label='Category' />
            <SelectOption value='emtType' label='Type' />
            <SelectOption value='flaggedWords' label='Flagged words' />
            <SelectOption value='lastModificationTime' label='Recent activity' />
        </Select>
    );
};
export default SortingDropDown;
