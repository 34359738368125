import Button from '@amzn/meridian/button';
import Modal, { ModalFooter } from '@amzn/meridian/modal';
import Row from '@amzn/meridian/row';
import Text from '@amzn/meridian/text';
import React from 'react';

type AlertProps = {
    showPrompt: boolean;
    onCancel: () => void;
    handleConfirm: () => void;
    onClose: () => void;
};

const AlertModal: React.FunctionComponent<AlertProps> = ({
    showPrompt,
    onCancel,
    handleConfirm,
    onClose
}: AlertProps) => {
    return (
        <Modal title={'Leave this page?'} open={showPrompt} onClose={onClose}>
            <Text>You have unsaved changes on this page. These changes will be lost upon leaving.</Text>
            <ModalFooter>
                <Row alignmentHorizontal='right'>
                    <Button type='secondary' onClick={handleConfirm}>
                        Stay on this page
                    </Button>
                    <Button onClick={onCancel}>Leave this page</Button>
                </Row>
            </ModalFooter>
        </Modal>
    );
};

export default AlertModal;
