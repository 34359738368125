import Button from '@amzn/meridian/button';
import Checkbox from '@amzn/meridian/checkbox';
import Column from '@amzn/meridian/column';
import Icon from '@amzn/meridian/icon';
import Link from '@amzn/meridian/link';
import Row from '@amzn/meridian/row';
import Text from '@amzn/meridian/text';
import editIconTokens from '@amzn/meridian-tokens/base/icon/edit';
import exportSmallTokens from '@amzn/meridian-tokens/base/icon/export-small';
import _, { isArray } from 'lodash';
import { DateTime } from 'luxon';
import moment from 'moment-timezone';
import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../context/store';
import { EditCaseVars, OffSiteLocationAdding, Proximity } from '../../../lib/CaseDefinitions';
import { ADDITIONAL_TAGS, CASE_STRINGS, CHARACTER_LIMIT, WIDTH_OF_CARD } from '../../../lib/constants';
import { getEventItems } from '../../../lib/EmtCTIDefinitions';
import { replaceFlaggedWords } from '../../../lib/flaggedWordHighlighter';
import { checkIfSitesArrayEqual, renderFacility, renderLocation, renderSeverity } from '../../../lib/helpers';
import { labels } from '../../../lib/labels';
import {
    changeProximity,
    reset,
    setInputLocation,
    setSecondarySiteIds,
    setSiteIds
} from '../../../slices/AddProximitySlice';
import { setCaseDetails } from '../../../slices/CaseDetailsSlice';
import { addToast } from '../../../slices/ToastSlice';
import { useLazyEditMarsCaseQuery } from '../../../slices/WotManApiSlice';
import AlertModal from '../../Alert/AlertModal';
import NewCaseEventInformation from '../../CasePages/CreateCase/NewCaseEventInformation';
import NewCaseEventSummary from '../../CasePages/CreateCase/NewCaseEventSummary';
import TitledButton from '../../CustomComponents/TitledButton';
import EmployeePopover from './EmployeePopover';

type SummaryProps = {
    setIsEditing: (isEditing: boolean) => void;
    isEditing: boolean;
    editingCaseInfo: EditCaseVars;
    setEditingCaseInfo: (editingCaseInfo: EditCaseVars) => void;
};
const SummaryTab: React.FunctionComponent<SummaryProps> = ({
    setIsEditing,
    isEditing,
    editingCaseInfo,
    setEditingCaseInfo
}: SummaryProps) => {
    const { caseDetails, isCaseOpen } = useSelector((state: RootState) => state.caseDetails);
    const { proximity, siteIds, inputLocation, secondarySiteIds } = useSelector(
        (state: RootState) => state.addProximity
    );
    const incidentDate = caseDetails.incidentTime
        ? DateTime.fromISO(`${caseDetails.incidentDate}T${caseDetails.incidentTime}`, {
              zone: 'utc'
          }).setZone(caseDetails.inputTimeZone || moment.tz.guess())
        : undefined;

    const dispatch = useAppDispatch();

    const [useEditMarsCase, useEditMarsCaseEndpoint] = useLazyEditMarsCaseQuery();

    const [showAlertPrompt, setShowAlertPrompt] = useState(false);

    const handleCancel = () => {
        setShowAlertPrompt(false);
        setIsEditing(false);
        setEditingCaseInfo({
            eventCategory: caseDetails.emtCategory,
            eventType: caseDetails.emtType,
            eventItem: caseDetails.emtItem,
            additionalTags: caseDetails.eventTags.filter((tag) => ADDITIONAL_TAGS.includes(tag)),
            dateOfEvent: incidentDate?.toISODate() || caseDetails.incidentDate,
            timeOfEvent: incidentDate?.toISOTime() || caseDetails.incidentTime,
            eventSummary: caseDetails.eventSummary,
            missingFields: [],
            isDirty: false,
            timezone: caseDetails.inputTimeZone || moment.tz.guess()
        });
    };

    const handleShowModal = () => {
        setShowAlertPrompt(false);
    };

    const isModalEditing = () => {
        if (editingCaseInfo.isDirty) {
            setShowAlertPrompt(true);
        } else {
            setIsEditing(false);
        }
    };

    useEffect(() => {
        if (!useEditMarsCaseEndpoint.isFetching && useEditMarsCaseEndpoint.isSuccess) {
            if (useEditMarsCaseEndpoint.data) {
                dispatch(
                    addToast({
                        type: 'success',
                        action: 'Edited case ',
                        message: caseDetails.caseName,
                        SIM: false,
                        timeout: 3000
                    })
                );
                dispatch(setCaseDetails(useEditMarsCaseEndpoint.data));
                setIsEditing(false);
            }
        } else if (!useEditMarsCaseEndpoint.isFetching && useEditMarsCaseEndpoint.isError) {
            dispatch(
                addToast({
                    type: 'error',
                    action: 'There was an error editing case',
                    message: '',
                    SIM: true,
                    timeout: undefined
                })
            );
        }
    }, [useEditMarsCaseEndpoint.isFetching]);

    useEffect(() => {
        return () => {
            dispatch(reset());
        };
    }, []);

    useEffect(() => {
        dispatch(changeProximity(caseDetails.proximity));
        if (caseDetails.primaryFacility && caseDetails.primaryFacility.length > 0) {
            dispatch(setSiteIds(caseDetails.primaryFacility));
        }
        if (caseDetails.secondaryFacilityList && caseDetails.secondaryFacilityList.length > 0) {
            dispatch(setSecondarySiteIds(caseDetails.secondaryFacilityList));
        }
        if (caseDetails.incidentLocation) {
            dispatch(setInputLocation(caseDetails.incidentLocation));
        }
    }, [caseDetails]);

    return (
        <Column width={WIDTH_OF_CARD} spacing='large'>
            {isEditing ? (
                <Column>
                    <NewCaseEventInformation
                        createCaseVars={editingCaseInfo}
                        setCreateCaseVars={setEditingCaseInfo}
                    />
                    <NewCaseEventSummary
                        createCaseVars={editingCaseInfo}
                        setCreateCaseVars={setEditingCaseInfo}
                    />
                    <Row alignmentHorizontal='right'>
                        <Button
                            type='secondary'
                            onClick={() => {
                                isModalEditing();
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={() => {
                                const currentMissedField: string[] = [];
                                if (editingCaseInfo.eventCategory == '') {
                                    currentMissedField.push('eventCategory');
                                }
                                if (editingCaseInfo.eventType == '') {
                                    currentMissedField.push('eventType');
                                }
                                if (
                                    editingCaseInfo.eventType != '' &&
                                    getEventItems(editingCaseInfo.eventType).length > 0 &&
                                    editingCaseInfo.eventItem == ''
                                ) {
                                    currentMissedField.push('eventItem');
                                }
                                if (editingCaseInfo.eventSummary == '') {
                                    currentMissedField.push('eventSummary');
                                }
                                if (!proximity) {
                                    currentMissedField.push('proximity');
                                }
                                if (proximity == Proximity.ONSITE && siteIds.length === 0) {
                                    currentMissedField.push('siteId');
                                }
                                setEditingCaseInfo({
                                    ...editingCaseInfo,
                                    missingFields: currentMissedField
                                });
                                if (
                                    currentMissedField.length == 0 &&
                                    inputLocation.address.length <= CHARACTER_LIMIT.NORMAL_TEXT_LIMIT &&
                                    inputLocation.city.length <= CHARACTER_LIMIT.NORMAL_TEXT_LIMIT &&
                                    inputLocation.state.length <= CHARACTER_LIMIT.NORMAL_TEXT_LIMIT &&
                                    inputLocation.zipcode.length <= CHARACTER_LIMIT.ZIPCODE_LIMIT &&
                                    editingCaseInfo.eventSummary.length <= CHARACTER_LIMIT.EVENT_SUMMARY_LIMIT
                                ) {
                                    let offsiteLocation: OffSiteLocationAdding | undefined = inputLocation;
                                    switch (proximity) {
                                        case Proximity.ONSITE:
                                            offsiteLocation = undefined;
                                    }
                                    let dateUtc;
                                    let timeUtc;
                                    let timeZone;
                                    if (editingCaseInfo.timeOfEvent) {
                                        const dateTimeOfEvent = DateTime.fromISO(
                                            editingCaseInfo.dateOfEvent + 'T' + editingCaseInfo.timeOfEvent,
                                            {
                                                zone: editingCaseInfo.timezone
                                            }
                                        ).toUTC();
                                        dateUtc = dateTimeOfEvent.toISODate();
                                        timeUtc = dateTimeOfEvent.toISOTime();
                                        timeZone = editingCaseInfo.timezone;
                                    } else {
                                        if (editingCaseInfo.dateOfEvent) {
                                            dateUtc = editingCaseInfo.dateOfEvent;
                                        }
                                    }
                                    useEditMarsCase({
                                        caseEdit: {
                                            eventType: editingCaseInfo.eventType,
                                            eventItem: editingCaseInfo.eventItem,
                                            eventTags: editingCaseInfo.additionalTags,
                                            eventSummary: editingCaseInfo.eventSummary,
                                            dateUtc,
                                            timeUtc,
                                            timeZone,
                                            proximity,
                                            offsiteLocation: offsiteLocation
                                                ? {
                                                      ...offsiteLocation
                                                  }
                                                : undefined,
                                            siteIds: siteIds.map((facility) => facility.siteId),
                                            secondarySiteIds: secondarySiteIds.map(
                                                (facility) => facility.siteId
                                            ),
                                            caseId: caseDetails.caseId
                                        }
                                    });
                                } else {
                                    dispatch(
                                        addToast({
                                            type: 'error',
                                            action: 'One or more invalid form fields. ',
                                            message: 'Correct any errors and submit again',
                                            SIM: false,
                                            timeout: 3000
                                        })
                                    );
                                }
                            }}
                            disabled={
                                (caseDetails.emtCategory === editingCaseInfo.eventCategory &&
                                    caseDetails.emtType === editingCaseInfo.eventType &&
                                    caseDetails.emtItem === editingCaseInfo.eventItem &&
                                    _.isEqual(
                                        caseDetails.eventTags,
                                        editingCaseInfo.additionalTags.concat('WIM')
                                    ) &&
                                    editingCaseInfo.timeOfEvent === incidentDate?.toISOTime() &&
                                    editingCaseInfo.dateOfEvent === incidentDate?.toISODate() &&
                                    caseDetails.inputTimeZone === editingCaseInfo.timezone &&
                                    caseDetails.eventSummary === editingCaseInfo.eventSummary &&
                                    caseDetails.proximity === proximity &&
                                    ((siteIds &&
                                        caseDetails.primaryFacility &&
                                        checkIfSitesArrayEqual(
                                            isArray(siteIds) ? siteIds : [siteIds],
                                            caseDetails.primaryFacility
                                        )) ||
                                        (!siteIds && caseDetails.primaryFacility.length == 0)) &&
                                    ((secondarySiteIds &&
                                        caseDetails.secondaryFacilityList &&
                                        checkIfSitesArrayEqual(
                                            isArray(secondarySiteIds) ? secondarySiteIds : [secondarySiteIds],
                                            caseDetails.secondaryFacilityList
                                        )) ||
                                        (!secondarySiteIds &&
                                            caseDetails.secondaryFacilityList.length == 0)) &&
                                    caseDetails.incidentLocation &&
                                    caseDetails.incidentLocation.address === inputLocation.address &&
                                    caseDetails.incidentLocation.city === inputLocation.city &&
                                    caseDetails.incidentLocation.state === inputLocation.state &&
                                    caseDetails.incidentLocation.country === inputLocation.country &&
                                    caseDetails.incidentLocation.zipcode === inputLocation.zipcode &&
                                    caseDetails.incidentLocation.region === inputLocation.region) ||
                                useEditMarsCaseEndpoint.isFetching
                            }
                        >
                            Save
                        </Button>
                    </Row>
                    {showAlertPrompt && (
                        <AlertModal
                            showPrompt={showAlertPrompt}
                            handleConfirm={handleShowModal}
                            onCancel={handleCancel}
                            onClose={handleShowModal}
                        ></AlertModal>
                    )}
                </Column>
            ) : (
                <Fragment>
                    <Row alignmentVertical={'top'} alignmentHorizontal={'justify'}>
                        <div>
                            <Text type='h100' color='secondary'>
                                {' '}
                                Report Submitted{' '}
                            </Text>
                            <Text color='secondary'>
                                {' '}
                                {DateTime.fromISO(caseDetails.reportTime).toLocaleString(
                                    DateTime.DATETIME_FULL
                                )}
                            </Text>
                        </div>
                        {caseDetails.canEdit && isCaseOpen && (
                            <TitledButton
                                title={labels.editConsultation}
                                buttonProps={{
                                    onClick: () => setIsEditing(true),
                                    type: 'secondary',
                                    size: 'small'
                                }}
                                iconProps={{ tokens: editIconTokens }}
                            />
                        )}
                    </Row>
                    <Column>
                        <Text type='h200'> Flagged words</Text>
                        <Text>
                            {caseDetails.flaggedWords.length > 0 ? (
                                <span>
                                    {replaceFlaggedWords(
                                        caseDetails.flaggedWords.join(', ').toString(),
                                        caseDetails.flaggedWords
                                    )}
                                </span>
                            ) : (
                                <Text color='secondary'> None</Text>
                            )}
                        </Text>
                    </Column>
                    <Text type='h200'>Event Information</Text>
                    <Row alignmentVertical='top' spacing='large'>
                        <Column>
                            <Column spacing='small' width='180px'>
                                <Text type='h100'> Tags </Text>
                                <Column spacing='xsmall'>
                                    {caseDetails.eventTags.map((tag) => (
                                        <Text key={tag}> {tag} </Text>
                                    ))}
                                </Column>
                            </Column>
                            {'eventFocusArea' in caseDetails && (
                                <Column spacing='none'>
                                    <Text type='h100'> Event Focus Area </Text>
                                    <Checkbox
                                        checked={caseDetails.eventFocusArea.isLifeFocused}
                                        disabled={true}
                                    >
                                        {' '}
                                        Life{' '}
                                    </Checkbox>
                                    <Checkbox
                                        checked={caseDetails.eventFocusArea.isPropertyFocused}
                                        disabled={true}
                                    >
                                        {' '}
                                        Property{' '}
                                    </Checkbox>
                                    <Checkbox
                                        checked={caseDetails.eventFocusArea.isBrandFocused}
                                        disabled={true}
                                    >
                                        {' '}
                                        Brand{' '}
                                    </Checkbox>
                                </Column>
                            )}
                        </Column>
                        {'privileged' in caseDetails && caseDetails.emtSev && (
                            <Column>
                                <Column spacing='none'>
                                    <Text type='h100'> Severity </Text>
                                    {renderSeverity(caseDetails.emtSev)}
                                </Column>
                                <Column spacing='none'>
                                    <Text type='h100'> Privileged and Confidential </Text>
                                    <Text> {caseDetails.privileged ? 'yes' : 'no'} </Text>
                                </Column>
                            </Column>
                        )}
                        <Column>
                            {caseDetails.incidentLocation && (
                                <Column spacing='none'>
                                    <Text type='h100'> Event location </Text>
                                    {renderLocation(caseDetails.incidentLocation)}
                                </Column>
                            )}
                            <Column spacing='none'>
                                <Text type='h100'> Proximity </Text>
                                <Text> {caseDetails.proximity} </Text>
                            </Column>
                        </Column>
                        <Column spacing='none'>
                            <Text type='h100'> {'Primary facility'} </Text>
                            <Row wrap='down' alignmentVertical='top' spacing='small'>
                                {caseDetails.primaryFacility && caseDetails.primaryFacility.length > 0 ? (
                                    caseDetails.primaryFacility.map((facility) => {
                                        return renderFacility(facility);
                                    })
                                ) : (
                                    <Text color='secondary'> None </Text>
                                )}
                            </Row>
                        </Column>
                        <Column spacing='none'>
                            <Text type='h100'> {'Secondary facilities'} </Text>
                            <Row wrap='down' alignmentVertical='top' spacing='small'>
                                {caseDetails.secondaryFacilityList &&
                                caseDetails.secondaryFacilityList.length > 0 ? (
                                    caseDetails.secondaryFacilityList.map((facility) => {
                                        return renderFacility(facility);
                                    })
                                ) : (
                                    <Text color='secondary'> None </Text>
                                )}
                            </Row>
                        </Column>
                    </Row>
                    <Column spacing='small'>
                        <Text type='h200'> {CASE_STRINGS.EVENT_SUMMARY} </Text>
                        <Text>
                            {' '}
                            {caseDetails.eventSummary.split('\n').map((item) => {
                                return (
                                    <span key={item}>
                                        {replaceFlaggedWords(item, caseDetails.totalFlaggedWords)}
                                        <br />
                                    </span>
                                );
                            })}{' '}
                        </Text>
                    </Column>
                    {'privileged' in caseDetails && (
                        <Column spacing='small'>
                            <Text type='h200'> Risk Assessment </Text>
                            <Text> {caseDetails.riskAssessment} </Text>
                        </Column>
                    )}
                    {'nextSteps' in caseDetails && (
                        <Column spacing='small'>
                            <Text type='h200'> Next Steps </Text>
                            <Text> {caseDetails.nextSteps} </Text>
                        </Column>
                    )}

                    {'actionItems' in caseDetails && (
                        <Row>
                            <Column spacing='small'>
                                <Text type='h200'> Action Items </Text>
                                <Text type='h100'> {caseDetails.actionItems.title} </Text>
                                <Text> {caseDetails.actionItems.description} </Text>
                                <Row spacing='small'>
                                    <Text type='h100'> Status: </Text>
                                    <Text> {caseDetails.actionItems.status} </Text>
                                </Row>
                                <Row spacing='Xlarge' alignmentVertical='top'>
                                    <Column spacing='none'>
                                        <Text type='h100'> Primary assignee </Text>
                                        <EmployeePopover employee={caseDetails.actionItems.primaryAssignee} />
                                    </Column>
                                    <Column spacing='none'>
                                        <Text type='h100'> Secondary assignees </Text>
                                        {caseDetails.actionItems.secondaryAssignee.map((assignee) => {
                                            return (
                                                <EmployeePopover key={assignee.login} employee={assignee} />
                                            );
                                        })}
                                    </Column>
                                </Row>
                            </Column>
                        </Row>
                    )}
                    {'actionItems' in caseDetails && ( //TODO: change to actually EMT LINK
                        <span style={{ width: 'fit-content' }}>
                            <Link type='secondary'>
                                <Row spacing='small' width='fit-content'>
                                    <span> View incident in EMT </span>
                                    <Icon tokens={exportSmallTokens} />
                                </Row>
                            </Link>
                        </span>
                    )}
                </Fragment>
            )}
        </Column>
    );
};

export default SummaryTab;
