import Column from '@amzn/meridian/column';
import Loader from '@amzn/meridian/loader';
import Row from '@amzn/meridian/row';
import Text from '@amzn/meridian/text';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { RootState } from '../../context/store';
import { WIDTH_OF_CARD, TAB_NAMES } from '../../lib/constants';
import { CreateCaseButton } from '../../lib/helpers';
import CaseHeader from '../Cases/CaseHeader';

const TemplatesDashboard: React.FunctionComponent = () => {
    const { dashboardCases, hasFinishedLoading } = useSelector((state: RootState) => state.dashboard);
    const { newHighSevCases, updatedHighLOCCases } = dashboardCases;

    return hasFinishedLoading ? (
        <React.Fragment>
            <Helmet defer={false}>
                <title> {TAB_NAMES.DASHBOARD} | MARS </title>
            </Helmet>
            <Row alignmentHorizontal={'center'}>
                <Column width={WIDTH_OF_CARD}>
                    <Row alignmentHorizontal='justify'>
                        <Text type='d100'> Dashboard </Text>
                        <CreateCaseButton />
                    </Row>
                    <Text type='b500'> High-Priority Notifications </Text>
                    <Text type='h300'> New Cases </Text>
                    <Column spacingInset='400 none'>
                        {newHighSevCases.length > 0 ? (
                            newHighSevCases.map((caseHeader) => (
                                <CaseHeader caseHeader={caseHeader} key={caseHeader.caseId} />
                            ))
                        ) : (
                            <Text alignment='center' color='secondary'>
                                No new high sev cases to display
                            </Text>
                        )}
                    </Column>
                    <Text type='h300'> Updated Cases </Text>
                    <Column spacingInset='400 none'>
                        {updatedHighLOCCases.length > 0 ? (
                            updatedHighLOCCases.map((caseHeader) => (
                                <CaseHeader caseHeader={caseHeader} key={caseHeader.caseId} />
                            ))
                        ) : (
                            <Text alignment='center' color='secondary'>
                                No updated high loc cases to display
                            </Text>
                        )}
                    </Column>
                </Column>
            </Row>
        </React.Fragment>
    ) : (
        <Loader />
    );
};

export default TemplatesDashboard;
