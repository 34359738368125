import Button from '@amzn/meridian/button';
import Column from '@amzn/meridian/column';
import Divider from '@amzn/meridian/divider';
import Icon from '@amzn/meridian/icon';
import Link from '@amzn/meridian/link';
import Modal, { ModalFooter } from '@amzn/meridian/modal';
import Row from '@amzn/meridian/row';
import Text from '@amzn/meridian/text';
import exportSmallTokens from '@amzn/meridian-tokens/base/icon/export-small';
import plusTokens from '@amzn/meridian-tokens/base/icon/plus';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../context/store';
import {
    CreatePersonInvolved,
    Facility,
    PersonInvolvedDisplaying,
    PersonInvolvedRole,
    PersonInvolvedType
} from '../../../lib/CaseDefinitions';
import {
    FACMAN_LINK,
    MODAL_WIDTH,
    SELECT_WIDTH,
    WIDTH_OF_CARD,
    QUERY_PARAM_CONTENT_ID,
    QUERY_PARAM_SITE_ID
} from '../../../lib/constants';
import { labels } from '../../../lib/labels';
import {
    setCaseDetails,
    closeDuplicateContact,
    setDuplicateContact,
    setReportedByDisplaying
} from '../../../slices/CaseDetailsSlice';
import { addToast } from '../../../slices/ToastSlice';
import { useLazyAddEditContactQuery } from '../../../slices/WotManApiSlice';
import AlertModal from '../../Alert/AlertModal';
import PersonCard from '../../CustomComponents/PersonCard';
import TitledButton from '../../CustomComponents/TitledButton';
import AddPersonsInvolved from './AddPersonsInvolved';

const PersonsInvolvedTab: React.FunctionComponent = () => {
    const { caseDetails, isCaseOpen } = useSelector((state: RootState) => state.caseDetails);
    const [isDisplayingModal, setIsDisplayingModal] = useState<boolean>(false);
    const emptyPersonInvolved = {
        email: '',
        name: '',
        login: '',
        phoneNumber: '',
        title: '',
        personId: '',
        isDirty: false
    };
    const [showAlertPrompt, setShowAlertPrompt] = useState(false);
    const dispatch = useAppDispatch();
    const victims = caseDetails.personsInvolved.filter(
        (person) =>
            person.type !== PersonInvolvedType.FACMAN_CONTACT && person.role === PersonInvolvedRole.VICTIM
    );
    const pois = caseDetails.personsInvolved.filter(
        (person) =>
            person.type !== PersonInvolvedType.FACMAN_CONTACT &&
            person.role === PersonInvolvedRole.PERSON_OF_INTEREST
    );
    const witnesses = caseDetails.personsInvolved.filter(
        (person) =>
            person.type !== PersonInvolvedType.FACMAN_CONTACT && person.role === PersonInvolvedRole.WITNESS
    );
    const contacts = caseDetails.personsInvolved.filter(
        (person) =>
            person.type !== PersonInvolvedType.FACMAN_CONTACT && person.role === PersonInvolvedRole.CONTACT
    );

    const primaryFacility = caseDetails.primaryFacility ? caseDetails.primaryFacility : [];
    const secondaryFacility = caseDetails.secondaryFacilityList ? caseDetails.secondaryFacilityList : [];

    const [createPersonInvolved, setCreatePersonInvolved] = useState<CreatePersonInvolved>(
        emptyPersonInvolved
    );

    const [missingRole, setMissingRole] = useState<boolean>(false);
    const [missingName, setMissingName] = useState<boolean>(false);
    const [nameIsEmptySpaces, setNameIsEmptySpaces] = useState<boolean>(false);
    const [useAddContact, useAddContactEndpoint] = useLazyAddEditContactQuery();
    const widthOfColumn = '280px';
    const widthOfSubColumns = [widthOfColumn, widthOfColumn, widthOfColumn];
    const { userLogin } = useSelector((state: RootState) => state.dashboard);

    useEffect(() => {
        if (!useAddContactEndpoint.isFetching && useAddContactEndpoint.isSuccess) {
            dispatch(setCaseDetails(useAddContactEndpoint.data));
            setIsDisplayingModal(false);
            dispatch(closeDuplicateContact());
            dispatch(
                addToast({
                    type: 'success',
                    action:
                        'Added ' + createPersonInvolved.role?.toLowerCase() + ' ' + createPersonInvolved.name,
                    message: '',
                    SIM: false,
                    timeout: 3000
                })
            );
        } else if (!useAddContactEndpoint.isFetching && useAddContactEndpoint.isError) {
            if (
                useAddContactEndpoint.error.data &&
                useAddContactEndpoint.error.data.indexOf('The user already exists') > 0
            ) {
                dispatch(setDuplicateContact());
            } else {
                dispatch(
                    addToast({
                        type: 'error',
                        action: 'There was an error adding/editing person involved, please try again',
                        message: '',
                        SIM: false,
                        timeout: 3000
                    })
                );
            }
        }
    }, [useAddContactEndpoint.isFetching]);

    const handleCancel = () => {
        setShowAlertPrompt(false);
        setIsDisplayingModal(false);
        dispatch(closeDuplicateContact());
        setMissingRole(false);
        setMissingName(false);
        setNameIsEmptySpaces(false);
    };

    const isFormFilled = () => {
        if (createPersonInvolved.isDirty || useAddContactEndpoint.isFetching) setShowAlertPrompt(true);
        else {
            handleCancel();
        }
    };

    const handleShowModal = () => setShowAlertPrompt(false);

    const ListOfPeople = (props: {
        title: string;
        list: PersonInvolvedDisplaying[];
        emptyListMessage?: string;
    }) => {
        const firstThird = Math.ceil(props.list.length / 3);
        const secondThird = Math.ceil((2 * props.list.length) / 3);

        const column = (listStart: number, listEnd: number) => (
            <Column>
                {props.list
                    .slice(listStart, listEnd)
                    .map((person: PersonInvolvedDisplaying, index: number) => (
                        <PersonCard person={person} key={index} cardWidth={widthOfColumn} />
                    ))}
            </Column>
        );

        return (
            <React.Fragment>
                <Text type='h100'> {props.title} </Text>
                {props.list.length == 0 ? (
                    <Text color='secondary'> {props.emptyListMessage ?? 'None'} </Text>
                ) : (
                    <Row widths={widthOfSubColumns} alignmentVertical='top'>
                        {column(0, firstThird)}
                        {column(firstThird, secondThird)}
                        {column(secondThird, props.list.length)}
                    </Row>
                )}
            </React.Fragment>
        );
    };

    const renderListOfFacManContacts = (facmanContactList: Facility[]) => {
        return facmanContactList.map((contact, index) => {
            return (
                contact.contentSectionId != 0 && (
                    <Row key={index}>
                        <Column spacing='none'>
                            <span>
                                <Link
                                    href={
                                        FACMAN_LINK +
                                        QUERY_PARAM_CONTENT_ID +
                                        contact.contentSectionId +
                                        QUERY_PARAM_SITE_ID +
                                        contact.siteId
                                    }
                                    target='_blank'
                                    type='secondary'
                                >
                                    {contact.siteCode} <Icon tokens={exportSmallTokens} />{' '}
                                </Link>
                            </span>
                        </Column>
                    </Row>
                )
            );
        });
    };

    const addEditContact = () => {
        let userRestricted = false;
        if (!createPersonInvolved.role) {
            setMissingRole(true);
        }
        if (createPersonInvolved.name == '') {
            setMissingName(true);
        }
        if (createPersonInvolved.name !== '' && !createPersonInvolved.name?.trim()) {
            setNameIsEmptySpaces(true);
        }
        if (
            createPersonInvolved.role != null &&
            createPersonInvolved.login != null &&
            createPersonInvolved.login != '' &&
            createPersonInvolved.login.toLowerCase() === userLogin.toLowerCase() &&
            (createPersonInvolved.role === PersonInvolvedRole.PERSON_OF_INTEREST ||
                createPersonInvolved.role === PersonInvolvedRole.VICTIM ||
                createPersonInvolved.role === PersonInvolvedRole.WITNESS)
        ) {
            userRestricted = true;
        }
        if (
            !userRestricted &&
            createPersonInvolved.contactId == null &&
            createPersonInvolved.role != null &&
            createPersonInvolved.name != '' &&
            createPersonInvolved.name?.trim()
        ) {
            const personInvolved =
                createPersonInvolved.personId !== ''
                    ? {
                          ...createPersonInvolved,
                          email: '',
                          name: '',
                          phoneNumber: '',
                          title: '',
                          personId: createPersonInvolved.personId,
                          incidentId: caseDetails.caseId
                      }
                    : {
                          ...createPersonInvolved,
                          incidentId: caseDetails.caseId
                      };
            useAddContact({
                personInvolved: personInvolved,
                personId: undefined
            });
            setMissingRole(false);
            setMissingName(false);
            setNameIsEmptySpaces(false);
        } else {
            dispatch(
                addToast({
                    type: 'error',
                    action: 'One or more invalid form fields. ',
                    message: 'Correct any errors and submit again',
                    SIM: false,
                    timeout: 3000
                })
            );
        }
    };

    const renderFacmanContacts = (contactList: Facility[]) => {
        const contactsBody: JSX.Element[] = [];
        for (let x = 0; x < contactList.length; x = x + 2) {
            contactsBody.push(
                <Row widths={widthOfSubColumns}>
                    {renderListOfFacManContacts(contactList.slice(x, x + 2))}
                </Row>
            );
        }
        return contactsBody;
    };

    return (
        <Column width={WIDTH_OF_CARD}>
            <Modal
                title='Add person involved'
                open={isDisplayingModal}
                width={MODAL_WIDTH}
                onClose={() => isFormFilled()}
            >
                <Column width={SELECT_WIDTH}>
                    <AddPersonsInvolved
                        personAdding={createPersonInvolved}
                        setCaseVars={setCreatePersonInvolved}
                        missingName={missingName && createPersonInvolved.name == ''}
                        missingRole={missingRole && createPersonInvolved.role == undefined}
                        isDisabledInput={false}
                        nameIsEmptySpaces={
                            nameIsEmptySpaces &&
                            createPersonInvolved.name !== '' &&
                            !createPersonInvolved.name?.trim()
                        }
                        isPersonInvolved={
                            userLogin.toLowerCase() === createPersonInvolved.login.toLowerCase().trim() &&
                            (createPersonInvolved.role === PersonInvolvedRole.VICTIM ||
                                createPersonInvolved.role === PersonInvolvedRole.WITNESS ||
                                createPersonInvolved.role === PersonInvolvedRole.PERSON_OF_INTEREST)
                        }
                    />
                </Column>
                <ModalFooter>
                    <Row alignmentHorizontal='right'>
                        <Row>
                            <Button type='secondary' onClick={() => isFormFilled()}>
                                Cancel
                            </Button>
                            <Button
                                disabled={useAddContactEndpoint.isFetching}
                                onClick={() => addEditContact()}
                            >
                                Submit
                            </Button>
                        </Row>
                    </Row>
                </ModalFooter>
            </Modal>
            {showAlertPrompt && (
                <AlertModal
                    showPrompt={showAlertPrompt}
                    handleConfirm={handleShowModal}
                    onCancel={handleCancel}
                    onClose={handleShowModal}
                ></AlertModal>
            )}
            <Row alignmentHorizontal='justify'>
                <Text type='h200'> Persons Involved </Text>
                {isCaseOpen && (
                    <TitledButton
                        title={labels.addPerson}
                        buttonProps={{
                            onClick: () => {
                                setIsDisplayingModal(true);
                                setCreatePersonInvolved(emptyPersonInvolved);
                            },
                            type: 'primary',
                            size: 'medium'
                        }}
                        iconProps={{ tokens: plusTokens }}
                    />
                )}
            </Row>
            <Column>
                <ListOfPeople title='Persons of interest' list={pois} />
                <ListOfPeople title='Victims' list={victims} />
                <ListOfPeople title='Witnesses' list={witnesses} />
            </Column>
            {caseDetails.reportedBy && (
                <Row>
                    <Column>
                        <Text type='h100'> Report by </Text>
                        <PersonCard
                            person={caseDetails.reportedBy}
                            dispatchCallback={setReportedByDisplaying}
                        />
                    </Column>
                </Row>
            )}
            <Divider size='small' />
            <ListOfPeople title='Contacts' list={contacts} emptyListMessage='No saved contacts' />
            <Column>
                {primaryFacility.length > 0 || secondaryFacility.length > 0 ? (
                    <Text type='h100'> Emergency/government contacts </Text>
                ) : null}
                {primaryFacility.length > 0 ? renderFacmanContacts(primaryFacility) : null}
                {secondaryFacility.length > 0 ? renderFacmanContacts(secondaryFacility) : null}
            </Column>
        </Column>
    );
};

export default PersonsInvolvedTab;
