import Column from '@amzn/meridian/column';
import Icon from '@amzn/meridian/icon';
import Row from '@amzn/meridian/row';
import Text from '@amzn/meridian/text';
import Tooltip from '@amzn/meridian/tooltip';
import geopinIconTokens from '@amzn/meridian-tokens/base/icon/geopin';
import React from 'react';
import { Case, EMTCase, MARSCase, Proximity, SiteIdAndCode } from '../../lib/CaseDefinitions';
import { renderFacmanLink } from '../../lib/helpers';

type CaseSiteLocationsProps = {
    caseHeader: Case | MARSCase | EMTCase;
    isCaseDetails: boolean;
};

const CaseSiteLocations: React.FunctionComponent<CaseSiteLocationsProps> = ({
    caseHeader,
    isCaseDetails
}: CaseSiteLocationsProps) => {
    let locationColumn;
    let primaryCountry: string | undefined;
    const siteNamesSet = new Set<string>();
    const siteIdAndCode: SiteIdAndCode[] = [];
    const primarySiteIdAndCode: SiteIdAndCode[] = [];
    const countriesSet = new Set<string>();

    const hasNoPrimaryandSecondaryLocation = () => {
        return !caseHeader.primaryLocation && !caseHeader.secondaryLocation;
    };

    const hasNonEmptyPrimaryLocation = () => {
        return caseHeader.primaryLocation && caseHeader.primaryLocation.length > 0;
    };

    const hasNonEmptyPrimaryFacility = () => {
        return (
            'secondaryFacilityList' in caseHeader &&
            caseHeader.primaryFacility &&
            caseHeader.primaryFacility.length > 0
        );
    };

    const hasNoPrimaryandSecondaryFacility = () => {
        return (
            'incidentLocation' in caseHeader &&
            !caseHeader.primaryFacility &&
            !caseHeader.secondaryFacilityList
        );
    };

    if (!isCaseDetails) {
        if (hasNonEmptyPrimaryLocation()) {
            primaryCountry = caseHeader.primaryLocation[0].country;
        }
        caseHeader.secondaryLocation?.forEach((facility) => {
            siteNamesSet.add(facility.siteName);
            if (!primaryCountry || !countriesSet.has(primaryCountry)) {
                countriesSet.add(facility.country);
            }
        });
    } else if (isCaseDetails) {
        if ('secondaryFacilityList' in caseHeader) {
            if (caseHeader.primaryFacility && caseHeader.primaryFacility.length > 0) {
                primarySiteIdAndCode.push({
                    siteId: caseHeader.primaryFacility[0].siteId,
                    siteCode: caseHeader.primaryFacility[0].siteCode
                });
                if (caseHeader.primaryFacility[0].country)
                    primaryCountry = caseHeader.primaryFacility[0].country;
            }
            caseHeader.secondaryFacilityList?.forEach((facility) => {
                siteNamesSet.add(facility.siteCode);
                siteIdAndCode.push({
                    siteCode: facility.siteCode,
                    siteId: facility.siteId
                });
                facility.country &&
                    (!primaryCountry || !countriesSet.has(primaryCountry)) &&
                    countriesSet.add(facility.country);
            });
        }
    }
    const siteNames = Array.from(siteNamesSet.values());
    const countries = Array.from(countriesSet.values());

    switch (caseHeader.proximity) {
        case Proximity.OFFSITE:
            locationColumn = (
                <Column tag='span' spacing='none' width={isCaseDetails ? '143px' : '125px'}>
                    <Text type={isCaseDetails ? 'h100' : 'h200'}> {caseHeader.proximity} </Text>
                    {isCaseDetails ? (
                        <Row spacing='xsmall'>
                            {primarySiteIdAndCode.length > 0
                                ? renderFacmanLink(primarySiteIdAndCode[0])
                                : siteIdAndCode.length > 0 && renderFacmanLink(siteIdAndCode[0])}
                        </Row>
                    ) : (
                        <Text tag={'span'}>
                            {' '}
                            {hasNonEmptyPrimaryLocation()
                                ? caseHeader.primaryLocation[0].siteName
                                : siteNames.length > 0 && siteNames[0]}
                        </Text>
                    )}
                    {(hasNonEmptyPrimaryLocation() || hasNonEmptyPrimaryFacility()) &&
                    siteNames.length > 0 ? (
                        <Tooltip position='right' title={siteNames.join(', ')}>
                            <Text color='secondary' tag={'span'}>
                                {' '}
                                and {siteNamesSet.size} more
                            </Text>
                        </Tooltip>
                    ) : (
                        siteNames.length > 1 && (
                            <Column spacing='none'>
                                <Tooltip
                                    position='right'
                                    title={siteNames.slice(1, siteNames.length).join(', ')}
                                >
                                    <Text color='secondary' tag={'span'}>
                                        {' '}
                                        and {siteNamesSet.size - 1} more
                                    </Text>
                                </Tooltip>
                            </Column>
                        )
                    )}
                    <Column spacing='none'>
                        <Text> {primaryCountry ?? ''} </Text>
                        {!primaryCountry && countriesSet.size === 1 && (
                            <Text color='secondary' tag={'span'}>
                                {' '}
                                {countries}
                            </Text>
                        )}
                        {!primaryCountry && countriesSet.size > 1 ? (
                            <Column spacing='none'>
                                <Text tag={'span'}> {countries[0]}</Text>
                                <Tooltip
                                    position='right'
                                    title={countries.slice(1, countries.length).join(', ')}
                                >
                                    <Text color='secondary' tag={'span'}>
                                        {' '}
                                        and {countriesSet.size - 1} more
                                    </Text>
                                </Tooltip>
                            </Column>
                        ) : (
                            primaryCountry &&
                            countriesSet.size > 1 && (
                                <Tooltip position='right' title={countries.join(', ')}>
                                    <Text color='secondary' tag={'span'}>
                                        {' '}
                                        and {countriesSet.size} more
                                    </Text>
                                </Tooltip>
                            )
                        )}
                    </Column>
                    {'incidentLocation' in caseHeader ? (
                        <Column spacing='none'>
                            {hasNoPrimaryandSecondaryFacility() &&
                                caseHeader.incidentLocation &&
                                caseHeader.incidentLocation.state != '' && (
                                    <Text breakWord={true}> {caseHeader.incidentLocation.state} </Text>
                                )}
                            {hasNoPrimaryandSecondaryFacility() &&
                                caseHeader.incidentLocation &&
                                caseHeader.incidentLocation.country &&
                                caseHeader.incidentLocation.country != '' && (
                                    <Text> {caseHeader.incidentLocation.country} </Text>
                                )}
                            {(!caseHeader.incidentLocation ||
                                caseHeader.incidentLocation.state == '' ||
                                caseHeader.incidentLocation.country == '') &&
                                !caseHeader.primaryFacility &&
                                siteNames.length === 0 && <Text color={'secondary'}> {'No address'} </Text>}
                        </Column>
                    ) : (
                        <Column spacing='none'>
                            {hasNoPrimaryandSecondaryLocation() &&
                                caseHeader.location.siteName &&
                                caseHeader.location.siteName != '' && (
                                    <Text breakWord={true}> {caseHeader.location.siteName} </Text>
                                )}
                            {hasNoPrimaryandSecondaryLocation() &&
                                caseHeader.location.country &&
                                caseHeader.location.country != '' && (
                                    <Text> {caseHeader.location.country} </Text>
                                )}
                            {(!caseHeader.location.siteName || caseHeader.location.siteName == '') &&
                                (!caseHeader.location.country || caseHeader.location.country == '') &&
                                !caseHeader.primaryLocation &&
                                siteNames.length === 0 && <Text color={'secondary'}> {'No address'} </Text>}
                        </Column>
                    )}
                </Column>
            );
            break;
        case Proximity.ONSITE:
            locationColumn = (
                <Column tag='span' spacing='none' width={isCaseDetails ? '143px' : '125px'}>
                    <Text type={isCaseDetails ? 'h100' : 'h200'}> Onsite </Text>
                    <Column spacing='none'>
                        {isCaseDetails ? (
                            <Row spacing='xsmall'>
                                {primarySiteIdAndCode.length > 0 && renderFacmanLink(primarySiteIdAndCode[0])}
                            </Row>
                        ) : (
                            <Text tag={'span'}>
                                {' '}
                                {hasNonEmptyPrimaryLocation() ? caseHeader.primaryLocation[0].siteName : ''}
                            </Text>
                        )}
                        {siteNames.length > 0 && (
                            <Tooltip position='right' title={siteNames.join(', ')}>
                                <Text color='secondary' tag={'span'}>
                                    {' '}
                                    and {siteNamesSet.size} more
                                </Text>
                            </Tooltip>
                        )}
                    </Column>
                    <Column spacing='none'>
                        <Text> {primaryCountry ?? ''} </Text>
                        {countriesSet.size > 1 && (
                            <Tooltip position='right' title={countries.join(', ')}>
                                <Text color='secondary' tag={'span'}>
                                    {' '}
                                    and {countriesSet.size} more
                                </Text>
                            </Tooltip>
                        )}
                    </Column>
                </Column>
            );
            break;
    }
    return isCaseDetails ? (
        <Column spacing='xsmall'>
            <Text type='h100'>Location</Text>
            {locationColumn}
        </Column>
    ) : (
        <Row alignmentVertical='top' spacing='small'>
            <Icon tokens={geopinIconTokens} />
            {locationColumn}
        </Row>
    );
};

export default CaseSiteLocations;
