import moment from 'moment-timezone';
import { LevelOfConcern } from './CaseDefinitions';

export const WIDTHS_OF_CASES_HEADER_COLUMNS = ['12%', '18%', '20%', '12%', '22%', '16%'];
export const WIDTHS_OF_CONSULTATION_HEADER_COLUMNS = ['21.6%', '19%', '23.7%', '21.6%', '13.1%'];
export const WIDTHS_OF_DETAILS_CARD = ['80px', '148px', '20px', '143px', '25px', '360px'];
export const WIDTH_OF_CARD = '888px';
export const SHEET_WIDTH_NUMBER = 280;
export const SHEET_MARGINS_NUMBER = 32;
export const SHEET_WIDTH = SHEET_WIDTH_NUMBER + 'px';
export const WIDGET_WIDTH_NUMBER = 272;
export const WIDGET_WIDTH = WIDGET_WIDTH_NUMBER + 'px';

export const NAVBAR_WIDTH_NUMBER = 250;
export const NAVBAR_WIDTH = NAVBAR_WIDTH_NUMBER + 'px';
export const ADDITIONAL_TAGS = [
    'Associate Wellbeing',
    'Customer Service Related',
    'DV / IPV-Related',
    'Hate / Bias-Related',
    'Hazmat',
    'Media Attention',
    'Ops Disruption',
    'Possible inebriant',
    'Sexual in Nature',
    'Terrorism-Related',
    'Weapon Involved'
];
export const MODAL_WIDTH = '635px';
export const SMALL_MODAL_WIDTH = '368px';
export const FILTER_PAGE_WIDTH = '1136px';
export const NUM_HEADERS_PER_PAGE = 6;
export const SELECT_WIDTH = '277px';
export const MAX_SELECT_POPOVER_HEIGHT = 200;
export const locValues = Object.keys(LevelOfConcern).filter((element) => {
    return isNaN(Number(element));
});
export const WIDTHS_OF_ADVANCED_SEARCH_COLUMNS = [SELECT_WIDTH, '431px', SELECT_WIDTH];
export const WIDTHS_OF_GENERATE_REPORT_COLUMNS = [SELECT_WIDTH, '549px', SELECT_WIDTH];
export const allTimezones = moment.tz.names();
export const PHOTO_URL = 'https://internal-cdn.amazon.com/badgephotos.amazon.com/?employeeid=';
export const SIM_LINK = 'https://t.corp.amazon.com/create/templates/510e52a6-640f-448e-8dbf-7ade4e8ad47b';
export const ADMIN_DIRECTORY_LINK = 'https://admindirectory.corp.amazon.com/users/';
export const CHIME_LINK = 'https://app.chime.aws/conversations/new?email=';
export const FACMAN_LINK = 'https://facman.guardian.amazon.dev/dashboard/facilityPage#';
export const QUERY_PARAM_CONTENT_ID = 'contentSectionId=';
export const QUERY_PARAM_SITE_ID = '&siteId=';
export const WIM_SIM_TEMPLATE =
    'https://sim.amazon.com/issues/create?template=f361c181-86d1-41f9-b71b-5101138d673e';

const WIDTH_OF_CLOSED_SIDEBAR = 64;
const BREAKPOINT2 = 1242;
const BREAKPOINT = BREAKPOINT2 - WIDGET_WIDTH_NUMBER;

export enum ResponsiveVariables {
    CLOSE_ON_OPEN,
    STACK,
    DO_NOTHING
}

export const responsiveVariables = {
    withNoneOpen: {
        default: true,
        [WIDTH_OF_CLOSED_SIDEBAR + BREAKPOINT2 + 'px']: false
    },
    withNavBarOpen: {
        default: true,
        [NAVBAR_WIDTH_NUMBER + BREAKPOINT2 + 'px']: false
    },
    withSheetOpen: {
        default: true,
        [SHEET_WIDTH_NUMBER + WIDTH_OF_CLOSED_SIDEBAR + BREAKPOINT2 + 'px']: false
    },
    withBothOpen: {
        default: ResponsiveVariables.CLOSE_ON_OPEN,
        [SHEET_WIDTH_NUMBER +
        SHEET_MARGINS_NUMBER +
        NAVBAR_WIDTH_NUMBER +
        BREAKPOINT +
        'px']: ResponsiveVariables.STACK,
        [SHEET_WIDTH_NUMBER +
        SHEET_MARGINS_NUMBER +
        NAVBAR_WIDTH_NUMBER +
        BREAKPOINT2 +
        'px']: ResponsiveVariables.DO_NOTHING
    }
};

export const CHARACTER_LIMIT = {
    EVENT_SUMMARY_LIMIT: 10000,
    CONSULTATION_SUMMARY_LIMIT: 10000,
    MANUAL_UPDATE_LIMIT: 2000,
    // 5MB because maximum return size of a lambda is 6 MB
    FILE_SIZE: 5242880,
    ZIPCODE_LIMIT: 15,
    NORMAL_TEXT_LIMIT: 255,
    NOTE_TEXT_LIMIT: 1000,
    PERSON_INVOLVED_LIMIT: 45
};

export const CASE_STRINGS = {
    SUMMARY: 'Event',
    EVENT_SUMMARY: 'Narrative'
};

export const MAX_FILE_SIZE_MB = CHARACTER_LIMIT.FILE_SIZE / 1048576;

export const TAB_NAMES = {
    ALL_CASES: 'All cases',
    DASHBOARD: 'Dashboard',
    MY_CASES: 'My cases',
    NEW_CASES: 'New cases',
    UPDATED_CASES: 'Updated cases',
    CONSULTATIONS: 'Engagements',
    METRICS: 'Metrics'
};

export const USER_ROLES = {
    ADMIN: 'Admin',
    SYSTEM_ADMIN: 'System Admin',
    STANDARD_USER: 'Standard User'
};
