import Column from '@amzn/meridian/column';
import DateRangePicker from '@amzn/meridian/date-range-picker';
import Row from '@amzn/meridian/row';
import Text from '@amzn/meridian/text';
import { Interval, DateTime } from 'luxon';
import React from 'react';
import { AdvancedFilter } from '../../../lib/FilterDefinitons';

type LocationInputProps = {
    filter: AdvancedFilter;
    missingDate: boolean;
    hasTitle?: boolean;
    setDates: (dateTo: string[2]) => void;
};

const DateRow: React.FunctionComponent<LocationInputProps> = ({
    filter,
    setDates,
    missingDate,
    hasTitle = false
}: LocationInputProps) => {
    let diffDays;
    const MAX_DAYS = 120;
    if (filter.dateFrom && filter.dateTo) {
        const date1 = DateTime.fromISO(`${filter.dateFrom}T00:00`);
        const date2 = DateTime.fromISO(`${filter.dateTo}T00:00`);
        const diff = Interval.fromDateTimes(date1, date2);
        diffDays = diff.length('days');
    } else {
        diffDays = undefined;
    }
    const diffDateError = (diffDays != undefined && diffDays > MAX_DAYS) || undefined;
    const missingDateOrUndefined = missingDate || undefined;

    return (
        <Row>
            <Column>
                {hasTitle && <Text type='h100'> Date range </Text>}
                <DateRangePicker
                    value={[filter.dateFrom, filter.dateTo]}
                    width={450}
                    onChange={setDates}
                    startLabel='Date of event from * '
                    endLabel='Date of event to * '
                    monthsInView={2}
                    endError={diffDateError || missingDateOrUndefined}
                    startError={diffDateError || missingDateOrUndefined}
                    errorMessage={
                        (diffDateError && `Date range must be less than or equal to ${MAX_DAYS} days`) ||
                        (missingDateOrUndefined && `Dates must be included in search`)
                    }
                />
            </Column>
        </Row>
    );
};

export default DateRow;
