import Button from '@amzn/meridian/button';
import Column from '@amzn/meridian/column';
import Icon from '@amzn/meridian/icon';
import Row from '@amzn/meridian/row';
import Text from '@amzn/meridian/text';
import React, { Fragment } from 'react';
import { bookmarkIconTokens } from '../../lib/customIcons';

type BookmarkProps = {
    isBookmarked: boolean;
    setBookmarked: (newBookmarked: boolean) => void;
    isDisabled: boolean;
};

const Bookmark: React.FunctionComponent<BookmarkProps> = ({
    isBookmarked,
    setBookmarked,
    isDisabled
}: BookmarkProps) => {
    const IsBookmarkedFragment = () => (
        <Fragment>
            <Row alignmentVertical='top'>
                <Column>
                    <Icon tokens={bookmarkIconTokens} />
                </Column>
                <Column spacing='none'>
                    <Text> Case bookmarked </Text>
                    <Button
                        disabled={isDisabled}
                        onClick={() => setBookmarked(false)}
                        type='tertiary'
                        size='small'
                    >
                        Remove
                    </Button>
                </Column>
            </Row>
        </Fragment>
    );

    const AddBookmarkButton = () => (
        <Button type='tertiary' disabled={isDisabled} onClick={() => setBookmarked(true)}>
            <Icon tokens={bookmarkIconTokens} />
            <Text> Bookmark case </Text>
        </Button>
    );

    return <Column spacing='none'>{isBookmarked ? <IsBookmarkedFragment /> : <AddBookmarkButton />}</Column>;
};

export default Bookmark;
