import Column from '@amzn/meridian/column';
import DatePicker from '@amzn/meridian/date-picker';
import InputGroup from '@amzn/meridian/input-group';
import Row from '@amzn/meridian/row';
import Select, { SelectOption } from '@amzn/meridian/select';
import Tag from '@amzn/meridian/tag';
import Text from '@amzn/meridian/text';
import TimePicker from '@amzn/meridian/time-picker';
import React, { useEffect } from 'react';
import { EditCaseVars } from '../../../lib/CaseDefinitions';
import { ADDITIONAL_TAGS, SELECT_WIDTH } from '../../../lib/constants';
import { getEventItems, sortedEventTypes } from '../../../lib/EmtCTIDefinitions';
import RouterPrompt from '../../Alert/RouterPrompt';
import { FormSelect } from '../../Inputs/FormFields';
import ProximityInput from '../../Inputs/ProximityInput';
import TimeZoneInput from '../../Inputs/TimeZoneInput';

type NewCaseEventInformationProps = {
    createCaseVars: EditCaseVars;
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    setCreateCaseVars: (vars: any) => void;
};

const NewCaseEventInformation: React.FunctionComponent<NewCaseEventInformationProps> = ({
    createCaseVars,
    setCreateCaseVars
}: NewCaseEventInformationProps) => {
    useEffect(() => {
        if (!createCaseVars.dateOfEvent) {
            setCreateCaseVars({
                ...createCaseVars,
                timeOfEvent: ''
            });
        }
    }, [createCaseVars.dateOfEvent]);

    return (
        <Column>
            <Text type='h300'> Event information </Text>
            <Row alignmentVertical={'top'}>
                <Column>
                    <Row alignmentVertical={'top'}>
                        <Select value='internal' label='Category' width={SELECT_WIDTH} disabled>
                            <SelectOption value='internal' label='MARS Investigation' />
                        </Select>
                        <FormSelect
                            value={createCaseVars.eventType}
                            onChange={(eventType) =>
                                setCreateCaseVars({
                                    ...createCaseVars,
                                    eventType,
                                    eventItem: '',
                                    missingFields: createCaseVars.missingFields.filter(
                                        (field) => field !== 'eventType'
                                    ),
                                    isDirty: true
                                })
                            }
                            label='Type *'
                            width={SELECT_WIDTH}
                            missing={createCaseVars.missingFields.includes('eventType')}
                            isBelow
                        >
                            {sortedEventTypes().map((option) => {
                                return <SelectOption value={option} label={option} key={option} />;
                            })}
                        </FormSelect>
                        {createCaseVars.eventType != '' &&
                            getEventItems(createCaseVars.eventType).length > 0 && (
                                <FormSelect
                                    value={createCaseVars.eventItem}
                                    onChange={(eventItem) =>
                                        setCreateCaseVars({
                                            ...createCaseVars,
                                            eventItem,
                                            missingFields: createCaseVars.missingFields.filter(
                                                (field) => field !== 'eventItem'
                                            ),
                                            isDirty: true
                                        })
                                    }
                                    label='Item *'
                                    width={SELECT_WIDTH}
                                    missing={createCaseVars.missingFields.includes('eventItem')}
                                    isBelow
                                >
                                    {getEventItems(createCaseVars.eventType).map((option) => {
                                        return <SelectOption value={option} label={option} key={option} />;
                                    })}
                                </FormSelect>
                            )}
                    </Row>
                    <Column alignmentVertical={'top'} width={SELECT_WIDTH}>
                        <FormSelect
                            value={createCaseVars.additionalTags}
                            onChange={(additionalTags) =>
                                setCreateCaseVars({
                                    ...createCaseVars,
                                    additionalTags,
                                    isDirty: true
                                })
                            }
                            label='Tags'
                            width={SELECT_WIDTH}
                            missing={false}
                            isBelow
                        >
                            {ADDITIONAL_TAGS.sort().map((tag) => {
                                return <SelectOption value={tag} label={tag} key={tag} />;
                            })}
                        </FormSelect>
                        {Array.isArray(createCaseVars.additionalTags) &&
                            createCaseVars.additionalTags.length > 1 && (
                                <Row wrap='down'>
                                    {createCaseVars.additionalTags.map((tag) => {
                                        return (
                                            <Tag
                                                key={tag}
                                                type='neutral'
                                                /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                                                // @ts-ignore
                                                onClose={() =>
                                                    setCreateCaseVars({
                                                        ...createCaseVars,
                                                        additionalTags: createCaseVars.additionalTags.filter(
                                                            (item) => item != tag
                                                        ),
                                                        isDirty: true
                                                    })
                                                }
                                            >
                                                {tag}
                                            </Tag>
                                        );
                                    })}
                                </Row>
                            )}
                    </Column>
                    <Column width={SELECT_WIDTH}>
                        <DatePicker
                            label='Date of event *'
                            value={createCaseVars.dateOfEvent}
                            onChange={(dateOfEvent) =>
                                setCreateCaseVars({
                                    ...createCaseVars,
                                    dateOfEvent,
                                    missingFields: createCaseVars.missingFields.filter(
                                        (field) => field !== 'dateOfEvent'
                                    ),
                                    isDirty: true
                                })
                            }
                            error={createCaseVars.missingFields.includes('dateOfEvent')}
                        />
                    </Column>
                </Column>
            </Row>
            {createCaseVars.dateOfEvent && createCaseVars.dateOfEvent != '' && (
                <InputGroup width={`${2 * parseInt(SELECT_WIDTH.slice(0, -2)) + 16}px`}>
                    <TimePicker
                        label='Time of event'
                        value={createCaseVars.timeOfEvent}
                        locale='en-GB'
                        onChange={(timeOfEvent) =>
                            setCreateCaseVars({
                                ...createCaseVars,
                                timeOfEvent,
                                isDirty: true
                            })
                        }
                    />
                    <TimeZoneInput
                        onChange={(timezone) =>
                            setCreateCaseVars({ ...createCaseVars, timezone, isDirty: true })
                        }
                        value={createCaseVars.timezone}
                    />
                </InputGroup>
            )}
            <ProximityInput
                missingFields={createCaseVars.missingFields}
                proximityOnChange={() =>
                    setCreateCaseVars({
                        ...createCaseVars,
                        missingFields: createCaseVars.missingFields.filter((field) => field !== 'proximity'),
                        isDirty: true
                    })
                }
                siteIdOnChange={() =>
                    setCreateCaseVars({
                        ...createCaseVars,
                        missingFields: createCaseVars.missingFields.filter((field) => field !== 'siteId'),
                        isDirty: true
                    })
                }
            />
            {createCaseVars.isDirty && <RouterPrompt when={createCaseVars.isDirty}></RouterPrompt>}
        </Column>
    );
};

export default NewCaseEventInformation;
